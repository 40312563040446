import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Error, Home } from 'Components/Icons';
import { NoResults } from 'Components/Loading';
import ScrollUtils from 'Utils/ScrollUtils';
import EventList from 'Components/Event/EventList';
import EventDetail from './EventDetail';
import { autorun } from 'mobx';
import { Constants } from 'Data/';
import LoadingStore from 'Stores/UI/LoadingStore';
import CampusDetail from 'Components/Campus/CampusDetail';
import Profile from 'WebApp/Views/Logged/Membership/Profile';

const THRESHOLD = 2 / 3;

@inject('dashboardStore', 'uiStore', 'eventBus')
@observer
class EventGrid extends React.Component {
    lastLoadedIndex = 0;
    localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);
    listenerRemoved = false;

    eventListRef = null;
    eventListContentRef = null;

    state = {
        profileToggle: false,
        campusToggle: false,
        active: null,
    };

    componentDidMount() {
        const {
            eventBus,
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        dashboardStore.clearEvents();
        dashboardStore.loadSettings(loadingStore);
        this.loadEvents();
        autorun((reaction) => {
            const {
                dashboardStore: { events },
            } = this.props;

            if (events && events.data && events.data.length) {
                this.scrollUtils.scrollToRef();

                //Ensure list has been rendered
                setTimeout(() => {
                    this.eventListRef = document.getElementById('infinite-list');
                    if (this.eventListRef) {
                        this.eventListContentRef = this.eventListRef.childNodes[0];

                        this.eventListRef.addEventListener('scroll', this.handleScroll);
                        this.scrollListenerDisposer = eventBus.on(
                            Constants.EVENTS.ALL_EVENTS_LOADED,
                            this.removeListener
                        );
                        this.handleScroll();
                    }
                }, 0);
                if (events.data[0].eventType !== Constants.EVENT_TYPE.EMPTY)
                    this.openFirstEvent();

                reaction.dispose();
            }
        });
    }

    updateSelectedEvent = (
        updateSelectedEventId,
        updateSelectedEventType,
        updateSelectedEventPeriodStart
    ) => {
        this.setState({
            active: updateSelectedEventId.toLowerCase(),
            eventType: updateSelectedEventType,
            periodStart: updateSelectedEventPeriodStart,
        });
    };

    removeListener = () => {
        const {
            dashboardStore: { events },
        } = this.props;
        if (!this.listenerRemoved && events && events.data && events.data.length) {
            if (this.eventListRef)
                this.eventListRef.removeEventListener('scroll', this.handleScroll);
            this.listenerRemoved = true;
        }
    };

    componentWillUnmount() {
        const {
            dashboardStore: { events },
        } = this.props;
        if (events && events.data && events.data.length) {
            this.removeListener();
            this.scrollListenerDisposer();
        }
    }

    campusToggle = (campusId) => {
        this.setState((prevState) => ({
            campusToggle: !prevState.campusToggle,
            campusId,
        }));
    };

    profileToggle = (profileId) => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
            profileId,
            isMemberProfile: true,
        }));
    };

    membershipToggle = (profileId) => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
            profileId,
            isMemberProfile: false,
        }));
    };

    handleScroll = () => {
        if (!this.eventListRef || !this.eventListContentRef) return;

        const {
            dashboardStore: { events },
        } = this.props;
        const scrollPosition = this.eventListRef.scrollTop;
        const elementHeight = this.eventListContentRef.offsetHeight;

        //If the user has scrolled past the threshold and there is no ongoing loading of events
        if (
            scrollPosition > elementHeight * THRESHOLD &&
            !events.ongoing &&
            !events.errored
        ) {
            this.loadEvents(true);
        }
    };

    updateList = async (newStatus, updatedEvent = {}) => {
        const {
            dashboardStore: { events },
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        if (!newStatus) {
            await dashboardStore.loadSettings(loadingStore);
            await dashboardStore.loadMoreEvents({ page: 0 }, loadingStore);
            this.loadEvents();
        } else {
            if (newStatus === Constants.EVENT_STATUS.DELETED) {
                this.loadEvents();
                this.setState({
                    active: events.data[0].id.toLowerCase(),
                    periodStart: events.data[0].periodStart,
                    eventType: events.data[0].eventType,
                });
            } else {
                const event = events.data.find((e) => e.id === updatedEvent.id);
                events.data[events.data.indexOf(event)] = Object.assign({}, event, {
                    ...updatedEvent,
                });
            }

            this.scrollUtils.scrollToRef();
        }
    };

    openFirstEvent = () => {
        const {
            dashboardStore: { events },
            currentId,
            currentType,
            currentPeriodStart,
        } = this.props;
        this.setState({
            active: currentId || events.data[0].id.toLowerCase(),
            eventType: currentType || events.data[0].eventType,
            periodStart: currentPeriodStart || events.data[0].periodStart,
        });
    };

    loadEvents = (nextPage = false) => {
        const { dashboardStore, communityEvents } = this.props;
        if (!nextPage) this.lastLoadedIndex = 0;

        const loadingStore = nextPage
            ? this.localLoadingStore
            : this.props.uiStore.loadingStore;

        dashboardStore.loadMoreEvents(
            {
                page: this.lastLoadedIndex++,
                communityEvents,
            },
            loadingStore
        );
    };

    scrollUtils = new ScrollUtils();

    render() {
        const {
                t,
                dashboardStore: { events, eventList },
                match,
                communityEvents = false,
            } = this.props,
            {
                profileToggle,
                profileId,
                campusToggle,
                campusId,
                isMemberProfile,
                active,
                eventType,
                periodStart,
            } = this.state;
        return (
            <div className="col-12">
                <div className="row no-gutters position-relative" id="list-page">
                    {events && events.errored ? (
                        <div className="row  col-12" ref={this.scrollUtils.registerRef}>
                            <NoResults
                                className="m-auto py-3"
                                icon={<Error size="3.75em" />}
                                title={t('Error.error')}
                                subTitle={t('Error.error_message')}
                                action={{
                                    text: t('Error.retry'),
                                    onClick: this.loadEvents,
                                }}
                            />
                        </div>
                    ) : events && events.data && events.data.length && active ? (
                        <Fragment>
                            <div className="col-md-6 position-relative">
                                <div
                                    className="position-absolute"
                                    id="infinite-list"
                                    ref={this.scrollUtils.registerRef}
                                >
                                    <EventList
                                        events={eventList}
                                        currentId={active}
                                        currentPeriodStart={periodStart}
                                        communityEvents={communityEvents}
                                        ref={this.scrollUtils.registerRef}
                                        onClick={this.updateSelectedEvent}
                                        moreEventsAreLoading={
                                            this.localLoadingStore.isLoading
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div id="event-details" className="pb-6">
                                    <EventDetail
                                        {...this.props}
                                        eventId={active}
                                        periodStart={periodStart}
                                        eventType={eventType}
                                        parentMatchURL={match.url}
                                        onEventUpdate={this.updateList}
                                        campusToggle={this.campusToggle}
                                        membershipToggle={this.membershipToggle}
                                        memberToggle={this.profileToggle}
                                    />
                                </div>
                            </div>
                            {campusToggle && (
                                <CampusDetail
                                    {...this.props}
                                    onClose={this.campusToggle}
                                    campusId={campusId}
                                    isOpen={campusToggle}
                                />
                            )}
                            {profileToggle && (
                                <Profile
                                    {...this.props}
                                    onClose={this.profileToggle}
                                    membershipId={profileId}
                                    memberId={profileId}
                                    isMemberProfile={isMemberProfile}
                                    isOpen={profileToggle}
                                    modal
                                />
                            )}
                        </Fragment>
                    ) : (
                        <div className="col-12">
                            <NoResults
                                className="m-auto py-3"
                                icon={<Home size="3.75em" />}
                                title={t('Home.no_event')}
                                subTitle={t('Home.no_event_text')}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default translate()(EventGrid);
