import LoadingStore from './UI/LoadingStore';
import ToastStore from './UI/ToastStore';
import SimpleModalStore from './UI/SimpleModalStore';

class UIStore {
    constructor() {
        this.loadingStore = new LoadingStore();
        this.toastStore = new ToastStore();
        this.simpleModalStore = new SimpleModalStore();
    }
}

export default UIStore;
