import React from 'react';

const Privacy = (props) => (
    <svg
        className="icon animated-fill"
        viewBox="0 0 24 24"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <g
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
            style={props.cssFill ? { fill: props.cssFill } : undefined}
        >
            <path d="M11.823 19.578a.466.466 0 0 1-.466-.466v-3.218a.466.466 0 0 1 .932 0v3.218a.466.466 0 0 1-.466.466" />
            <path d="M11.857 24c4.057 0 7.357-3.301 7.357-7.357a7.28 7.28 0 0 0-1.455-4.393V5.965c0-.12 0-.243-.007-.37a5.894 5.894 0 0 0-.443-1.912A5.95 5.95 0 0 0 11.795 0a5.948 5.948 0 0 0-5.514 3.68 5.878 5.878 0 0 0-.444 1.914c-.007.126-.007.251-.007.371v6.46a7.292 7.292 0 0 0-1.33 4.218C4.5 20.7 7.8 24 11.857 24zm0-.93a6.434 6.434 0 0 1-6.426-6.427c0-1.377.43-2.689 1.24-3.796a6.45 6.45 0 0 1 5.186-2.632 6.388 6.388 0 0 1 5.07 2.478l.008.01a6.373 6.373 0 0 1 1.348 3.94 6.434 6.434 0 0 1-6.426 6.426zM6.76 5.964c0-.106 0-.21.005-.313.035-.56.16-1.102.375-1.613A5.022 5.022 0 0 1 11.795.93a5.02 5.02 0 0 1 4.653 3.11c.215.509.34 1.052.375 1.611.005.103.005.207.005.313v5.25L16.7 11.1a5.125 5.125 0 0 0-.373-.305 5.045 5.045 0 0 0-.276-.203 7.237 7.237 0 0 0-1.153-.654c-.051-.023-.103-.047-.154-.068a6.24 6.24 0 0 0-.225-.092c-.065-.025-.13-.05-.195-.072a3.882 3.882 0 0 0-.196-.066c-.057-.02-.115-.037-.175-.055a8.895 8.895 0 0 0-.425-.111l-.136-.03a5.413 5.413 0 0 0-.328-.062 4.83 4.83 0 0 0-.152-.023c-.063-.01-.126-.017-.19-.025l-.02-.002c-.063-.008-.126-.013-.191-.02l-.148-.012-.138-.006c-.052-.004-.104-.005-.155-.007a8.965 8.965 0 0 0-.431 0c-.07.002-.138.005-.207.01-.011 0-.021 0-.031.002a3.617 3.617 0 0 0-.19.015c-.043.003-.087.006-.128.011a7.122 7.122 0 0 0-.737.116l-.134.03a7.015 7.015 0 0 0-.693.196c-.063.023-.126.044-.19.068a7.329 7.329 0 0 0-.988.448c-.074.04-.145.081-.217.123a4.866 4.866 0 0 0-.271.168l-.12.078-.12.083a8.78 8.78 0 0 0-.511.395l-.161.14-.18.166V5.965z" />
        </g>
    </svg>
);

export default Privacy;
