import React from 'react';

const Facebook = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M84.4 144H58.19a2.79 2.79 0 0 1-2.79-2.8V71.38H31.94a2.79 2.79 0 0 1-2.79-2.8V49.3a2.79 2.79 0 0 1 2.79-2.8h21.49c0-13.59 1.82-19.91 2.15-20.94C62.29-.58 87.75-.07 89.82 0h22.24a2.8 2.8 0 0 1 2.79 2.8v20.1a2.79 2.79 0 0 1-2.79 2.79h-10.93c-10.24 0-13.88 6.18-13.88 12v8.81h23.48a2.8 2.8 0 0 1 2.8 2.8v19.35a2.8 2.8 0 0 1-2.8 2.8H87.2v69.75a2.8 2.8 0 0 1-2.8 2.8zM61 138.41h20.61V68.65a2.79 2.79 0 0 1 2.79-2.79h23.53V52.1H84.45a2.81 2.81 0 0 1-2.8-2.8V37.66c0-8.74 6-17.56 19.48-17.56h8.13V5.61H89.57C88.65 5.54 66.74 4.4 61 27c0 .08 0 .16-.07.24s-2.16 6.54-1.85 22a2.8 2.8 0 0 1-2.8 2.86H34.74v13.69h23.45A2.79 2.79 0 0 1 61 68.58z"
        />
    </svg>
);

export default Facebook;
