import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { When } from 'react-if';

import { InvoiceDetailHeader, InvoiceSummary } from './';
import { Constants } from 'Data/';

const InvoiceDetailPropTypes = {
    invoice: PropTypes.object,
    tryPayment: PropTypes.func,
    retryPayment: PropTypes.func,
    parentMatchURL: PropTypes.string,
    paneDetail: PropTypes.bool,
    membership: PropTypes.object,
};

class InvoiceDetail extends PureComponent {
    static propTypes = InvoiceDetailPropTypes;

    render() {
        const {
            invoice,
            tryPayment,
            retryPayment,
            parentMatchURL,
            membership,
            t,
            paneDetail,
        } = this.props;
        
        return invoice ? (
            <Fragment>
                <InvoiceDetailHeader
                    invoice={invoice}
                    paneDetail={paneDetail}
                    parentMatchURL={parentMatchURL}
                    holderPaymentMethod={membership && membership.paymentMethod}
                />
                {invoice.status === Constants.INVOICE_STATUSES.FAILED &&
                    invoice.reasonMessage && (
                        <div className="bg-danger text-white text-center text-sm py-1">
                            {invoice.reasonMessage}
                        </div>
                    )}
                <div
                    className={`bg-white d-flex flex-column ${
                        !paneDetail ? 'pt-3 px-2 px-md-20' : ''
                    }`}
                >
                    <InvoiceSummary
                        invoiceSummary={invoice}
                        hideSecDepInfo
                        paneDetail={paneDetail}
                        membershipRights={membership && membership.rights}
                    />
                    {/*#TODO ajuster lorsque le payment method personal sera disponible*/}
                    <When
                        condition={
                            invoice.paymentCustomer.type ===
                            Constants.HOLDER_TYPE.MEMBERSHIP
                        }
                    >
                        {invoice.isCheckPayable &&
                            invoice.status !== Constants.INVOICE_STATUSES.SUCCESS && (
                                <div className="text-center mt-6 text-warm-grey text-sm mb-6">
                                    <p>{t('Invoice.invoice_remit_check_to')}</p>
                                    <p className="font-italic">
                                        {t('Invoice.invoice_check_address')}
                                    </p>
                                    <p>{t('Invoice.invoice_or_pay_through_app')}</p>
                                </div>
                            )}

                        {invoice.status === Constants.INVOICE_STATUSES.FAILED && (
                            <div className={`mt-3 mb-6 ${paneDetail ? 'mx-3' : 'mx-10'}`}>
                                <button
                                    className="btn btn-primary w-100"
                                    onClick={retryPayment}
                                >
                                    {t('Invoice.payment_fail_retry')}
                                </button>
                            </div>
                        )}
                        {invoice.status ===
                            Constants.INVOICE_STATUSES.AWAITING_PAYMENT && 
                            membership && membership.paymentMethod && (
                                <div className={`mt-3 mb-6 ${paneDetail ? 'mx-3' : 'mx-10'}`}>
                                    <button
                                        className="btn btn-primary w-100"
                                        onClick={tryPayment}
                                    >
                                        {t('Invoice.pay_invoice_payment_method', {
                                            param1:
                                                membership.paymentMethod
                                                    ?.paymentMethodType === 'CreditCard'
                                                    ? 'credit card'
                                                    : 'debit',
                                        })}
                                    </button>
                                </div>
                            )}
                    </When>
                </div>
            </Fragment>
        ) : null;
    }
}

export { InvoiceDetailPropTypes };

export default translate()(InvoiceDetail);
