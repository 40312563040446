import React from 'react';

const Arrow = ({ className, size, fill }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M18.729 11.232L6.722.293a1.016 1.016 0 0 0-1.427 0 1.003 1.003 0 0 0 0 1.42l11.283 10.28-11.283 10.29a1.002 1.002 0 0 0 0 1.42c.395.39 1.033.39 1.427 0l12.007-10.94c.21-.21.3-.49.286-.77a.995.995 0 0 0-.286-.76"
        />
    </svg>
);

export default Arrow;
