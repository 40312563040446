import { observable, action } from 'mobx';

const ERROR_CODES = {
    // Login
    E001001: 'Account.invalid_login',
    E001002: 'Account.email_not_confirmed',
    E001003: 'Account.already_used_email',

    // Subscription
    E008013: 'Membership.error_has_active_subscriptions',

    // Payment method
    E013001: 'PaymentMethod.no_payment_method',
    E013003: 'PaymentMethod.consent_version_outdated',

    // Payment gateway
    E014006: 'PaymentMethod.payment_method_verification_failed',
    E014007: 'PaymentMethod.not_verified',

    // Invoice
    E018004: 'Invoice.invoice_check_message',
    E018005: 'Membership.error_customer_has_open_invoices',

    // Invitation
    E004006: 'Error.error_message',
};

// TODO: Find a way to automate the registered keys array...
export default class ModelStateStore {
    @observable errors = {};
    @observable errorCodes = [];

    constructor(...fields) {
        this.registeredFields = fields;
    }

    @action setModelState(modelState, t) {
        modelState = Object.assign({}, modelState);
        const errors = {};
        const errorCodes = modelState.errorCode || [];

        if (modelState.errorCode) {
            const codeTranslation = ERROR_CODES[modelState.errorCode];
            const message = t(codeTranslation || 'Error.error_message');
            modelState.errorCode = message;
        }

        delete modelState.modelRequest;

        for (var entry of Object.entries(modelState)) {
            const key = entry[0].toLowerCase();
            const value = entry[1];
            const registeredField = this.registeredFields.find(
                (field) => (field.from || field).toLowerCase() === key
            );
            const errorsKey = registeredField
                ? registeredField.to || registeredField
                : 'other';
            errors[errorsKey] = (errors[errorsKey] || []).concat(value);
        }

        this.errors = errors;
        this.errorCodes = errorCodes;
    }

    @action setErrorCode(errorCode, t) {
        const codeTranslation = ERROR_CODES[errorCode];
        const message = t(codeTranslation || 'Error.error_message');
        this.errors = { other: [message] };
        this.errorCodes = [errorCode];
    }

    @action setError(error, t) {
        this.errors = { other: [error || t('Error.error_message')] };
        this.errorCodes = [];
    }

    @action setResponse(response, t) {
        if (response && response.data && response.data.modelState)
            this.setModelState(response.data.modelState, t);
        else if (response && response.data && response.data.error)
            this.setErrorCode(response.data.error, t);
        else if (response && response.data && response.data.error_description)
            this.setError(response.data.error_description, t);
        else this.setError(response && response.message, t);
    }

    @action setStripeResponse(response) {
        const errors = {};
        const key = response.code;
        const value = response.message;
        const registeredField = this.registeredFields.find(
            (field) => (field.from || field).toLowerCase() === key
        );
        const errorsKey = registeredField
            ? registeredField.to || registeredField
            : 'other';
        errors[errorsKey] = (errors[errorsKey] || []).concat(value);

        this.errors = errors;
    }
}
