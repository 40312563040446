import React from 'react';

const Home = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M132.92 33.23V0H11.08v59.09a22 22 0 0 0 5.8 14.76h-.26V144h112.61V71.4a21.75 21.75 0 0 0 3.69-12.31zm-21.86 42.46h-.39a16.68 16.68 0 0 1-16.52-16.6V38.77h33.23v20.32a16.51 16.51 0 0 1-16.32 16.6zM55.38 38.77h33.24v20.32a16.52 16.52 0 0 1-16.33 16.6h-.39a16.68 16.68 0 0 1-16.52-16.6zm72-33.23v27.69H16.62V5.54zM16.62 38.77h33.23v20.32a16.52 16.52 0 0 1-16.33 16.6h-.39a16.67 16.67 0 0 1-16.51-16.6zm44.3 99.69v-36.92h24v36.92zm29.54 0V96H55.38v42.46H22.15V78.09a21.9 21.9 0 0 0 11 3.14h.39a22 22 0 0 0 19.19-11.3 22 22 0 0 0 19.17 11.3h.39a22 22 0 0 0 19.19-11.3 22 22 0 0 0 19.19 11.3h.39A21.93 21.93 0 0 0 123.69 77v61.43z"
        />
    </svg>
);

export default Home;
