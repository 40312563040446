import React from 'react';

const LinkedIn = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M141.19 143.18h-27a2.81 2.81 0 0 1-2.81-2.81V86.92c0-11.1-5.25-17.73-14-17.73-6.75 0-12.63 4.71-14.42 9.26a18.45 18.45 0 0 0-.78 6.34v55.58a2.8 2.8 0 0 1-2.8 2.81H51.81a2.81 2.81 0 0 1-2.81-2.81V69.08c0-9.51 0-17.72-.29-24.74a2.82 2.82 0 0 1 .76-2.05 2.85 2.85 0 0 1 2-.89l25.08-.48a2.8 2.8 0 0 1 2.85 2.54l.75 7.81C85 46.16 93.8 39.75 107.78 39.75c22.68 0 36.22 16.33 36.22 43.68v56.94a2.81 2.81 0 0 1-2.81 2.81zM117 137.56h21.41V83.43c0-24.19-11.16-38.06-30.61-38.06-16.81 0-24.81 10.7-26.86 14a2.83 2.83 0 0 1-2.38 1.32H78a2.81 2.81 0 0 1-2.8-2.54l-1.15-11.56-19.62.41c.21 6.48.2 13.81.19 22.12v68.48h21.9V84.79a23 23 0 0 1 1.17-8.41c2.43-6.2 10.15-12.8 19.64-12.8 11.94 0 19.66 9.16 19.66 23.34zm-85.89 5.13H3.58a2.8 2.8 0 0 1-2.8-2.81V43.73a2.8 2.8 0 0 1 2.8-2.81h27.51a2.81 2.81 0 0 1 2.81 2.81v96.15a2.81 2.81 0 0 1-2.81 2.81zm-24.7-5.61h21.87V46.54H6.39zm11.7-101.21C7.62 35.87 0 28.51 0 18.35S7.78.82 18.49.82C29 .82 36.55 8 36.79 18.29c0 10.22-7.85 17.58-18.68 17.58zm.38-29.43c-7.58 0-12.88 4.9-12.88 11.91s5.14 11.91 12.5 11.91c7.82 0 13.07-4.78 13.07-11.91C31 11.14 26 6.44 18.49 6.44z"
        />
    </svg>
);

export default LinkedIn;
