import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody } from 'reactstrap';
import { ListItem } from 'Components/Forms';
import { AngleRight, Memberships, X } from 'Components/Icons';

export default class CommunalAreaModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        subscriptions: PropTypes.array.isRequired,
    };

    static defaultProps = {
        isOpen: false,
        onClose: () => {
            return null;
        },
        title: '',
        subscriptions: [],
    };

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.onClose}
                className="modal-dialog-centered modal-full-screen adaptive CommunalAreaModal"
            >
                <div className="modal-header d-flex align-items-center p-1 position-relative">
                    <h3 className="text-center w-100">{this.props.title}</h3>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.props.onClose}
                    >
                        <span aria-hidden="true">
                            <X fill="dark" size="24px" />
                        </span>
                    </button>
                </div>

                <ModalBody className="d-flex flex-column bg-light-grey mh-0 pt-1">
                    <div className="scrollable d-flex flex-column">
                        {this.props.subscriptions
                            .sort((a, b) =>
                                a.membership.name.localeCompare(b.membership.name)
                            )
                            .map((subscription, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() =>
                                        this.props.communalAreaClick(subscription)
                                    }
                                    clickable
                                    title={subscription.membership.name}
                                    withImage
                                    imgSrc={subscription.membership.imageUrl}
                                    defaultImg={
                                        <div className="img">
                                            <Memberships size="50%" fill="#ffffff" />
                                        </div>
                                    }
                                    action={
                                        <div className="text-primary list-group-item-status dropdown-indicator">
                                            <AngleRight size="24px" />
                                        </div>
                                    }
                                />
                            ))}
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
