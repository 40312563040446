import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import MembershipDashboard from 'WebApp/Views/Logged/Membership/MembershipDashboard';
import CommunityDashboard from 'WebApp/Views/Logged/Community/CommunityDashboard';
import SubscriptionModal from 'WebApp/Views/Logged/Subscription/SubscriptionModal';
import BookingModal from 'WebApp/Views/Logged/Booking/BookingModal';
import EventDashboard from 'WebApp/Views/Logged/Event/EventDashboard';
import CampusDashboard from 'WebApp/Views/Logged/Campus/CampusDashboard';
import NoMatch from 'WebApp/Views/NoMatch';

// TODO: Re-add this when the personal dashboard will be in the sprint scope
// import PersonalDashboard from 'WebApp/Views/Logged/Personal/PersonalDashboard';

class LoggedRouter extends React.Component {
    renderSubscription = (routeProps) => {
        const parts = routeProps.match.url.split('/');
        parts.pop();
        const parentUrl = parts.join('/');
        return <SubscriptionModal {...routeProps} parentMatchURL={parentUrl} />;
    };

    renderBookingModal = (routeProps) => {
        const parts = routeProps.match.url.split('/');
        parts.pop();
        const parentUrl = parts.join('/');
        return <BookingModal {...routeProps} parentMatchURL={parentUrl} />;
    };

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route path="/dashboard/events" component={EventDashboard} />
                    <Route path="/dashboard/company" component={MembershipDashboard} />
                    {/* TODO: Re-add this when the personal dashboard will be in the sprint scope */}
                    {/*<Route path="/dashboard/personal" component={PersonalDashboard} />*/}
                    <Route path="/dashboard/community" component={CommunityDashboard} />
                    <Route path="/dashboard/campus" component={CampusDashboard} />
                    <Route component={NoMatch} />
                </Switch>
                <Switch>
                    <Route
                        path="/dashboard/company/subscribe-to-a-plan"
                        render={this.renderSubscription}
                    />
                    <Route
                        path="/dashboard/company/buy-a-day-pass"
                        render={this.renderSubscription}
                    />
                    <Route
                        path="/dashboard/company/book-a-room"
                        render={this.renderBookingModal}
                    />
                    {/* TODO: Re-add this when the personal dashboard will be in the sprint scope */}
                    {/*<Route path="/dashboard/personal/subscribe-to-a-plan" render={this.renderSubscription} />*/}
                    <Route
                        path="/dashboard/company/detail/:id/subscribe-to-a-plan"
                        render={this.renderSubscription}
                    />
                </Switch>
            </Fragment>
        );
    }
}

export default LoggedRouter;
