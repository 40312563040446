import React from 'react';

const SvgScreenSharingDark = ({ fill, size, className }) => (
    <svg
        viewBox="0 0 24 24"
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M16.679 15.786c0 .634-.513 1.147-1.147 1.147H1.772a1.147 1.147 0 0 1-1.147-1.147V8.072c0-.633.514-1.146 1.147-1.146h13.76c.634 0 1.147.513 1.147 1.146v7.714zM4.587 20.728h8.13v-.626h-8.13v.626zM15.553 6.3H1.793C.816 6.3 0 7.074 0 8.052v7.714a1.81 1.81 0 0 0 1.793 1.793h6.546v1.918H3.961v1.876h9.382v-1.876H8.965v-1.918h6.588c.977 0 1.752-.816 1.752-1.793V8.052A1.74 1.74 0 0 0 15.553 6.3z" />
            <path d="M2.45 15.075h12.378V8.768H2.45v6.307zm-.522.52h13.421V8.247H1.929v7.348z" />
            <path d="M6.7 2v4.767h.666v-4.1h15.968v9.945h-6.338v.686h2.673v.669h-2.673v.666h3.338v-1.356H24V2z" />
        </g>
    </svg>
);

export default SvgScreenSharingDark;
