import timezones from 'commonconnect-timezones';

const TimeZoneUtils = {
    getTimeZone(locations, locationId) {
        if (!locations) return '';
        const location = locations.find((loc) => loc.id === locationId);
        if (!location) return '';
        return TimeZoneUtils.getTimeZoneWithCampus(location);
    },
    getTimeZoneWithCampus(campus) {
        const timezone = timezones.find((zone) => zone.id === campus.timeZone);
        return timezone ? timezone.description : '';
    },
};

export default TimeZoneUtils;
