import React from 'react';

const CreditCard = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="-2 0 64 60"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M57.314 22.336H2.727v-8.822c0-1.043.848-1.89 1.89-1.89h50.807c1.042 0 1.89.847 1.89 1.89v8.822zm0 23.855a1.894 1.894 0 0 1-1.89 1.896H4.618a1.894 1.894 0 0 1-1.891-1.896V24.65h54.587v21.542zM55.424 9.31H4.618a4.21 4.21 0 0 0-4.204 4.204v32.677A4.211 4.211 0 0 0 4.618 50.4h50.806a4.211 4.211 0 0 0 4.204-4.209V13.514a4.21 4.21 0 0 0-4.204-4.204z" />
            <path d="M31.984 35.046H9.509a1.156 1.156 0 0 1 0-2.313h22.475a1.157 1.157 0 0 1 0 2.313" />
        </g>
    </svg>
);

export default CreditCard;
