import React from 'react';

const Lightbulb24 = (props) => (
    <svg
        className={`icon ${props.className || ''}`}
        viewBox="0 0 24 24"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <g fill={props.fill || 'currentColor'} fillRule="evenodd">
            <path d="M9.539 17.526h4.612V15.82l.256-.144c1.708-.96 2.768-2.796 2.768-4.79 0-3.01-2.39-5.46-5.33-5.46s-5.332 2.45-5.332 5.46c0 1.994 1.061 3.83 2.77 4.79l.256.144v1.706zm5.619 1.007H8.532v-2.132c-1.874-1.173-3.026-3.259-3.026-5.515 0-3.566 2.844-6.467 6.339-6.467 3.494 0 6.337 2.901 6.337 6.467 0 2.257-1.151 4.342-3.024 5.515v2.132zM11.844 2.995a.503.503 0 0 1-.503-.503V.503a.503.503 0 1 1 1.007 0v1.989a.503.503 0 0 1-.504.503M17.99 5.72a.502.502 0 0 1-.36-.856l1.376-1.406a.503.503 0 1 1 .72.704l-1.375 1.406a.503.503 0 0 1-.36.152M22.397 12.09h-1.944a.503.503 0 1 1 0-1.007h1.944a.503.503 0 1 1 0 1.007M2.948 11.795H1.003a.503.503 0 1 1 0-1.006h1.945a.503.503 0 1 1 0 1.006M5.613 5.512a.503.503 0 0 1-.36-.151L3.878 3.955a.503.503 0 0 1 .72-.705l1.375 1.406a.504.504 0 0 1-.36.856M9.64 20.992v.963c0 .456.37.827.827.827h2.954a.828.828 0 0 0 .827-.827v-.963H9.639zm3.78 2.797h-2.953a1.836 1.836 0 0 1-1.835-1.834v-1.97h6.623v1.97a1.836 1.836 0 0 1-1.834 1.834z" />
        </g>
    </svg>
);

export default Lightbulb24;
