import React from 'react';

const AngleDown = ({ fill = 'currentColor', size = '1em', className }) => (
    <svg
        className={'icon ' + className}
        viewBox="0 0 998 582"
        width={size}
        height={size}
        fill={fill}
    >
        <path d="M998 83q0 13-10 23L522 572q-10 10-23 10t-23-10L10 106Q0 96 0 83t10-23l50-50Q70 0 83 0t23 10l393 393L892 10q10-10 23-10t23 10l50 50q10 10 10 23z" />
    </svg>
);

export default AngleDown;
