import React from 'react';

const Error = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="-2 -2 64 64"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M26.231 41.185h7.538l1.509-38.86H24.722zm8.655 2.325h-9.772a1.162 1.162 0 0 1-1.162-1.118L22.353 1.207A1.164 1.164 0 0 1 23.515 0h12.97a1.163 1.163 0 0 1 1.162 1.207l-1.6 41.185a1.162 1.162 0 0 1-1.16 1.118zM30 47.103a5.292 5.292 0 0 0-5.286 5.287A5.292 5.292 0 0 0 30 57.676a5.292 5.292 0 0 0 5.286-5.286A5.292 5.292 0 0 0 30 47.103M30 60c-4.196 0-7.61-3.414-7.61-7.61 0-4.197 3.414-7.61 7.61-7.61s7.61 3.413 7.61 7.61c0 4.196-3.414 7.61-7.61 7.61"
        />
    </svg>
);

export default Error;
