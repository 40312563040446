import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react/index';
import ModelStateStore from 'Stores/ModelStateStore';
import { Consent, Errors, FormGroup } from 'Components/Forms';
import ActionBase from './ActionBase';
import qs from 'qs';

import HttpService from 'Services/HttpService';
import { Member } from 'Components/Icons';

async function handleResetPasswordActionError(error) {
    if (error.response) {
        if (error.response.status === 404) {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        } else if (error.response.status) {
            this.setState({
                resultMessageKey: 'Error.error_message',
                loginTextKey: 'Account.login',
                loginPath: '/',
            });
            return true;
        }
    }
    return false;
}

@inject('uiStore')
class ResetPassword extends ActionBase {
    constructor(props) {
        super(props);
        const parts = this.props.match.url.split('/');
        this.actionCall = async () => {
            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            this.setState({
                component: (
                    <ResetPasswordForm
                        email={params.email}
                        token={params.token}
                        {...this.props}
                        setPassword={parts.includes('set-password')}
                    />
                ),
            });
        };

        this.handleError = handleResetPasswordActionError.bind(this);
    }
}

@inject('uiStore')
@observer
class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.modelStateStore = new ModelStateStore(
            { to: 'password', from: 'requestResetPassword.Password' },
            { to: 'confirmPassword', from: 'requestResetPassword.Other' },
            { to: 'confirmPassword', from: 'requestResetPassword.ConfirmPassword' }
        );

        this.state = {
            password: '',
            confirmPassword: '',
            consentChecked: false,
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'consentChecked') {
            this.setState({ consentChecked: !this.state.consentChecked });
        } else {
            this.setState({ [name]: value });
        }
    };

    resetPassword = async (e) => {
        e.preventDefault();

        const { confirmPassword, password } = this.state;

        const {
                uiStore: { loadingStore, toastStore },
                t,
                email,
                token,
                history,
            } = this.props,
            { consentChecked } = this.state;
        if (!consentChecked) {
            toastStore.enqueueErrorToast({
                message: t('Settings.consent_terms_and_policy_error'),
            });
            return;
        }
        const data = {
            email: email,
            token: token,
            password: password,
            confirmPassword: confirmPassword,
        };
        loadingStore.addLoading();
        try {
            await HttpService.resetPasswordByToken(data);
            toastStore.enqueueToast({
                message: t('Profile.password_has_changed'),
                icon: Member,
            });
            history.push(`/`);
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        }
        loadingStore.removeLoading();
    };

    render() {
        const { t, email, setPassword } = this.props,
            { consentChecked, password, confirmPassword } = this.state,
            { errors } = this.modelStateStore;

        return (
            <Fragment>
                <h3 className="text-center pt-2">
                    {setPassword
                        ? t('Account.set_password')
                        : t('Account.reset_password')}
                </h3>
                <form className="card-body" onSubmit={this.resetPassword}>
                    <div className="row form-group mb-0">
                        <div className="col-12">
                            <p>
                                <span className="text-xs font-weight-medium d-block">
                                    {t('Account.email')}
                                </span>
                                {email}
                            </p>
                        </div>
                        <FormGroup
                            id="password"
                            autoFocus
                            displayName={t('Account.password')}
                            type="password"
                            className="col-12 col-md-6 mb-0"
                            required={true}
                            onChange={this.handleInputChange}
                            minlength={6}
                            maxlength={50}
                            errors={errors}
                            value={password}
                        />
                        <FormGroup
                            id="confirmPassword"
                            displayName={t('Account.confirm_password')}
                            type="password"
                            className="col-12 col-md-6 mb-0"
                            required={true}
                            onChange={this.handleInputChange}
                            minlength={6}
                            maxlength={50}
                            errors={errors}
                            value={confirmPassword}
                        />
                        <div className="form-group col-12 consentChecked mb-0">
                            <Consent
                                id="consentChecked"
                                displayName=""
                                labelClass="pl-0"
                                text={t('Settings.consent_terms_and_policy', {
                                    param1: `<a href="${t(
                                        'Settings.terms_of_use_url'
                                    )}" target="_blank" rel="noopener noreferrer">${t(
                                        'Settings.terms_of_use'
                                    )}</a>`,
                                    param2: `<a href="${t(
                                        'Settings.privacy_policy_url'
                                    )}" target="_blank" rel="noopener noreferrer">${t(
                                        'Settings.privacy_policy'
                                    )}</a>`,
                                })}
                                onChange={this.handleInputChange}
                                checked={consentChecked}
                            />
                        </div>
                        <div className="col-12">
                            <Errors errors={errors['other']} />
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <input
                            type="submit"
                            value={
                                setPassword
                                    ? t('Account.set_password')
                                    : t('Account.reset_password')
                            }
                            className="btn btn-primary w-100"
                        />
                    </div>
                </form>
            </Fragment>
        );
    }
}

export default translate()(ResetPassword);
