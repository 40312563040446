import React, { PureComponent } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { translate } from 'react-i18next';
import classNames from 'classnames';

import { X, Dot } from 'Components/Icons';

import RecurrenceConflictTimeSlotStep from './RecurrenceConflictTimeSlotStep';
import RecurrenceConflictConfirmationStep from './RecurrenceConflictConfirmationStep';

const STEPS = {
    TIMESLOT: 'timeslot',
    CONFIRMATION: 'confirmation',
};

class RecurrenceConflictEdit extends PureComponent {
    state = {
        currentStep: STEPS.TIMESLOT,
    };

    submitTimeslotStep = () => {
        this.setState({
            currentStep: STEPS.CONFIRMATION,
        });
    };

    back = () => {
        this.setState({
            currentStep: STEPS.TIMESLOT,
        });
    };

    submit = () => {
        const { store, onComplete } = this.props;
        store.updateConflict(store.currentConflictId, {
            treated: true,
        });
        onComplete();
    };

    cancel = async () => {
        const { store } = this.props;

        store.updateState('currentConflictId', '');
    };

    render() {
        const { store, t } = this.props;

        if (!store.currentConflictId) return null;

        const { currentStep } = this.state;
        return (
            <Modal isOpen className="modal-lg modal-full-screen">
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.cancel}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title mx-auto">
                        {t('Booking.edit_conflict_title')}
                    </h5>
                </div>
                <ModalBody>
                    <div className="bg-dark p-2">
                        <div className="dot-steps mb-2">
                            <span
                                className={classNames({
                                    active: currentStep === STEPS.TIMESLOT,
                                    completed: currentStep === STEPS.CONFIRMATION,
                                    'c-pointer': currentStep === STEPS.CONFIRMATION,
                                })}
                                onClick={
                                    currentStep === STEPS.CONFIRMATION
                                        ? this.back
                                        : undefined
                                }
                            >
                                <Dot size="1em" />
                            </span>
                            <div
                                className={classNames('step-separator', {
                                    completed: currentStep === STEPS.CONFIRMATION,
                                })}
                            />
                            <span
                                className={classNames({
                                    active: currentStep === STEPS.CONFIRMATION,
                                })}
                            >
                                <Dot size="1em" />
                            </span>
                        </div>
                        <div className="text-white font-weight-semibold text-center">
                            {t('Booking.booking_availabilities_title')}
                        </div>
                    </div>
                    <div className="bg-light-grey p-3 flex-grow-1 d-flex flex-column">
                        {currentStep === STEPS.TIMESLOT && (
                            <RecurrenceConflictTimeSlotStep
                                store={store}
                                timezone={this.props.timezone}
                                onComplete={this.submitTimeslotStep}
                            />
                        )}
                        {currentStep === STEPS.CONFIRMATION && (
                            <RecurrenceConflictConfirmationStep
                                store={store}
                                timezone={this.props.timezone}
                            />
                        )}
                    </div>
                    {currentStep === STEPS.CONFIRMATION && (
                        <div className="p-3 d-flex">
                            <button
                                type="button"
                                className="btn btn-light flex-1 mr-1 font-weight-bold bottom-action bottom-action-small border"
                                onClick={this.back}
                            >
                                {t('tutorial.back')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary flex-1 ml-1 font-weight-bold bottom-action bottom-action-small"
                                onClick={this.submit}
                            >
                                {t('Membership.confirm')}
                            </button>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(RecurrenceConflictEdit);
