import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { ActionPane, InfoPane } from 'Components/Modal';
import PlanBackground from 'Assets/Images/Plan.jpg';
import { PlanSelection } from 'Components/Plan';
import DayPassDateSelection from 'Components/Subscription/DayPassDateSelection';
import Constants from 'Data/Constants';
import { inject } from 'mobx-react';

@inject('seedStore')
class PlanStep extends React.Component {
    componentDidMount() {
        const { history, seedStore, match } = this.props;
        if (seedStore && seedStore.subscription && seedStore.subscription.dayPass) {
            history.push(`${match.url}/date`);
        }
    }

    onPlanSelected = (planItem, plan) => {
        const { onComplete, stepKey, storeKey, store, history, match } = this.props;

        store.plan = plan;

        if (plan.interval === Constants.PLAN_INTERVALS.DAILY) {
            store.updateState(storeKey, plan.id);
            history.push(`${match.url}/date`);
        } else {
            onComplete(stepKey, plan.id);
        }
        store.updateState('touched', true);
    };

    dateSelectionCompleted = () => {
        const { onComplete, stepKey, store } = this.props;
        onComplete(stepKey, null);
        store.updateState('modalType', Constants.EVENT_TYPE.DAY_PASS);
    };

    updateSelectedDate = (day, { selected, disabled }) => {
        if (disabled || selected) return;
        this.props.store.updateState('dateObj', day);
    };

    updateNbPlansAvailable = (count) => {
        this.props.store.updateState('nbPlansAvailable', count);
    };

    renderPlanSelection = () => {
        const { onDetails } = this.props;
        return (
            <PlanSelection
                onSelected={this.onPlanSelected}
                campusId={this.props.store.campusId}
                className="py-2"
                detailsLink
                onDetails={onDetails}
                dayPassSubscription={this.props.store.dayPassSubscription}
                updatePlan={this.updateNbPlansAvailable}
            />
        );
    };

    renderDateSelection = () => {
        return (
            <DayPassDateSelection
                store={this.props.store}
                onDateSelected={this.updateSelectedDate}
                onComplete={this.dateSelectionCompleted}
                previousUrl={this.props.match.url}
            />
        );
    };

    render() {
        const { t, match, store, modalTitle } = this.props;
        return (
            <Fragment>
                <InfoPane
                    title={
                        store.dayPassSubscription
                            ? t('Membership.day_pass_title')
                            : t('Plan.plan_title_short')
                    }
                    subTitle={
                        store.dayPassSubscription
                            ? t('Membership.select_one_day_pass')
                            : t('Membership.select_one_plan_web')
                    }
                    bgImgUrl={PlanBackground}
                    modalTitle={modalTitle}
                />
                <ActionPane>
                    <Switch>
                        <Route
                            path={`${match.url}/date`}
                            render={this.renderDateSelection}
                        />
                        <Route path={match.url} render={this.renderPlanSelection} />
                    </Switch>
                </ActionPane>
            </Fragment>
        );
    }
}

export default translate()(PlanStep);
