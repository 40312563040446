import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import timezone from 'moment-timezone';
import classnames from 'classnames';
import CurrentBooking from './CurrentBooking';
import BookingConflict from 'WebApp/Views/Logged/Booking/FlexibleBooking/BookingConflict';

const ZOOM_HOURS = 0;
const ZOOM_HALF_HOURS = 1;
const ZOOM_QUARTER_HOURS = 2;

export default class Resources extends React.Component {
    constructor(props) {
        super(props);
        this.isOdd = false;
        this.timeCellIndex = 0;
        this.currentResourceId = null;
        this.loaded = false;

        this.state = {
            timespanDivision: this.calculateTimepanDivision(props.zoom),
            subCellsPerTimespan: this.calculateSubCellsPerTimespan(props.zoom),
            resources: props.resources || [],
        };
    }

    componentDidMount() {
        setTimeout(this.scrollToFirstAvailablePeriod, 300);
    }

    componentDidUpdate(prevProps) {
        // Watch for this.props.zoom update
        if (prevProps.zoom !== this.props.zoom) {
            this.setState({
                timespanDivision: this.calculateTimepanDivision(this.props.zoom),
                subCellsPerTimespan: this.calculateSubCellsPerTimespan(this.props.zoom),
            });
        }

        if (prevProps.resources !== this.props.resources) {
            this.setState({
                resources: this.props.resources,
            });
        }
    }

    scrollToFirstAvailablePeriod = () => {
        const classToScrollTo =
            this.props.view === 'bookings' ? 'Available' : 'CurrentBooking';
        const availablePeriods = document.getElementsByClassName(classToScrollTo);
        if (availablePeriods.length) {
            let earliestAvailablePeriod = availablePeriods[0].offsetTop;

            if (availablePeriods.length) {
                for (let element of availablePeriods) {
                    if (element.offsetTop < earliestAvailablePeriod)
                        earliestAvailablePeriod = element.offsetTop;
                }

                // Skip scrollTo if navigator === edge
                if (window.navigator.userAgent.indexOf('Edge') <= -1) {
                    this.props.flexibleBookingCalendarRef.current.scrollTo({
                        top: earliestAvailablePeriod,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    calculateTimepanDivision = (zoom) => {
        switch (zoom) {
            case ZOOM_HOURS:
                return 1;
            case ZOOM_HALF_HOURS:
                return 2;
            case ZOOM_QUARTER_HOURS:
                return 4;
            default:
                return 1;
        }
    };

    calculateSubCellsPerTimespan = (zoom) => {
        switch (zoom) {
            case ZOOM_HOURS:
                return 4;
            case ZOOM_HALF_HOURS:
                return 2;
            case ZOOM_QUARTER_HOURS:
                return 1;
            default:
                return 4;
        }
    };

    generatePeriod = (resourceId, period, resource) => {
        if (resourceId !== this.currentResourceId) {
            this.currentResourceId = resourceId;
            this.timeCellIndex = 0;
        }

        var start = moment.parseZone(period.start);
        var quarterCellTime = moment.parseZone(period.start);
        var end = moment.parseZone(period.end);
        var duration = moment.duration(end.diff(start)).asHours();

        const periodContent =
            this.props.zoom === 0 && duration >= 1
                ? this.props.resourcePeriod(period)
                : this.props.zoom === 1 && duration >= 0.5
                ? this.props.resourcePeriod(period)
                : this.props.zoom === 2
                ? this.props.resourcePeriod(period)
                : null;

        const numberOfQuarterCells = (duration * 60) / 15;
        const isAvailablePeriod = period.status === 'Available';

        let quarterCells = [];

        for (let index = 1; index <= numberOfQuarterCells; index++) {
            if (this.timeCellIndex % this.state.subCellsPerTimespan === 0) {
                this.isOdd = !this.isOdd;
            }

            quarterCells.push(
                <div
                    key={`${resourceId}_${index}`}
                    id={`${this.props.view}_${resourceId}_${quarterCellTime.format(
                        'HH:mm'
                    )}`}
                    data-id={resourceId}
                    data-time={quarterCellTime.format('HH:mm')}
                    className={classnames(
                        'quarterCells',
                        `zoom-${this.props.zoom}`,
                        this.isOdd ? 'odd' : 'even'
                    )}
                    onClick={
                        isAvailablePeriod
                            ? (e) => this.handleTimeCellClick(e, period, resource)
                            : null
                    }
                >
                    <div className="period-background w-100 h-100" />
                </div>
            );

            this.timeCellIndex++;
            quarterCellTime = quarterCellTime.add(15, 'minutes');
        }

        return (
            <div
                key={`${resourceId}_${end}`}
                className={classnames(
                    'period',
                    period.unavailabilityReason
                        ? period.unavailabilityReason
                        : 'Available',
                    period.booking &&
                        period.booking.id === this.props.currentBooking.bookingId &&
                        'CurrentBookingPeriod'
                )}
            >
                {periodContent}
                {quarterCells}
            </div>
        );
    };

    handleTimeCellClick = (e, period, resource) => {
        const { id, time } = e.target.dataset;
        const splitTime = time.split(':');
        const bookingInformation = this.getBookingInformation(
            id,
            splitTime,
            period,
            resource
        );

        this.props.onTimeCellClick(e, bookingInformation, period);
    };

    getBookingInformation = (conferenceRoomId, splitTime, { start, end }, resource) => {
        var bookingStart = moment.parseZone(start).hours(parseInt(splitTime[0]));
        var bookingEnd = null;

        const { maximumBookingDuration } = resource;
        const maximumBookingDurationInHours = moment
            .duration(maximumBookingDuration)
            .asHours();

        // Calculate booking start time based on zoom
        if (this.props.zoom === ZOOM_HOURS) {
            bookingStart.minutes(0);

            while (bookingStart.isBefore(moment.parseZone(start))) {
                bookingStart.add(15, 'minutes');
            }
        } else if (this.props.zoom === ZOOM_HALF_HOURS) {
            const minutes = splitTime[1] === '00' || splitTime[1] === '15' ? 0 : 30;
            bookingStart.minutes(minutes);

            while (bookingStart.isBefore(moment.parseZone(start))) {
                bookingStart.add(30, 'minutes');
            }
        } else {
            bookingStart.minutes(splitTime[1]);
        }

        const bookingEndTimeToAdd =
            maximumBookingDurationInHours < 1 ? maximumBookingDurationInHours : 1;

        // Calculate booking end time
        bookingEnd = moment(bookingStart).add(bookingEndTimeToAdd, 'hours');
        while (bookingEnd.isAfter(moment.parseZone(end))) {
            bookingEnd.subtract(15, 'minutes');
        }

        return {
            conferenceRoomId,
            bookingStart: timezone(bookingStart).tz(this.props.timezone).format(),
            bookingEnd: timezone(bookingEnd).tz(this.props.timezone).format(),
            bookingAmenity: [resource],
        };
    };

    handleBookingResize = ({ bookingStart, bookingEnd }) => {
        const formatedBookingStart = bookingStart
            ? timezone(bookingStart).tz(this.props.timezone).format()
            : null;
        const formatedBookingEnd = bookingEnd
            ? timezone(bookingEnd).tz(this.props.timezone).format()
            : null;
        this.props.onBookingResize({ formatedBookingStart, formatedBookingEnd });
    };

    render() {
        const { resources } = this.state;

        if (resources.length <= 0) {
            return null;
        }

        return (
            <div className="FlexibleBooking__Resources">
                {this.props.view === 'bookings' && (
                    <div className="resources-header-row">
                        {resources.length &&
                            resources.map((resource) => {
                                return (
                                    <div className="resource-head">
                                        {this.props.resourceHeader(resource)}
                                    </div>
                                );
                            })}
                    </div>
                )}

                <div className="resources-content-row">
                    {resources.length &&
                        resources.map((resource, index) => {
                            if (index === resources.length - 1) {
                                this.loaded = true;
                            }
                            const { periods } = resource;
                            return (
                                <div
                                    key={resource.id}
                                    id={resource.id}
                                    className="resource"
                                >
                                    <div
                                        className={`resource-content zoom-${this.props.zoom} position-relative`}
                                    >
                                        {periods.map((period) => {
                                            return this.generatePeriod(
                                                resource.id,
                                                period,
                                                resource
                                            );
                                        })}

                                        {this.props.currentBooking &&
                                            this.props.currentBooking.conferenceRoomId ===
                                                resource.id && (
                                                <CurrentBooking
                                                    currentBooking={
                                                        this.props.currentBooking
                                                    }
                                                    selectedDate={this.props.selectedDate}
                                                    bookingError={this.props.bookingError}
                                                    zoom={this.props.zoom}
                                                    view={this.props.view}
                                                    isEditing={this.props.isEditing}
                                                    resizable={this.props.resizable}
                                                    onResize={this.handleBookingResize}
                                                    onBookingClick={
                                                        this.props.onBookingClick
                                                    }
                                                />
                                            )}
                                        {this.props.currentConflict &&
                                            this.props.currentConflict
                                                .conferenceRoomId === resource.id && (
                                                <BookingConflict
                                                    currentBooking={
                                                        this.props.currentConflict
                                                    }
                                                    zoom={this.props.zoom}
                                                    view={this.props.view}
                                                />
                                            )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

Resources.defaultProps = {
    resources: [],
    currentBooking: {},
};

Resources.propTypes = {
    resources: PropTypes.array,
    currentBooking: PropTypes.object,
};
