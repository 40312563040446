import React from 'react';

const Instagram = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M121.72 47.48h-13.28a7.25 7.25 0 0 1-7.24-7.24V27a7.25 7.25 0 0 1 7.24-7.24h13.28A7.25 7.25 0 0 1 129 27v13.24a7.25 7.25 0 0 1-7.28 7.24zm-13.28-22.12a1.61 1.61 0 0 0-1.61 1.64v13.24a1.61 1.61 0 0 0 1.61 1.61h13.28a1.62 1.62 0 0 0 1.61-1.61V27a1.62 1.62 0 0 0-1.61-1.61z"
        />
        <path
            fill={fill || 'currentColor'}
            d="M94.47 59.41l-.82-.63M51.36 59.06l-.46.35"
        />
        <path
            fill={fill || 'currentColor'}
            d="M113.42 2H30.58A30.62 30.62 0 0 0 0 32.6v78.8A30.62 30.62 0 0 0 30.58 142h82.84A30.62 30.62 0 0 0 144 111.4V32.6A30.62 30.62 0 0 0 113.42 2zM138 111.4a24.56 24.56 0 0 1-24.54 24.54H30.58A24.56 24.56 0 0 1 6 111.4v-52h36.66a40.72 40.72 0 1 0 60 0H138zm-30.23-24.51A35.09 35.09 0 1 1 50.9 59.41l.46-.35a35 35 0 0 1 42.29-.28l.82.63a35.06 35.06 0 0 1 13.3 27.48zM138 53.37H95.76a40.64 40.64 0 0 0-46.16 0H6V32.6A24.56 24.56 0 0 1 30.58 8.06h82.84A24.56 24.56 0 0 1 138 32.6z"
        />
        <path
            fill={fill || 'currentColor'}
            d="M72.25 110.38a23.49 23.49 0 1 1 23.48-23.49 23.52 23.52 0 0 1-23.48 23.49zm0-41.35A17.86 17.86 0 1 0 90.1 86.89 17.88 17.88 0 0 0 72.25 69z"
        />
    </svg>
);

export default Instagram;
