import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import HttpService from 'Services/HttpService';
import CONSTANTS from 'Data/Constants';
import { Memberships } from 'Components/Icons';
import FloorPlanUnitTooltip from './FloorPlanUnitTooltip';

const pinSize = 28;
const pinRadius = pinSize / 2;
const pinStrokeWidth = 2;

export default function FloorPlanUnit({
    unit,
    isSelected = false,
    onClick,
    onUnitInformationClick,
    className,
}) {
    const [enterpriseLogo, setEnterpriseLogo] = useState(null);
    useEffect(() => {
        if (
            unit.type === CONSTANTS.UNIT_TYPES.ENTERPRISE &&
            unit.subscriptions.length === 1
        ) {
            getEnterpriseLogo();
        }
    });

    const getEnterpriseLogo = async () => {
        try {
            const { data } = await HttpService.membershipProfile(
                unit.subscriptions[0].membership.id
            );

            if (data && data.imageUrl) setEnterpriseLogo(data.imageUrl);
        } catch (errors) {
            return;
        } finally {
        }
    };

    function handleMouseUp(e) {
        e.stopPropagation();
        onClick(unit);
    }

    if (unit.type === CONSTANTS.UNIT_TYPES.ENTERPRISE && unit.subscriptions.length === 0)
        return null;

    return (
        <svg
            onMouseUp={handleMouseUp}
            className={classnames('floorplan-unit', unit.type, className)}
        >
            <polygon
                x={unit.poleOfInaccessibility.x}
                y={unit.poleOfInaccessibility.y}
                points={unit.points}
                style={{ cursor: 'pointer' }}
            />
            <svg
                width={pinSize}
                height={pinSize}
                x={unit.poleOfInaccessibility.x - pinRadius}
                y={unit.poleOfInaccessibility.y - pinRadius}
                id={`u-${unit.svgElementId}`}
                style={{ overflow: 'visible' }}
            >
                <circle
                    cx={pinRadius}
                    cy={pinRadius}
                    r={pinRadius - pinStrokeWidth}
                    style={{
                        stroke: 'rgba(255, 255, 255, 0.5)',
                        strokeWidth: pinStrokeWidth,
                        fill: 'white',
                        cursor: 'pointer',
                    }}
                />
                {unit.type !== CONSTANTS.UNIT_TYPES.ENTERPRISE ? (
                    <text
                        x="50%"
                        y="56%"
                        style={{
                            dominantBaseline: 'middle',
                            textAnchor: 'middle',
                            cursor: 'pointer',
                        }}
                    >
                        {unit.subscriptions.length}
                    </text>
                ) : (
                    <>
                        {enterpriseLogo ? (
                            <>
                                <clipPath id="clipCircle">
                                    <circle
                                        cx={pinRadius}
                                        cy={pinRadius}
                                        r={pinRadius - pinStrokeWidth}
                                        style={{
                                            stroke: 'rgba(255, 255, 255, 0.5)',
                                            strokeWidth: pinStrokeWidth,
                                            fill: '#ffffff',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </clipPath>
                                <image
                                    width="100%"
                                    height="100%"
                                    clipPath="url(#clipCircle)"
                                    href={enterpriseLogo}
                                    x="0"
                                    y="0"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </>
                        ) : (
                            <Memberships size="16px" x="6" y="7" />
                        )}
                    </>
                )}
                <FloorPlanUnitTooltip
                    unit={unit}
                    isSelected={isSelected}
                    onUnitInformationClick={onUnitInformationClick}
                />
            </svg>
        </svg>
    );
}
