import React from 'react';

const ZipCode = ({ size, fill, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 1 36 36"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M28.364 21.631H5.378V9.033h22.986l3.576 6.298-3.576 6.3zM12.561 35.397h7.846V23.01h-7.846v12.388zm0-27.742h7.846V2.378h-7.846v5.277zm20.77 7.336l-3.966-6.988a.693.693 0 0 0-.599-.348h-6.98V1.69a.69.69 0 0 0-.689-.69h-9.224a.69.69 0 0 0-.69.69v5.965H4.688A.688.688 0 0 0 4 8.343v13.976c0 .382.308.69.688.69h6.495v13.079c0 .38.308.688.69.688h9.224c.38 0 .688-.309.688-.688V23.009h6.981a.69.69 0 0 0 .6-.35l3.964-6.988a.684.684 0 0 0 0-.68z"
        />
    </svg>
);

export default ZipCode;
