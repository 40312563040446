import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { ListGroup, Modal, ModalBody } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { Link, Route, Switch } from 'react-router-dom';
import memoize from 'memoize-one';

import { Arrow, Back, CheckOutline, Error, Plan, X } from 'Components/Icons';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import { Image } from 'Components/Misc';
import LoadingStore from 'Stores/UI/LoadingStore';
import HttpService from 'Services/HttpService';
import Constants from 'Data/Constants';
import { ListItem, SelectList } from 'Components/Forms';
import NoMatch from 'WebApp/Views/NoMatch';

const { PLAN_INTERVALS } = Constants;

const defaultPlanImg = (
    <div className="img">
        <Plan size="50%" />
    </div>
);

@inject('uiStore', 'dashboardStore')
@observer
class SubscriptionEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription: null,
            plans: [],
            selectedPlanId: '',
            selectedAddonIds: [],
            errored: false,
        };

        this.permissionChecked = false;

        this.localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);
    }

    componentDidMount() {
        this.cancellation = HttpService.cancellable();
        this.loadSubscription();
    }

    componentDidUpdate(prevProps, prevState) {
        const { dashboardStore } = this.props,
            { subscription } = this.state;
        if (
            dashboardStore.memberships &&
            dashboardStore.memberships.data &&
            subscription &&
            !this.permissionChecked
        ) {
            this.permissionChecked = true;
            const membership = dashboardStore.memberships.data.find(
                (membership) => membership.id === subscription.membershipId
            );

            if (
                !membership ||
                !membership.rights.includes(Constants.MEMBERSHIP_RIGHTS.SUBSCRIBE_PLAN) ||
                subscription.linked.length
            ) {
                this.props.history.replace(this.props.parentMatchURL);
            }
        }
    }

    componentWillUnmount() {
        this.cancellation.cancel();
    }

    loadSubscription = async () => {
        const { subscriptionId } = this.props;

        this.localLoadingStore.addLoading();
        try {
            const { data } = await HttpService.subscription(
                subscriptionId,
                this.cancellation
            );
            this.setState({
                subscription: data,
                selectedPlanId: data.planId,
                selectedAddonIds: data.addOns.map((addOn) => addOn.id),
            });
            this.loadPlans();
        } catch (error) {
            if (this.cancellation.isCancelled(error)) return;
            this.setState({ errored: true });
        } finally {
            this.localLoadingStore.removeLoading();
        }
    };

    loadPlans = async () => {
        this.localLoadingStore.addLoading();
        try {
            const { data } = await HttpService.plans(
                this.state.subscription.campusId,
                this.cancellation
            );
            const plans = data.filter(
                (plan) => plan.interval === this.state.subscription.plan.interval
            );
            this.setState({ plans: plans });
        } catch (error) {
            if (this.cancellation.isCancelled(error)) return;
            this.setState({ errored: true });
        } finally {
            this.localLoadingStore.removeLoading();
        }
    };

    close = () => this.props.history.push(this.props.parentMatchURL);

    updateSelectedPlan = (selectedPlanId) => {
        this.setState({ selectedPlanId: selectedPlanId, selectedAddonIds: [] });
        this.props.history.replace(this.props.match.url);
    };

    updateSelectedAddOns = (selectedAddonIds) => {
        this.setState({ selectedAddonIds: selectedAddonIds });
    };

    confirm = async () => {
        const {
            uiStore: { loadingStore, toastStore },
            t,
            history,
            parentMatchURL,
            onComplete,
        } = this.props;

        const data = {
            subscriptionId: this.state.subscription.id,
            planId: this.state.selectedPlanId,
            addonsIds: this.state.selectedAddonIds,
        };

        try {
            loadingStore.addLoading();
            await HttpService.editSubscription(data);
            toastStore.enqueueToast({
                icon: CheckOutline,
                message: t('Subscription.send_request_success_message'),
            });
            onComplete();
            history.push(parentMatchURL);
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    renderBase = (renderProps) => {
        const { t, match } = this.props;
        const { errored } = this.state;
        const selectedPlan = this.state.plans.find(
            (plan) => plan.id === this.state.selectedPlanId
        );
        const selectedAddons = selectedPlan
            ? selectedPlan.addOns.filter((addOn) =>
                  this.state.selectedAddonIds.some((addOnId) => addOn.id === addOnId)
              )
            : [];
        return errored ? (
            <div className="px-7 py-3">
                <NoResults
                    icon={<Error size="5em" />}
                    title={t('Subscription.subscription')}
                    subTitle={t('Error.error_message')}
                    action={{
                        text: t('Error.retry'),
                        onClick: this.loadSubscription,
                    }}
                />
            </div>
        ) : (
            <Fragment>
                <p className="text-sm text-center font-weight-medium my-3">
                    {t('Subscription.subscription_edit_message_web')}
                </p>
                {selectedPlan ? (
                    <div className="list-group mb-3">
                        <Link
                            to={`${match.url}/plan`}
                            className="list-group-item list-group-item-action"
                        >
                            <div className="list-group-item-image">
                                <Image
                                    title={selectedPlan.name}
                                    imgSrc={selectedPlan.imageUrl}
                                    defaultImg={defaultPlanImg}
                                />
                            </div>
                            <div className="list-group-item-text pl-2">
                                <span className="title">{selectedPlan.name}</span>
                                <span className="sub-title text-truncate mw-100">
                                    {selectedPlan.interval === PLAN_INTERVALS.DAILY
                                        ? t('Membership.starting_at', {
                                              param1: t('Plan.daily_amount', {
                                                  param1: `$${selectedPlan.price}`,
                                              }),
                                          })
                                        : selectedPlan.interval === PLAN_INTERVALS.MONTHLY
                                        ? t('Membership.starting_at', {
                                              param1: t('Plan.monthly_amount', {
                                                  param1: `$${selectedPlan.price}`,
                                              }),
                                          })
                                        : null}
                                </span>
                            </div>
                            <div className="list-group-item-status text-primary">
                                <Arrow size="1.5em" />
                            </div>
                        </Link>
                        {selectedPlan.addOns.length ? (
                            <Link
                                to={`${match.url}/add-ons`}
                                className="list-group-item list-group-item-action"
                            >
                                <div className="list-group-item-text pl-2">
                                    <span className="title">{t('Plan.add_ons')}</span>
                                    <span className="sub-title text-truncate mw-100">
                                        {selectedAddons
                                            .map((addOn) => addOn.name)
                                            .join(', ')}
                                    </span>
                                </div>
                                <div className="list-group-item-status text-primary">
                                    <Arrow size="1.5em" />
                                </div>
                            </Link>
                        ) : null}
                    </div>
                ) : (
                    <div style={{ height: '280px' }} />
                ) // Placeholder to have approximately the same height as when the data is loaded.
                }
                <button
                    className="btn btn-primary w-100 bottom-action"
                    onClick={this.confirm}
                >
                    {t('Subscription.subscription_edit_confirm')}
                </button>
            </Fragment>
        );
    };

    generatePlanItems = memoize((plans, t) =>
        plans
            ? plans.map((plan) => ({
                  id: plan.id,
                  title: plan.name,
                  subTitle:
                      plan.interval === PLAN_INTERVALS.DAILY
                          ? t('Membership.starting_at', {
                                param1: t('Plan.daily_amount', {
                                    param1: `$${plan.price}`,
                                }),
                            })
                          : plan.interval === PLAN_INTERVALS.MONTHLY
                          ? t('Membership.starting_at', {
                                param1: t('Plan.monthly_amount', {
                                    param1: `$${plan.price}`,
                                }),
                            })
                          : null,
                  imgSrc: plan.imageUrl,
                  defaultImg: defaultPlanImg,
                  withImage: true,
              }))
            : []
    );

    renderPlanSelection = (renderProps) => {
        const { t, history, match } = this.props;

        if (this.state.errored) {
            history.replace(match.url);
            return null;
        }

        const planItems = this.generatePlanItems(this.state.plans, t);
        return (
            <Fragment>
                <p className="text-sm text-center font-weight-medium my-3">
                    {t('Membership.select_plan_title')}
                </p>
                <ListGroup>
                    {planItems.map((plan) => (
                        <ListItem
                            key={plan.id}
                            {...plan}
                            clickable
                            onClick={this.updateSelectedPlan}
                        />
                    ))}
                </ListGroup>
            </Fragment>
        );
    };

    generateAddOnItems = memoize((selectedPlanId, plans, t) => {
        const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
        return selectedPlan
            ? selectedPlan.addOns.map((addOn) => ({
                  id: addOn.id,
                  title: addOn.name,
              }))
            : [];
    });

    renderAddOnSelection = (renderProps) => {
        const { t, match, history } = this.props;
        const addOnItems = this.generateAddOnItems(
            this.state.selectedPlanId,
            this.state.plans,
            t
        );

        if (!addOnItems.length || this.state.errored) {
            history.replace(match.url);
            return null;
        }
        return (
            <Fragment>
                <p className="text-sm text-center font-weight-medium my-3">
                    {t('Membership.upgrade_plan_message_web')}
                </p>
                <SelectList
                    items={addOnItems}
                    onChange={this.updateSelectedAddOns}
                    multiSelect={true}
                    value={this.state.selectedAddonIds}
                    className="pb-3"
                />
                <Link to={match.url} className="btn btn-primary w-100 bottom-action">
                    {t('Subscription.select')}
                </Link>
            </Fragment>
        );
    };

    render() {
        const { t, match, location } = this.props;
        return (
            <Modal
                isOpen={true}
                className="modal-dialog-centered modal-full-screen adaptive"
                toggle={this.close}
            >
                <div className="modal-header">
                    {location.pathname === match.url ? (
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={this.close}
                        >
                            <span aria-hidden="true">
                                <X fill="currentColor" size="24px" />
                            </span>
                        </button>
                    ) : (
                        <Link className="close" aria-label="Close" to={match.url}>
                            <span aria-hidden="true">
                                <Back fill="currentColor" size="24px" />
                            </span>
                        </Link>
                    )}
                    <h5 className="modal-title mx-auto">
                        {t('Subscription.subscription_edit_title')}
                    </h5>
                </div>
                <ModalBody className="d-flex flex-column mh-0 bg-body">
                    <div className="scrollable d-flex flex-column position-relative">
                        <Switch>
                            <Route
                                path={`${match.url}/add-ons`}
                                render={this.renderAddOnSelection}
                            />
                            <Route
                                path={`${match.url}/plan`}
                                render={this.renderPlanSelection}
                            />
                            <Route exact path={match.url} render={this.renderBase} />
                            <Route component={NoMatch} />
                        </Switch>
                        <LoadingSpinner loading={this.localLoadingStore.isLoading} />
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(SubscriptionEditModal);
