import React from 'react';

const Password = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 30 30"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M14.543 23.84a.543.543 0 0 1-.543-.543v-3.754a.544.544 0 0 1 1.087 0v3.754c0 .3-.243.544-.544.544" />
            <path d="M14.583 29c4.733 0 8.583-3.852 8.583-8.583a8.494 8.494 0 0 0-1.698-5.126V7.96c0-.14 0-.283-.007-.431a6.877 6.877 0 0 0-.517-2.232A6.942 6.942 0 0 0 14.51 1a6.94 6.94 0 0 0-6.433 4.294 6.857 6.857 0 0 0-.518 2.232c-.008.148-.008.293-.008.433v7.536A8.508 8.508 0 0 0 6 20.417C6 25.148 9.85 29 14.583 29zm0-1.086c-4.133 0-7.497-3.364-7.497-7.497 0-1.606.5-3.137 1.447-4.428a7.525 7.525 0 0 1 6.05-3.071 7.452 7.452 0 0 1 5.914 2.89l.01.012a7.435 7.435 0 0 1 1.573 4.597c0 4.133-3.364 7.497-7.497 7.497zM8.638 7.96c0-.124 0-.245.005-.365a5.764 5.764 0 0 1 .438-1.882 5.86 5.86 0 0 1 5.43-3.626 5.858 5.858 0 0 1 5.429 3.628c.25.594.396 1.227.437 1.88.006.12.006.24.006.365v6.126l-.15-.134a5.98 5.98 0 0 0-.435-.355 4.085 4.085 0 0 0-.186-.14 8.443 8.443 0 0 0-1.48-.86 7.28 7.28 0 0 0-.443-.186c-.076-.03-.152-.06-.228-.085a4.529 4.529 0 0 0-.229-.077c-.066-.023-.134-.043-.204-.064-.085-.026-.173-.049-.26-.072a5.244 5.244 0 0 0-.235-.058 6.316 6.316 0 0 0-.542-.107 5.634 5.634 0 0 0-.177-.028c-.073-.011-.147-.019-.221-.029l-.023-.002c-.074-.01-.148-.015-.224-.023-.056-.004-.114-.01-.173-.013-.054-.004-.108-.006-.16-.008-.061-.004-.121-.006-.181-.008a10.46 10.46 0 0 0-.504 0 4.867 4.867 0 0 0-.277.014 4.22 4.22 0 0 0-.222.017 3.567 3.567 0 0 0-.281.03c-.245.028-.488.07-.729.12l-.155.035-.126.03a8.185 8.185 0 0 0-.682.199 8.55 8.55 0 0 0-1.375.6c-.086.046-.169.095-.253.144a7.369 7.369 0 0 0-.317.196l-.14.091-.139.097c-.068.049-.136.097-.204.148-.134.1-.264.206-.392.313l-.189.163-.21.194V7.959z" />
        </g>
    </svg>
);

export default Password;
