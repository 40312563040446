import React from 'react';

const LogoIcon = ({ fill, size, className }) => (
    <svg className={`icon ${className || ''}`} viewBox="0 0 60 60" width={size || '1em'}>
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M52.929 33.621l-.439-.08-.08.444c-1.975 11.04-11.404 19-22.432 19-12.565 0-22.786-10.35-22.786-23.07 0-12.721 10.221-23.07 22.786-23.07 11.028 0 20.457 7.96 22.42 18.93l.08.443.45-.011 1.279-.235.44-.081-.081-.445C52.403 13.352 42.068 4.625 29.978 4.625 16.206 4.625 5 15.97 5 29.915c0 13.945 11.206 25.29 24.978 25.29 12.09 0 22.425-8.729 24.576-20.754l.08-.445-.427-.149-1.278-.236z" />
            <path d="M29.978 17.33c5.35 0 10.089 3.455 11.79 8.599l.143.428.444-.077 1.233-.418.424-.144-.144-.43c-2.021-6.114-7.594-10.178-13.89-10.178-8.063 0-14.622 6.641-14.622 14.805 0 8.163 6.559 14.804 14.622 14.804 8.062 0 14.622-6.641 14.622-14.804v-1.109H30.994v2.219H42.36c-.558 6.47-5.908 11.474-12.382 11.474-6.853 0-12.43-5.645-12.43-12.584 0-6.94 5.577-12.585 12.43-12.585M46.963 31.024h13.025v-2.22H46.963z" />
        </g>
    </svg>
);

export default LogoIcon;
