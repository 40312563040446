import React from 'react';
import { inject, observer } from 'mobx-react';

import Toast from './Toast';

@inject('uiStore')
@observer
class ToastContainer extends React.Component {
    dismissToast = () => {
        const {
            uiStore: { toastStore },
        } = this.props;
        toastStore.dismissCurrentToast();
    };

    render() {
        const {
            uiStore: { toastStore },
        } = this.props;
        const currentToast = toastStore.currentToast;
        return (
            <div className="toast-container">
                {currentToast && <Toast dismiss={this.dismissToast} {...currentToast} />}
            </div>
        );
    }
}

export default ToastContainer;
