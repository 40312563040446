import React from 'react';
import { translate } from 'react-i18next';
import { FormGroup, Errors } from 'Components/Forms';

class ChangePassword extends React.PureComponent {
    state = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit({ ...this.state });
    };

    render() {
        const { t, errors } = this.props,
            { currentPassword, newPassword, confirmPassword } = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <FormGroup
                    id="currentPassword"
                    value={currentPassword}
                    displayName={t('Profile.current_password')}
                    required={true}
                    onChange={this.handleInputChange}
                    minlength={6}
                    maxlength={50}
                    type="password"
                    errors={errors}
                />
                <FormGroup
                    id="newPassword"
                    value={newPassword}
                    displayName={t('Profile.new_password')}
                    required={true}
                    onChange={this.handleInputChange}
                    minlength={6}
                    maxlength={50}
                    type="password"
                    errors={errors}
                />
                <FormGroup
                    id="confirmPassword"
                    value={confirmPassword}
                    displayName={t('Account.confirm_password')}
                    required={true}
                    onChange={this.handleInputChange}
                    minlength={6}
                    maxlength={50}
                    type="password"
                    errors={errors}
                />
                <Errors errors={errors['other']} />
                <button type="submit" className="btn btn-primary w-100 mt-2">
                    {t('Profile.change_password')}
                </button>
            </form>
        );
    }
}

export default translate()(ChangePassword);
