import React from 'react';
const Bookmark = (props) => (
    <svg
        className={'icon' + (props.className ? ` ${props.className}` : '')}
        width={props.size || '1em'}
        height={props.size || '1em'}
        viewBox="0 0 15 20"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L0.0213824 20L7.092 14.9446L14.1818 19.9993V0H0ZM0.776846 0.776152H13.4056V18.4927L7.09144 13.9909L0.795868 18.4921L0.776846 0.776152Z"
            fill={props.fill || 'currentColor'}
        />
    </svg>
);

export default Bookmark;
