import React from 'react';
import PropTypes from 'prop-types';

const Picture = ({ fill, size = '1em' }) => (
    <svg className="icon" viewBox="0 0 60 60" width={size} height={size}>
        <g fill="none" fillRule="evenodd">
            <circle cx={30} cy={30} r={30} fill={fill} opacity={0.6} />
            <g fill="#FFF">
                <path d="M30.412 25.57c-3.542 0-6.424 2.812-6.424 6.268 0 3.455 2.882 6.267 6.424 6.267 3.542 0 6.425-2.812 6.425-6.267 0-3.456-2.883-6.267-6.425-6.267m0 13.7c-4.201 0-7.62-3.335-7.62-7.433 0-4.099 3.419-7.433 7.62-7.433 4.201 0 7.62 3.334 7.62 7.433 0 4.098-3.419 7.432-7.62 7.432" />
                <path d="M18.916 21.326c-1.5 0-2.721 1.19-2.721 2.654v15.242c0 1.464 1.22 2.655 2.721 2.655h22.992c1.5 0 2.721-1.191 2.721-2.655V23.98c0-1.464-1.22-2.654-2.72-2.654h-3.232l-1.903-3.265a.506.506 0 0 0-.439-.253l-11.392-.07a.494.494 0 0 0-.442.244l-2.007 3.344h-3.578zm22.992 21.716H18.916c-2.16 0-3.916-1.713-3.916-3.82V23.98c0-2.107 1.757-3.82 3.916-3.82h2.893l1.66-2.765a1.712 1.712 0 0 1 1.47-.823h.012l11.392.07c.606.004 1.17.327 1.47.843l1.559 2.675h2.536c2.16 0 3.916 1.713 3.916 3.82v15.242c0 2.107-1.756 3.82-3.916 3.82z" />
            </g>
        </g>
    </svg>
);

Picture.propTypes = {
    fill: PropTypes.string.isRequired,
    size: PropTypes.string,
};

export default Picture;
