import React from 'react';
import { translate } from 'react-i18next';
import Constants from 'Data/Constants';
import InvoiceDetail from 'WebApp/Views/Logged/Invoice/InvoiceDetail';
import { inject, observer } from 'mobx-react';
import BookingDetail from 'WebApp/Views/Logged/Booking/BookingDetail';
import SubscriptionDetail from 'WebApp/Views/Logged/Subscription/SubscriptionDetail';
import EventCommunityDetail from './EventCommunityDetail';

@inject('dashboardStore', 'uiStore')
@observer
class EventDetail extends React.Component {
    eventType = (type) => {
        const {
            match,
            parentMatchURL,
            onEventUpdate,
            history,
            eventId,
            memberToggle,
            campusToggle,
            membershipToggle,
        } = this.props;
        switch (type.toLowerCase()) {
            case Constants.EVENT_TYPE.INVOICE.toLowerCase():
                return (
                    <InvoiceDetail
                        skipPermissionCheck={true}
                        match={match}
                        history={history}
                        paneDetail={true}
                        parentMatchURL={parentMatchURL}
                        onInvoiceUpdate={onEventUpdate}
                        invoiceId={eventId}
                    />
                );
            case Constants.EVENT_TYPE.BOOKING.toLowerCase():
                return (
                    <BookingDetail
                        match={match}
                        paneDetail={true}
                        history={history}
                        onBookingUpdate={onEventUpdate}
                        bookingId={eventId}
                        parentMatchURL={parentMatchURL}
                    />
                );
            case Constants.EVENT_TYPE.SUBSCRIPTION.toLowerCase():
                return (
                    <SubscriptionDetail
                        paneDetail={true}
                        history={history}
                        match={match}
                        subscriptionId={eventId}
                        parentMatchURL={parentMatchURL}
                        onComplete={onEventUpdate}
                    />
                );
            case Constants.EVENT_TYPE.COMMUNITYEVENT.toLowerCase():
                return (
                    <EventCommunityDetail
                        eventId={eventId}
                        parentMatchURL={parentMatchURL}
                        campusToggle={campusToggle}
                        membershipToggle={membershipToggle}
                        memberToggle={memberToggle}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const {
            dashboardStore: { memberships },
            eventType,
        } = this.props;

        return eventType && memberships && memberships.data
            ? this.eventType(eventType)
            : '';
    }
}

export default translate()(EventDetail);
