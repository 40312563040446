import React from 'react';

const SvgTelevision = ({ fill, size, className }) => (
    <svg
        viewBox="0 0 24 24"
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M0 3.5v15.646h4.88v1.88h14.036v-1.88H24V3.5H0zm.924 14.722h22.152V4.424H.924v13.798zm4.879 1.88h12.189v-.928H5.803v.928z"
        />
    </svg>
);

export default SvgTelevision;
