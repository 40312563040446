import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { translate } from 'react-i18next';

import { CheckFull, ConferenceRoom, Conflict } from 'Components/Icons';
import { Image } from 'Components/Misc';
import { StringUtils } from 'Utils/';

const imgClassName = 'img rounded-circle mr-1';
const defaultRoomImg = (
    <div className={`${imgClassName} bg-light text-white align-item-center`}>
        <ConferenceRoom size="50%" />
    </div>
);

class ConflictListItem extends React.PureComponent {
    render() {
        const {
            tag,
            className,
            style,

            title,
            superTitle,
            subTitle,

            treated,
            reason,
            amenity,

            action,
            t,
        } = this.props;
        return (
            <ListGroupItem
                tag={tag}
                to={this.props.to}
                className={`conflict-list-item ${className || ''}`}
                style={style}
            >
                <div className="list-group-item-text">
                    {superTitle && (
                        <span className="super-title text-xs text-secondary font-weight-light">
                            {superTitle}
                        </span>
                    )}
                    {title && (
                        <span className={`title ${superTitle ? 'text-sm' : ''}`}>
                            {title}
                        </span>
                    )}
                    {subTitle && (
                        <span
                            className={`sub-title ${
                                superTitle ? 'font-weight-normal' : ''
                            }`}
                        >
                            {subTitle}
                        </span>
                    )}
                </div>
                {action}
                <div className="text-xs conflict-info d-flex">
                    <div className="amenity-info">
                        <Image
                            className={imgClassName}
                            title={title}
                            imgSrc={amenity.imageUrl}
                            defaultImg={defaultRoomImg}
                        />
                        <span>{amenity.name}</span>
                    </div>
                    <div
                        className={
                            'conflict-reason text-right' +
                            (!treated ? ' text-danger' : ' text-success')
                        }
                    >
                        <span>
                            {!treated
                                ? StringUtils.getDisplayConflictReason(reason, t)
                                : t('Booking.conflict_resolved')}
                        </span>
                        <span className="pl-1">
                            {!treated ? (
                                <Conflict size="2.4em" />
                            ) : (
                                <CheckFull size="2.4em" />
                            )}
                        </span>
                    </div>
                </div>
            </ListGroupItem>
        );
    }
}

export default translate()(ConflictListItem);
