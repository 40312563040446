import React from 'react';

const Rebate = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M18.485 7.84a2.356 2.356 0 1 0-1.666-.686 2.344 2.344 0 0 0 1.666.686zm-.832-3.188a1.177 1.177 0 1 1-.343.832 1.165 1.165 0 0 1 .343-.832zm0 0"
        />
        <path
            fill={fill || 'currentColor'}
            d="M13.066.635L0 13.7l10.27 10.269 13.066-13.065L24 .031zm9.12 9.753L10.27 22.305 1.665 13.7 13.579 1.785l9.163-.505zm0 0"
        />
    </svg>
);

export default Rebate;
