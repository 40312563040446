import React from 'react';

const Website = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <defs>
            <clipPath id="a">
                <path fill="none" d="M0 9.11h144v125.78H0z" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)">
            <path
                fill={fill || 'currentColor'}
                d="M129.46 9.18H14.92A14.77 14.77 0 0 0 0 23.76V88a15.06 15.06 0 0 0 14.92 14.92h54.49v16H33v15.61h78.09v-15.67H74.62v-16h54.84A14.76 14.76 0 0 0 144 88V23.76a14.47 14.47 0 0 0-14.54-14.58zm-23.6 120.09H38.18v-5.21h67.68zm33-41.13a9.54 9.54 0 0 1-9.55 9.54H14.75a9.54 9.54 0 0 1-9.54-9.54V23.93a9.54 9.54 0 0 1 9.54-9.54h114.53a9.54 9.54 0 0 1 9.55 9.54zm0 0"
            />
        </g>
        <path
            fill={fill || 'currentColor'}
            d="M127.77 86.55H16.05V25.38h111.72zM20.39 82.21h103V29.72h-103z"
        />
    </svg>
);

export default Website;
