import React from 'react';

const PaymentMethod = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 48 48"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M44.169 28.133h-8.19a.915.915 0 0 0-.917.916v6.124c0 .507.41.916.916.916h8.19V46.17H3.832V15.133H44.17v13zm-7.276 6.127h7.276v-4.298h-7.276v4.298zm4.173-31.1l.121 10.144H8.86L41.066 3.16zm4.017 10.144h-2.064l-.138-11.4a.91.91 0 0 0-1.189-.86L2.665 13.336l-.026.01a.785.785 0 0 0-.122.049.695.695 0 0 0-.117.069.188.188 0 0 0-.053.039.387.387 0 0 0-.078.068.683.683 0 0 0-.089.105s-.006.003-.003.007a.52.52 0 0 0-.066.098l-.01.02a.967.967 0 0 0-.055.13.673.673 0 0 0-.033.141.692.692 0 0 0-.013.144v32.87c0 .504.41.913.914.913h42.169a.915.915 0 0 0 .917-.913v-32.87a.915.915 0 0 0-.917-.913z"
        />
    </svg>
);

export default PaymentMethod;
