import React from 'react';

const Member = (props) => (
    <svg
        className={`icon ${props.className || ''}`}
        viewBox="0 0 24 24"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            d="M19.188 23.032H4.008V18.28c0-2.24 1.592-4.135 3.694-4.622.303-.071.618-.111.941-.118.04-.003.082-.003.122-.003h5.73a4.549 4.549 0 0 1 1.782.355 4.746 4.746 0 0 1 2.911 4.388v4.752zm-4.693-10.464h-5.73c-3.138 0-5.725 2.572-5.725 5.712V24h17.117v-5.72c0-3.14-2.522-5.712-5.662-5.712zM11.63 8.72a3.88 3.88 0 0 1-3.875-3.875A3.88 3.88 0 0 1 11.63.97a3.88 3.88 0 0 1 3.877 3.875A3.88 3.88 0 0 1 11.63 8.72m0-8.72a4.85 4.85 0 0 0-4.843 4.845 4.849 4.849 0 0 0 4.843 4.843 4.849 4.849 0 0 0 4.845-4.843A4.85 4.85 0 0 0 11.63 0"
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
        />
    </svg>
);

export default Member;
