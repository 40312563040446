import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { UncontrolledTooltip } from 'reactstrap';

import MemberCircle from 'Assets/Icons/member-circle.svg';
import { Image } from 'Components/Misc';

const memberShape = {
    id: PropTypes.string.isRequired,
    imgSrc: PropTypes.string,
    name: PropTypes.string,
};

class MemberPictureList extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        idPrefix: PropTypes.string,
        members: PropTypes.oneOfType([
            MobxPropTypes.observableArrayOf(PropTypes.shape(memberShape)),
            PropTypes.arrayOf(PropTypes.shape(memberShape)),
        ]).isRequired,
    };

    render() {
        const { className, members, idPrefix } = this.props;
        return (
            <ul className={`rounded-picture-list ${className || ''}`}>
                {members &&
                    //TODO: See if we could make the concept of showing the first X elements with a "+Y elements" at the end more generic.
                    members
                        .slice(0, 6)
                        .map((member) => {
                            return (
                                <li className="rounded-picture-list-item" key={member.id}>
                                    <Image
                                        id={`_${idPrefix}${member.id}`}
                                        imgSrc={member.imgSrc}
                                        defaultImgSrc={MemberCircle}
                                    />
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={`_${idPrefix}${member.id}`}
                                    >
                                        {member.name}
                                    </UncontrolledTooltip>
                                </li>
                            );
                        })
                        .concat(
                            members.length <= 6
                                ? []
                                : [
                                      <li
                                          key="and_more"
                                          className="rounded-picture-list-item text-sm text-primary"
                                      >
                                          +{members.length - 6}
                                      </li>,
                                  ]
                        )}
            </ul>
        );
    }
}

export default MemberPictureList;
