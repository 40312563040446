import React, { Component } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { inject } from 'mobx-react';
import { Tooltip } from 'reactstrap';
import { SegmentHeader } from 'Components/Dashboard';
import { MessageIcon } from './';
import { Delete, MarkRead, MarkUnread } from 'Components/Icons';
import { Constants } from 'Data/';
import { StringUtils } from 'Utils/';
import MessageStore from 'Stores/MessageStore';

const { NOTIFICATION_TYPE, MESSAGE_STATUS } = Constants;

@inject('uiStore')
class MessageDetail extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }

    messageStore = new MessageStore();

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    isAvailable = () => {
        const { message, history, t } = this.props;
        this.messageStore.isAvailable(message.entityType, message.entityId, history, t);
    };

    conflictToggle = () => {
        const { conflictToggle, message } = this.props;
        conflictToggle(message.entityId);
    };

    detailButton = (entityType, entityId, t) => {
        const button = (stringKey) => (
            <button
                onClick={
                    entityType ===
                    (NOTIFICATION_TYPE.RECURRENCE || NOTIFICATION_TYPE.BOOKING_CONFLICT)
                        ? this.conflictToggle
                        : this.isAvailable
                }
                className="btn btn-primary w-100 mt-1"
            >
                {t(stringKey)}
            </button>
        );
        switch (entityType) {
            case NOTIFICATION_TYPE.PROPOSAL:
                return button('Message.see_subscription');
            case NOTIFICATION_TYPE.INVOICE:
                return button('Message.see_invoice');
            case NOTIFICATION_TYPE.INVITATION:
                return button('Message.see_invitation');
            case NOTIFICATION_TYPE.RECURRENCE:
                return button('Message.see_conflict');
            case NOTIFICATION_TYPE.BOOKING_CONFLICT:
                return button('Message.see_conflict');
            default:
                return null;
        }
    };

    deleteMessage = async () => {
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Delete size="3.75em" />,
                title: t('Message.delete_message'),
                message: t('Message.delete_message_confirmation'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;
        this.props.setStatus(MESSAGE_STATUS.DELETED);
    };

    toggleReadState = () => {
        const { message } = this.props;
        message.status === MESSAGE_STATUS.UNREAD
            ? this.props.setStatus(MESSAGE_STATUS.READ)
            : this.props.setStatus(MESSAGE_STATUS.UNREAD);
    };

    render() {
        const { message, t } = this.props;
        return message ? (
            <div>
                <SegmentHeader
                    icon={
                        <MessageIcon
                            size="36px"
                            messageType={message.type}
                            notificationBadgeProps={{
                                badgeClassName: 'bg-secondary',
                                badgePosition: Constants.BADGE_POSITIONS.TOP_RIGHT,
                                badgeVisible: message.status === MESSAGE_STATUS.UNREAD,
                            }}
                        />
                    }
                    text={<span className="text-uppercase">{message.title}</span>}
                >
                    <div className="text-sm mt-1">
                        {moment(message.date).format(Constants.DATE_FORMAT)}
                    </div>
                    <button
                        className="close right btn btn-link"
                        onClick={this.deleteMessage}
                    >
                        <Delete size="24px" />
                    </button>

                    <button
                        className="close right btn btn-link mr-6"
                        onClick={this.toggleReadState}
                        id="Tooltip"
                    >
                        {message.status === MESSAGE_STATUS.UNREAD ? (
                            <MarkRead size="24px" />
                        ) : (
                            <MarkUnread size="24px" />
                        )}
                    </button>
                    <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpen}
                        target={'Tooltip'}
                        toggle={this.toggle}
                    >
                        {message.status === MESSAGE_STATUS.UNREAD
                            ? t('Message.mark_as_read')
                            : t('Message.mark_as_unread')}
                    </Tooltip>
                </SegmentHeader>
                <div className="bg-white pt-2 pb-3 px-3 message-body d-flex flex-column">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: StringUtils.sanitize(message.body),
                        }}
                        className="flex-grow-1 break-words"
                    />
                    {message.entityType &&
                        message.entityId &&
                        this.detailButton(message.entityType, message.entityId, t)}
                </div>
            </div>
        ) : null;
    }
}

export default translate()(MessageDetail);
