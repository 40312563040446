import colorVariables from 'Sass/variables.scss';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export default createMuiTheme({
    palette: {
        primary: {
            main: colorVariables.primary,
        },
    },

    typography: {
        fontFamily: "'Alleyn', sans-serif",
    },
    overrides: {
        MuiMenuItem: {
            root: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: colorVariables.primary,
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: colorVariables.white,
            },
            toolbarBtnSelected: {
                color: colorVariables.white,
            },
        },

        MuiPickersDay: {
            daySelected: {
                color: colorVariables.white,
            },
            current: {
                color: colorVariables.primary,
            },
        },
    },
});
