import { injectStripe } from 'react-stripe-elements';
import React from 'react';
import PropTypes from 'prop-types';
import { ApplePay, GooglePay } from 'Components/Icons/';
import { translate } from 'react-i18next';

class PaymentRequestButtons extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
    };

    componentDidMount() {
        const { stripe, invoice, action } = this.props;

        const total = invoice.total.toString().split('.').join('');

        let paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: invoice.currency.toLowerCase(),
            total: {
                label: `Invoice #${invoice.number}`,
                amount: Number(total),
            },
        });
        let button = document.getElementById('payment-request-button');

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(function (result) {
            if (result) {
                button.style.display = 'block';

                // Attach button click to start payment request
                button.addEventListener('click', paymentRequest.show);

                // Switch out button with Apple Pay button
                if (result.applePay) {
                    button.style.backgroundColor = '#000';
                    button.querySelector('.googlepay').style.display = 'none';
                    button.querySelector('.applepay').style.display = 'block';
                }
            } else {
                button.style.display = 'none';
            }
        });

        paymentRequest.on('token', function (ev) {
            action(ev.token);
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div id="paymentRequestButtons" className="pb-2">
                <div id="payment-request-button" className="mt-4">
                    <button
                        type="button"
                        className=" btn btn-dark text-white d-block p-0 mx-auto"
                    >
                        <GooglePay className="googlepay  mx-2" size="5em" />
                        <ApplePay className="applepay mx-2 d-none" size="5em" />
                    </button>
                    <div className="h-separator mx-20 mt-2 text-warm-grey">
                        <span>{t('or')}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(injectStripe(PaymentRequestButtons));
