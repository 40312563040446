import React from 'react';

const RadioSelect = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 32 32"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#E1E1E1"
                d="M16 30C8.268 30 2 23.733 2 16 2 8.268 8.268 2 16 2s14 6.268 14 14c0 7.733-6.268 14-14 14m0-30C7.164 0 0 7.164 0 16c0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16"
            />
            <circle cx={16} cy={16} r={8} fill={fill || 'currentColor'} />
        </g>
    </svg>
);

export default RadioSelect;
