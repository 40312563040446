import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

class ActionResult extends PureComponent {
    static propTypes = {
        component: PropTypes.object,
        textKey: PropTypes.string,
        loginPath: PropTypes.string,
        loginTextKey: PropTypes.string,
    };

    static defaultProps = {
        loginTextKey: 'account.login',
    };

    fallbackToStore = () => {
        window.location.replace(this.props.storeLink);
    };
    openApp = () => {
        window.location.replace(this.props.deepLink);
    };
    triggerAppOpen = () => {
        this.openApp();
        setTimeout(this.fallbackToStore, 250);
    };

    render() {
        const {
            textKey,
            loginPath,
            loginTextKey,
            t,
            component,
            deepLink = null,
        } = this.props;
        return (
            <Fragment>
                {textKey && <div>{t(textKey)}</div>}
                {component}
                {loginPath && loginTextKey ? (
                    !!deepLink ? (
                        <button
                            type="button"
                            onClick={this.triggerAppOpen}
                            className="btn btn-primary mt-3 mt-lg-8 d-block w-75 mx-auto"
                        >
                            {t(loginTextKey)}
                        </button>
                    ) : (
                        <Link
                            to={loginPath}
                            className="btn btn-primary mt-3 mt-lg-8 d-block w-75 mx-auto"
                        >
                            {t(loginTextKey)}
                        </Link>
                    )
                ) : null}
            </Fragment>
        );
    }
}

export default translate()(ActionResult);
