import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react/index';
import { translate } from 'react-i18next';
import { Link, Route } from 'react-router-dom';

import { LoadingSpinner, NoResults } from 'Components/Loading';
import Constants from 'Data/Constants';
import HttpService from 'Services/HttpService';
import LoadingStore from 'Stores/UI/LoadingStore';
import { PaymentMethodOverview } from 'Components/PaymentMethod';
import { Edit, Error } from 'Components/Icons';
import VerifyAchAccount from './VerifyAchAccount';

@inject('uiStore')
@observer
class CurrentPaymentMethod extends React.Component {
    static propTypes = {
        parentMatchURL: PropTypes.string,
        membershipId: PropTypes.string.isRequired,
        editPath: PropTypes.string.isRequired,
        onLoaded: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            paymentMethod: null,
            errored: null,
        };

        this.localLoadingStore = new LoadingStore(this.props.uiStore);
    }

    componentDidMount() {
        this.loadPaymentMethod();
    }

    componentWillUnmount() {
        this.cancellation.cancel();
    }

    loadPaymentMethod = async () => {
        if (this.cancellation) this.cancellation.cancel();

        this.cancellation = HttpService.cancellable();

        this.localLoadingStore.addLoading();
        this.setState({ errored: false });
        try {
            const { data } = await HttpService.paymentMethod(
                {
                    holderType: Constants.HOLDER_TYPE.MEMBERSHIP,
                    holderId: this.props.membershipId,
                },
                this.cancellation
            );

            this.setState({ paymentMethod: data });
            this.props.onLoaded(data);
        } catch (error) {
            if (this.cancellation.isCancelled(error)) return;
            this.setState({ errored: true });
        }
        this.localLoadingStore.removeLoading();
    };

    renderVerifyAchAccount = (routeProps) => {
        const { parentMatchURL } = this.props;
        return (
            <VerifyAchAccount
                {...routeProps}
                paymentMethodId={routeProps.match.params.paymentMethodId}
                parentMatchURL={parentMatchURL}
                onComplete={this.loadPaymentMethod}
            />
        );
    };

    render() {
        const { paymentMethod, errored } = this.state,
            { t, parentMatchURL, editPath } = this.props;
        return (
            <Fragment>
                <div className="content d-flex flex-column">
                    <div className="step-header p-3">
                        <h4 className="text-center mx-auto mt-0 text-uppercase">
                            {t('PaymentMethod.membership_payment_method')}
                        </h4>
                        <Link
                            to={`${parentMatchURL}/${editPath}`}
                            className="close right"
                        >
                            <Edit />
                        </Link>
                    </div>
                    <div className="px-4 px-md-8 px-lg-17 d-flex flex-column justify-content-center flex-grow-1">
                        {paymentMethod ? (
                            <PaymentMethodOverview
                                paymentMethod={paymentMethod}
                                achVerificationPath={`${parentMatchURL}/verify-ach-account/${paymentMethod.id}`}
                            />
                        ) : errored ? (
                            <NoResults
                                icon={<Error size="5em" />}
                                title={t('Error.error')}
                                subTitle={t('Error.error_message')}
                                action={{
                                    text: t('Error.retry'),
                                    onClick: this.loadPaymentMethod,
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <LoadingSpinner loading={this.localLoadingStore.isLoading} />
                <Route
                    path={`${parentMatchURL}/verify-ach-account/:paymentMethodId`}
                    render={this.renderVerifyAchAccount}
                />
            </Fragment>
        );
    }
}

export default translate()(CurrentPaymentMethod);
