import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { translate } from 'react-i18next';
import { values } from 'mobx';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import memoize from 'memoize-one';
import moment from 'moment';

import { Delete, Edit, X } from 'Components/Icons';
import ConflictListItem from 'Components/Booking/ConflictListItem';
import { Constants } from 'Data/index';
import RecurrenceConflictEdit from 'WebApp/Views/Logged/Booking/Recurrence/RecurrenceConflictEdit';

@inject('uiStore')
@observer
class RecurrenceConflicts extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        conflicts: PropTypes.shape({
            data: MobxPropTypes.arrayOrObservableArray,
        }),
        updateConflict: PropTypes.func,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
    };

    mapRecurringConflicts = memoize((recurringConflicts) =>
        recurringConflicts
            .filter((recurringConflict) => !recurringConflict.isCancelled)
            .map((recurringConflict) => ({
                withImage: false,
                id: recurringConflict.id,
                title: moment
                    .parseZone(recurringConflict.periodStart)
                    .format(Constants.LONG_TEXT_DATE_FORMAT),
                subTitle:
                    moment
                        .parseZone(recurringConflict.periodStart)
                        .format(Constants.SHORT_TIME_FORMAT) +
                    ' - ' +
                    moment
                        .parseZone(recurringConflict.periodEnd)
                        .format(Constants.SHORT_TIME_FORMAT),
                amenityId: recurringConflict.amenityId,
                treated: recurringConflict.treated,
                reason: recurringConflict.reason,
                amenity: recurringConflict.amenity,
            }))
    );

    cancelBooking = (id) => async () => {
        const {
            uiStore: { simpleModalStore },
            store,
            t,
        } = this.props;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Delete size="3.75em" />,
                title: t('Booking.delete_booking_title'),
                message: t('Booking.delete_booking_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        store.updateConflict(id, {
            isCancelled: true,
            treated: true,
        });
    };

    editBooking = (id) => () => {
        const { store } = this.props;
        store.updateState('currentConflictId', id);
    };

    onBookingEdited = () => {
        const { store } = this.props;
        store.updateState('currentConflictId', '');
    };

    render() {
        const { isOpen, onCancel, onSubmit, store, t } = this.props;

        const items =
            store.conflicts && store.conflicts.data
                ? this.mapRecurringConflicts(values(store.conflicts.data))
                : [];
        return (
            <>
                <Modal isOpen={isOpen}>
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={onCancel}
                        >
                            <span aria-hidden="true">
                                <X fill="currentColor" size="24px" />
                            </span>
                        </button>
                        <h5 className="modal-title mx-auto">
                            {t('Booking.booking_conflicts')}
                        </h5>
                    </div>
                    <ModalBody>
                        <div className="bg-light-grey p-3">
                            <p
                                className="text-center text-sm font-weight-medium mb-3"
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        store.hasRemainingConflicts
                                            ? 'Booking.recurrence_not_available'
                                            : 'Booking.conflicts_resolved_message'
                                    ),
                                }}
                            />
                            {items.length ? (
                                <ul className="list-group">
                                    {items.map((item) => (
                                        <ConflictListItem
                                            tag="li"
                                            className="list-group-item-spaced"
                                            key={item.id}
                                            {...item}
                                            action={
                                                <div className="action text-right">
                                                    <span
                                                        className="c-pointer pr-2 text-primary"
                                                        onClick={this.cancelBooking(
                                                            item.id
                                                        )}
                                                    >
                                                        <Delete size="1.5em" />
                                                    </span>
                                                    <span
                                                        className="c-pointer text-primary"
                                                        onClick={this.editBooking(
                                                            item.id
                                                        )}
                                                    >
                                                        <Edit size="1.5em" />
                                                    </span>
                                                </div>
                                            }
                                        />
                                    ))}
                                </ul>
                            ) : null}
                        </div>
                        <div className="p-3 d-flex">
                            <button
                                type="button"
                                className="btn btn-light flex-1 mr-1 font-weight-bold bottom-action bottom-action-small border"
                                onClick={onCancel}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary flex-1 ml-1 font-weight-bold bottom-action bottom-action-small"
                                onClick={onSubmit}
                            >
                                {t('Booking.confirm_recurring_booking')}
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <RecurrenceConflictEdit
                    isOpen={store.currentConflictId}
                    store={store}
                    onComplete={this.onBookingEdited}
                    timezone={this.props.timezone}
                />
            </>
        );
    }
}

export default translate()(RecurrenceConflicts);
