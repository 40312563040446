import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { translate } from 'react-i18next';

class Dropdown extends React.PureComponent {
    static propTypes = {
        button: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        keepOpen: PropTypes.bool,
        fullWidth: PropTypes.bool,
    };

    state = { open: false };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Keep reference to DOM element
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    // Close dropdown on outside click
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.dismiss();
        }
    };

    toggle = () => {
        const newState = !this.state.open;
        this.setState({
            open: newState,
        });
    };

    dismiss = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { button, children, t, keepOpen, fullWidth, className = '' } = this.props,
            { open } = this.state;
        const Button = button;

        return (
            <div
                className={`${className} multi-select-dropdown`}
                ref={this.setWrapperRef}
            >
                <Button t={t} onClick={this.toggle} open={open} />
                <div
                    className={classNames(
                        'multi-select-dropdown-items',
                        { open: open },
                        { 'w-100': fullWidth }
                    )}
                    onClick={keepOpen ? undefined : this.toggle}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export { Dropdown as DropdownClass };
export default translate()(Dropdown);
