import React from 'react';

const I = ({ fill, size, className, ...props }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 20 21"
        width={size || '1em'}
        height={size || '1em'}
        {...props}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M10.074.74A9.574 9.574 0 0 0 .5 10.316a9.575 9.575 0 0 0 19.149 0A9.574 9.574 0 0 0 10.074.74m0 1.05c4.701 0 8.525 3.824 8.525 8.524 0 4.7-3.824 8.525-8.525 8.525-4.7 0-8.524-3.824-8.524-8.525 0-4.7 3.824-8.525 8.524-8.525" />
            <path d="M10.03 5.158a1.056 1.056 0 1 0-.001 2.112 1.056 1.056 0 0 0 0-2.112m0 1.05h-.002.002m.011 2.314H8.447a.386.386 0 0 0 0 .772h.739c-.004.03-.01.06-.01.092v4.853c0 .033.007.065.01.098h-.74a.386.386 0 0 0 0 .77h3.257a.386.386 0 0 0 0-.77h-.809c.004-.033.01-.065.01-.098V9.386L10.9 8.9c0-.212-.128-.376-.34-.376h-.506l-.014-.001" />
        </g>
    </svg>
);

export default I;
