import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';
import { If } from 'react-if';
import MembersRights from 'WebApp/Views/Logged/Membership/MembersRights';
import { Member } from 'Components/Icons';
import { inject, observer } from 'mobx-react';
import { AddMembersUtils } from 'Utils/';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

@inject('uiStore')
@observer
class MembersRightsStep extends React.Component {
    componentDidMount = () => {
        const {
            store: { memberRightsStep },
            history,
            parentMatchURL,
            store,
        } = this.props;
        store.modalSize &&
            UpdateStateStoreUtils(store, 'modalSize', `${store.modalSize} modal-xl`);
        if (!memberRightsStep) {
            history.replace(`${parentMatchURL}/members-rights/skip`);
        }
    };

    nextStep = async () => {
        const {
            uiStore: { loadingStore, toastStore },
            onComplete,
            stepKey,
            t,
            store,
        } = this.props;
        loadingStore.addLoading();
        await AddMembersUtils(loadingStore, toastStore, t, store);
        onComplete(stepKey, null);
        loadingStore.removeLoading();
    };

    editMembers = () => {
        const { store, history, parentMatchURL } = this.props;
        UpdateStateStoreUtils(store, 'memberRightsStep', true);
        history.replace(`${parentMatchURL}/members-rights`);
    };

    renderSkipChoice = () => {
        const { t } = this.props;

        return (
            <div className="flex-grow-1 d-flex flex-column bg-dark segment-body text-center">
                <div className="bg-black text-center text-sm py-2 px-10">
                    <Member size="3em" className="mb-2 text-secondary" />
                    <div>{t('Membership.company_members_step_info')}</div>
                </div>
                <div className="d-flex flex-column justify-content-center flex-grow-1">
                    <button
                        className="btn btn-primary w-100 mb-1 rounded-0"
                        onClick={this.editMembers}
                    >
                        {t('yes')}
                    </button>
                    <button
                        className="btn btn-light w-100 mb-3 rounded-0"
                        onClick={this.nextStep}
                    >
                        {t('Membership.not_for_now_only_send_invitations')}
                    </button>
                </div>
            </div>
        );
    };

    renderMembersRights = () => {
        const { store } = this.props;
        return (
            <MembersRights
                {...this.props}
                membershipId={store.membershipId}
                nextStep={this.nextStep}
            />
        );
    };

    render() {
        const { parentMatchURL, store } = this.props;
        return (
            <Fragment>
                <If condition={store.memberRightsStep}>
                    <Route
                        path={`${parentMatchURL}/members-rights`}
                        render={this.renderMembersRights}
                    />
                </If>
                <Route
                    exact
                    path={`${parentMatchURL}/members-rights/skip`}
                    render={this.renderSkipChoice}
                />
            </Fragment>
        );
    }
}

export default translate()(MembersRightsStep);
