import React from 'react';

const CheckOutline = (props) => (
    <svg
        className="icon"
        viewBox="0 0 48 48"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
            d="M41.7 3L18.538 34.844l-13.263-12.2L0 28.378l19.697 18.12L48 7.582 41.7 3zm.375 2.377l3.548 2.58-26.18 35.995L2.406 28.277l2.97-3.229L18.791 37.39 42.075 5.377z"
        />
    </svg>
);

export default CheckOutline;
