import React from 'react';

const MarkRead = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M22.435 21.02c0 .33-.084.646-.243.929l-6.56-6.722-.791-.812.088-.07 5.62-4.434.882-.695.907-.717c.064.19.097.393.097.6V21.02zm-1.912 1.91H2.816c-.355 0-.697-.097-.994-.279l6.14-6.375.948-1.049.798-.882.068-.074h-.003l.077-.083 1.775-1.859 1.432 1.475.477.49.048.05.821.883.803.863.005.005 6.364 6.518a1.89 1.89 0 0 1-1.052.317zM.906 21.02V9.1c0-.201.033-.399.092-.588l.975.768.882.695 5.547 4.37.092.072-.781.81-6.531 6.781a1.877 1.877 0 0 1-.276-.988zm.59-13.298l.002.003.348-.227.127-.084v.714l-.495-.39.018-.016zm1.36-5.016h17.692v6.05l-6.365 5.025-2.181-2.244-.004-.005a.413.413 0 0 0-.427-.215l-.023.005a.408.408 0 0 0-.294.207l-2.13 2.232-6.269-4.938V2.706zm8.833-1.642l1.162.76h-2.324l1.162-.76zm9.742 6.365l.323.212c.034.028.065.058.096.087l-.42.33v-.63zm1.647.485a2.376 2.376 0 0 0-.15-.281c-.02-.03-.034-.061-.056-.087-.366-.45-.483-.487-1.441-1.098V1.824h-7.077L11.94.247a.43.43 0 0 0-.534.022s-1.031.655-2.443 1.555h-6.99V6.3c-.548.356-.9.588-.952.633a2.792 2.792 0 0 0-.562.63A2.813 2.813 0 0 0 0 9.1v11.92c0 .756.294 1.463.832 1.994a2.793 2.793 0 0 0 1.984.822h17.707a2.79 2.79 0 0 0 2.042-.88 2.792 2.792 0 0 0 .778-1.936V9.1c0-.415-.093-.818-.265-1.186z" />
            <path d="M14.259 4.571a.412.412 0 0 0-.562.151l-2.634 4.561L9.59 7.924a.412.412 0 0 0-.601.563l1.883 1.737a.412.412 0 0 0 .582.019c.05-.047 2.956-5.109 2.956-5.109a.412.412 0 0 0-.151-.563" />
        </g>
    </svg>
);

export default MarkRead;
