import { inject } from 'mobx-react';
import { translate } from 'react-i18next';
import qs from 'qs';
import HttpService from 'Services/HttpService';
import ActionBase from './ActionBase';
import PaymentRequestButtons from './PaymentRequestButtons';
import { InvoiceSummary } from 'Components/Invoice';
import React, { Fragment } from 'react';
import { ListItem } from 'Components/Forms';
import { Invoices, Memberships, X } from 'Components/Icons';
import { MemberPictureList } from 'Components/Membership';
import { Constants } from 'Data';
import memoize from 'memoize-one';
import PaymentMethodStore from 'Stores/PaymentMethod/PaymentMethodStore';
import { CreditCardForm } from 'Components/PaymentMethod';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import colorVariables from 'Sass/variables.scss';

async function handleInvoiceActionError(error) {
    if (error.response) {
        if (error.response.status === 404) {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        } else if (
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error === 'E008016'
        ) {
            const { location } = this.props;
            const params = qs.parse(location.search, { ignoreQueryPrefix: true });
            const { data: invoice } = await HttpService.fetchInvoiceByToken(
                params.invoiceId,
                null,
                params.token
            );
            this.setState({
                loginTextKey: 'Account.login',
                resultMessageKey: 'Invoice.invoice_already_paid',
                loginPath: `/dashboard/company/detail/${invoice.membership.id}/invoices/${params.invoiceId}`,
            });
            return true;
        } else if (error.response.config &&
            error.response.config.headers &&
            error.response.config.headers['Limited-Access-Token']) {
                this.setState({
                    resultMessageKey: 'Error.expired_link',
                    loginTextKey: 'Account.login',
                    loginPath: '/',
                });
            return true;
        } else if (error.response.status) {
            this.setState({
                resultMessageKey: 'Error.error_message',
                loginTextKey: 'Account.login',
                loginPath: '/',
            });
            return true;
        }
    }
    return false;
}

@inject('uiStore')
class PayInvoiceAsGuest extends ActionBase {
    constructor(props) {
        super(props);

        this.actionCall = async () => {
            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            //#TODO change for the right end point
            const { data: invoice } = await HttpService.fetchInvoiceByToken(
                params.invoiceId,
                null,
                params.token
            );

            invoice && invoice.status !== Constants.INVOICE_STATUSES.SUCCESS
                ? this.setState({
                      classComponent: 'component',
                      component: (
                          <InvoiceGuest
                              invoice={invoice}
                              token={params.token}
                              {...this.props}
                          />
                      ),
                  })
                : this.setState({
                      loginTextKey: 'Account.login',
                      resultMessageKey: 'Invoice.invoice_already_paid',
                      loginPath: `/dashboard/company/detail/${invoice.membership.id}/invoices/${params.invoiceId}`,
                  });
        };

        this.handleError = handleInvoiceActionError.bind(this);
    }
}

const defaultMembershipImg = (
    <div className="img rounded-circle">
        <Memberships size="50%" />
    </div>
);

class InvoiceGuest extends React.Component {
    constructor(props) {
        super(props);

        const {
            uiStore: { loadingStore, toastStore },
            invoice,
        } = this.props;
        this.paymentMethodStore = new PaymentMethodStore(
            invoice.paymentCustomer.id,
            invoice.paymentCustomer.type,
            loadingStore,
            toastStore
        );
        this.state = {
            modal: true,
            stripe: window.Stripe(invoice.stripePublicKey),
            isPaid: false,
            loginTextKey: 'Account.login',
            resultMessageKey: 'Invoice.success_paid_invoice_message',
            loginPath: `/dashboard/company/detail/${invoice.membership.id}/invoices/${invoice.id}`,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    mapMembers = memoize((members) =>
        members.map((member) => ({
            id: member.id,
            imgSrc: member.imageUrl,
            name: `${member.firstName} ${member.lastName}`,
        }))
    );

    tryPayment = async (stripeToken) => {
        const {
            uiStore: { loadingStore, toastStore },
            invoice,
            t,
            token,
        } = this.props;
        try {
            loadingStore.addLoading();
            await HttpService.tryPaymentWithToken({
                id: invoice.id,
                token: token,
                stripeToken: stripeToken.id,
                ignoreErrorToast: true,
            });
            toastStore.enqueueToast({
                message: t('Invoice.success_paid_invoice_message'),
                icon: Invoices,
            });
            this.setState({ isPaid: true });
        } catch (err) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    render() {
        const { invoice, t } = this.props,
            { isPaid, loginPath, loginTextKey, resultMessageKey } = this.state;
        const membersItems =
            invoice.membership && invoice.membership.members
                ? this.mapMembers(invoice.membership.members)
                : [];
        let paymentStepLink = '/payment-method';
        if (invoice.paymentMethod) {
            if (
                invoice.paymentMethod.paymentMethodType ===
                Constants.PAYMENT_METHOD_TYPES.ACH
            ) {
                invoice.paymentMethod.status === Constants.ACH_STATUS.UNVERIFIED
                    ? (paymentStepLink = `/verify-ach-account/${invoice.paymentMethod.id}`)
                    : (paymentStepLink = '');
            } else if (
                invoice.paymentMethod.paymentMethodType ===
                Constants.PAYMENT_METHOD_TYPES.CREDIT_CARD
            ) {
                paymentStepLink = '';
            }
        }

        return (
            <Fragment>
                <div className="row no-gutters">
                    <div className="col-lg-5 col-12">
                        <div className="h3 py-4 border-bottom border-bg-body text-center">
                            {t('Invoice.pay_invoice_guest_title')}
                        </div>
                        <ListItem
                            key={invoice.membership.id}
                            withImage
                            className="list-group-item-spaced border-bottom border-bg-body"
                            defaultImg={defaultMembershipImg}
                            title={invoice.membership.name}
                            subTitle={
                                <MemberPictureList
                                    members={membersItems}
                                    className="mt-1"
                                    idPrefix={invoice.membership.id}
                                />
                            }
                            imgSrc={invoice.membership.imageUrl}
                        />
                        <InvoiceSummary
                            invoiceSummary={invoice}
                            hideSecDepInfo
                            guestPayment
                            isPaid={isPaid}
                        />
                    </div>
                    <div
                        className={classnames('bg-body col-lg-7 col-12', {
                            'd-flex': isPaid,
                        })}
                    >
                        {this.state.stripe && (
                            <StripeProvider stripe={this.state.stripe}>
                                <Elements>
                                    <Fragment>
                                        {!isPaid && (
                                            <PaymentRequestButtons
                                                invoice={invoice}
                                                stripe={this.state.stripe}
                                                action={this.tryPayment}
                                            />
                                        )}
                                        <div
                                            className={classnames(
                                                'bg-white mt-2 mb-4 mx-lg-10 ',
                                                {
                                                    'd-flex flex-grow-1 align-items-center justify-content-center': isPaid,
                                                }
                                            )}
                                        >
                                            {!isPaid ? (
                                                <CreditCardForm
                                                    store={this.paymentMethodStore}
                                                    path={'credit-card'}
                                                    payAsGuest
                                                    action={this.tryPayment}
                                                />
                                            ) : (
                                                <div className="p-2 p-lg-5 text-center">
                                                    <h5>{t(resultMessageKey)}</h5>
                                                    <Link
                                                        to={loginPath}
                                                        className="btn btn-primary mt-3 mt-lg-8 d-block w-75 mx-auto"
                                                    >
                                                        {t(loginTextKey)}
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </Fragment>
                                </Elements>
                            </StripeProvider>
                        )}
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="modal-dialog-centered modal-dark"
                >
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={this.toggle}
                        >
                            <span aria-hidden="true">
                                <X fill="currentColor" size="24px" />
                            </span>
                        </button>
                        <h4 className="text-center mx-auto mt-3 text-uppercase">{''}</h4>
                    </div>
                    <ModalBody>
                        <div className="flex-grow-1">
                            <div className="pt-5 pb-10 contents h-100 d-flex flex-column align-item-center  px-3 px-xl-7">
                                <div className="header align-item-center">
                                    <Invoices
                                        fill={colorVariables.secondary}
                                        size="3.75em"
                                        className="d-block mx-auto mb-3 mb-xl-4 mt-4"
                                    />
                                </div>
                                <div className="flex-grow-1 align-item-center w-100">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('Invoice.pay_as_guest_explain'),
                                        }}
                                        className="mb-5 text-center"
                                    />
                                    <Link
                                        className="btn btn-primary w-100 mb-3"
                                        to={`/dashboard/company/detail/${invoice.membership.id}/invoices/${invoice.id}${paymentStepLink}`}
                                    >
                                        {t('Invoice.pay_by_login')}
                                    </Link>
                                    <button
                                        onClick={this.toggle}
                                        type="button"
                                        className="btn btn-light w-100 mb-3"
                                    >
                                        {t('Invoice.one_time_payment')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export { handleInvoiceActionError };

export default translate()(PayInvoiceAsGuest);
