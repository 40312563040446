import React from 'react';

const EMail = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 36 36"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M.357 6.51l.038.03a4.65 4.65 0 0 1 .313-.568l-.35.538zm35-.427c.086.14.163.282.23.432l.038-.03-.267-.403zM1.125 5.335l-.417.637c.14-.212.297-.412.47-.595l-.053-.042zm33.725-.018l-.053.043c.173.18.33.375.473.587l-.42-.63z" />
            <path d="M31.648 29.672H4.343c-.548 0-1.075-.15-1.533-.432L14.2 17.415l3.795 2.988 3.785-2.988 11.49 11.768c-.478.32-1.038.49-1.623.49m-30.25-2.945V8.341c0-.307.05-.612.143-.902l11.558 9.105L1.822 28.25a2.899 2.899 0 0 1-.425-1.523m2.946-21.33h27.305c.772 0 1.502.303 2.047.833l-15.7 12.393L2.28 6.245a2.94 2.94 0 0 1 2.063-.847m30.254 2.945v18.384a2.9 2.9 0 0 1-.375 1.43L22.885 16.543l11.562-9.125c.098.294.15.607.15.925m.99-1.828a3.974 3.974 0 0 0-.23-.432l-.087-.135a4.34 4.34 0 0 0-.473-.588A4.324 4.324 0 0 0 31.648 4H4.344a4.318 4.318 0 0 0-3.165 1.378A4.354 4.354 0 0 0 .395 6.54 4.304 4.304 0 0 0 0 8.343v18.384c0 1.165.455 2.256 1.282 3.075a4.318 4.318 0 0 0 3.06 1.268h27.306c1.2 0 2.32-.483 3.15-1.358a4.303 4.303 0 0 0 1.197-2.985V8.343a4.29 4.29 0 0 0-.407-1.828" />
        </g>
    </svg>
);

export default EMail;
