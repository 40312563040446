import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';

import HttpService from 'Services/HttpService';
import CONSTANTS from 'Data/Constants';
import DropdownFloorPlan from 'Components/FloorPlan/DropdownFloorPlan';
import FloorPlanSvg from 'Components/FloorPlan/FloorPlanSvg';
import SearchCompanyOnFloorPlan from 'Components/FloorPlan/SearchCompanyOnFloorPlan';
import Profile from 'WebApp/Views/Logged/Membership/Profile';
import Constants from 'Data/Constants';
import CommunalAreaModal from 'Components/FloorPlan/CommunalAreaModal';

const initialState = {
    floors: [],
    units: [],
    selectedFloor: {},
    selectedCampus: {},
    profileToggle: false,
    communalAreaToggle: false,
    membershipId: null,
    subscriptions: [],
    communalAreaModalTitle: '',
};

export const mapUnitsToFloor = (unitAreas, unitOccupancyStatuses) => {
    let units = [];
    unitAreas.forEach((unitArea) => {
        unitOccupancyStatuses.forEach((unitOccupancyStatus) => {
            if (unitOccupancyStatus.id === unitArea.unitId) {
                // Remove unitId because we already have same id from unitOccupancy.id
                const { unitId, ...unitAreaRest } = unitArea;
                units.push({
                    ...unitOccupancyStatus,
                    ...unitAreaRest,
                });
            }
        });
    });

    return units;
};

export const loadFloorUnits = async (unitIds, cancelSource) => {
    const { data } = await HttpService.unitOccupancyStatuses(
        { unitIds, campusesWithManagementRights: true, unitType: 'All' },
        cancelSource
    );

    const filteredData = data.filter(
        (el) =>
            el.type === CONSTANTS.UNIT_TYPES.COMMUNAL ||
            el.type === CONSTANTS.UNIT_TYPES.ENTERPRISE
    );

    return filteredData;
};

@inject('dashboardStore', 'uiStore', 'floorPlanStore')
@observer
class FloorPlan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...initialState,
        };
    }

    componentDidMount() {
        const { floorPlanStore } = this.props;
        if (floorPlanStore.campusId && floorPlanStore.unitId) {
            this.loadFloorPlan(floorPlanStore.campusId, floorPlanStore.unitId);
            floorPlanStore.updateState('campusId', false);
        } else {
            this.loadFloorPlan();
        }
    }

    loadFloorPlan = async (campusId = null, unitId = null) => {
        const {
            dashboardStore,
            uiStore: { loadingStore },
        } = this.props;

        if (!this.cancellation) this.cancellation = HttpService.cancellable();

        loadingStore.addLoading();

        try {
            const { data } = await HttpService.floorPlan(
                campusId || dashboardStore.profile.data.activeCampuses[0].id,
                this.cancellation
            );

            const selectedCampus = campusId
                ? dashboardStore.profile.data.activeCampuses.find(
                      (activeCampus) => activeCampus.id === campusId
                  )
                : dashboardStore.profile.data.activeCampuses[0];

            // If campus has floors
            if (data.floors.length > 0) {
                const unitIds = data.floors[0].unitAreas.map(
                    (unitArea) => unitArea.unitId
                );
                const unitOccupancyStatuses = await loadFloorUnits(
                    unitIds,
                    this.cancellation
                );

                const units = mapUnitsToFloor(
                    data.floors[0].unitAreas,
                    unitOccupancyStatuses
                );

                this.setState({
                    floors: data.floors,
                    selectedFloor: unitId
                        ? data.floors.find((floor) =>
                              floor.unitAreas.find((unit) => unit.unitId === unitId)
                          )
                        : data.floors[0],
                    units,
                    selectedCampus,
                });
            } else {
                this.setState({
                    ...initialState,
                    selectedCampus,
                });
            }
        } catch (errors) {
            return;
        } finally {
            loadingStore.removeLoading();
        }
    };

    onCampusChange = (campusId) => {
        if (campusId !== this.state.selectedCampus.id) this.loadFloorPlan(campusId);
    };

    onFloorChange = async (floorId) => {
        if (floorId !== this.state.selectedFloor.id) {
            const selectedFloor = this.state.floors.find((floor) => floor.id === floorId);

            const unitIds = selectedFloor.unitAreas.map((unitArea) => unitArea.unitId);
            const unitOccupancyStatuses = await loadFloorUnits(
                unitIds,
                this.cancellation
            );

            const units = mapUnitsToFloor(selectedFloor.unitAreas, unitOccupancyStatuses);

            this.setState({ selectedFloor, units });
        }
    };

    mapDropdownItems = (items) => {
        return items.map((item) => ({
            ...item,
            title: item.name,
        }));
    };

    handleUnitInformationClick = (unit) => {
        if (unit.type === Constants.UNIT_TYPES.ENTERPRISE) {
            this.setState({
                profileToggle: true,
                membershipId: unit.subscriptions[0].membership.id,
            });
        } else if (unit.type === Constants.UNIT_TYPES.COMMUNAL) {
            this.setState({
                communalAreaToggle: true,
                subscriptions: unit.subscriptions,
                communalAreaModalTitle: unit.name,
            });
        }
    };

    handleCommunalAreaModalClose = () => {
        this.setState({
            communalAreaToggle: false,
            subscriptions: [],
        });
    };

    handleCommunalAreaClick = (subscription) => {
        this.setState({
            profileToggle: true,
            membershipId: subscription.membership.id,
        });
    };

    render() {
        const { t, dashboardStore, floorPlanStore } = this.props;

        return (
            <div className="floorplan-container w-100 mt-3">
                <div className="d-flex w-100">
                    <div className="floorplan-dropdowns d-flex flex-wrap align-items-start align-self-start flex-shrink-0">
                        {dashboardStore.profile.data.activeCampuses.length > 1 && (
                            <DropdownFloorPlan
                                items={this.mapDropdownItems(
                                    dashboardStore.profile.data.activeCampuses
                                )}
                                onChange={this.onCampusChange}
                                value={
                                    this.state.selectedCampus.id
                                        ? this.state.selectedCampus.id
                                        : ''
                                }
                                loadingText={t('loading.loading_message')}
                            />
                        )}

                        {this.state.floors.length > 0 && (
                            <DropdownFloorPlan
                                items={this.mapDropdownItems(this.state.floors)}
                                onChange={this.onFloorChange}
                                value={
                                    this.state.selectedFloor.id
                                        ? this.state.selectedFloor.id
                                        : ''
                                }
                                loadingText={t('loading.loading_message')}
                            />
                        )}

                        <SearchCompanyOnFloorPlan t={t} />
                    </div>
                    {this.state.floors.length > 1 ? (
                        <FloorPlanSvg
                            floor={this.state.selectedFloor}
                            units={this.state.units}
                            className="d-flex ml-1"
                            unitId={floorPlanStore.unitId}
                            onUnitInformationClick={this.handleUnitInformationClick}
                        />
                    ) : (
                        <div className="no-results d-flex flex-column align-items-center justify-content-center text-center w-100">
                            <h3>{t('FloorPlan.no_available_floorplan_title')}</h3>
                            <p>{t('FloorPlan.no_available_floorplan_message')}</p>
                        </div>
                    )}
                </div>

                {this.state.profileToggle && (
                    <Profile
                        onClose={() => this.setState({ profileToggle: false })}
                        membershipId={this.state.membershipId}
                        isMemberProfile={false}
                        isOpen={this.state.profileToggle}
                        modal
                    />
                )}

                {this.state.communalAreaToggle && (
                    <CommunalAreaModal
                        isOpen={this.state.communalAreaToggle}
                        title={this.state.communalAreaModalTitle}
                        subscriptions={this.state.subscriptions}
                        onClose={this.handleCommunalAreaModalClose}
                        communalAreaClick={this.handleCommunalAreaClick}
                    />
                )}
            </div>
        );
    }
}

export default translate()(FloorPlan);
