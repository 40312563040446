import React from 'react';

const DeclineCircle = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 40 40"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill="none" fillRule="evenodd">
            <circle
                cx={19}
                cy={19}
                r={19}
                fill={fill || 'currentColor'}
                transform="translate(1 1)"
            />
            <g fill="#FFF" fillRule="nonzero">
                <path d="M14.987 15.666a.48.48 0 0 1 .68-.679l9.489 9.49a.48.48 0 0 1-.68.679l-9.489-9.49z" />
                <path d="M15.666 25.156a.48.48 0 0 1-.679-.68l9.49-9.489a.48.48 0 0 1 .679.68l-9.49 9.489z" />
            </g>
        </g>
    </svg>
);

export default DeclineCircle;
