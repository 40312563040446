import scrollToComponent from 'react-scroll-to-component';

class ScrollUtils {
    registerRef = (node) => {
        this.componentRef = node;
    };

    scrollToRef = () => {
        // Defer scrolling to next loop cycle to ensure that the component
        // is rendered so the offset can be properly computed.
        setTimeout(() => {
            scrollToComponent(this.componentRef, {
                align: 'top',
            });
        }, 0);
    };
}

export default ScrollUtils;
