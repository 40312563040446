import React from 'react';
import uuidv4 from 'uuid/v4';

const Membership = (props) => {
    const id = `_${uuidv4()}`;
    return (
        <svg
            className="icon"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 54 54"
            width={props.size || '1em'}
            height={props.size || '1em'}
        >
            <defs>
                <circle id={`${id}a`} cx={27} cy={27} r={27} />
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill="#E1E1E1" xlinkHref={`#${id}a`} />
                <path
                    d="M17.47 25.1a2.05 2.05 0 0 1 .43-.052c.016-.002.032-.002.047-.002l2.773.006a2.076 2.076 0 0 1 .799.161 2.124 2.124 0 0 1 1.303 1.974l-.005 2.136-7.016-.015.005-2.136A2.153 2.153 0 0 1 17.47 25.1m3.251-.845l-2.772-.007a2.937 2.937 0 0 0-2.943 2.922L15 30.103l8.614.019.006-2.933c.003-1.614-1.297-2.931-2.899-2.934m6.274 3.601a1.713 1.713 0 0 1-1.712-1.71 1.713 1.713 0 0 1 3.423 0c0 .942-.767 1.71-1.711 1.71m0-4.22a2.512 2.512 0 0 0-2.51 2.51 2.511 2.511 0 0 0 2.51 2.508 2.51 2.51 0 0 0 2.507-2.508 2.51 2.51 0 0 0-2.507-2.51m-1.865 6.935c.137-.031.278-.05.432-.053l.045-.001h2.774l.055.001c.257.007.508.059.744.158a2.122 2.122 0 0 1 1.306 1.971v2.136H23.47v-2.136c0-.98.698-1.853 1.66-2.076m3.25-.852h-2.773a2.934 2.934 0 0 0-2.935 2.928v2.933h8.614v-2.933c0-1.615-1.303-2.928-2.905-2.928m6.329-7.331a1.713 1.713 0 0 1-1.713-1.71c0-.942.768-1.71 1.712-1.71.942 0 1.71.768 1.71 1.71 0 .943-.768 1.71-1.71 1.71m0-4.219a2.512 2.512 0 0 0-2.51 2.51 2.511 2.511 0 0 0 2.51 2.507 2.51 2.51 0 0 0 2.508-2.508 2.511 2.511 0 0 0-2.508-2.509m-1.865 6.935c.137-.032.28-.05.433-.053.015-.002.031-.002.044-.002h2.774a2.077 2.077 0 0 1 .8.16 2.122 2.122 0 0 1 1.306 1.97v2.136h-7.017V27.18c0-.981.698-1.853 1.66-2.076m3.251-.853h-2.774a2.935 2.935 0 0 0-2.934 2.929v2.933H39V27.18c0-1.614-1.303-2.929-2.905-2.929m-16.751-1.863h-.006a1.7 1.7 0 0 1-1.206-.503 1.697 1.697 0 0 1-.499-1.21c0-.457.18-.886.504-1.208.324-.32.751-.499 1.207-.499h.003a1.712 1.712 0 0 1 1.706 1.714 1.712 1.712 0 0 1-1.71 1.706m1.777-3.48a2.492 2.492 0 0 0-1.772-.739h-.006a2.49 2.49 0 0 0-1.768.731 2.497 2.497 0 0 0-.74 1.773 2.512 2.512 0 0 0 2.503 2.513h.007c.666 0 1.294-.26 1.77-.731a2.491 2.491 0 0 0 .74-1.772c0-.668-.26-1.3-.734-1.774"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
};

export default Membership;
