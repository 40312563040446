import React from 'react';

const PersonalInfo = ({ fill = 'currentColor', size = '1em' }) => (
    <svg className="icon" viewBox="0 1 24 24" width={size} height={size}>
        <path
            d="M11.64 3.353c1.88 0 3.41 1.53 3.41 3.41 0 1.88-1.53 3.41-3.41 3.41-1.88 0-3.41-1.53-3.41-3.41 0-1.88 1.53-3.41 3.41-3.41m0 7.673a4.268 4.268 0 0 0 4.262-4.263A4.268 4.268 0 0 0 11.64 2.5a4.268 4.268 0 0 0-4.262 4.263 4.268 4.268 0 0 0 4.262 4.263m6.65 11.741h-3.382L12.063 17.8l1.85-3.387h.248c2.292 0 4.129 1.88 4.129 4.173v4.182zm-6.708-4.074l2.342 4.074H9.341l2.24-4.074zm-6.65-.108c0-2.292 1.894-4.173 4.186-4.173h.025l1.941 3.396-2.716 4.96H4.933v-4.183zm8.01-4.173l-1.377 2.502-1.438-2.502h2.814zm1.219-.852H9.118c-2.762 0-5.038 2.263-5.038 5.025v5.035h15.062v-5.035c0-2.762-2.219-5.025-4.981-5.025z"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
);

export default PersonalInfo;
