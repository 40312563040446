import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import ConferenceRoomBackground from 'Assets/Images/ConferenceRoom.jpg';
import { InfoPane } from 'Components/Modal';
import { ConferenceRoomActionPane, ConferenceRoomStepBase } from 'Components/Booking';
import { sortBy } from 'Utils/ObjectUtils';

@inject('uiStore', 'dashboardStore')
@observer
class ConferenceRoomStep extends ConferenceRoomStepBase {
    componentDidMount() {
        const { store, storeKey } = this.props;

        if (store[storeKey] && store.timeSlot) {
            this.updateRoomCost(store[storeKey]);
        }
    }

    componentWillUnmount() {
        if (this.cancellation) this.cancellation.cancel();
    }

    updateSelectedConferenceRoom = (selectedConferenceRoomId) => {
        const { storeKey, store } = this.props;
        store.updateState(storeKey, selectedConferenceRoomId);
        this.updateRoomCost(selectedConferenceRoomId);
    };

    updateRoomCost = (selectedConferenceRoomId) => {
        const { store } = this.props,
            { timeSlot } = store;
        const amenity = timeSlot.amenities.find(
            (room) => room.id === selectedConferenceRoomId
        );
        this.setState({
            totalCreditCost:
                (amenity.creditPointsPerBlock * timeSlot.duration) /
                amenity.minutesPerBlock,
        });
    };

    nextStep = () => {
        const {
            onComplete,
            store,
            storeKey,
            stepKey,
            uiStore: { toastStore },
            t,
        } = this.props;

        if (!store[storeKey]) {
            return toastStore.enqueueErrorToast({
                message: t('Membership.no_selection_message'),
            });
        }
        store.updateState('touched', true);
        onComplete(stepKey, null);
    };

    render() {
        const { t, store, storeKey, parentMatchURL, onDetails, modalTitle } = this.props,
            { totalCreditCost } = this.state,
            { timeSlot } = store;
        const conferenceRoomItems =
            timeSlot && timeSlot.amenities
                ? this.mapConferencesRoom(sortBy(timeSlot.amenities, 'name'), t)
                : [];

        return (
            <Fragment>
                <InfoPane
                    title={t('Booking.conference_room_title')}
                    subTitle={t('Booking.select_campus_booking')}
                    bgImgUrl={ConferenceRoomBackground}
                    modalTitle={modalTitle}
                />
                {timeSlot && (
                    <ConferenceRoomActionPane
                        previousLocation={`${parentMatchURL}/time-slot`}
                        timeSlot={timeSlot}
                        onDetails={onDetails}
                        conferenceRoomItems={conferenceRoomItems}
                        totalCreditCost={totalCreditCost}
                        selectedRoomId={store[storeKey]}
                        updateSelectedConferenceRoom={this.updateSelectedConferenceRoom}
                        nextStep={this.nextStep}
                    />
                )}
            </Fragment>
        );
    }
}

export default translate()(ConferenceRoomStep);
