import React from 'react';

const Search = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 18 18"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M7.571 13.755c-3.538 0-6.406-2.823-6.406-6.304 0-3.482 2.868-6.305 6.406-6.305s6.407 2.823 6.407 6.305c0 3.481-2.869 6.304-6.407 6.304m10.254 3.262l-4.646-4.572a7.352 7.352 0 0 0 1.963-4.994c0-4.115-3.39-7.45-7.57-7.45C3.39 0 0 3.335 0 7.45s3.39 7.45 7.571 7.45a7.611 7.611 0 0 0 4.766-1.664L17 17.827a.588.588 0 0 0 .824 0 .566.566 0 0 0 0-.81"
            fillRule="evenodd"
        />
    </svg>
);

export default Search;
