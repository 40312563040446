import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import { ListItem } from 'Components/Forms';
import { Delete, EMail } from 'Components/Icons';
import { Constants } from 'Data/';
import { StringUtils } from 'Utils/';
import MemberCircle from 'Assets/Icons/member-circle.svg';

class RemoveMembers extends React.PureComponent {
    static propTypes = {
        members: PropTypes.array,
        profileMember: PropTypes.object,
        onDeleteMember: PropTypes.func.isRequired,
    };

    canDeleteMember = (member, profileMember) => {
        return (
            member !== profileMember &&
            !member.roles.includes(Constants.MEMBER_ROLES.OWNER)
        );
    };

    handleClickFactory = (member) => () => {
        const { onDeleteMember } = this.props;
        onDeleteMember(member);
    };

    render() {
        const { members, profileMember, t } = this.props;

        return (
            <div className="d-flex flex-column bg-body mh-0">
                <div className="scrollable d-flex flex-column">
                    <hr className="list-item-spacer" />
                    {members.map((member) => {
                        const rights = StringUtils.getDisplayRole(member, 1, t);
                        return (
                            <ListItem
                                key={member.id}
                                id={member}
                                withImage={true}
                                imgSrc={member.isEmailInvitation ? null : member.imageUrl}
                                defaultImgSrc={
                                    member.isEmailInvitation ? null : MemberCircle
                                }
                                img={
                                    member.isEmailInvitation ? (
                                        <EMail
                                            size="24px"
                                            className="pendingMemberIcon"
                                        />
                                    ) : null
                                }
                                title={
                                    member === profileMember
                                        ? t('Member.me', {
                                              param1: `${member.firstName || ''} ${
                                                  member.lastName || ''
                                              }`,
                                          })
                                        : `${member.firstName || ''} ${
                                              member.lastName || ''
                                          }`
                                }
                                subTitle={
                                    member.roles.includes(
                                        Constants.MEMBER_ROLES.PENDING_MEMBER
                                    ) ? (
                                        <span className="font-italic">{rights}</span>
                                    ) : (
                                        rights
                                    )
                                }
                                action={
                                    this.canDeleteMember(member, profileMember) && (
                                        <span
                                            className="btn btn-link text-primary p-0 c-pointer"
                                            onClick={this.handleClickFactory(member)}
                                        >
                                            <Delete size="1.75em" />
                                        </span>
                                    )
                                }
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default translate()(RemoveMembers);
