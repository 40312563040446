import React from 'react';

class NotificationBadge extends React.PureComponent {
    render() {
        const { className } = this.props;
        return <div className={`notification-badge ${className || ''}`}></div>;
    }
}

export default NotificationBadge;
