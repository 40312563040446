import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';

import { Back, Date } from 'Components/Icons';
import { FormGroup, SelectableListItem, SelectList } from 'Components/Forms';
import Constants from 'Data/Constants';
import ActionPane from 'Components/Modal/ActionPane';
import RecurringBookingStore from 'Stores/RecurringBooking/RecurringBookingStore';

@inject('uiStore')
class RecurrenceDurationStep extends Component {
    static propTypes = {
        parentMatchURL: PropTypes.string.isRequired,
        onComplete: PropTypes.func.isRequired,
        store: PropTypes.instanceOf(RecurringBookingStore),
    };

    state = {
        items: [],
        data: [],
        selectRecurringDurationId: '',
        selectedOccurrencesId: '',
        showCustomOccurrenceCount: false,
        customSelected: false,
    };

    componentDidMount() {
        this.itemMapperDisposer = autorun(this.mapRecurringDurations);
        const { store, history, parentMatchURL } = this.props;

        if (!store.recurrenceOption) {
            return history.replace(`${parentMatchURL}/recurrence-type`);
        }

        if (store.recurrenceDuration) {
            if (store.recurrenceDuration.date) {
                this.setState({
                    selectRecurringDurationId: store.recurrenceDuration.name,
                });
            } else {
                this.setState({
                    customSelected: true,
                    showCustomOccurrenceCount: true,
                    selectedOccurrencesId: store.recurrenceDuration.occurrences,
                });
            }
        }
    }

    componentWillUnmount() {
        this.itemMapperDisposer();
    }

    mapRecurringDurations = () => {
        const {
            store: { recurrenceOption },
            t,
        } = this.props;
        if (recurrenceOption) {
            const items = recurrenceOption.endDates.map((recurringDuration) => ({
                withImage: false,
                id: recurringDuration.name,
                title: recurringDuration.name,
                subTitle:
                    t('Booking.starting_day_you_selected') +
                    ' ' +
                    t('Booking.for_occurrence', {
                        param1: recurringDuration.occurrences,
                        count: recurringDuration.occurrences,
                    }) +
                    ' ' +
                    t('Booking.until_end_message'),
            }));
            this.setState({
                showCustomOccurrenceCount: recurrenceOption.maxOccurrences > 0,
                items,
            });
        }
    };

    customOccurrencesChoice = (t, recurrenceOption) => {
        return {
            id: Constants.RECURRING_PERSONAL,
            title: t('Booking.select_number_occurrences'),
            withImage: false,
            className: 'recurring_personal',
            subTitle: recurrenceOption
                ? this.personalListItems(recurrenceOption.maxOccurrences)
                : '',
        };
    };

    loadOccurrences = (nbOccurrences) => {
        let occurrences = [];
        for (let i = 1; i <= nbOccurrences; i++) {
            occurrences.push({
                value: i,
            });
        }
        return occurrences;
    };

    personalListItems = (nbOccurrences) => {
        const { t } = this.props;
        return (
            <div className="dropdownOccurrence pt-3 pb-1">
                <FormGroup
                    id="holderType"
                    displayName={t('Booking.occurrences_label')}
                    values={this.loadOccurrences(nbOccurrences)}
                    value={this.state.selectedOccurrencesId}
                    type="select"
                    required={true}
                    onChange={this.updateSelectedRecurringOccurrence}
                />
            </div>
        );
    };

    updateSelectedRecurringDuration = (selectRecurringDurationId) => {
        const { store } = this.props,
            { recurrenceOption } = store;

        store.updateState(
            'recurrenceDuration',
            recurrenceOption.endDates.find(
                (duration) => duration.name === selectRecurringDurationId
            )
        );
        this.setState({ selectRecurringDurationId, customSelected: false });
    };

    updateCustom = () => {
        if (this.state.customSelected && !this.state.selectRecurringDurationId) return;

        this.setState({
            customSelected: true,
            selectRecurringDurationId: '',
        });
    };

    updateSelectedRecurringOccurrence = (e) => {
        const { store } = this.props;
        this.setState({
            selectedOccurrencesId: e.target.value,
        });
        store.updateState('recurrenceDuration', {
            occurrences: e.target.value,
            date: null,
        });
    };

    handleNextClick = async () => {
        const {
                uiStore: { toastStore },
                store,
                onComplete,
                t,
            } = this.props,
            { recurrenceDuration } = store;
        if (!recurrenceDuration) {
            return toastStore.enqueueErrorToast({
                message: t('Membership.no_selection_message'),
            });
        }

        const result = await store.loadSummaryAndConflicts();

        if (result) {
            onComplete();
        }
    };

    render() {
        const {
                t,
                parentMatchURL,
                store: { recurrenceOption },
            } = this.props,
            { items } = this.state;

        // TODO: make this view more responsive for iPad.
        return (
            <ActionPane>
                <div className="content">
                    <div className="px-3 pl-xl-3 pr-xl-7 bg-dark d-flex pt-3 text-white">
                        <Link
                            to={`${parentMatchURL}/recurrence-type`}
                            className="text-white"
                        >
                            <Back width="1.5em" height="1.5em" />
                        </Link>
                        <div className="d-flex flex-column ml-2 text-center flex-grow-1">
                            <span className="text-primary">
                                <Date size="1.5em" />
                            </span>
                            <span
                                className="mb-3 text-xs"
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'Booking.recurring_select_duration_message'
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <SelectList
                        className="p-3 p-xl-7 recurring-booking-duration-list"
                        items={items}
                        onChange={this.updateSelectedRecurringDuration}
                        value={this.state.selectRecurringDurationId}
                        multiSelect={false}
                        spaced
                        selectionIndicatorType={Constants.SELECTION_INDICATOR_TYPE.RADIO}
                    >
                        <SelectableListItem
                            {...this.customOccurrencesChoice(t, recurrenceOption)}
                            selectionIndicatorType={
                                Constants.SELECTION_INDICATOR_TYPE.RADIO
                            }
                            onClick={this.updateCustom}
                            selected={this.state.customSelected}
                        />
                    </SelectList>
                </div>
                <div>
                    <div
                        className="m-3  text-xs text-center text-warm-grey"
                        dangerouslySetInnerHTML={{
                            __html: t('Booking.recurring_duration_message'),
                        }}
                    />
                    <button
                        className="btn btn-primary w-100 bottom-action position-relative"
                        onClick={this.handleNextClick}
                        style={{ zIndex: 3 }}
                    >
                        {t('next')}
                    </button>
                </div>
            </ActionPane>
        );
    }
}

export default translate()(RecurrenceDurationStep);
