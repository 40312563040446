import axios from 'axios';
import i18n from 'i18next';

import RootStore from 'Stores/RootStore';
import {
    Ach,
    Campuses,
    CGMessages,
    CheckPayment,
    ConferenceRoom,
    CreditCard,
    CreditPoints,
    Conflict,
    Error,
    Invoices,
    Member,
    Memberships,
    MyBookings,
    PaymentMethod,
    PersonalInfo,
    Plan,
    Proposal,
} from 'Components/Icons';
import Constants from 'Data/Constants';

const {
    uiStore: { toastStore },
} = RootStore;

class ErrorHandler {
    toastIconMap = {
        Generic: Error,
        Booking: MyBookings,
        Campus: Campuses,
        Credit: CreditPoints,
        Invoice: Invoices,
        Member: Member,
        Membership: Memberships,
        Message: CGMessages,
        PaymentMethod: PaymentMethod,
        PaymentMethodACH: Ach,
        PaymentMethodCreditCard: CreditCard,
        PaymentMethodCheck: CheckPayment,
        Plan: Plan,
        Profile: PersonalInfo,
        Quote: Proposal,
        Room: ConferenceRoom,
        RecurrenceConflict: Conflict,
        Invita: '',
    };

    setup() {
        axios.interceptors.response.use((response) => response, this.handleRejection);
    }

    handleRejection = (error) => {
        //If the request was cancelled, do nothing.
        if (axios.isCancel(error)) return Promise.reject(error);

        if (error.response) {
            if (!error.response.config.ignoreErrorToast) {
                if (error.response.status === 400) this.handleBadRequest(error);
                else if (error.response.status >= 500) this.handleGenericError(error);
            }
        } else if (error.request) {
            //probably no network
            this.handleNetworkError();
            console.warn('no network');
        } else {
            this.handleGenericError(error);
        }

        return Promise.reject(error);
    };

    handleNetworkError = () => {
        toastStore.enqueueErrorToast({
            message: i18n.t('Error.error_network_message'),
        });
    };

    handleBadRequest = (error) => {
        const { response } = error;
        //TODO: check if response.data exists.
        if (response.data.error) {
            toastStore.enqueueToast({
                message: response.data.error_description || i18n.t('Error.error_message'),
                icon:
                    this.toastIconMap[response.data.toast_icon] ||
                    this.toastIconMap.Generic,
                preventAutoDismiss:
                    response.data.toast_type === Constants.TOAST_TYPE.PERSISTENT,
            });
        } else if (!response.data.modelState) {
            this.handleGenericError(error);
        }
    };

    handleGenericError = (error) => {
        console.warn(error);
        toastStore.enqueueErrorToast({
            message: i18n.t('Error.error_message'),
        });
    };
}

export default new ErrorHandler();
