import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import { X, Error, Plan } from 'Components/Icons';
import HttpService from 'Services/HttpService';
import { inject } from 'mobx-react';
import { NoResults } from 'Components/Loading';
import { Slideshow } from 'Components/Slideshow';
import Constants from 'Data/Constants';
import { ListItemHeader } from 'Components/Forms';

const { PLAN_INTERVALS } = Constants;

@inject('uiStore', 'seedStore', 'dashboardStore')
class PlanDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plan: { ongoing: true },
        };
    }

    componentDidMount() {
        this.loadPlan();
    }

    loadPlan = async () => {
        const {
            uiStore: { loadingStore },
            planId,
            campusId,
        } = this.props;
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        loadingStore.addLoading();
        this.setState({ plan: { ongoing: true } });
        try {
            const plan = (await HttpService.planDetail(planId, this.cancellation)).data;
            const campus = (await HttpService.campusDetail(campusId, this.cancellation))
                .data;

            let planSlides = [plan.imageUrl];
            if (plan.imageUrls.length > 0) planSlides.push(...plan.imageUrls);

            const slides = planSlides.map((img) => ({
                src: img,
            }));
            this.setState({
                plan: { data: plan, slides, ongoing: false },
                campus,
            });
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.setState({ plan: { errored: true, ongoing: false } });
        } finally {
            loadingStore.removeLoading();
        }
    };

    preparePrice = (interval, price) => {
        const { t } = this.props;
        switch (interval) {
            case PLAN_INTERVALS.DAILY:
                return t('Membership.starting_at', {
                    param1: t('Plan.daily_amount', {
                        param1: `<span class="font-weight-semibold text-lg">$${price}</span>`,
                    }),
                });
            case PLAN_INTERVALS.MONTHLY:
                return t('Membership.starting_at', {
                    param1: t('Plan.monthly_amount', {
                        param1: `<span class="font-weight-semibold text-lg">$${price}</span>`,
                    }),
                });
            default:
                return null;
        }
    };

    priceAddOn = (interval, price) => {
        const { t } = this.props;
        return `${
            interval === PLAN_INTERVALS.DAILY
                ? t('Plan.daily_amount', { param1: `$${price}` })
                : interval === PLAN_INTERVALS.MONTHLY
                ? t('Plan.monthly_amount', { param1: `$${price}` })
                : null
        }`;
    };

    inclusion = (inclusion) => {
        return inclusion.replace(/ -/g, '<br/>- ');
    };

    subscribeToPlan = () => {
        const { match, history, seedStore } = this.props,
            {
                plan: { data },
                campus,
            } = this.state;
        const subscription = {
            campus,
            match,
            plan: data,
            dayPass: data.interval === Constants.PLAN_INTERVALS.DAILY,
        };
        seedStore.set('subscription', subscription);

        data.interval === Constants.PLAN_INTERVALS.MONTHLY
            ? history.push('/dashboard/company/subscribe-to-a-plan')
            : history.push('/dashboard/company/buy-a-day-pass');
    };

    render() {
        const {
                t,
                bookThis = false,
                isOpen,
                onClose,
                dashboardStore: { profile },
            } = this.props,
            { plan, campus } = this.state;

        return (
            <Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={onClose}
                    className="modal-dialog-centered modal-full-screen adaptive"
                >
                    {!plan.errored && plan.data && (
                        <div className="modal-header p-0 bookingDetail position-relative text-white">
                            <Slideshow images={plan.slides} />
                            <div className="d-flex bookingTitle position-absolute h-100 w-100">
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Save"
                                    onClick={onClose}
                                >
                                    <span aria-hidden="true">
                                        <X fill="white" size="24px" />
                                    </span>
                                </button>
                                <div className="p-3 pb-5 d-flex w-100 justify-content-between align-items-end">
                                    <div className="CampusInfo">
                                        <div className="sub-title">{campus.name}</div>
                                        <h3 className="font-weight-medium text-uppercase">
                                            {plan.data.name}
                                        </h3>
                                        <div className="text-secondary d-flex">
                                            <Plan size="1.333em" />
                                            <div className="pl-1">
                                                {plan.data.interval ===
                                                Constants.PLAN_INTERVALS.MONTHLY
                                                    ? t('Plan.plan_title_short')
                                                    : t('Membership.day_pass_title')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ModalBody className="d-flex flex-column bg-white mh-0">
                        {plan && plan.errored ? (
                            <NoResults
                                className="m-auto pt-2"
                                icon={<Error size="3.75em" />}
                                title={t('Error.error')}
                                subTitle={t('Error.error_message')}
                                action={{
                                    text: t('Error.retry'),
                                    onClick: this.loadPlan,
                                }}
                            />
                        ) : (
                            plan &&
                            plan.data && (
                                <Fragment>
                                    <div className="scrollable d-flex flex-column">
                                        <ListItemHeader
                                            superTitle={campus.name}
                                            title={plan.data.name}
                                        />
                                        <div
                                            className="pt-0 pb-1 px-2  text-xs"
                                            dangerouslySetInnerHTML={{
                                                __html: this.preparePrice(
                                                    plan.data.interval,
                                                    plan.data.price
                                                ),
                                            }}
                                        />
                                        {plan.data.description && (
                                            <div
                                                className="pt-0 pb-1 px-2  text-sm"
                                                dangerouslySetInnerHTML={{
                                                    __html: plan.data.description,
                                                }}
                                            />
                                        )}
                                        {plan.data.designedFor && (
                                            <Fragment>
                                                <p className="px-2  text-uppercase font-weight-semibold">
                                                    {t('Plan.designed_for')}
                                                </p>
                                                <div
                                                    className="pt-0 pb-1 px-2  text-sm"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.inclusion(
                                                            plan.data.designedFor
                                                        ),
                                                    }}
                                                />
                                            </Fragment>
                                        )}
                                        {plan.data.inclusion && (
                                            <Fragment>
                                                <p className="px-2  text-uppercase font-weight-semibold">
                                                    {t('Plan.whats_included')}
                                                </p>
                                                <div
                                                    className="pt-0 pb-1 px-2  text-sm"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.inclusion(
                                                            plan.data.inclusion
                                                        ),
                                                    }}
                                                />
                                            </Fragment>
                                        )}
                                        {plan.data.terms && (
                                            <Fragment>
                                                <p className="px-2  text-uppercase font-weight-semibold">
                                                    {t('Plan.terms')}
                                                </p>
                                                <div
                                                    className="pt-0 pb-1 px-2  text-sm"
                                                    dangerouslySetInnerHTML={{
                                                        __html: plan.data.terms,
                                                    }}
                                                />
                                            </Fragment>
                                        )}
                                        {plan.data.addOns && plan.data.addOns.length ? (
                                            <Fragment>
                                                <p className="px-2  text-uppercase font-weight-semibold">
                                                    {t('Plan.add_ons')}
                                                </p>
                                                {plan.data.addOns.map((item) => (
                                                    <div
                                                        className="pt-0 pb-1 px-2  text-sm"
                                                        key={item.id}
                                                    >
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </Fragment>
                                        ) : null}
                                    </div>
                                    {//#TODO Remove after Corenet
                                    bookThis &&
                                    campus.id !== profile.data.coreNetCampusId ? (
                                        <button
                                            className="btn btn-primary w-100 rounded-0 bottom-action"
                                            onClick={this.subscribeToPlan}
                                        >
                                            {plan.data.interval ===
                                            Constants.PLAN_INTERVALS.MONTHLY
                                                ? t('Plan.subscribe_to_plan')
                                                : t('Plan.purchase_pass')}
                                        </button>
                                    ) : null}
                                </Fragment>
                            )
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default translate()(PlanDetail);
