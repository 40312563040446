import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

import RootStore from 'Stores/RootStore';

const dashboardStore = RootStore.dashboardStore;
const store = RootStore.authStore;

function isRefresh(config) {
    return (
        config.data &&
        config.url.includes('/token') &&
        qs.parse(config.data).grant_type === 'refresh_token'
    );
}

function isLimitedAccessToken(config) {
    return (
        config.headers && config.headers['Limited-Access-Token']
    );
}

const auth = {
    ongoing: null,

    setup: () => {
        axios.interceptors.request.use(async (config) => {
            if (store.shouldRefresh && !isRefresh(config)) {
                await auth.refresh();
            }

            if (store.loggedIn) {
                config.headers.Authorization = `Bearer ${store.accessToken}`;
            }

            if (!config.url.includes('/token')) {
                config.headers[
                    'App-Info'
                ] = `Web;cgplus.cgworkplace.com;${process.env.REACT_APP_VERSION}`;
            }

            return config;
        });

        axios.interceptors.response.use(
            (response) => {
                //If there is a new app version, automatically reload to get it. Only on GET requests.
                if (response.config.method === 'get' && response.headers['x-update']) {
                    const now = moment();
                    const lastUpdateAttempt = localStorage.getItem('lastUpdateAttempt');
                    // Check last update attempt to prevent infinite reloads.
                    if (
                        !lastUpdateAttempt ||
                        moment.duration(now.diff(moment(lastUpdateAttempt))).asMinutes() >
                            10
                    ) {
                        localStorage.setItem('lastUpdateAttempt', now.toISOString());
                        window.location.reload();
                    }
                }
                return response;
            },
            (error) => {
                if (
                    error.response &&
                    ((error.response.data &&
                        error.response.data.error === 'invalid_grant') ||
                        error.response.status === 401) &&
                    !isRefresh(error.response.config) &&
                    !isLimitedAccessToken(error.response.config)
                ) {
                    return auth.refresh().then(() => axios(error.response.config));
                }

                return Promise.reject(error);
            }
        );
    },

    login(email, password) {
        auth.ongoing && auth.ongoing.cancel();
        auth.ongoing = axios.CancelToken.source();
        return (auth.ongoing.promise = axios({
            url: '/token',
            method: 'post',
            data: qs.stringify({
                grant_type: 'password',
                username: email,
                password: password,
            }),
            cancelToken: auth.ongoing.token,
        })
            .then((x) => {
                auth.ongoing = null;
                store.login(x.data);
                return x;
            })
            .catch((x) => {
                auth.ongoing = null;
                throw x;
            }));
    },

    logout: () => {
        auth.ongoing && auth.ongoing.cancel();
        store.logout();
        dashboardStore.clear();
    },

    refresh(resp) {
        if (auth.ongoing) return auth.ongoing.promise;
        auth.ongoing = axios.CancelToken.source();
        return (auth.ongoing.promise = axios({
            url: '/token',
            method: 'post',
            data: qs.stringify({
                grant_type: 'refresh_token',
                refresh_token: resp ? resp.data.refreshToken : store.refreshToken,
            }),
            cancelToken: auth.ongoing.token,
        })
            .then((x) => {
                auth.ongoing = null;
                store.login(x.data);
                return x;
            })
            .catch((error) => {
                auth.ongoing = null;
                store.logout();
                throw error;
            }));
    },
};

export default auth;
