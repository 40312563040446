import React, { Component } from 'react';
import qs from 'qs';

import ActionResult from 'Components/Actions/ActionResult';
import NoResults from 'Components/Loading/NoResults';
import { Error as ErrorIcon, LogoName } from 'Components/Icons';
import NoMatch from 'WebApp/Views/NoMatch';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

class ActionBase extends Component {
    state = {
        loading: true,
        errored: false,
        notFound: false,
        resultMessageKey: '',
        loginPath: '',
        loginTextKey: '',
        isComponent: false,
    };

    actionCall = async () => {
        console.error('Must override action in child.');
        throw new Error('Must be overridden in child.');
    };

    componentDidMount() {
        const { location } = this.props;
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        this.prepareState(params);
        this.token = params.token;
        this.action();
    }

    prepareState = (params) => {};

    action = async () => {
        const {
            uiStore: { loadingStore },
        } = this.props;
        try {
            loadingStore.addLoading();
            this.setState({ loading: true, errored: false });
            await this.actionCall();
        } catch (e) {
            if (!this.handleError || !this.handleError(e))
                this.setState({ errored: true });
        } finally {
            this.setState({ loading: false });
            loadingStore.removeLoading();
        }
    };

    render() {
        const {
            loginPath,
            loading,
            errored,
            resultMessageKey,
            loginTextKey,
            notFound,
            component,
            classComponent,
            deepLink,
            storeLink,
        } = this.state;
        const { t, history } = this.props;

        return (
            <div className="action-result-page">
                <div className="grey-section" />
                <div className="blue-section" />
                <div className={`content ${classComponent}`}>
                    <div className="text-center">
                        <Link to="/">
                            <LogoName
                                className={classnames('logo', { 'w-100': !component })}
                                fill="#fff"
                                size="35em"
                            />
                        </Link>
                    </div>
                    <div
                        className={classnames('mt-5 bg-white', {
                            'result p-2 p-md-4 p-lg-7': !component,
                        })}
                    >
                        {loading ? null : notFound ? (
                            <NoMatch history={history} />
                        ) : errored ? (
                            <NoResults
                                icon={<ErrorIcon size="60px" />}
                                title={t('Error.error')}
                                subTitle={t('Error.error_message')}
                                action={{
                                    text: t('Error.retry'),
                                    onClick: this.action,
                                }}
                            />
                        ) : (
                            <ActionResult
                                component={component}
                                textKey={resultMessageKey}
                                loginPath={loginPath}
                                deepLink={deepLink}
                                storeLink={storeLink}
                                loginTextKey={loginTextKey}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ActionBase;
