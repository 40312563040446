import React from 'react';

const Plan = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M23.053 22.663H.947V10.937a7.043 7.043 0 0 0 6.092 3.515h2.519L12 16.895l2.442-2.443h2.52a7.043 7.043 0 0 0 6.09-3.515v11.726zM8.084 6.873h7.769V1.948H8.084v4.927zm5.524 7.074L12 15.555l-1.608-1.608L12 12.34l1.608 1.607zm.897-.442L12 11l-2.505 2.505H7.039A6.1 6.1 0 0 1 .96 7.821h22.08a6.1 6.1 0 0 1-6.078 5.684h-2.456zM16.8 6.874V1H7.137v5.874H0V23.61h24V6.874h-7.2z"
        />
    </svg>
);

export default Plan;
