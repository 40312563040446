import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AngleDown } from 'Components/Icons';
import { Constants } from 'Data/';
import { Dropdown, SelectList } from 'Components/Forms';

const dropdownButton = (chosenItem) => ({ onClick, open }) => {
    return (
        <div
            onClick={onClick}
            className={classnames(
                'text-primary list-group-item-status dropdown-indicator d-flex h-100',
                open && 'toggled'
            )}
        >
            <div className="w-100">
                {chosenItem ? (                    
                    <>
                        {chosenItem.title}{' '}
                    </>
                ) : ''}
            </div>
            <AngleDown size="18px" />
        </div>
    );
};

function DropdownIndustry({ items = [], value = '', onChange, loadingText = null, t }) {
    return (
        <div className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey dropdown-list">
            <label className="text-warm-grey">
                {t('Membership.industry')}
            </label>
            
            <Dropdown
                button=
                {
                    dropdownButton(
                        (value ? items.find((item) => item.value === value) : '')
                    )
                }
                className="form-control col-12"
            >
                <SelectList
                    tag="div"
                    id="industry"
                    className="list-group-compact"
                    items={items}
                    value={value ? value : ''}
                    onChange={onChange}
                    multiSelect={false}
                    isDropDown
                    selectionIndicatorType={Constants.SELECTION_INDICATOR_TYPE.NONE}
                >
                </SelectList>
            </Dropdown>
        </div>
    );
}

DropdownIndustry.propTypes = {
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DropdownIndustry;
