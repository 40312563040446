import React, { Fragment, PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';

import RecurrenceConflictTimeSlotStep from './RecurrenceConflictTimeSlotStep';
import RecurrenceConflictConferenceRoomStep from './RecurrenceConflictConferenceRoomStep';
import { inject } from 'mobx-react';

@inject('seedStore')
class RecurrenceConflictResolutionStep extends PureComponent {
    componentDidMount() {
        const { location, match, history } = this.props;
        if (location.pathname === match.url) {
            history.replace(`${match.url}/time-slot`);
        }
    }

    nextResolveStep = (path) => () => {
        const {
            history,
            match: { url },
        } = this.props;
        history.push(`${url}/${path}`);
    };

    renderTimeSlot = (routerProps) => {
        const { parentMatchURL, store } = this.props;
        return (
            <RecurrenceConflictTimeSlotStep
                {...routerProps}
                previousLocation={`${parentMatchURL}/conflicts`}
                store={store}
                onComplete={this.nextResolveStep('conference-room')}
            />
        );
    };

    renderConferenceRooms = (routerProps) => {
        const { match, store, seedStore } = this.props;
        return (
            <RecurrenceConflictConferenceRoomStep
                {...routerProps}
                previousLocation={`${match.url}/time-slot`}
                store={store}
                seedStore={seedStore}
                onComplete={this.conflictResolved}
            />
        );
    };

    conflictResolved = () => {
        const { parentMatchURL, history, store } = this.props;
        store.updateState('currentConflictId', '');
        history.push(`${parentMatchURL}/conflicts`);
    };

    render() {
        const { match } = this.props;
        return (
            <Fragment>
                <Route path={`${match.url}/time-slot`} render={this.renderTimeSlot} />
                <Route
                    path={`${match.url}/conference-room`}
                    render={this.renderConferenceRooms}
                />
            </Fragment>
        );
    }
}

export default translate()(RecurrenceConflictResolutionStep);
