import React from 'react';
import PropTypes from 'prop-types';

import {
    CGMessages,
    IconWithNotificationBadge,
    Proposal,
    Plan,
    Invoices,
    Memberships,
    Conflict,
} from 'Components/Icons';

import { Constants } from 'Data/';

const { MESSAGE_TYPE } = Constants;

class MessageIcon extends React.PureComponent {
    static propTypes = {
        messageType: PropTypes.string,
        inCircle: PropTypes.bool,
        circleClassName: PropTypes.string,
        size: PropTypes.string,
        notificationBadgeProps: PropTypes.shape(IconWithNotificationBadge.propTypes),
    };

    getIconForType = (messageType) => {
        switch (messageType) {
            case MESSAGE_TYPE.QUOTE_CREATED:
            case MESSAGE_TYPE.QUOTE_REFUSED:
                return Proposal;
            case MESSAGE_TYPE.REQUEST_CREATED:
            case MESSAGE_TYPE.REQUEST_REFUSED:
            case MESSAGE_TYPE.SUBSCRIPTION_CANCELLED:
            case MESSAGE_TYPE.SUBSCRIPTION_CREATED:
                return Plan;
            case MESSAGE_TYPE.INVOICE_STATUS_FAILED:
            case MESSAGE_TYPE.INVOICE_STATUS_PAID:
            case MESSAGE_TYPE.INVOICE_STATUS_VOID:
            case MESSAGE_TYPE.INVOICE_STATUS_REFUNDED:
            case MESSAGE_TYPE.INVOICE_STATUS_AWAITING:
                return Invoices;
            case MESSAGE_TYPE.MEMBERSHIP_INVITATION:
                return Memberships;
            case MESSAGE_TYPE.RECURRENCE_ERRORS:
                return Conflict;
            default:
                return CGMessages;
        }
    };

    render() {
        const {
            inCircle,
            messageType,
            size,
            circleClassName,
            notificationBadgeProps,
        } = this.props;
        const Icon = this.getIconForType(messageType);
        return inCircle ? (
            <div
                className={
                    'img rounded-circle bg-black' +
                    (circleClassName ? ` ${circleClassName}` : '')
                }
            >
                <Icon size="55%" />
            </div>
        ) : (
            <IconWithNotificationBadge {...notificationBadgeProps}>
                <Icon size={size} />
            </IconWithNotificationBadge>
        );
    }
}

export default MessageIcon;
