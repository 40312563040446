import React from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import SubscriptionDetail from './SubscriptionDetail';
import { X } from 'Components/Icons';

class SubscriptionDetailModal extends React.Component {
    state = {
        loaded: false,
    };

    close = () => this.props.history.push(this.props.parentMatchURL);

    onLoaded = () => this.setState({ loaded: true });

    render() {
        return (
            <Modal
                isOpen={true}
                className={`modal-dialog-centered modal-lg modal-full-screen ${
                    this.state.loaded ? '' : 'invisible'
                }`}
                toggle={this.close}
            >
                <ModalBody className="bg-body">
                    <button
                        type="button"
                        className="close modal-dark position-absolute m-4"
                        aria-label="Close"
                        onClick={this.close}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <SubscriptionDetail {...this.props} modal onLoaded={this.onLoaded} />
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(SubscriptionDetailModal);
