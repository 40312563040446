import { action, computed, observable } from 'mobx';
import { TimeSlotUtils } from 'Utils/';
import RecurringBookingStore from './RecurringBooking/RecurringBookingStore';
import RootStore from './RootStore';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

class BookingStore {
    constructor(seedStore) {
        const { bookingExpress } = RootStore.dashboardStore;

        if (seedStore && !!seedStore.get('booking')) {
            UpdateStateStoreUtils(
                this,
                'campusId',
                seedStore.get('booking').campus ? seedStore.get('booking').campus.id : ''
            );
            UpdateStateStoreUtils(
                this,
                'conferenceRoomId',
                seedStore.get('booking').room ? seedStore.get('booking').room.id : ''
            );
            UpdateStateStoreUtils(
                this,
                'preSelectedConferenceRoomId',
                seedStore.get('booking').room ? seedStore.get('booking').room.id : ''
            );
            UpdateStateStoreUtils(this, 'campus', seedStore.get('booking').campus || '');
        }

        if (bookingExpress) {
            UpdateStateStoreUtils(
                this,
                'creditBalanceId',
                bookingExpress.creditBalanceId || ''
            );
            UpdateStateStoreUtils(
                this,
                'creditBalance',
                bookingExpress.creditBalance || ''
            );
            UpdateStateStoreUtils(this, 'campus', bookingExpress.campus || '');
            UpdateStateStoreUtils(
                this,
                'campusId',
                bookingExpress.campus ? bookingExpress.campus.id : ''
            );
        }
        this.recurringStore = new RecurringBookingStore(this);
    }

    @observable bookingId;
    @observable information = null;
    @observable bookingTitle = '';
    @observable bookingOrganizer = '';
    @observable bookingDescription = '';
    @observable creditBalanceId = '';
    @observable bookingAttendance = false;
    @observable selectedMemberIds = [];
    @observable attendees = [];
    @observable campusId = '';
    @observable bookingStart = '';
    @observable bookingEnd = '';
    @observable amenityFilters = [];
    @observable conferenceRoomId = '';
    @observable bookingAmenity = [];
    @observable recurring = false;
    @observable isPrivate = false;
    preSelectedBookingStart = '';
    preSelectedBookingEnd = '';
    preSelectedConferenceRoomId = '';

    creditBalance = null;
    campus = null;
    timeSlot = null;
    recurringStore = null;

    @computed get isEdit() {
        return !!this.bookingId;
    }

    @computed get isComplete() {
        return !!(
            this.creditBalanceId &&
            this.campusId &&
            this.bookingStart &&
            this.conferenceRoomId &&
            this.recurringStore.isComplete
        );
    }

    @action setAttendees = async (t) => {
        if (!this.bookingId) {
            await RootStore.dashboardStore.loadProfile(RootStore.uiStore.loadingStore);
            if (RootStore.dashboardStore.profile.data)
                this.attendees = [
                    {
                        id: RootStore.dashboardStore.profile.data.id,
                        imgSrc: RootStore.dashboardStore.profile.data.imageUrl,
                        name: t('Member.me', {
                            param1: `${RootStore.dashboardStore.profile.data.lastName}, ${RootStore.dashboardStore.profile.data.firstName}`,
                        }),
                    },
                ];
        }
        if (RootStore.dashboardStore.profile.data)
            this.bookingOrganizer = RootStore.dashboardStore.profile.data.id;
    };

    @action updateState(key, value) {
        if (key === 'campusId' && this.campusId !== value) {
            this.updateState('bookingStart', '');
            this.updateState('bookingEnd', '');
            this.updateState('recurring', false);
            this.timeSlot = null;
        }

        // if (key === 'bookingStart' && this.bookingStart !== value) {
        //     this.updateState('conferenceRoomId', this.preSelectedConferenceRoomId);
        // }
        if (key === 'timeSlot') {
            this.timeSlot &&
            this.timeSlot.amenities.find(
                (room) => room.id === this.preSelectedConferenceRoomId
            )
                ? this.updateState('conferenceRoomId', this.preSelectedConferenceRoomId)
                : this.updateState('conferenceRoomId', '');
        }

        UpdateStateStoreUtils(this, key, value);
    }

    @action
    async seed(customData) {
        let data = customData;
        if (!data) {
            const { default: seedData } = await import('./Seeds/BookingStoreSeed');
            data = seedData;
        }

        Object.keys(data).forEach((key) => {
            if (key === 'timeSlot') {
                this.timeSlot = TimeSlotUtils.inflateTimeSlot(data[key]);
            } else {
                this[key] = data[key];
            }
        });
    }
}

export default BookingStore;
