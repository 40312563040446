import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import classnames from 'classnames';
import HttpService from 'Services/HttpService';
import colorVariables from 'Sass/variables.scss';
import { InfiniteSelectList, MultiSelectDropDown, SelectList } from 'Components/Forms';
import { Campuses, Search, EMail, Add, Lightbulb, X, Password } from 'Components/Icons';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import Constants from 'Data/Constants';
import { EmailUtils, sortBy } from 'Utils/';
import LoadingStore from 'Stores/UI/LoadingStore';
import { handlePositionError } from 'Utils/PositionErrorHandler';
import { Image } from 'Components/Misc';
import Member from 'Components/Icons/Member';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

const ALL_CAMPUSES_ID = 'default';
const { CAMPUS_STATUSES, DEFAULT_PAGE_SIZE } = Constants;

const defaultCampusImg = (
    <div className="img rounded-circle">
        <Campuses size="50%" />
    </div>
);

@inject('uiStore')
@observer
class MemberInvitation extends React.Component {
    static propTypes = {
        membersSelected: PropTypes.func.isRequired,
        membersEmailsSelected: PropTypes.func.isRequired,
        membershipId: PropTypes.string.isRequired,
        selectedMemberIds: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string)
            .isRequired,
        className: PropTypes.string,
        padSearch: PropTypes.bool,
    };

    static defaultProps = {
        padSearch: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selectedCampusIds: [ALL_CAMPUSES_ID],
            members: [],
            campuses: [],
            totalMemberCount: DEFAULT_PAGE_SIZE * 2,
            inputEmailSize: 0,
            currentsPendingEmails: [],
        };

        this.loadedRowMap = {};
        this.lastPageIndex = -1;

        this.localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);
        this.updateMemberList = debounce(this.searchMembers, 100);
    }

    componentDidMount() {
        const { attendance, membershipId, store } = this.props;
        this.cancellation = HttpService.cancellable();
        let currentsPendingEmails = [];
        if (store && store.currentsMembers && store.currentsMembers.length) {
            store.currentsMembers.forEach(
                (member) => member.email && currentsPendingEmails.push(member.email)
            );
        }
        this.setState({ currentsPendingEmails });

        if (attendance && membershipId) {
            this.loadMembershipMembers();
        } else {
            this.loadCampuses();
            this.loadMembers();
        }
    }

    componentWillUnmount() {
        this.cancellation.cancel();
    }

    loadCampuses = async () => {
        const { t } = this.props;
        this.localLoadingStore.addLoading();
        const { campusesPromise, positionPromise } = HttpService.campusesWithDistance(
            (campusPromise) =>
                campusPromise.then((resp) =>
                    resp.data
                        .filter((campus) => campus.status === CAMPUS_STATUSES.ACTIVE)
                        .map((campus) => ({
                            id: campus.id,
                            title: campus.name,
                            imgSrc: campus.mainImageUrl,
                            subTitle: campus.isSubscribed
                                ? t('Campus.my_campus_title')
                                : null,
                            lat: campus.location.latitude,
                            lng: campus.location.longitude,
                            defaultImg: defaultCampusImg,
                        }))
                ),
            false,
            '',
            this.cancellation
        );

        const prependAllLocations = (campuses) =>
            [
                {
                    id: ALL_CAMPUSES_ID,
                    title: t('Member.all_campus_filter_title'),
                    withImage: false,
                    isDefault: true,
                },
            ].concat(campuses);

        try {
            this.setState({ campuses: prependAllLocations(await campusesPromise) });
        } catch (campusError) {}

        this.localLoadingStore.removeLoading();
        const formattedPositionPromise = positionPromise.then((campuses) =>
            campuses
                .map((campus) => {
                    if (campus.subTitle) return campus;

                    return Object.assign({}, campus, {
                        subTitle:
                            campus.distance >= 1000
                                ? t('Campus.mile_1000_format')
                                : t('Campus.mile_format', { decimal0: campus.distance }),
                    });
                })
                .sort(
                    (lhs, rhs) =>
                        CAMPUS_STATUSES.indexOf(lhs.status) -
                            CAMPUS_STATUSES.indexOf(rhs.status) ||
                        lhs.distance - rhs.distance
                )
        );

        try {
            this.setState({
                campuses: prependAllLocations(await formattedPositionPromise),
            });
        } catch (positionError) {
            const {
                uiStore: { toastStore },
            } = this.props;
            handlePositionError(toastStore, t);
        }
    };

    loadMembers = async () => {
        this.localLoadingStore.addLoading();
        await this.searchMembers(false, undefined, undefined);
        this.localLoadingStore.removeLoading();
    };

    loadMembershipMembers = async () => {
        const { membershipId, organizer, t } = this.props;
        this.localLoadingStore.addLoading();

        try {
            const {
                data: { members },
            } = await HttpService.membershipDetail(membershipId, this.cancellation, true);
            const membershipMembers = sortBy(
                members.map((member) => ({
                    id: member.id,
                    title:
                        !!organizer && organizer === member.id
                            ? t('Member.me', {
                                  param1: `${member.lastName}, ${member.firstName}`,
                              })
                            : `${member.lastName}, ${member.firstName}`,
                    subTitle: (
                        <div className="sub-title-email">
                            {member.isPrivate ? (
                                <Fragment>
                                    <Password size="2em" fill={colorVariables.primary} />
                                    {t('Profile.privacy_profile_title')}
                                </Fragment>
                            ) : (
                                member.contactInfo?.email
                            )}
                        </div>
                    ),
                    imgSrc: member.imageUrl,
                })),
                'title'
            );
            if (organizer)
                membershipMembers.sort(function (member) {
                    return member.id === organizer ? -1 : 0;
                });
            this.setState({ members: membershipMembers });
        } catch (e) {
        } finally {
            this.localLoadingStore.removeLoading();
        }
    };

    updateMembers = (selectedMemberIds) => {
        this.props.membersSelected(selectedMemberIds);
        if (this.props.store.addMembers || this.props.store.isCreation)
            this.pendingMembers(selectedMemberIds);
    };

    pendingMembers = async (selectedMemberIds) => {
        const { store } = this.props;
        if (selectedMemberIds.length) {
            const request = {
                accountIds: selectedMemberIds,
            };
            try {
                const {
                    data: { items },
                } = await HttpService.members(
                    {
                        request: request,
                    },
                    this.cancellation
                );

                const pendingMembers = items.map((member) => ({
                    ...member,
                    accountId: member.id,
                    id: member.id,
                    name: `${member.lastName}, ${member.firstName}`,
                    imgUrl: member.imageUrl,
                    roles: [Constants.MEMBER_ROLES.MEMBER],
                }));

                UpdateStateStoreUtils(
                    store,
                    'pendingMembers',
                    sortBy(pendingMembers, 'name')
                );
            } catch (searchError) {}
        } else {
            UpdateStateStoreUtils(store, 'pendingMembers', []);
        }
    };

    updateMembersEmails = (selectedMemberEmails) => {
        this.props.membersEmailsSelected(selectedMemberEmails);
    };

    addMembersEmails = () => {
        const { selectedMemberEmails } = this.props,
            { searchText } = this.state;

        if (!this.emailExist(searchText)) {
            this.updateMembersEmails([...selectedMemberEmails, searchText]);
        }
        this.setState(
            {
                searchText: '',
                inputEmailSize: 0,
                totalMemberCount: 2 * DEFAULT_PAGE_SIZE,
            },
            () => {
                this.searchMembers();
            }
        );
    };

    removeMembersEmails = (memberEmail) => () => {
        const { selectedMemberEmails } = this.props;
        const array = [...selectedMemberEmails]; // make a separate copy of the array
        const index = array.indexOf(memberEmail);
        if (index !== -1) {
            array.splice(index, 1);
            this.updateMembersEmails(array);
        }
    };

    emailExist = (email) => {
        const { selectedMemberEmails } = this.props,
            { currentsPendingEmails } = this.state;
        return (
            selectedMemberEmails.indexOf(email) !== -1 ||
            currentsPendingEmails.indexOf(email) !== -1
        );
    };

    updateCampuses = (selectedCampusIds) => {
        this.setState({ selectedCampusIds: selectedCampusIds });
        this.updateMemberList();
    };

    keyPress = (event) => {
        if (event.keyCode === 13) {
            const target = event.target;
            if (EmailUtils.isValidEmail(target.value)) this.addMembersEmails();
        }
    };

    handleSearchChange = (event) => {
        const target = event.target;
        this.setState({
            searchText: target.value,
            inputEmailSize: target.value.length * 9 + 70,
        });
        if (!this.props.attendance) this.updateMemberList();
    };

    searchMembers = async (
        nextPage = false,
        startIndex = 0,
        stopIndex = DEFAULT_PAGE_SIZE - 1
    ) => {
        //If it is a "new" search, reset the loading tracking.
        const { t, store } = this.props;
        const { searchText, selectedCampusIds } = this.state;
        if (!nextPage) {
            this.loadedRowMap = {};
            this.lastPageIndex = -1;
        }

        //If a request has already been made for that index, do not load it again.
        if (this.loadedRowMap[startIndex]) return;

        this.lastPageIndex++;
        const request = {
            searchTerm: searchText,
            includeCurrentMember: false,
        };

        if (selectedCampusIds.length && !selectedCampusIds.includes(ALL_CAMPUSES_ID))
            request.campusIds = selectedCampusIds;

        //Set the range as loading.
        for (let i = startIndex; i <= stopIndex; i++) {
            this.loadedRowMap[i] = 'loading';
        }

        let currentsMembers = [];
        if (store && store.currentsMembers && store.currentsMembers.length)
            store.currentsMembers.forEach((member) => currentsMembers.push(member.id));
        try {
            const {
                data: { items },
            } = await HttpService.members(
                {
                    request: request,
                    page: nextPage ? this.lastPageIndex : 0,
                    pageSize: DEFAULT_PAGE_SIZE,
                },
                this.cancellation
            );

            const members = sortBy(
                items.map((member) => ({
                    id: member.id,
                    title: `${member.lastName}, ${member.firstName}`,
                    subTitle: (
                        <div className="sub-title-email">
                            {member.isPrivate ? (
                                <Fragment>
                                    <Password size="2em" fill={colorVariables.primary} />
                                    {t('Profile.privacy_profile_title')}
                                </Fragment>
                            ) : (
                                member.contactInfo?.email
                            )}
                        </div>
                    ),
                    imgSrc: member.imageUrl,
                    className: currentsMembers.includes(member.id) ? 'disabled' : '',
                    selectable: !currentsMembers.includes(member.id),
                })),
                'title'
            );

            let newState = {
                totalMemberCount:
                    members.length === DEFAULT_PAGE_SIZE
                        ? this.state.totalMemberCount + DEFAULT_PAGE_SIZE
                        : nextPage
                        ? this.state.members.length + members.length
                        : members.length,
            };
            if (nextPage) {
                newState.members = [...this.state.members, ...members];
            } else {
                newState.members = members;
                this.lastPageIndex = 0;
            }
            this.setState(newState);
            //Set the range as loaded.
            for (let i = startIndex; i <= stopIndex; i++) {
                this.loadedRowMap[i] = 'loaded';
            }
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;

            //The rows have not been loaded; remove their loaded status.
            for (let i = startIndex; i <= stopIndex; i++) {
                delete this.loadedRowMap[i];
            }
            this.lastPageIndex--;
        }
    };

    loadNextMembers = async (startIndex, endIndex) => {
        return await this.searchMembers(true, startIndex, endIndex);
    };

    isRowLoaded = ({ index }) => {
        return !!this.loadedRowMap[index];
    };

    render() {
        const {
                t,
                selectedMemberIds,
                className,
                padSearch,
                selectedMemberEmails,
                attendance = false,
            } = this.props,
            {
                members,
                campuses,
                selectedCampusIds,
                searchText,
                totalMemberCount,
                inputEmailSize,
            } = this.state;
        const inputEmailStyle = {
            width: `${inputEmailSize}px`,
        };
        return (
            <div className={`member-invitation ${className || ''}`}>
                {!attendance && (
                    <div className="bg-black text-center text-sm py-2 px-10">
                        <Member size="2em" fill={colorVariables.secondary} />
                        <div
                            className="pt-1"
                            dangerouslySetInnerHTML={{
                                __html: t('Membership.membership_selection_message'),
                            }}
                        />
                    </div>
                )}
                <div className={'w-100 py-2' + (padSearch ? ' px-2' : '')}>
                    <div className="col-md-6 m-auto">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-white">
                                    <Search
                                        fill={colorVariables.warmGrey}
                                        size={'1.125em'}
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control bg-white"
                                placeholder={t('Membership.search_name_email_message')}
                                aria-label={t('Member.member_search')}
                                value={searchText}
                                onKeyDown={this.keyPress}
                                onChange={this.handleSearchChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    <div
                        className={classnames(
                            'member-list d-flex flex-column h-100 overflow-visible',
                            {
                                'col-6': !attendance,
                                'col-12': attendance,
                            }
                        )}
                    >
                        <Fragment>
                            {!attendance && (
                                <div className="text-center py-2 font-weight-medium position-relative">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('Membership.cg_member_title'),
                                        }}
                                    />
                                    <MultiSelectDropDown
                                        items={campuses}
                                        withImages={true}
                                        className="campus"
                                        defaultOptionExclusive={true}
                                        onChange={this.updateCampuses}
                                        value={selectedCampusIds}
                                    />
                                </div>
                            )}
                            {members.length ? (
                                <div className="member-list overflow-auto position-relative flex-grow-1 list-group-compact">
                                    {attendance ? (
                                        <SelectList
                                            items={members.filter((member) =>
                                                member.title
                                                    .toLowerCase()
                                                    .includes(searchText.toLowerCase())
                                            )}
                                            withImages={true}
                                            onChange={this.updateMembers}
                                            value={selectedMemberIds}
                                            multiSelect={true}
                                        />
                                    ) : (
                                        <InfiniteSelectList
                                            items={members}
                                            withImages={true}
                                            onChange={this.updateMembers}
                                            multiSelect={true}
                                            value={selectedMemberIds}
                                            loadNextPage={this.loadNextMembers}
                                            totalItemCount={totalMemberCount}
                                            isRowLoaded={this.isRowLoaded}
                                        />
                                    )}
                                </div>
                            ) : (
                                <NoResults
                                    className="flex-grow-1 h-auto"
                                    icon={<Search size="3.5em" />}
                                    title={t('search.no_results')}
                                    subTitle={t('Member.no_results_message')}
                                />
                            )}
                        </Fragment>
                    </div>
                    {!attendance && (
                        <div className="member-list col-6 d-flex flex-column h-100">
                            <Fragment>
                                <div className="text-center py-2">
                                    <span className="font-weight-medium">
                                        {t('Membership.not_cg_member_title')}
                                    </span>
                                </div>
                                {!members.length && this.emailExist(searchText) ? (
                                    <div className="align-item-center px-2 px-lg-5">
                                        <NoResults
                                            subTitle={t(
                                                'Membership.email_already_invited'
                                            )}
                                            className="px-2 px-lg-5"
                                        />
                                    </div>
                                ) : EmailUtils.isValidEmail(searchText) ? (
                                    <div className="align-item-top px-2 px-lg-5 mb-1">
                                        <div
                                            className="input-group addEmail c-pointer"
                                            onClick={this.addMembersEmails}
                                        >
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-white px-1 c-pointer">
                                                    <Add
                                                        fill={colorVariables.primary}
                                                        size={'1em'}
                                                    />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control bg-white c-pointer text-truncate"
                                                value={`${t(
                                                    'Membership.invite_prefix'
                                                )} ${searchText}`}
                                                disabled
                                                style={inputEmailStyle}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </Fragment>
                            {selectedMemberEmails.length ? (
                                <div className="member-list overflow-auto position-relative flex-grow-1">
                                    <div className="list-group-compact list-group">
                                        {selectedMemberEmails.map((email) => {
                                            return (
                                                <div
                                                    className="list-group-item-action list-group-item text-sm"
                                                    onClick={this.removeMembersEmails(
                                                        email
                                                    )}
                                                    key={email}
                                                >
                                                    <div className="list-group-item-image pr-1 text-dark">
                                                        <Image
                                                            img={<EMail size="16px" />}
                                                        />
                                                    </div>
                                                    <div className="list-group-item-text">
                                                        {email}
                                                    </div>
                                                    <div className="list-group-item-image pl-1 text-black">
                                                        <Image
                                                            img={
                                                                <X
                                                                    fill="currentColor"
                                                                    size="16px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                !EmailUtils.isValidEmail(searchText) && (
                                    <div className="text-center pt-2 d-flex justify-content-center align-items-center flex-grow-1 flex-column">
                                        <Lightbulb size={'3.5em'} />
                                        <p className="pt-4 mb-1 text-sm">
                                            {t('Membership.enter_valid_email_message')}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
                <LoadingSpinner loading={this.localLoadingStore.isLoading} />
            </div>
        );
    }
}

export default translate()(MemberInvitation);
