import React from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import ModelStateStore from 'Stores/ModelStateStore';
import HttpService from 'Services/HttpService';
import { Constants } from 'Data/';

import { EditProfile as EditProfileComponent } from 'Components/Personal';
import { Member, PersonalInfo, X } from 'Components/Icons';
import ChangePassword from './ChangePassword';
import NoMatch from 'WebApp/Views/NoMatch';

@inject('uiStore', 'dashboardStore')
@observer
class EditProfile extends React.Component {
    touched = false;

    modelStateStore = new ModelStateStore(
        { to: 'image', from: 'updateAccountRequest.image' },
        { to: 'address_addressLine1', from: 'updateAccountRequest.address.addressLine1' },
        { to: 'address_city', from: 'updateAccountRequest.address.city' },
        { to: 'address_country', from: 'updateAccountRequest.address.country' },
        { to: 'address_postalCode', from: 'updateAccountRequest.address.postalCode' },
        { to: 'address_state', from: 'updateAccountRequest.address.state' },
        { to: 'birthDate', from: 'updateAccountRequest.birthDate' },
        { to: 'salutation', from: 'updateAccountRequest.salutation' },
        { to: 'firstName', from: 'updateAccountRequest.firstName' },
        { to: 'middleName', from: 'updateAccountRequest.middleName' },
        { to: 'lastName', from: 'updateAccountRequest.lastName' },
        { to: 'phoneNumber', from: 'updateAccountRequest.contactInfo.phone1' },
        { to: 'phoneNumber2', from: 'updateAccountRequest.contactInfo.phone2' },
        { to: 'email', from: 'updateAccountRequest.contactInfo.email' },
        {
            to: 'contactInfo_angelListUrl',
            from: 'updateAccountRequest.contactInfo.angelListUrl',
        },
        {
            to: 'contactInfo_twitterUrl',
            from: 'updateAccountRequest.contactInfo.twitterUrl',
        },
        {
            to: 'contactInfo_facebookUrl',
            from: 'updateAccountRequest.contactInfo.facebookUrl',
        },
        {
            to: 'contactInfo_linkedInUrl',
            from: 'updateAccountRequest.contactInfo.linkedInUrl',
        },
        {
            to: 'contactInfo_instagramUrl',
            from: 'updateAccountRequest.contactInfo.instagramUrl',
        }
    );

    onEdit = async (event, formState) => {
        event.preventDefault();

        const {
            uiStore: { loadingStore, toastStore },
            t,
            onComplete,
            dashboardStore,
        } = this.props;
        const { profile } = dashboardStore;

        if (!profile) {
            toastStore.enqueueErrorToast({
                message: t('Error.error_message'),
            });
            return;
        }

        const data = Object.assign({ id: profile.id }, formState);
        loadingStore.addLoading();
        try {
            await HttpService.editProfile(data);
            toastStore.enqueueToast({
                message: t('Account.account_successfully_updated'),
                icon: Member,
            });
            dashboardStore.loadProfile(loadingStore);
            onComplete();
            this.close(false);
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        }
        loadingStore.removeLoading();
    };

    setTouched = () => {
        if (!this.touched) {
            this.touched = true;
        }
    };

    close = async (checkTouched = true) => {
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        if (
            checkTouched &&
            this.touched &&
            !(await simpleModalStore.confirm({
                titleIcon: <PersonalInfo size="3.75em" />,
                title: t('Membership.leave_edit_title'),
                message: t('Membership.leave_edit_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        const { history, parentMatchURL } = this.props;
        history.push(parentMatchURL);
    };

    renderChangePassword = (routeProps) => (
        <ChangePassword {...routeProps} parentMatchURL={this.props.match.url} />
    );

    render() {
        const {
                t,
                dashboardStore: { profile },
                match,
            } = this.props,
            { errors } = this.modelStateStore;

        return (
            <Modal
                isOpen={true}
                className="modal-dialog-centered modal-lg modal-full-screen adaptive"
            >
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.close}
                    >
                        <span aria-hidden="true">
                            <X size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title mx-auto px-2">
                        {t('Profile.edit_profile_title')}
                    </h5>
                </div>
                <ModalBody className="d-flex flex-column mh-0">
                    <div className="scrollable d-flex flex-column  px-1 px-md-5 pb-5">
                        <EditProfileComponent
                            onSubmit={this.onEdit}
                            matchUrl={match.url}
                            errors={errors}
                            profile={profile && profile.data}
                            onTouch={this.setTouched}
                        />
                    </div>
                </ModalBody>
                <Switch>
                    <Route
                        path={`${match.url}/change-password`}
                        render={this.renderChangePassword}
                    />
                    <Route path={`${match.url}/:invalid`} component={NoMatch} />
                </Switch>
            </Modal>
        );
    }
}

export default translate()(EditProfile);
