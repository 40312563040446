import React from 'react';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from 'reactstrap';
import { AngleLeft, AngleRight } from 'Components/Icons';

import { Constants } from 'Data/';

export const dateFormat = 'dddd, MMMM D, YYYY';

class DateControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pickerOpen: false,
            selectedDate: props.selectedDate || moment().format(dateFormat),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDate !== this.props.selectedDate) {
            this.setState({ selectedDate: this.props.selectedDate });
        }
    }

    handleSubtractDay = () => {
        this.setState((state) => {
            const newDate = moment(state.selectedDate)
                .subtract(1, 'day')
                .format(dateFormat);
            this.props.onChange(newDate);
            return {
                selectedDate: newDate,
            };
        });
    };

    handleAddDay = () => {
        this.setState((state) => {
            const newDate = moment(state.selectedDate).add(1, 'day').format(dateFormat);
            this.props.onChange(newDate);
            return {
                selectedDate: newDate,
            };
        });
    };

    togglePicker = (e) => {
        this.setState((prevState) => ({ pickerOpen: !prevState.pickerOpen }));
    };

    handleDateChange = (date) => {
        this.setState({
            selectedDate: moment(date).format(dateFormat),
        });
        this.props.onChange(date);
    };

    handleTodayClick = () => {
        const selectedDate = moment().format(dateFormat);
        this.setState({ selectedDate });
        this.props.onChange(selectedDate);
    };

    renderDateComponent = (props) => {
        return (
            <div
                className="FlexibleBooking__ActionsBar_DateControls_CurrentDate"
                onClick={this.togglePicker}
            >
                {moment(props.value).format(dateFormat)}
            </div>
        );
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div
                    className={classnames(
                        'FlexibleBooking__ActionsBar_DateControls',
                        this.props.className
                    )}
                >
                    <Button
                        className="btn-change-date"
                        color="link"
                        onClick={this.handleSubtractDay}
                        disabled={moment(this.state.selectedDate).isSame(moment(), 'day')}
                    >
                        <AngleLeft />
                    </Button>
                    <div className="FlexibleBooking__ActionsBar_DateControls_DatePicker">
                        <KeyboardDatePicker
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format={Constants.DATE_FORMAT}
                            maxDate={this.props.maxDate}
                            disablePast
                            openPicker={this.togglePicker}
                            open={this.state.pickerOpen}
                            onClose={this.togglePicker}
                            TextFieldComponent={this.renderDateComponent}
                        />
                    </div>
                    <Button
                        className="btn-change-date"
                        color="link"
                        onClick={this.handleAddDay}
                        disabled={moment(this.state.selectedDate).isSame(
                            this.props.maxDate,
                            'day'
                        )}
                    >
                        <AngleRight />
                    </Button>
                </div>
                {this.props.showTodayButton && (
                    <button
                        className="btn btn-light btn-sm"
                        onClick={this.handleTodayClick}
                    >
                        {t('Invita.today')}
                    </button>
                )}
            </>
        );
    }
}

DateControls.defaultProps = {
    toggleDatePicker: () => {},
    onChange: () => {},
    showTodayButton: true,
};

export default translate()(DateControls);
