import React from 'react';

const AngleLeft = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 10 16"
        width={size || '1em'}
        height={size || '1em'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.391352 8.51011L8.39769 15.805C8.66041 16.065 9.08583 16.065 9.34922 15.805C9.61127 15.5449 9.61127 15.1181 9.34922 14.8581L1.82565 8.00333L9.34922 1.1419C9.61127 0.88185 9.61127 0.461763 9.34922 0.195041C9.08583 -0.0650135 8.66041 -0.0650135 8.39769 0.195041L0.391352 7.48989C0.251323 7.62992 0.191311 7.81663 0.200646 8.00333C0.191311 8.18337 0.251323 8.37008 0.391352 8.51011Z"
            fill={fill || 'currentColor'}
        />
    </svg>
);

export default AngleLeft;
