import React from 'react';
import { translate } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Invitations from 'Services/Invitations';

import { StringUtils } from 'Utils/';

import CreateMembership from './CreateMembership';
import FirstLogin from 'WebApp/Views/Logged/FirstLogin';
import { NoResults } from 'Components/Loading';
import { InvitationList, MembershipsGrid } from 'Components/Membership';
import { Error } from 'Components/Icons';
import NoMatch from 'WebApp/Views/NoMatch';
import EditProfile from 'WebApp/Views/Logged/Personal/EditProfile';

const nothing = () => null;

@inject('uiStore', 'dashboardStore')
@observer
class MembershipList extends React.Component {
    closeModalAndReload = () => {
        const { history, parentMatchURL } = this.props;
        this.loadMemberships();
        history.push(parentMatchURL);
    };

    invitationLoaded = false;

    renderCreateMembership = (routeProps) => (
        <CreateMembership
            {...routeProps}
            onUpdate={this.closeModalAndReload}
            parentMatchURL={this.props.parentMatchURL}
        />
    );

    renderFirstLogin = (routeProps) => (
        <FirstLogin {...routeProps} parentMatchURL={this.props.parentMatchURL} />
    );

    renderEditProfile = (routeProps) => (
        <EditProfile
            {...routeProps}
            parentMatchURL={this.props.parentMatchURL}
            onComplete={this.loadProfile}
        />
    );

    onInvitationDetail = (proposal) => {
        Invitations.invitationDetail(proposal, this.props.t);
    };

    onAcceptInvitation = (e) => {
        e.stopPropagation();
        const { proposals } = this.props.dashboardStore,
            id = e.currentTarget.getAttribute('data-id');
        const proposal = proposals.data.find((proposal) => proposal.id === id);
        Invitations.acceptInvitation(proposal, this.props.t);
    };

    onDeclineInvitation = (e) => {
        e.stopPropagation();
        const { proposals } = this.props.dashboardStore,
            id = e.currentTarget.getAttribute('data-id');
        const proposal = proposals.data.find((proposal) => proposal.id === id);
        Invitations.rejectInvitation(proposal, this.props.t);
    };

    load = () => {
        const {
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        dashboardStore.load(loadingStore);
    };

    loadMemberships = () => {
        const {
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        dashboardStore.loadMemberships(loadingStore);
    };

    loadProfile = () =>
        this.props.dashboardStore.loadProfile(this.props.uiStore.loadingStore);

    render() {
        const {
                t,
                parentMatchURL,
                dashboardStore: { memberships, proposals },
                location,
            } = this.props,
            statusMessage = StringUtils.membershipsStatusEnumeration(
                memberships && memberships.data,
                t
            );
        const proposalsFailed = proposals && !proposals.data && !proposals.ongoing;
        const membershipsFailed =
            memberships && !memberships.data && !memberships.ongoing;
        return (
            <React.Fragment>
                <div className="bg-dark p-7 text-center">
                    <h5 className="text-uppercase m-0">
                        {t('Membership.all_memberships')}
                    </h5>
                    {statusMessage && (
                        <div
                            className="text-secondary text-sm mt-2"
                            dangerouslySetInnerHTML={{ __html: statusMessage }}
                        />
                    )}
                </div>
                {proposalsFailed && membershipsFailed ? (
                    <div className="row py-6">
                        <NoResults
                            className="offset-md-3 col-md-6 col-12"
                            icon={<Error size="5em" />}
                            title={t('Error.error')}
                            subTitle={t('Error.error_message')}
                            action={{ text: t('Error.retry'), onClick: this.load }}
                        />
                    </div>
                ) : (
                    <React.Fragment>
                        {proposals && proposals.data && proposals.data.length ? (
                            <InvitationList
                                key="invitations"
                                proposals={proposals}
                                onInvitationDetail={this.onInvitationDetail}
                                onDeclineInvitation={this.onDeclineInvitation}
                                onAcceptInvitation={this.onAcceptInvitation}
                                location={location}
                                t={t}
                            />
                        ) : null}

                        <MembershipsGrid
                            key="memberships"
                            memberships={memberships}
                            loadMemberships={this.loadMemberships}
                            matchUrl={parentMatchURL}
                            className="mt-4"
                            t={t}
                        />
                        <div key="create_a_membership" className="row py-6">
                            <div className="offset-md-3 col-md-6 col-12">
                                <Link
                                    className="btn btn-primary w-100"
                                    to={`${parentMatchURL}/create`}
                                >
                                    {t('Membership.create_a_membership')}
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {/* Sub-components */}
                <Switch>
                    <Route
                        path={`${parentMatchURL}/create`}
                        render={this.renderCreateMembership}
                    />
                    <Route
                        path={`${parentMatchURL}/edit-profile`}
                        render={this.renderEditProfile}
                    />
                    <Route
                        path={`${parentMatchURL}/first-login`}
                        render={this.renderFirstLogin}
                    />
                    <Route path={`${parentMatchURL}/invitation`} render={nothing} />
                    <Route path={`${parentMatchURL}/book-a-room`} render={nothing} />
                    <Route
                        path={`${parentMatchURL}/subscribe-to-a-plan`}
                        render={nothing}
                    />
                    <Route path={`${parentMatchURL}/buy-a-day-pass`} render={nothing} />
                    <Route path={`${parentMatchURL}/:invalid`} component={NoMatch} />
                </Switch>
            </React.Fragment>
        );
    }
}

export default translate()(MembershipList);
