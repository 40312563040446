import React, { Component, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'Assets/Fonts/stylesheet.css';
import 'Sass/App.scss';

import Main from 'Routers/Main';
import GlobalLoader from 'Components/Loading/GlobalLoadingSpinner';
import ToastContainer from 'Components/Toasts/ToastContainer';
import { SimpleModalContainer } from 'Components/Modal';

class App extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
                <SimpleModalContainer />
                <GlobalLoader />
                <ToastContainer />
            </Fragment>
        );
    }
}

export default App;
