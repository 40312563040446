class EventBus {
    listeners = {};
    on(key, listener) {
        let listeners = this.listeners[key];
        if (!listeners) this.listeners[key] = listeners = [];
        listeners.push(listener);
        return () => this.off(key, listener);
    }
    off(key, listener) {
        const listeners = this.listeners[key];
        if (!listeners) return;
        listeners.splice(listeners.indexOf(listener), 1);
    }
    emit(key, message) {
        (this.listeners[key] || []).forEach((listener) => listener(message));
    }
}

export default EventBus;
