import React from 'react';

const Company = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M37.21 24.87a14.72 14.72 0 0 0-10.48-4.38 14.85 14.85 0 0 0 0 29.69 14.83 14.83 0 0 0 10.48-25.31zM26.7 45.46a10.12 10.12 0 0 1 0-20.24 10.12 10.12 0 0 1 0 20.24zM34.85 56.5h-16.4A17.37 17.37 0 0 0 1 73.75V91.1l51 .11V73.86A17.27 17.27 0 0 0 34.85 56.5zm-19.23 5a12.1 12.1 0 0 1 2.54-.31h17a12.31 12.31 0 0 1 4.41.94 12.58 12.58 0 0 1 7.71 11.68v12.63l-41.54-.05V73.76a12.73 12.73 0 0 1 9.88-12.26zM72 52.84a14.85 14.85 0 1 0 14.81 14.85A14.86 14.86 0 0 0 72 52.84zm0 25a10.12 10.12 0 1 1 10.1-10.15A10.14 10.14 0 0 1 72 77.81zM80.17 88.83H63.76a17.36 17.36 0 0 0-17.37 17.32v17.36h51v-17.36a17.27 17.27 0 0 0-17.22-17.32zm-19.23 5a12.8 12.8 0 0 1 2.55-.31h17a12.09 12.09 0 0 1 4.41.93 12.56 12.56 0 0 1 7.73 11.66v12.64H51.11v-12.6a12.74 12.74 0 0 1 9.83-12.28zM117.61 20.49a14.85 14.85 0 1 0 14.84 14.85 14.86 14.86 0 0 0-14.84-14.85zm0 25a10.12 10.12 0 1 1 10.12-10.12 10.15 10.15 0 0 1-10.12 10.09zM125.81 56.48H109.4A17.36 17.36 0 0 0 92 73.8v17.36h51V73.8a17.28 17.28 0 0 0-17.19-17.32zm-19.23 5a13 13 0 0 1 2.56-.31h17a12.25 12.25 0 0 1 4.4.93 12.56 12.56 0 0 1 7.73 11.66v12.68H96.76V73.8a12.74 12.74 0 0 1 9.82-12.28z"
        />
    </svg>
);

export default Company;
