import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { CGMessages, Delete } from 'Components/Icons';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import { MessageActions, MessageList } from 'Components/Message';
import { Constants } from 'Data/';
import HttpService from 'Services/HttpService';

import MessageDetail from './MessageDetail';
import ScrollUtils from 'Utils/ScrollUtils';
import { observable } from 'mobx';
import LoadingStore from 'Stores/UI/LoadingStore';
import BookingsConflictsModal from 'WebApp/Views/Logged/Booking/BookingsConflictsModal';

const { DEFAULT_PAGE_SIZE } = Constants;

@inject('dashboardStore', 'uiStore', 'eventBus')
@observer
class PersonalMessages extends React.Component {
    loadedRowMap = {};
    lastPageIndex = -1;

    state = {
        messages: [],
        active: null,
        totalMessageCount: DEFAULT_PAGE_SIZE * 2,
        conflictToggle: false,
        recurrenceId: null,
    };

    @observable localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);

    componentDidMount() {
        const {
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        dashboardStore.loadSettings(loadingStore);
        this.initMessagesList();
    }

    initMessagesList = async () => {
        this.localLoadingStore.addLoading();
        await this.loadMoreMessages(false, undefined, undefined, true);
        this.localLoadingStore.removeLoading();
    };

    updateSelectedMessage = (selectedMessageId) => {
        this.setState({ active: selectedMessageId });
    };

    openFirstMessage = () => {
        const { messages } = this.state;
        const { currentId } = this.props;
        this.setState({ active: currentId || messages[0].id });
    };

    loadMoreMessages = async (
        nextPage = false,
        startIndex = 0,
        stopIndex = DEFAULT_PAGE_SIZE - 1,
        openFirstMessage = false
    ) => {
        //If it is a "new" search, reset the loading tracking.
        if (!nextPage) {
            this.loadedRowMap = {};
            this.lastPageIndex = -1;
        }

        //If a request has already been made for that index, do not load it again.
        if (this.loadedRowMap[startIndex]) return;

        this.lastPageIndex++;

        //Set the range as loading.
        for (let i = startIndex; i <= stopIndex; i++) {
            this.loadedRowMap[i] = 'loading';
        }

        try {
            const { data } = await HttpService.messages(
                {
                    page: nextPage ? this.lastPageIndex : 0,
                    pageSize: DEFAULT_PAGE_SIZE,
                    forManagers: false,
                },
                this.cancellation
            );

            let newState = {
                totalMessageCount:
                    data.length === DEFAULT_PAGE_SIZE
                        ? this.state.totalMessageCount + DEFAULT_PAGE_SIZE
                        : nextPage
                        ? this.state.messages.length + data.length
                        : data.length,
            };
            if (nextPage) {
                newState.messages = [...this.state.messages, ...data];
            } else {
                newState.messages = data;
                this.lastPageIndex = 0;
            }

            this.setState(newState, () => {
                if (openFirstMessage) {
                    this.scrollUtils.scrollToRef();
                    this.openFirstMessage();
                }
            });

            //Set the range as loaded.
            for (let i = startIndex; i <= stopIndex; i++) {
                this.loadedRowMap[i] = 'loaded';
            }
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;

            //The rows have not been loaded; remove their loaded status.
            for (let i = startIndex; i <= stopIndex; i++) {
                delete this.loadedRowMap[i];
            }
            this.lastPageIndex--;
        }
    };

    updateList = (messageId, newStatus) => {
        const { messages } = this.state;

        const newMessages = messages.slice();
        if (newStatus === Constants.MESSAGE_STATUS.DELETED) {
            const messageIndex = newMessages.findIndex((m) => m.id === messageId);
            newMessages.splice(messageIndex, 1);

            const newIndex =
                messageIndex < messages.length ? messageIndex : messages.length - 1;
            this.setState({ active: messages[newIndex].id });
        } else {
            const message = newMessages.find((m) => m.id === messageId);
            newMessages[newMessages.indexOf(message)] = Object.assign({}, message, {
                status: newStatus,
            });
        }
        this.setState({
            messages: newMessages,
        });
    };

    deleteAllMessages = async () => {
        const {
                uiStore: { simpleModalStore },
                dashboardStore,
                t,
                history,
                match,
            } = this.props,
            { messages } = this.state;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Delete size="3.75em" />,
                title: t('Message.delete_messages'),
                message: t('Message.delete_all_messages_confirmation'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        const backup = messages.slice();
        try {
            this.setState({
                messages: [],
            });
            await HttpService.setAllMessagesStatus({
                status: Constants.MESSAGE_STATUS.DELETED,
                forManagers: false,
            });
            await dashboardStore.checkUnreadmessagse();
            history.replace(match.url);
        } catch (err) {
            this.setState({
                messages: backup,
            });
        }
    };

    markAllMessagesAsRead = async () => {
        const { dashboardStore } = this.props,
            { messages } = this.state;
        try {
            const newMessages = messages.slice();
            newMessages.forEach(
                (message) => (message.status = Constants.MESSAGE_STATUS.READ)
            );

            this.setState({
                messages: newMessages,
            });
            await HttpService.setAllMessagesStatus({
                status: Constants.MESSAGE_STATUS.READ,
                forManagers: false,
            });
            await dashboardStore.checkUnreadmessagse();
            this.props.eventBus.emit('all_messages_read');
        } catch (err) {}
    };

    conflictToggle = (recurrenceId) => {
        this.setState((prevState) => ({
            conflictToggle: !prevState.conflictToggle,
            recurrenceId,
        }));
    };

    loadNextMessages = async (startIndex, endIndex) => {
        return await this.loadMoreMessages(true, startIndex, endIndex);
    };

    isRowLoaded = ({ index }) => {
        return !!this.loadedRowMap[index];
    };

    scrollUtils = new ScrollUtils();

    render() {
        const { t, match } = this.props,
            { messages, active, conflictToggle, recurrenceId } = this.state;
        return (
            <div className="d-flex position-relative">
                {messages.length ? (
                    <Fragment>
                        <div className="w-50 pr-1">
                            <div className="position-relative">
                                <MessageActions
                                    onDeleteAll={this.deleteAllMessages}
                                    onAllRead={this.markAllMessagesAsRead}
                                />
                            </div>
                            <div id="message-list">
                                <MessageList
                                    ref={this.scrollUtils.registerRef}
                                    items={messages}
                                    matchUrl={match.url}
                                    onClick={this.updateSelectedMessage}
                                    currentId={active}
                                    loadNextPage={this.loadNextMessages}
                                    isRowLoaded={this.isRowLoaded}
                                    totalItemCount={this.state.totalMessageCount}
                                />
                            </div>
                        </div>
                        <div className="w-50 pl-1">
                            <MessageDetail
                                {...this.props}
                                conflictToggle={this.conflictToggle}
                                currentMessage={active}
                                onMessageUpdate={this.updateList}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <NoResults
                        className="m-auto py-3"
                        icon={<CGMessages size="3.75em" />}
                        title={t('Message.no_messages')}
                        subTitle={t('Message.no_messages_description')}
                    />
                )}

                <LoadingSpinner loading={this.localLoadingStore.isLoading} />
                {conflictToggle && (
                    <BookingsConflictsModal
                        {...this.props}
                        onClose={this.conflictToggle}
                        bookingId={recurrenceId}
                        toggle={conflictToggle}
                    />
                )}
            </div>
        );
    }
}

export default translate()(PersonalMessages);
