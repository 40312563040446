import React from 'react';

const MyPlan = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
        viewBox="0 0 144 135.66"
    >
        <g data-name="Layer 2">
            <path
                fill={fill || 'currentColor'}
                d="M100.8 35.24V0h-58v35.24H0v100.42h144V35.24zM87 75L72 60 57 75H42.23A36.6 36.6 0 0 1 5.77 40.93h132.46A36.6 36.6 0 0 1 101.77 75zm-5.39 2.65L72 87.33l-9.65-9.65L72 68zM48.5 5.68h46.62v29.56H48.5zM138.32 130H5.68V59.62a42.25 42.25 0 0 0 36.55 21.09h15.12L72 95.37l14.65-14.66h15.11a42.25 42.25 0 0 0 36.55-21.09zm0 0"
                data-name="Layer 1"
            />
        </g>
    </svg>
);

export default MyPlan;
