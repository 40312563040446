import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { injectStripe } from 'react-stripe-elements';
import { observer } from 'mobx-react';

import { Ach } from 'Components/Icons';
import { Errors, FormGroup, Consent } from 'Components/Forms';
import colorVariables from 'Sass/variables.scss';
import { LoadingSpinner } from 'Components/Loading';
import PaymentMethodSavedInfo from './PaymentMethodSavedInfo';

const HOLDER_TYPES = [
    { resourceKey: 'PaymentMethod.ach_individual', value: 'individual' },
    { resourceKey: 'PaymentMethod.ach_company', value: 'company' },
];

@observer
class ACHForm extends React.Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { store } = this.props;
        if (!this.props.stripe) {
            store.achLoadingStore.addLoading();
        } else {
            store.setStripe(this.props.stripe);
        }

        store.loadConsent();
    }

    componentDidUpdate(prevProps, prevState) {
        const { store } = this.props;
        if (!prevProps.stripe && this.props.stripe) {
            store.achLoadingStore.removeLoading();
            store.setStripe(this.props.stripe);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.props.store.setState('ach', { [name]: value });
    };

    submit = (e) => {
        e.preventDefault();
        this.props.action(this.props.path);
    };

    render() {
        const {
            t,
            store: {
                ach,
                achLoadingStore,
                achModelStateStore: { errors },
            },
            path,
        } = this.props;

        return (
            <form id={path} className="px-5 px-xl-15" onSubmit={this.submit}>
                <div className="header">
                    <Ach
                        fill={colorVariables.secondary}
                        size="3.75em"
                        className="d-block mx-auto mb-3 mt-1"
                    />
                </div>
                <div className="row pb-2">
                    <FormGroup
                        id="holderName"
                        displayName={t('PaymentMethod.account_holder_name')}
                        type="text"
                        className="col-12"
                        required={true}
                        errors={errors}
                        onChange={this.handleInputChange}
                        value={ach.holderName}
                    />
                    <FormGroup
                        id="holderType"
                        displayName={t('PaymentMethod.account_holder_type')}
                        values={HOLDER_TYPES}
                        value={ach.holderType}
                        type="select"
                        className="col-12"
                        required={true}
                        errors={errors}
                        onChange={this.handleInputChange}
                    />
                    <FormGroup
                        id="routingNumber"
                        displayName={t('PaymentMethod.routing_number')}
                        type="text"
                        className="col-12"
                        required={true}
                        errors={errors}
                        onChange={this.handleInputChange}
                        value={ach.routingNumber}
                    />
                    <FormGroup
                        id="accountNumber"
                        displayName={t('PaymentMethod.account_number')}
                        type="text"
                        className="col-12"
                        required={true}
                        errors={errors}
                        onChange={this.handleInputChange}
                        value={ach.accountNumber}
                    />
                    <div className="form-group col-12">
                        <Consent
                            id="consentChecked"
                            displayName={t('PaymentMethod.transaction_configure')}
                            text={ach.consent && ach.consent.content}
                            onChange={this.handleInputChange}
                            checked={ach.consentChecked}
                        />
                    </div>
                    <Errors errors={errors['other']} />
                    <PaymentMethodSavedInfo />
                    <LoadingSpinner loading={achLoadingStore.isLoading} />
                </div>
            </form>
        );
    }
}

export default translate()(injectStripe(ACHForm));
