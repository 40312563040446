import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SubscriptionDetails } from './';
import { MembershipHeader } from 'Components/Membership';
import { InvoiceSummary } from 'Components/Invoice';
import Constants from 'Data/Constants';
import classnames from 'classnames';

class SubscriptionOverview extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        // Membership & subscription propTypes are request up from the sub-views.
        membership: PropTypes.any.isRequired,
        subscription: PropTypes.any,
        headerChildren: PropTypes.node,
        headerOtherChildren: PropTypes.node,
        topRightPaneChildren: PropTypes.node,
        bottomRightPaneChildren: PropTypes.node,
        editURL: PropTypes.string,
        canEdit: PropTypes.bool,
        paneDetail: PropTypes.bool,
        smallContext: PropTypes.bool,
        isSubscriptionRequestOverview: PropTypes.bool,
    };

    render() {
        const {
            className,
            paneDetail,
            membership,
            subscription,
            headerChildren,
            headerOtherChildren,
            topRightPaneChildren,
            bottomRightPaneChildren,
            editURL,
            canEdit,
            t,
            smallContext,
            isSubscriptionRequestOverview,
            modal,
        } = this.props;
        const paymentShowDetails =
            subscription &&
            membership.rights.some(
                (right) =>
                    right === Constants.MEMBERSHIP_RIGHTS.FINANCE ||
                    right === Constants.MEMBERSHIP_RIGHTS.SUBSCRIBE_PLAN
            );

        return (
            <div
                className={classnames(className, { 'd-flex flex-column mh-100': modal })}
            >
                <MembershipHeader
                    membership={membership}
                    otherChildren={headerOtherChildren}
                >
                    {headerChildren}
                </MembershipHeader>
                {canEdit &&
                    editURL &&
                    subscription &&
                    subscription.plan.interval !== Constants.PLAN_INTERVALS.DAILY && (
                        /* Edit subscription in a next feature */
                        /* <Link
                            to={editURL}
                            className="btn btn-primary w-100 bottom-action"
                        >
                            {t('Subscription.edit_my_subscription')}
                        </Link> */

                        <div className="w-100 text-center mt-1 mb-1">
                            {t('Subscription.edit_subscription_message')}
                        </div>
                    )}
                {subscription && (
                    <div className="d-flex flex-wrap no-gutters scrollable">
                        <SubscriptionDetails
                            paneDetail={paneDetail}
                            subscription={subscription}
                            membershipRights={membership.rights}
                            paymentShowDetails={paymentShowDetails}
                            isSubscriptionRequestOverview={isSubscriptionRequestOverview}
                        />
                        {paymentShowDetails && !isSubscriptionRequestOverview && (
                            <div className={classnames('w-50', { 'w-100': paneDetail })}>
                                {topRightPaneChildren}
                                <InvoiceSummary
                                    smallContext={smallContext}
                                    invoiceSummary={subscription.invoiceSummary}
                                    paneDetail={paneDetail}
                                    interval={subscription.plan.interval}
                                    membershipRights={membership.rights}
                                />
                                {bottomRightPaneChildren}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default translate()(SubscriptionOverview);
