import React from 'react';

const ZoomOut = ({ size, fill, className }) => (
    <svg
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.73168 12.2301C3.5859 12.2301 1.03576 9.71993 1.03576 6.62466C1.03576 3.52888 3.5859 1.01925 6.73168 1.01925C9.87747 1.01925 12.4281 3.52888 12.4281 6.62466C12.4281 9.71993 9.87747 12.2301 6.73168 12.2301ZM15.8485 15.1303L11.7174 11.0648C12.7987 9.88897 13.4634 8.33508 13.4634 6.62466C13.4634 2.96624 10.4496 0 6.73168 0C3.01375 0 0 2.96624 0 6.62466C0 10.2831 3.01375 13.2488 6.73168 13.2488C8.33809 13.2488 9.81145 12.6937 10.9687 11.7694L15.1158 15.8505C15.3183 16.05 15.6459 16.05 15.8485 15.8505C16.0505 15.6519 16.0505 15.3288 15.8485 15.1303Z"
            fill={fill || 'currentColor'}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5026 6.50158V7.50183H3.00075V6.50158H10.5026Z"
            fill={fill || 'currentColor'}
        />
    </svg>
);

export default ZoomOut;
