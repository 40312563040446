import React from 'react';

const X = ({ fill, size = '1em' }) => (
    <svg className="icon" viewBox="0 0 20 20" width={size} height={size}>
        <g fill={fill || 'currentColor'}>
            <path d="M.756 1.99A.873.873 0 0 1 1.99.756L19.244 18.01a.873.873 0 1 1-1.234 1.234L.756 1.99z" />
            <path d="M1.99 19.244A.873.873 0 1 1 .756 18.01L18.01.756a.873.873 0 0 1 1.234 1.234L1.99 19.244z" />
        </g>
    </svg>
);

export default X;
