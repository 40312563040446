import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Phone from 'Components/Icons/Phone';
import ScreenSharing from 'Components/Icons/ScreenSharing';
import Television from 'Components/Icons/Television';
import WhiteBoard from 'Components/Icons/WhiteBoard';
import { translate } from 'react-i18next';
import classnames from 'classnames';

class ConferenceRoomAmenitiesList extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        hasPhone: PropTypes.bool,
        hasScreenSharing: PropTypes.bool,
        hasTv: PropTypes.bool,
        hasWhiteboard: PropTypes.bool,
    };

    render() {
        const {
            hasPhone,
            hasScreenSharing,
            hasTv,
            hasWhiteboard,
            className,
            amenitiesClass,
            details,
            t,
            paneDetail,
            amenitiesSize = '1.5em',
            googleAddon = false,
        } = this.props;
        return (
            <div
                className={classnames(
                    {
                        'text-center font-weight-medium': details,
                        'conference-room-amenity-list': !details,
                        'pb-1': !!paneDetail,
                    },
                    'pt-0 ' + className
                )}
            >
                {details && (
                    <div className="text-xs text-left font-weight-bold py-1 text-uppercase">
                        {t('Room.features')}
                    </div>
                )}
                <span
                    className={classnames({
                        'mt-1 optionAvailable d-flex': details,
                        'justify-content-center': details && !googleAddon,
                    })}
                >
                    {hasPhone ? (
                        <div
                            className={classnames(
                                { 'px-3': details && !googleAddon, 'px-2': googleAddon },
                                amenitiesClass,
                                'd-inline-block'
                            )}
                        >
                            <Phone size={amenitiesSize} />
                            {details && (
                                <div className="text-xs my-1">{t('Room.has_phone')}</div>
                            )}
                        </div>
                    ) : null}
                    {hasScreenSharing ? (
                        <div
                            className={classnames(
                                { 'px-3': details && !googleAddon, 'px-2': googleAddon },
                                amenitiesClass,
                                'd-inline-block'
                            )}
                        >
                            <ScreenSharing size={amenitiesSize} />
                            {details && (
                                <div className="text-xs my-1">
                                    {t('Room.has_screen_sharing')}
                                </div>
                            )}
                        </div>
                    ) : null}
                    {hasTv ? (
                        <div
                            className={classnames(
                                { 'px-3': details && !googleAddon, 'px-2': googleAddon },
                                amenitiesClass,
                                'd-inline-block'
                            )}
                        >
                            <Television size={amenitiesSize} />
                            {details && (
                                <div className="text-xs my-1">{t('Room.has_tv')}</div>
                            )}
                        </div>
                    ) : null}
                    {hasWhiteboard ? (
                        <div
                            className={classnames(
                                { 'px-3': details && !googleAddon, 'px-2': googleAddon },
                                amenitiesClass,
                                'd-inline-block'
                            )}
                        >
                            <WhiteBoard size={amenitiesSize} />
                            {details && (
                                <div className="text-xs my-1">
                                    {t('Room.has_whiteboard')}
                                </div>
                            )}
                        </div>
                    ) : null}
                </span>
            </div>
        );
    }
}

export default translate()(ConferenceRoomAmenitiesList);
