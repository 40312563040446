import React from 'react';

const Delete = ({ fill = 'currentColor', stroke, size = '1em', className = '' }) => (
    <svg className={`icon ${className}`} viewBox="0 0 24 24" width={size} height={size}>
        <g fill={fill} fillRule="evenodd" stroke={stroke} strokeWidth={0.5}>
            <path d="M3.092 6.042H20.85V3.493H3.092v2.549zm1.546 17.015h14.665V6.973H4.638v16.084zM9.295 2.562h5.352V.932H9.295v1.63zm12.02 0h-5.737V.467A.466.466 0 0 0 15.112 0H8.828a.466.466 0 0 0-.465.467v2.095H2.625a.466.466 0 0 0-.465.466v3.48c0 .257.208.465.465.465h1.082v16.549c0 .256.208.465.465.465h15.596a.467.467 0 0 0 .467-.465V6.973h1.08a.467.467 0 0 0 .467-.465v-3.48a.467.467 0 0 0-.467-.466z" />
            <path d="M8.1 21.184a.466.466 0 0 1-.465-.466v-9.715a.466.466 0 1 1 .931 0v9.715a.466.466 0 0 1-.465.466m3.869 0a.466.466 0 0 1-.465-.466v-9.715a.466.466 0 1 1 .931 0v9.715a.466.466 0 0 1-.466.466m3.87 0a.466.466 0 0 1-.466-.466v-9.715a.466.466 0 1 1 .932 0v9.715a.466.466 0 0 1-.466.466" />
        </g>
    </svg>
);

export default Delete;
