import React from 'react';

const CgMessages = (props) => (
    <svg
        className={'icon' + (props.className ? ` ${props.className}` : '')}
        viewBox="0 0 60 60"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <g fill={props.fill || 'currentColor'} fillRule="evenodd">
            <path d="M30 3.492c-15.306 0-27.758 10.38-27.758 23.137 0 7.644 4.528 14.787 12.112 19.109l.455.26 1.514 8.386 6.503-5.397.529.11c2.142.444 4.379.67 6.645.67 15.306 0 27.758-10.38 27.758-23.138C57.758 13.871 45.306 3.492 30 3.492zm-15.202 55.07l-2.01-11.141C4.77 42.669 0 34.933 0 26.629 0 12.635 13.458 1.25 30 1.25s30 11.385 30 25.38c0 13.994-13.458 25.379-30 25.379-2.232 0-4.44-.206-6.568-.61l-8.634 7.163z" />
            <path d="M19.364 25.291c-.894 0-1.623.728-1.623 1.623 0 .894.729 1.622 1.623 1.622.895 0 1.623-.728 1.623-1.622 0-.895-.728-1.623-1.623-1.623m0 5.487a3.87 3.87 0 0 1-3.864-3.864 3.87 3.87 0 0 1 3.864-3.865 3.869 3.869 0 0 1 3.865 3.865 3.869 3.869 0 0 1-3.865 3.864M30 25.291c-.895 0-1.623.728-1.623 1.623 0 .894.728 1.622 1.623 1.622.894 0 1.622-.728 1.622-1.622 0-.895-.728-1.623-1.622-1.623m0 5.487a3.87 3.87 0 0 1-3.865-3.864A3.87 3.87 0 0 1 30 23.049a3.869 3.869 0 0 1 3.864 3.865A3.869 3.869 0 0 1 30 30.778m10.636-5.487c-.895 0-1.623.728-1.623 1.623 0 .894.728 1.622 1.623 1.622.894 0 1.622-.728 1.622-1.622 0-.895-.728-1.623-1.622-1.623m0 5.487a3.87 3.87 0 0 1-3.865-3.864 3.87 3.87 0 0 1 3.865-3.865 3.869 3.869 0 0 1 3.864 3.865 3.869 3.869 0 0 1-3.864 3.864" />
        </g>
    </svg>
);

export default CgMessages;
