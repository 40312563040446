import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { inject } from 'mobx-react';

import HttpService from 'Services/HttpService';
import { MemberInvitation } from 'Components/Membership';
import { Member, X, ConferenceRoom } from 'Components/Icons';
import classnames from 'classnames';
import { Constants } from 'Data/';
import { sortBy } from 'Utils/ObjectUtils';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

@inject('uiStore')
class AddMembers extends React.Component {
    state = {
        selectedMemberIds: [],
        selectedMemberEmails: [],
        membershipId: this.props.membershipId || this.props.store.membershipId,
    };

    componentDidMount() {
        const { attendance, attendees, store } = this.props;
        if (store.addMembers || store.isCreation)
            this.setState({
                selectedMemberIds: this.props.store?.selectedMemberIds || [],
                selectedMemberEmails: this.props.store?.selectedMemberEmails || [],
            });
        else
            this.setState({
                selectedMemberIds:
                    attendance && attendees && attendees.length
                        ? attendees.map((attendee) => attendee.id)
                        : [],
            });
    }

    toggle = async () => {
        const {
            onClose,
            attendance = false,
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        if (
            this.state.touched &&
            !(await simpleModalStore.confirm({
                titleIcon: <Member size="3.75em" />,
                title: attendance
                    ? t('Booking.leave_attendance_title')
                    : t('Membership.leave_members_title'),
                message: attendance
                    ? t('Booking.leave_attendance_message')
                    : t('Membership.leave_members_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;
        onClose
            ? onClose()
            : this.props.history.push(
                  '/dashboard/company/detail/' + this.state.membershipId
              );
    };

    saveAttendees = async () => {
        const { onClose, store } = this.props;
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();
        try {
            if (
                store.creditBalance.paymentMethodHolder.holderType ===
                Constants.HOLDER_TYPE.MEMBERSHIP
            ) {
                const { data: membership } = await HttpService.membershipDetail(
                    store.creditBalance.paymentMethodHolder.holderId,
                    this.cancellation
                );
                UpdateStateStoreUtils(
                    store,
                    'attendees',
                    membership.members
                        .filter((member) =>
                            this.state.selectedMemberIds.includes(member.id)
                        )
                        .map((member) => ({
                            id: member.id,
                            imgSrc: member.imageUrl,
                            name: `${member.firstName} ${member.lastName}`,
                        }))
                );
            }
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            UpdateStateStoreUtils(store, 'attendees', []);
            return null;
        } finally {
            UpdateStateStoreUtils(
                store,
                'selectedMemberIds',
                this.state.selectedMemberIds
            );
            onClose();
        }
    };

    updateAttendance = async () => {
        const {
            store,
            uiStore: { toastStore, loadingStore },
            onClose,
            t,
        } = this.props;
        try {
            loadingStore.addLoading();
            const request = {
                amenityId: store.amenity.id,
                periodStart: store.periodStart,
                periodEnd: store.periodEnd,
                isPrivate: store.isPrivate,
                title: store.title,
                description: store.description,
                invitedAccountIds: this.state.selectedMemberIds,
            };
            await HttpService.updateBooking({
                bookingId: store.id,
                data: request,
            });

            toastStore.enqueueToast({
                icon: Member,
                message: t('Membership.invitations_sent'),
            });
            onClose();
        } catch (e) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    nextStep = () => {
        const { onComplete, stepKey, store } = this.props,
            { selectedMemberIds, selectedMemberEmails } = this.state;
        const countMembers = selectedMemberIds.length + selectedMemberEmails.length;
        UpdateStateStoreUtils(store, 'countMembers', countMembers);
        if (countMembers === 0 && store.isCreation)
            UpdateStateStoreUtils(store, 'memberStep', false);
        const nonMembers =
            selectedMemberEmails.map((memberEmail) => ({
                email: memberEmail,
                name: memberEmail,
                id: memberEmail,
                roles: [Constants.MEMBER_ROLES.MEMBER],
            })) || [];
        UpdateStateStoreUtils(store, 'selectedMemberIds', selectedMemberIds);
        UpdateStateStoreUtils(store, 'selectedMemberEmails', selectedMemberEmails);
        UpdateStateStoreUtils(
            store,
            'pendingMembers',
            sortBy([...store.pendingMembers, ...nonMembers], 'name')
        );
        onComplete(stepKey, null);
    };

    membersSelected = (selectedMemberIds) => {
        const { store } = this.props;
        UpdateStateStoreUtils(store, 'touched', true);
        this.setState({ selectedMemberIds: selectedMemberIds, touched: true });
    };

    membersEmailsSelected = (selectedMemberEmails) => {
        this.setState({ selectedMemberEmails: selectedMemberEmails, touched: true });
    };

    RenderMemberInvitation = () => {
        const {
                t,
                attendance = false,
                editAttendance = false,
                organizer = null,
                store,
            } = this.props,
            { selectedMemberIds, selectedMemberEmails, membershipId } = this.state;
        const selectedMembers =
            selectedMemberEmails.length + selectedMemberIds.length > 0;
        return (
            <Fragment>
                <div
                    className={classnames(
                        { scrollable: !store },
                        'd-flex flex-column flex-grow-1'
                    )}
                >
                    <MemberInvitation
                        membersSelected={this.membersSelected}
                        membersEmailsSelected={this.membersEmailsSelected}
                        membershipId={membershipId}
                        selectedMemberIds={selectedMemberIds}
                        selectedMemberEmails={selectedMemberEmails}
                        attendance={attendance}
                        store={store}
                        organizer={organizer}
                    />
                </div>
                {attendance && (
                    <div className="time-slot-info px-3 px-xl-7">
                        <div className="text-center mt-1 mb-1">
                            {t('Booking.total_attendance', {
                                param1: selectedMemberIds.length,
                                count: selectedMemberIds.length,
                            })}
                        </div>
                    </div>
                )}
                <button
                    className={classnames('btn btn-primary bottom-action w-100', {
                        disabled: !selectedMembers && store.addMembers,
                    })}
                    onClick={
                        editAttendance
                            ? this.updateAttendance
                            : attendance
                            ? this.saveAttendees
                            : this.nextStep
                    }
                >
                    {selectedMembers && !store.addMembers
                        ? t('Membership.confirm')
                        : t('next')}
                </button>
            </Fragment>
        );
    };

    render() {
        const {
            t,
            noModal,
            isOpen = true,
            attendance = false,
            editAttendance = false,
            store,
        } = this.props;
        return noModal || store.addMembers || store.isCreation ? (
            this.RenderMemberInvitation()
        ) : (
            <Modal
                isOpen={isOpen}
                toggle={this.toggle}
                className="modal-dialog-centered modal-full-screen"
            >
                <div
                    className={classnames({ 'bg-dark': editAttendance }, 'modal-header')}
                >
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.toggle}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title mx-auto">
                        {editAttendance
                            ? t('Booking.edit_attendance')
                            : attendance
                            ? t('Booking.add_attendees')
                            : t('Membership.membership_add_members')}
                    </h5>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column bg-body mh-0 flex-grow-1">
                        {editAttendance && (
                            <Fragment>
                                <div className="text-center text-secondary bg-black pt-2">
                                    <ConferenceRoom size="2em" />
                                </div>
                                <div
                                    className="bg-black text-center text-sm pb-2 px-10"
                                    dangerouslySetInnerHTML={{
                                        __html: t('Booking.select_attendance'),
                                    }}
                                />
                            </Fragment>
                        )}
                        {this.RenderMemberInvitation()}
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(AddMembers);
