import React from 'react';

const Twitter = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M46.19 126C33.44 126 15.8 123.5 1 112.41a2.8 2.8 0 0 1 2.17-5 57.41 57.41 0 0 0 14.41.25c8.12-.86 14.86-3.31 20.11-7.3A29.11 29.11 0 0 1 16.07 80a2.8 2.8 0 0 1 .61-2.71 2.75 2.75 0 0 1 2-.91C12.44 71.93 6 64.09 6.18 50.56a2.8 2.8 0 0 1 4.45-2.21 17 17 0 0 0 1.76 1.1c-5.1-7.32-10.43-19.83-2.12-34a2.8 2.8 0 0 1 4.55-.45 99.51 99.51 0 0 0 16.73 14.86C40.2 36 53.29 43 67.9 43.2a31.13 31.13 0 0 1-.29-4.2 30.66 30.66 0 0 1 51.66-22.33 29.16 29.16 0 0 0 16.18-5.53 2.81 2.81 0 0 1 4.42 2.07c.06.94 0 5-5.4 11a16.18 16.18 0 0 0 5-2.09 2.8 2.8 0 0 1 4.37 3c-.3 1.05-2.19 5.29-14.28 16.38A90.28 90.28 0 0 1 123 76.06c-7 17.34-23.11 39.81-59.69 48.35A88.26 88.26 0 0 1 46.19 126zm-32.5-12.41C36.08 124.27 61.8 119 62.1 119c27.35-6.39 46.09-21.47 55.63-44.8a84.24 84.24 0 0 0 6.21-33.64 2.78 2.78 0 0 1 .91-2.26c3.58-3.25 6.23-5.86 8.2-7.93a63.74 63.74 0 0 1-7.24 1.05 2.8 2.8 0 0 1-1.93-5 44.68 44.68 0 0 0 6.88-6.42 34.25 34.25 0 0 1-12.67 2.28 2.8 2.8 0 0 1-2-.83A25.07 25.07 0 0 0 73.2 39a25.16 25.16 0 0 0 .8 6.23 2.79 2.79 0 0 1-2.55 3.49c-13.9.78-28.84-4.19-43.22-14.38a108.76 108.76 0 0 1-14.86-12.66c-2.74 6.59-2.44 13.35.91 20.15A35.44 35.44 0 0 0 21.92 52a2.79 2.79 0 0 1-1.41 4.89A16.11 16.11 0 0 1 12 55.5C14.35 72.38 30.1 75.85 30.83 76a2.79 2.79 0 0 1 .59 5.28 18.79 18.79 0 0 1-8.34 1.2c6.55 13.16 20.63 13.19 20.82 13.18a2.84 2.84 0 0 1 2.58 1.65 2.79 2.79 0 0 1-.48 3c-6.53 7.23-16.2 11.7-27.95 12.92-1.52.18-2.98.27-4.36.32z"
        />
    </svg>
);

export default Twitter;
