import React, { Fragment } from 'react';
import { translate } from 'react-i18next';

import { CampusSelection } from 'Components/Campus';
import CampusDetail from 'Components/Campus/CampusDetail';

class CampusDashboard extends React.Component {
    state = {
        campusId: null,
        campusToggle: false,
    };

    campusToggle = (campus) => {
        this.setState((prevState) => ({
            campusToggle: !prevState.campusToggle,
            campusId: campus.id,
        }));
    };

    render() {
        const { campusToggle, campusId } = this.state;
        return (
            <Fragment>
                <CampusSelection
                    onSelected={this.campusToggle}
                    className="campusDashboardList d-flex flex-row flex-wrap"
                />
                {campusToggle && (
                    <CampusDetail
                        {...this.props}
                        campusId={campusId}
                        isOpen={campusToggle}
                        onClose={this.campusToggle}
                        viewPlans
                        viewRooms
                    />
                )}
            </Fragment>
        );
    }
}

export default translate()(CampusDashboard);
