import React from 'react';

const Addons = (props) => (
    <svg
        className="icon"
        viewBox="0 0 24 24"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            d="M21.96 7.662H2.04a1.026 1.026 0 0 1 0-2.05h19.92a1.026 1.026 0 0 1 0 2.05zm-9.453 7.662h8.946V8.676h-8.946v6.648zm8.946 5.87c0 .988-.803 1.792-1.791 1.792h-7.155v-6.648h8.946v4.856zm-18.906-5.87h8.946V8.676H2.547v6.648zm8.946 7.662H4.338a1.793 1.793 0 0 1-1.791-1.792v-4.856h8.946v6.648zM9.702 1.014c.987 0 1.79.804 1.79 1.792s-.803 1.791-1.79 1.791A1.794 1.794 0 0 1 7.91 2.806c0-.988.804-1.792 1.792-1.792zm4.597 0c.987 0 1.791.804 1.791 1.792s-.804 1.791-1.791 1.791a1.793 1.793 0 0 1-1.792-1.791c0-.988.804-1.792 1.792-1.792zm2.188 3.583l.227-.39c.26-.443.39-.915.39-1.401A2.81 2.81 0 0 0 14.3 0a2.8 2.8 0 0 0-2.104.969L12 1.192 11.805.97A2.8 2.8 0 0 0 9.702 0a2.809 2.809 0 0 0-2.806 2.806c0 .486.13.958.39 1.402l.227.39h-6.24C.572 4.597 0 5.167 0 5.87v1.532c0 .702.571 1.273 1.274 1.273h.258v12.518A2.809 2.809 0 0 0 4.338 24h15.324a2.809 2.809 0 0 0 2.806-2.806V8.676h.259c.702 0 1.273-.57 1.273-1.273V5.871c0-.703-.571-1.274-1.273-1.274h-6.24z"
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
        />
    </svg>
);

export default Addons;
