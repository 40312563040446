import React from 'react';

const AngelList = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M37.17 82.64c-3.93-6.94-4.61-13.25 1.69-19 5-4.55 8.11-5.16 16.41-2.14C52.45 52.84 49.59 44.47 47 36a183.28 183.28 0 0 1-4.85-18.66 23.16 23.16 0 0 1 .44-9.69c1-4.15 4.24-6.56 8.5-7.47 4.06-.87 6.74 1.18 9 4.16 6.56 8.87 10.28 19.12 14.11 29.3 2.25 6 4.3 12 6.62 18.56 2.36-6.43 4.27-12.46 6.75-18.23 3.2-7.49 6.77-14.86 10.43-22.16a25.26 25.26 0 0 1 4.66-6.11 9.11 9.11 0 0 1 15 2.32c1.33 2.92 2.4 6.79 1.63 9.72C116 30.28 112 42.62 108.23 55c-.61 2-1.4 3.93-2.16 6.09l3.16 1c5.56 1.71 9.51 5.22 10.9 10.9 7.18 29.37-7 62.29-39.7 69.65-21.19 4.76-41.68-3.35-52.95-21a34.88 34.88 0 0 1-5.26-22.49c.71-7.67 4.35-13.24 12.16-15.44.77-.22 1.51-.57 2.79-1.07zm-2.29 20.54a44.37 44.37 0 0 0 3.81 5.33c3 3 7.66 8 11.14 10.3 1.26.84 4.5 0 5.93-1.11.93-.76 1-5.09.12-6.34a236 236 0 0 0-14.81-18.7c-2.88-3.28-6.29-3-9.26.34a16 16 0 0 0-3.28 5.81c-1.54 4.92-.33 9.71 1.65 14.27C41.79 139.69 78.7 147 99.85 126.93c14.24-13.53 17.73-30.43 15.43-49.19-.49-4.06-2.4-7-6.07-8.61-12.68-5.61-26.07-7.43-39.81-7.06-3 .09-4.43 2-4.59 4.93-.22 4.25 1.51 7.85 5.79 9.32a86.29 86.29 0 0 0 15.25 3.52 109.7 109.7 0 0 0 13.22.62c2.19.06 4.49-.15 5.13 2.67s-3.15 3-4.69 4a30.29 30.29 0 0 1-4.59 2c-15.26 6.34-22.18 20.23-18 36 .43 1.62.59 3.31.91 5.15-4.18 0-7-4.11-8.76-18l-6.42.54c.85 4.66 0 8.89-4.35 11.51s-9 1.87-12.87-.78A47.65 47.65 0 0 1 36 115c-2.91-3.49-3.42-6.71-1.12-11.82zM75.32 57.5c-.69-2-1.23-3.54-1.8-5.11C68.72 39.23 64.09 26 59 13c-2.12-5.48-4.88-7.19-7.65-6.16-3 1.13-4.6 4.67-3.08 9.76 4.17 14 9 29.59 13.21 42.73 5.25-.05 9.14-1.83 13.84-1.83zm11.27-.39c3.76.72 7 1.27 10.26 2 1.87.43 2.84-.05 3.45-1.91 4.29-13 8.68-26 12.92-39a8.59 8.59 0 0 0 .35-4.89c-.52-1.62-1.78-3.74-3.16-4.15s-3.68.65-4.86 1.8a16.43 16.43 0 0 0-3.55 5.7C96.83 30 91.81 43.35 86.59 57.11zM48.8 65.62c-5.38-.17-9.76 5.7-8.32 10.31A65.1 65.1 0 0 0 57.28 103c1.73 1.62 4.69 2.28 7.2 2.65 3.08.45 5.85-2.51 5-5.43-3.35-11.45-8-24.16-16.88-32.56a14.37 14.37 0 0 0-3.8-2.04zm26.05 30.67l12-10.84-17.21-2.89z"
        />
    </svg>
);

export default AngelList;
