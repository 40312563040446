import React from 'react';

const SecurityDeposit = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g data-name="Layer 2">
            <g data-name="Layer 1">
                <path d="M18.693 5.923c-3.404 0-6.162-2.484-6.162-5.486V0h-.997v.437c0 3.007-2.75 5.486-6.162 5.486H1.684v4.838a13.053 13.053 0 0 0 2.782 7.98c1.804 2.348 4.334 4.136 7.307 5.171l.256.088.255-.088a15.54 15.54 0 0 0 7.281-5.171 13.01 13.01 0 0 0 2.75-7.978v-4.84zm2.691 4.838a12.077 12.077 0 0 1-2.558 7.409 14.614 14.614 0 0 1-6.8 4.838c-2.777-.977-5.141-2.65-6.82-4.838a12.107 12.107 0 0 1-2.58-7.409V6.858h2.751a7.512 7.512 0 0 0 5.009-1.872 6.386 6.386 0 0 0 1.647-2.294 6.38 6.38 0 0 0 1.648 2.294 7.505 7.505 0 0 0 5.012 1.872h2.691z" />
                <path
                    d="M11.608 19.98v-1.422a4.446 4.446 0 0 1-1.969-.677 2.992 2.992 0 0 1-1.213-1.666l1.007-.385a2.558 2.558 0 0 0 .82 1.191 2.321 2.321 0 0 0 1.355.504v-3.439q-.428-.103-.844-.237a4.71 4.71 0 0 1-.798-.344 2.235 2.235 0 0 1-1.126-1.951 2.495 2.495 0 0 1 .222-1.085 2.383 2.383 0 0 1 .6-.79 2.839 2.839 0 0 1 .88-.517 4.855 4.855 0 0 1 1.066-.258V7.663h.908v1.245a3.98 3.98 0 0 1 1.747.516 2.62 2.62 0 0 1 1.126 1.415l-1.008.343a1.72 1.72 0 0 0-.717-.962 2.31 2.31 0 0 0-1.148-.351v3.182c.318.07.633.147.945.23a3.724 3.724 0 0 1 .9.377 2.447 2.447 0 0 1 .906.927 2.514 2.514 0 0 1 .326 1.25 2.486 2.486 0 0 1-.288 1.162 2.667 2.667 0 0 1-1.734 1.376 8.165 8.165 0 0 1-1.055.185v1.422zm0-10.111a2.235 2.235 0 0 0-.614.119 1.788 1.788 0 0 0-.541.288 1.446 1.446 0 0 0-.386.46 1.29 1.29 0 0 0-.147.629 1.398 1.398 0 0 0 .139.651 1.329 1.329 0 0 0 .378.444 2.104 2.104 0 0 0 .54.289 3.858 3.858 0 0 0 .63.172zm.908 7.654a2.333 2.333 0 0 0 .688-.105 2.046 2.046 0 0 0 .613-.302 1.57 1.57 0 0 0 .446-.504 1.375 1.375 0 0 0 .172-.688 1.362 1.362 0 0 0-.172-.703 1.547 1.547 0 0 0-.446-.481 2.275 2.275 0 0 0-.613-.298 3.705 3.705 0 0 0-.688-.154z"
                    fill={fill || 'currentColor'}
                />
            </g>
        </g>
    </svg>
);

export default SecurityDeposit;
