import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormGroup } from 'Components/Forms';
import MembershipSpecifications from 'Components/Membership/MembershipSpecifications';
import ModelStateStore from 'Stores/ModelStateStore';
import HttpService from 'Services/HttpService';
import { Memberships } from 'Components/Icons';
import { If } from 'react-if';
import Constants from 'Data/Constants';
import { isEmpty } from 'Utils/ObjectUtils';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';
import DropdownIndustry from 'Components/Membership/DropdownIndustry';

class ProfileStep extends React.Component {
    componentDidMount = () => {
        const {
            store: { profile, isCreation },
            history,
            parentMatchURL,
            store,
        } = this.props;
        const emptyProfile =
            isEmpty(store.membership.presentation) &&
            isEmpty(store.membership.industry) &&
            isEmpty(store.membership.numberOfEmployees) &&
            isEmpty(store.membership.address) &&
            isEmpty(store.membership.contactInfo);
        if ((!profile || emptyProfile) && isCreation) {
            UpdateStateStoreUtils(store, 'profile', false);
            history.replace(`${parentMatchURL}/profile/skip`);
        } else {
            history.replace(`${parentMatchURL}/profile`);
        }
    };

    nextStep = () => {
        const { onComplete, stepKey } = this.props;
        onComplete(stepKey, false);
    };

    editProfile = () => {
        const { store, history, parentMatchURL } = this.props;
        UpdateStateStoreUtils(store, 'profile', true);
        history.replace(`${parentMatchURL}/profile`);
    };

    renderSkipChoice = () => {
        const {
            t,
            store: { isCreation },
        } = this.props;

        return (
            <div className="flex-grow-1 d-flex flex-column bg-dark segment-body text-center">
                {isCreation && (
                    <div className="bg-black text-center text-sm py-2 px-10">
                        <Memberships size="3em" className="mb-2 text-secondary" />
                        <div>{t('Membership.company_profile_step_info')}</div>
                    </div>
                )}

                <div className="d-flex flex-column justify-content-center flex-grow-1">
                    <button
                        className="btn btn-primary w-100 mb-1 rounded-0"
                        onClick={this.editProfile}
                    >
                        {t('yes')}
                    </button>

                    <button
                        className="btn btn-light w-100 mb-3 rounded-0"
                        onClick={this.nextStep}
                    >
                        {t('not_for_now')}
                    </button>
                </div>
            </div>
        );
    };

    renderStep = () => {
        return <RenderStepProfile {...this.props} />;
    };

    render() {
        const { parentMatchURL, store } = this.props;
        return (
            <Fragment>
                <If condition={store.profile}>
                    <Route path={`${parentMatchURL}/profile`} render={this.renderStep} />
                </If>
                <Route
                    exact
                    path={`${parentMatchURL}/profile/skip`}
                    render={this.renderSkipChoice}
                />
            </Fragment>
        );
    }
}

export default translate()(ProfileStep);

@inject('dashboardStore', 'uiStore')
@observer
class RenderStepProfile extends React.Component {
    constructor(props) {
        super(props);
        this.modelStateStore = new ModelStateStore(
            {
                to: 'contactInfo.phone1',
                from: 'updateMembershipRequest.contactInfo.phone1',
            },
            {
                to: 'contactInfo.phone2',
                from: 'updateMembershipRequest.contactInfo.phone2',
            },
            {
                to: 'contactInfo.website',
                from: 'updateMembershipRequest.contactInfo.website',
            },
            {
                to: 'address.addressLine1',
                from: 'updateMembershipRequest.address.addressLine1',
            },
            { 
                to: 'address.city',
                from: 'updateMembershipRequest.address.city'
            },
            { 
                to: 'address.state',
                from: 'updateMembershipRequest.address.state'
            },
            {
                to: 'address.postalCode',
                from: 'updateMembershipRequest.address.postalCode',
            },
            { 
                to: 'address.country',
                from: 'updateMembershipRequest.address.country'
            },
            {
                to: 'contactInfo.angelListUrl',
                from: 'updateMembershipRequest.contactInfo.angelListUrl',
            },
            {
                to: 'contactInfo.twitterUrl',
                from: 'updateMembershipRequest.contactInfo.twitterUrl',
            },
            {
                to: 'contactInfo.facebookUrl',
                from: 'updateMembershipRequest.contactInfo.facebookUrl',
            },
            {
                to: 'contactInfo.linkedInUrl',
                from: 'updateMembershipRequest.contactInfo.linkedInUrl',
            },
            {
                to: 'contactInfo.instagramUrl',
                from: 'updateMembershipRequest.contactInfo.instagramUrl',
            },
            {
                to: 'numberOfEmployees',
                from: 'updateMembershipRequest.numberOfEmployees',
            },
            {
                to: 'industry',
                from: 'updateMembershipRequest.industry',
            }
        );
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount = () => {
        const {
            uiStore: { loadingStore },
            dashboardStore,
            store: { membership },
            store,
        } = this.props;
        if (membership) {
            dashboardStore.loadExpertises(loadingStore);
            UpdateStateStoreUtils(
                store,
                `membership.expertiseIds`,
                membership.expertises
                    ? membership.expertises.map((expertise) => expertise.id)
                    : []
            );
            UpdateStateStoreUtils(
                store,
                `expertiseIds`,
                membership.expertises
                    ? membership.expertises.map((expertise) => expertise.id)
                    : []
            );
            UpdateStateStoreUtils(
                store,
                `membership.contactInfo`,
                membership.contactInfo ? membership.contactInfo : {}
            );
            UpdateStateStoreUtils(
                store,
                `membership.address`,
                membership.address ? membership.address : {}
            );
        }
    };

    nextStep = () => {
        const { onComplete, stepKey, store } = this.props;
        onComplete(stepKey, store.profile);
    };

    editMembership = async (event) => {
        event.preventDefault();
        
        const {
            uiStore: { loadingStore, toastStore },
            t,
            store: { membership, touched },
            store,
            dashboardStore,
        } = this.props;

        if (!membership) {
            toastStore.enqueueErrorToast({
                message: t('Error.error_message'),
            });
            return;
        }
        loadingStore.addLoading();
        try {
            if (touched) {
                await HttpService.editMembership(membership);
                toastStore.enqueueToast({
                    message: t('Membership.sucess_edit_membership_message'),
                    icon: Memberships,
                });
                dashboardStore.loadMemberships(loadingStore);
            }
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        } finally {
            UpdateStateStoreUtils(store, 'touched', false);
            if (
                isEmpty(store.membership.presentation) &&
                isEmpty(store.membership.industry) &&
                isEmpty(store.membership.address) &&
                isEmpty(store.membership.contactInfo)
            )
                UpdateStateStoreUtils(store, 'profile', false);
            this.nextStep();
        }
        loadingStore.removeLoading();
    };

    updateExpertises = (id, value) => {
        const {
            store,
            store: { membership },
        } = this.props;
        if (value) {
            UpdateStateStoreUtils(store, `membership.expertiseIds`, [
                ...membership.expertiseIds,
                id,
            ]);
            UpdateStateStoreUtils(store, `expertiseIds`, [
                ...membership.expertiseIds,
                id,
            ]);
        } else {
            var array = [...membership.expertiseIds]; // make a separate copy of the array
            var index = array.indexOf(id);
            if (index !== -1) {
                array.splice(index, 1);
                UpdateStateStoreUtils(store, `membership.expertiseIds`, array);
                UpdateStateStoreUtils(store, `expertiseIds`, array);
            }
        }
    };

    handleEditorChange = (editor) => {
        const { store } = this.props;
        UpdateStateStoreUtils(store, 'membership.presentation', editor.level.content);
        UpdateStateStoreUtils(store, 'touched', true);
    };

    handleInputChange = (event) => {
        const { store } = this.props;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        UpdateStateStoreUtils(store, `membership.${name}`, value);
        UpdateStateStoreUtils(store, 'touched', true);
    };

    updateIndustry = (value) => {
        const { store } = this.props;
        UpdateStateStoreUtils(store, 'membership.industry', value);
        UpdateStateStoreUtils(store, 'touched', true);
    };

    render() {
        const {
                t,
                dashboardStore: { expertises },
                store: { membership, expertiseIds },
            } = this.props,
            { errors } = this.modelStateStore;


        const renderIndustryDropDownItems = (values) => {
            const rows = [];
            rows.push({ id: '', value: key, title: '' })
            for (var key in values) 
            {
                rows.push({
                    id: key, 
                    value: key,
                    title: this.props.t(`Membership.industry_${values[key]}`)
                })
            }
            return rows;
        }

        return (
            <form
                onSubmit={this.editMembership}
                className="d-flex flex-column bg-body mh-0"
            >
                <div className="scrollable d-flex flex-column">
                    <div className="bg-black text-center text-sm py-2 px-10">
                        {t('Membership.membership_information_message')}
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                    <div className="list-group-very-compact">
                        <FormGroup
                            id="presentation"
                            labelClassName="text-warm-grey"
                            className="px-5 py-1 px-md-11 bg-white mb-1"
                            displayName={t('Profile.profile_introduction_title')}
                            type="Editor"
                            onChange={this.handleEditorChange}
                            value={membership.presentation || ''}
                        />
                        <FormGroup
                            id="numberOfEmployees"
                            labelClassName="text-warm-grey"
                            className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                            displayName={t('Membership.number_of_employees')}
                            onChange={this.handleInputChange}
                            maxlength={10}
                            value={membership.numberOfEmployees}
                            errors={errors}
                        />
                        <DropdownIndustry
                            items={renderIndustryDropDownItems(Constants.INDUSTRIES)}
                            onChange={(e) => this.updateIndustry(e)}
                            value={
                                membership.industry
                                    ? membership.industry
                                    : ''
                            }
                            t={t}
                        />
                            <FormGroup
                                id="contactInfo.phone1"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.phone')}
                                type="text"
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.phone1}
                                errors={errors}
                            />
                        </div>

                        {expertiseIds &&
                        expertises &&
                        expertises.data &&
                        expertises.data.length ? (
                            <MembershipSpecifications
                                className="py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey"
                                onChange={this.updateExpertises}
                                specs={expertises.data}
                                activeSpecs={expertiseIds}
                                type={Constants.SPECIFICATIONS.EXPERTISE}
                            />
                        ) : null}
                        <div className="list-group-very-compact">
                            <FormGroup
                                id="contactInfo.website"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Membership.website')}
                                type="text"
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.website}
                                errors={errors}
                            />
                            <FormGroup
                                id="address.addressLine1"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.address')}
                                onChange={this.handleInputChange}
                                maxlength={100}
                                value={membership.address?.addressLine1}
                                errors={errors}
                            />
                            <FormGroup
                                id="address.city"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.city')}
                                onChange={this.handleInputChange}
                                maxlength={100}
                                value={membership.address?.city}
                                errors={errors}
                            />
                            <FormGroup
                                id="address.state"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.state')}
                                onChange={this.handleInputChange}
                                maxlength={100}
                                value={membership.address?.state}
                                errors={errors}
                            />
                            <FormGroup
                                id="address.postalCode"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.zipcode')}
                                onChange={this.handleInputChange}
                                maxlength={100}
                                value={membership.address?.postalCode}
                                errors={errors}
                            />
                            <FormGroup
                                id="address.country"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Account.country')}
                                onChange={this.handleInputChange}
                                maxlength={100}
                                value={membership.address?.country}
                                errors={errors}
                            />
                            <FormGroup
                                id="contactInfo.angelListUrl"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Profile.profile_angellist_title')}
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.angelListUrl}
                                errors={errors}
                            />
                            <FormGroup
                                id="contactInfo.twitterUrl"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Profile.profile_twitter_title')}
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.twitterUrl}
                                errors={errors}
                            />
                            <FormGroup
                                id="contactInfo.facebookUrl"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Profile.profile_facebook_title')}
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.facebookUrl}
                                errors={errors}
                            />
                            <FormGroup
                                id="contactInfo.linkedInUrl"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Profile.profile_linkedin_title')}
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.linkedInUrl}
                                errors={errors}
                            />
                            <FormGroup
                                id="contactInfo.instagramUrl"
                                labelClassName="text-warm-grey"
                                className="px-5 py-1 px-md-11 bg-white mb-0 border-bottom border-4 border-light-grey"
                                displayName={t('Profile.profile_instagram_title')}
                                onChange={this.handleInputChange}
                                value={membership.contactInfo?.instagramUrl}
                                errors={errors}
                            />
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary w-100 bottom-action" type="submit">
                    {t('next')}
                </button>
            </form>
        );
    }
}
