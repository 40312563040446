import React from 'react';

const Add16 = (props) => (
    <svg
        className={`icon ${props.className || ''}`}
        viewBox="0 0 16 16"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M7.548 0v7.553H0v.899h7.548V16h.9V8.452h7.548v-.9H8.448V0z"
            fillRule="evenodd"
        />
    </svg>
);

export default Add16;
