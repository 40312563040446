import { observable, computed, action } from 'mobx';

class LoadingStore {
    constructor(parent) {
        this.parent = parent;
    }

    @observable loadingCount = 0;

    @computed get isLoading() {
        return !(this.parent && this.parent.isLoading) && this.loadingCount > 0;
    }

    @action addLoading() {
        this.loadingCount++;
    }

    @action removeLoading() {
        if (this.loadingCount > 0) this.loadingCount--;
    }
}

export default LoadingStore;
