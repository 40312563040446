import React from 'react';

const Information = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M23.63 3.86L20.143.37A1.306 1.306 0 0 0 19.11.006a1.618 1.618 0 0 0-1 .473L2.885 15.737l-.077.078L.002 24 8.2 21.18 23.517 5.89c.591-.592.646-1.502.113-2.03zM1.527 22.475l1.9-5.548 3.644 3.642zm6.413-2.372L3.9 16.06 16.105 3.838l4.06 4.057zM22.85 5.219L20.83 7.23l-4.057-4.06 2.01-2.017a.667.667 0 0 1 .408-.2.368.368 0 0 1 .283.085l3.488 3.499c.155.143.1.466-.113.681z"
        />
    </svg>
);

export default Information;
