import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { inject } from 'mobx-react';
import qs from 'qs';
import { Route } from 'react-router-dom';

import { InvoiceDetail as InvoiceDetailComponent } from 'Components/Invoice';
import HttpService from 'Services/HttpService';
import { Constants } from 'Data/';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import { Error, Invoices } from 'Components/Icons';
import NoMatch from 'WebApp/Views/NoMatch';
import ScrollUtils from 'Utils/ScrollUtils';
import UpdatePaymentMethod from 'WebApp/Views/Logged/PaymentMethod/UpdatePaymentMethod';
import PayInvoiceModal from './PayInvoiceModal';
import VerifyAchAccount from 'WebApp/Views/Logged/PaymentMethod/VerifyAchAccount';
import moment from 'moment';

const { ACH_STATUS } = Constants;
const formatDate = (date) => moment(date).format(Constants.TEXT_DATE_FORMAT);

@inject('uiStore')
class InvoiceDetail extends PureComponent {
    static propTypes = {
        onTry: PropTypes.func,
        membership: PropTypes.object,
        parentMatchURL: PropTypes.string,
        skipPermissionCheck: PropTypes.bool,
        // onPaymentMethodChanged: PropTypes.func
    };

    state = {
        invoice: { ongoing: false },
        notFound: false,
        membership: null,
        errored: false,
    };

    fromEmail = false;

    componentDidMount() {
        if (this.props.skipPermissionCheck) {
            this.loadInvoice();
        } else if (this.props.membership && !this.permissionChecked) {
            this.checkPermission();
        }

        if (this.props.location && this.props.location.search) {
            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            if (params.fromEmail) {
                this.fromEmail = true;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.membership !== this.props.membership &&
            this.props.membership &&
            !this.permissionChecked
        ) {
            this.checkPermission();
        }
        if (prevProps.invoiceId !== this.props.invoiceId && this.props.invoiceId) {
            this.loadInvoice();
        }
    }

    componentWillUnmount() {
        if (this.membershipCancellation) this.membershipCancellation.cancel();

        if (this.invoiceCancellation) this.invoiceCancellation.cancel();
    }

    checkPermission = () => {
        const { membership, history, parentMatchURL } = this.props;
        this.permissionChecked = true;

        if (!membership.rights.includes(Constants.MEMBERSHIP_RIGHTS.FINANCE)) {
            return history.replace(parentMatchURL);
        }

        this.loadInvoice();
        if (this.fromEmail) {
            this.checkPaymentMethod();
        }
    };

    loadMembership = async (membershipId, tryPayment = false) => {
        if (this.membershipCancellation) this.membershipCancellation.cancel();
        this.membershipCancellation = HttpService.cancellable();

        try {
            const { data: membership } = await HttpService.membershipDetail(
                membershipId,
                this.membershipCancellation
            );
            this.setState({ membership, errored: false });
            if (membership.paymentMethod.status === ACH_STATUS.UNVERIFIED) {
                this.props.onTry();
            } else if (tryPayment) this.tryPayment();
        } catch (error) {
            if (this.membershipCancellation.isCancelled(error)) return;
            this.setState({ errored: true });
        }
    };

    loadInvoice = async () => {
        if (this.invoiceCancellation) this.invoiceCancellation.cancel();
        this.invoiceCancellation = HttpService.cancellable();

        const { match, invoiceId } = this.props;
        this.setState({ invoice: { ongoing: true } });
        try {
            const { data } = await HttpService.invoice(
                match.params.invoiceId || invoiceId,
                this.invoiceCancellation
            );
            this.setState({ invoice: { ongoing: false, data } });
            if (data.paymentCustomer.type === Constants.HOLDER_TYPE.MEMBERSHIP) {
                this.loadMembership(data.paymentCustomer.id);
            } else {
                //#TODO verifier le moyen de payement si personal
                this.setState({
                    membership: {
                        paymentMethod: data.paymentMethod,
                    },
                });
            }
            return data;
        } catch (err) {
            if (this.invoiceCancellation.isCancelled(err)) return;

            if (err.response && err.response.status === 404)
                this.setState({ notFound: true });

            this.setState({ invoice: { ongoing: false, errored: true } });
            return null;
        } finally {
            this.scrollUtils.scrollToRef();
        }
    };

    checkPaymentMethod = () => {
        const { history, match } = this.props,
            { membership } = this.state;

        if (membership && !membership.paymentMethod) {
            history.push(`${match.url}/payment-method`);
            return false;
        }
        if (membership && membership.paymentMethod.status === ACH_STATUS.UNVERIFIED) {
            history.push(
                `${match.url}/verify-ach-account/${
                    membership.paymentMethod && membership.paymentMethod.id
                }`
            );
            return false;
        }

        return true;
    };

    retryPayment = () => this.tryPayment(null, 'retry');

    tryPayment = async (e, action = 'try') => {
        const {
                uiStore: { simpleModalStore, loadingStore, toastStore },
                match,
                onTry,
                onInvoiceUpdate,
                t,
                invoiceId,
            } = this.props,
            { invoice } = this.state;

        if (
            moment.parseZone(invoice.data.dueDate).diff(moment(), 'days') > 5 &&
            !(await simpleModalStore.confirm({
                titleIcon: <Invoices size="3.75em" />,
                title: t('Invoice.early_payment_warning_title', {
                    param1: formatDate(invoice.data.dueDate),
                }),
                message: t('Invoice.early_payment_warning_message', {
                    param1: formatDate(invoice.data.dueDate),
                }),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        if (!this.checkPaymentMethod()) return;

        try {
            loadingStore.addLoading();
            const { data: newInvoice } = await HttpService.tryPayment(
                action,
                match.params.invoiceId || invoiceId
            );
            this.setState({ invoice: { data: newInvoice } });
            toastStore.enqueueToast({
                message: t('Invoice.success_paid_invoice_message'),
                icon: Invoices,
            });
            if (onInvoiceUpdate)
                onInvoiceUpdate(Constants.EVENT_STATUS.UPDATED, {
                    id: match.params.invoiceId || invoiceId,
                    invoiceStatus: newInvoice.status,
                });
            if (onTry) onTry();
        } catch (err) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    completePaymentMethodFlow = () => {
        const { membership } = this.state;
        this.loadMembership(membership.id, true);
    };

    scrollUtils = new ScrollUtils();

    renderInvoice = () => {
        const { parentMatchURL, t, history, membership, paneDetail = false } = this.props,
            { invoice, notFound } = this.state;

        return notFound ? (
            <NoMatch history={history} />
        ) : invoice.ongoing ? (
            <div
                className="position-relative mb-6"
                style={{ minHeight: '720px' }}
                ref={this.scrollUtils.registerRef}
            >
                <LoadingSpinner loading={invoice.ongoing} />
            </div>
        ) : invoice.errored ? (
            <div className="px-2 px-md-20" ref={this.scrollUtils.registerRef}>
                <NoResults
                    icon={<Error size="3.75em" />}
                    title={t('Error.error')}
                    subTitle={t('Error.error_message')}
                    action={{
                        text: t('Error.retry'),
                        onClick: this.loadInvoice,
                    }}
                />
            </div>
        ) : invoice.data ? (
            <InvoiceDetailComponent
                ref={this.scrollUtils.registerRef}
                invoice={invoice.data}
                tryPayment={this.tryPayment}
                retryPayment={this.retryPayment}
                membership={membership}
                paneDetail={paneDetail}
                parentMatchURL={parentMatchURL}
            />
        ) : null;
    };

    renderUpdatePaymentMethod = (routeProps) => (
        <UpdatePaymentMethod
            {...routeProps}
            holderId={this.state.membership && this.state.membership.id}
            holderType={Constants.HOLDER_TYPE.MEMBERSHIP}
            parentMatchURL={this.props.match.url}
            onComplete={this.completePaymentMethodFlow}
        />
    );

    renderPayInvoiceModal = (routeProps) => (
        <PayInvoiceModal
            {...routeProps}
            invoice={this.state.invoice.data}
            tryPayment={this.tryPayment}
            retryPayment={this.retryPayment}
            membership={this.state.membership}
            parentMatchURL={this.props.match.url}
            backURL={this.props.match.url + '/payment-method'}
        />
    );

    renderVerifyAchAccount = (routeProps) => (
        <VerifyAchAccount
            {...routeProps}
            paymentMethodId={routeProps.match.params.paymentMethodId}
            parentMatchURL={this.props.match.url}
            onComplete={this.completePaymentMethodFlow}
        />
    );

    render() {
        const { match } = this.props;

        return (
            <Fragment>
                {this.renderInvoice()}
                <Route
                    path={`${match.url}/payment-method`}
                    render={this.renderUpdatePaymentMethod}
                />
                <Route path={`${match.url}/pay`} render={this.renderPayInvoiceModal} />
                <Route
                    path={`${match.url}/verify-ach-account/:paymentMethodId`}
                    render={this.renderVerifyAchAccount}
                />
            </Fragment>
        );
    }
}

export default translate()(InvoiceDetail);
