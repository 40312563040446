import React from 'react';

const JobPost60 = ({ className, size, fill }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 60 60"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            d="M21.933 0v9.393L10 25.331l8.44 8.44-1.194 8.168L30.06 60l12.984-18.057-1.19-8.126.013.013 8.5-8.5L38.279 9.186V0H21.933zm2.335 8.562h11.676V2.335H24.268v6.227zM19.692 41.35l4.456-30.453H36.14l4.456 30.45-10.523 14.635L19.692 41.35zm19.26-27.37l8.334 11.13-5.849 5.848-2.484-16.977zm-25.87 11.13l8.236-11-2.455 16.78-5.78-5.78z"
            fill={fill || 'currentColor'}
            fillRule="evenodd"
        />
    </svg>
);

export default JobPost60;
