import React from 'react';
import uuidv4 from 'uuid/v4';

const Placeholder = (props) => {
    const id = `_${uuidv4()}`;
    return (
        <svg
            className="icon"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 130 130"
            width={props.size}
            height={props.size}
        >
            <defs>
                <circle id={`${id}a`} cx={65} cy={65} r={65} />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id={`${id}b`} fill="#fff">
                    <use xlinkHref={`#${id}a`} />
                </mask>
                <use fill="#E1E1E1" xlinkHref={`#${id}a`} />
                <g fill="#FFF" mask={`url(#${id}b)`}>
                    <path d="M83.58 91.66H46.895V80.177c0-5.414 3.847-9.993 8.926-11.17.733-.173 1.494-.27 2.275-.285.097-.008.198-.008.294-.008h13.848c.097 0 .197 0 .294.008 1.422.032 2.771.33 4.012.85 4.14 1.715 7.036 5.836 7.036 10.605V91.66zM72.238 66.373H58.39c-7.584 0-13.835 6.215-13.835 13.804V94H85.92V80.177c0-7.589-6.094-13.804-13.682-13.804zm-6.924-9.3c-5.163 0-9.364-4.2-9.364-9.364 0-5.164 4.2-9.365 9.364-9.365 5.164 0 9.369 4.201 9.369 9.365 0 5.163-4.205 9.364-9.369 9.364m0-21.073c-6.453 0-11.705 5.252-11.705 11.709 0 6.456 5.252 11.704 11.705 11.704 6.456 0 11.709-5.248 11.709-11.704 0-6.457-5.253-11.71-11.709-11.71" />
                </g>
            </g>
        </svg>
    );
};

export default Placeholder;
