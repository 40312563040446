import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import HttpService from 'Services/HttpService';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import {
    BookingDetailInfo,
    BookingDetailRoomInfo,
    BookingDetailTitlePanel,
} from 'Components/Booking';
import NoResults from 'Components/Loading/NoResults';
import MyBookings from 'Components/Icons/MyBookings';
import Constants from 'Data/Constants';
import BookingModalEdit from './BookingModalEdit';
import classnames from 'classnames';
import TimeZoneUtils from 'Utils/TimeZoneUtils';
import { ListGroup, Modal, ModalBody } from 'reactstrap';
import CampusDetail from 'Components/Campus/CampusDetail';
import Profile from 'WebApp/Views/Logged/Membership/Profile';
import { LoadingSpinner } from 'Components/Loading';
import { Arrow, X } from 'Components/Icons';
import { ListItem } from 'Components/Forms';
import MemberCircle from 'Assets/Icons/member-circle.svg';
import AddMembers from 'WebApp/Views/Logged/Membership/AddMembers';

@inject('uiStore', 'dashboardStore')
@observer
class BookingDetail extends React.Component {
    static propTypes = {
        bookingId: PropTypes.string.isRequired,
    };

    state = {
        booking: { ongoing: true },
        membershipId: '',
        editAttendees: false,
        profileToggle: false,
        campusToggle: false,
        attendeesToggle: false,
    };

    componentDidMount() {
        this.loadBooking();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bookingId !== this.props.bookingId && this.props.bookingId) {
            this.loadBooking();
        }
    }

    loadBooking = async (updateList = false) => {
        const { history, bookingId } = this.props;
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        this.setState({ booking: { ongoing: true } });
        try {
            const { data: booking } = await HttpService.bookingDetail(
                bookingId,
                this.cancellation
            );

            this.setState({
                booking: { data: booking, ongoing: false },
                membershipId: booking.creditBalance.id,
            });
            if (updateList) {
                this.updateList(booking);
            }

            this.loadCampus(booking.amenity.campusId);
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            if (
                err.response &&
                err.response.data &&
                err.response.data.modelState &&
                err.response.data.modelState.errorCode &&
                err.response.data.modelState.errorCode.includes('E004008')
            ) {
                history.replace('/events');
            } else {
                this.setState({ booking: { errored: true } });
            }
        }
    };

    updateList = (booking) => {
        const { onBookingUpdate, dashboardStore } = this.props;
        let campus = null;
        if (
            dashboardStore.locations &&
            dashboardStore.locations.data &&
            this.state.booking.data
        ) {
            campus = dashboardStore.locations.data.find(
                (cam) => cam.id === booking.amenity.campusId
            );
        }
        const recurrence = booking ? booking.recurrence : null;
        const timeZone = campus ? TimeZoneUtils.getTimeZoneWithCampus(campus) : null;

        onBookingUpdate(Constants.EVENT_STATUS.UPDATED, {
            id: booking.id,
            name: booking.amenity.name,
            periodEnd: booking.periodEnd,
            periodStart: booking.periodStart,
            timeZone: timeZone,
            isBookingRecurring: recurrence,
            description: booking.creditBalance.title,
            imageUrl: booking.amenity.imageUrl,
        });
    };

    closeModalAndReload = () => {
        const { history, parentMatchURL } = this.props;
        this.setState({ editAttendees: false, attendeesToggle: false });

        this.loadBooking(true);
        history.push(parentMatchURL);
    };

    loadCampus = async (campusId) => {
        if (this.campusCancellation) this.campusCancellation.cancel();
        this.campusCancellation = HttpService.cancellable();

        this.setState({ campus: { ongoing: true } });
        try {
            const campus = (
                await HttpService.campusDetail(campusId, this.campusCancellation)
            ).data;
            this.setState({
                campus: { data: campus, ongoing: false },
                campusId: campusId,
            });
        } catch (err) {
            if (this.campusCancellation.isCancelled(err)) return;

            this.setState({ campus: { errored: true, ongoing: false } });
        }
    };

    cancelBooking = async () => {
        const {
            uiStore: { loadingStore, toastStore, simpleModalStore },
            t,
            onBookingUpdate,
        } = this.props;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <MyBookings size="3.75em" />,
                title: t('Booking.cancel_my_booking'),
                message: t('Booking.cancel_my_booking_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        loadingStore.addLoading();
        try {
            await HttpService.cancelBooking({
                bookingId: this.state.booking.data.id,
            });
            toastStore.enqueueToast({
                icon: MyBookings,
                message: t('Booking.cancel_booking_confirmed_message'),
            });
            if (onBookingUpdate) onBookingUpdate(Constants.EVENT_STATUS.DELETED);
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    cancelBookingRecurrence = async () => {
        const {
            uiStore: { loadingStore, toastStore, simpleModalStore },
            t,
            onBookingUpdate,
        } = this.props;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <MyBookings size="3.75em" />,
                title: t('Booking.cancel_my_recurrence'),
                message: t('Booking.cancel_my_recurrence_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        loadingStore.addLoading();
        try {
            await HttpService.cancelBookingRecurrence({
                recurrenceId: this.state.booking.data.recurrence.id,
            });
            toastStore.enqueueToast({
                icon: MyBookings,
                message: t('Booking.cancel_my_recurrence_success'),
            });
            if (onBookingUpdate) onBookingUpdate(Constants.EVENT_STATUS.DELETED);
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    renderBookingModal = (routeProps) => {
        return (
            <BookingModalEdit
                {...routeProps}
                parentMatchURL={this.props.parentMatchURL}
                booking={this.state.booking.data}
                onUpdate={this.closeModalAndReload}
            />
        );
    };

    campusToggle = (campusId) => {
        this.setState((prevState) => ({
            campusToggle: !prevState.campusToggle,
            campusId,
        }));
    };

    attendeesToggle = () => {
        this.setState((prevState) => ({
            attendeesToggle: !prevState.attendeesToggle,
        }));
    };

    profileToggle = (profileId) => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
            profileId,
            isMemberProfile: true,
        }));
    };

    membershipToggle = (profileId) => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
            profileId,
            isMemberProfile: false,
        }));
    };

    editAttendeesToggle = () => {
        this.setState({
            editAttendees: !this.state.editAttendees,
            attendeesToggle: false,
        });
    };

    bookingDetail = () => {
        const { t } = this.props,
            { booking } = this.state;
        return booking.ongoing ? (
            <div className="w-100 mb-6" style={{ minHeight: '920px' }}>
                <LoadingSpinner loading={booking.ongoing} />
            </div>
        ) : booking.errored ? (
            <div className="py-5">
                <NoResults
                    className="m-auto"
                    icon={<MyBookings size="3.75em" />}
                    title={t('Booking.booking_no_booking')}
                    subTitle={t('Booking.booking_no_booking_text')}
                />
            </div>
        ) : (
            <Fragment>
                {this.bookingDetailHeader()}
                {this.bookingDetailContent()}
            </Fragment>
        );
    };

    bookingDetailHeader = () => {
        const {
                dashboardStore,
                parentMatchURL,
                paneDetail = false,
                onClose,
                isModal = false,
                bookingHistory = false,
            } = this.props,
            { booking } = this.state;
        const currentBooking = booking.data ? booking.data : {};
        const amenity = currentBooking ? currentBooking.amenity : null;
        let campus = null;
        if (dashboardStore.locations && dashboardStore.locations.data && booking.data) {
            campus = dashboardStore.locations.data.find(
                (cam) => cam.id === currentBooking.amenity.campusId
            );
        }

        return (
            <BookingDetailTitlePanel
                amenity={amenity}
                currentBooking={currentBooking}
                parentMatchURL={parentMatchURL}
                paneDetail={paneDetail}
                campus={campus}
                isModal={isModal}
                bookingHistory={bookingHistory}
                onClose={onClose}
            />
        );
    };

    setAttendeeName = (attendees) => {
        return attendees && attendees.length
            ? attendees.map((attendee) => ({
                  ...attendee,
                  imgSrc: attendee.imageUrl,
                  name: `${attendee.lastName} ${attendee.firstName}`,
              }))
            : [];
    };

    bookingDetailContent = () => {
        const { dashboardStore, t, paneDetail = false } = this.props;
        const currentBooking = this.state.booking.data ? this.state.booking.data : {};
        const amenity = currentBooking ? currentBooking.amenity : null;
        const organizer = currentBooking ? currentBooking.organizer : null;
        let campus = null;
        if (
            dashboardStore.locations &&
            dashboardStore.locations.data &&
            this.state.booking.data
        ) {
            campus = dashboardStore.locations.data.find(
                (cam) => cam.id === currentBooking.amenity.campusId
            );
        }
        Object.assign(organizer, { roles: currentBooking.organizerRoles });

        const recurrence = currentBooking ? currentBooking.recurrence : null;
        return (
            <Fragment>
                <div className="d-flex flex-wrap pb-3 ">
                    <BookingDetailInfo
                        amenity={amenity}
                        currentBooking={currentBooking}
                        campus={campus}
                        attendees={this.setAttendeeName(currentBooking.invitedAccounts)}
                        recurrence={recurrence}
                        paneDetail={paneDetail}
                        organizer={organizer}
                        attendeesToggle={this.attendeesToggle}
                        editAttendeesToggle={this.editAttendeesToggle}
                        campusToggle={this.campusToggle}
                        membershipToggle={this.membershipToggle}
                        memberToggle={this.profileToggle}
                    />
                    <BookingDetailRoomInfo
                        amenity={amenity}
                        currentBooking={currentBooking}
                        paneDetail={paneDetail}
                    />
                </div>
                {currentBooking.rights.includes('Cancellable') && (
                    <div
                        className={classnames('d-flex mb-3 flex-column', {
                            'mx-3': paneDetail,
                        })}
                    >
                        <div
                            className={classnames('mb-3 w-50 mx-auto', {
                                'w-100 ': paneDetail,
                            })}
                        >
                            <button
                                className="btn btn-sm btn-light text-danger w-100"
                                onClick={this.cancelBooking}
                            >
                                {t(
                                    `Booking.${
                                        recurrence
                                            ? 'cancel_only_my_booking'
                                            : 'cancel_my_booking'
                                    }`
                                )}
                            </button>
                        </div>
                        {recurrence && (
                            <div
                                className={classnames('mb-3  w-50 mx-auto', {
                                    'w-100 ': paneDetail,
                                })}
                            >
                                <button
                                    className="btn btn-sm btn-light text-danger w-100"
                                    onClick={this.cancelBookingRecurrence}
                                >
                                    {t('Booking.cancel_my_recurrence')}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Fragment>
        );
    };

    render() {
        const {
                isOpen,
                onClose,
                isModal = false,
                parentMatchURL,
                t,
                dashboardStore: { profile },
            } = this.props,
            {
                profileToggle,
                profileId,
                campusToggle,
                campusId,
                isMemberProfile,
                attendeesToggle,
                booking,
                editAttendees,
            } = this.state;
        const invitedAccounts =
            booking.data && booking.data.invitedAccounts
                ? booking.data.invitedAccounts
                : [];

        return (
            <Fragment>
                {isModal ? (
                    <Modal
                        isOpen={isOpen}
                        toggle={onClose}
                        className="modal-dialog-centered modal-full-screen adaptive"
                    >
                        <ModalBody className="bg-light-grey">
                            <div className="bookingDetail position-relative">
                                {this.bookingDetail()}
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (
                    <Fragment>
                        <div className="bookingDetail position-relative">
                            {this.bookingDetail()}
                        </div>
                    </Fragment>
                )}
                {attendeesToggle && booking.data && (
                    <Modal
                        isOpen={attendeesToggle}
                        toggle={this.attendeesToggle}
                        className="modal-dialog modal-dialog-centered modal-full-screen adaptive"
                    >
                        <div className="modal-header bg-dark p-0 pt-2">
                            <button
                                type="button"
                                className="close"
                                aria-label="Save"
                                onClick={this.attendeesToggle}
                            >
                                <span aria-hidden="true">
                                    <X fill="white" size="24px" />
                                </span>
                            </button>
                            <h5 className="modal-title mx-auto">
                                {t('Booking.booking_attendance_title')}
                            </h5>
                            <div className="bg-black text-center text-sm p-2 px-10">
                                {t('Booking.total_attendance', {
                                    param1: invitedAccounts.length,
                                    count: invitedAccounts.length,
                                })}
                            </div>
                            {booking.data.rights.includes('Editable') && (
                                <span
                                    className="btn btn-primary d-block rounded-0 c-pointer"
                                    onClick={this.editAttendeesToggle}
                                >
                                    {t('Booking.edit_attendance')}
                                </span>
                            )}
                        </div>
                        <ModalBody className="d-flex flex-column bg-body mh-0">
                            <div className="scrollable d-flex flex-column">
                                <ListGroup tag="div" className="list-group-compact">
                                    {this.setAttendeeName(invitedAccounts).map(
                                        (member) => (
                                            <ListItem
                                                key={member.id}
                                                id={member.id}
                                                withImage
                                                className="default list-group-item-action"
                                                clickable
                                                onClick={this.profileToggle}
                                                defaultImgSrc={MemberCircle}
                                                title={
                                                    member.id === profile.data.id
                                                        ? t('Member.me', {
                                                              param1: `${
                                                                  member.name || ''
                                                              }`,
                                                          })
                                                        : `${member.name || ''} `
                                                }
                                                subTitle={
                                                    member.id ===
                                                    booking.data.organizer.id
                                                        ? t('Booking.creator_title')
                                                        : ''
                                                }
                                                imgSrc={member.imgSrc}
                                                action={
                                                    <Arrow
                                                        size="1.5em"
                                                        className="text-primary"
                                                    />
                                                }
                                            />
                                        )
                                    )}
                                </ListGroup>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                {campusToggle && (
                    <CampusDetail
                        {...this.props}
                        onClose={this.campusToggle}
                        campusId={campusId}
                        isOpen={campusToggle}
                    />
                )}
                {profileToggle && (
                    <Profile
                        {...this.props}
                        onClose={this.membershipToggle}
                        membershipId={profileId}
                        memberId={profileId}
                        isOpen={profileToggle}
                        isMemberProfile={isMemberProfile}
                        modal
                    />
                )}
                {editAttendees && booking.data ? (
                    <AddMembers
                        membershipId={
                            booking.data.creditBalance.paymentMethodHolder.holderId
                        }
                        isOpen={editAttendees}
                        store={booking.data}
                        organizer={booking.data.organizer}
                        attendees={
                            booking.data.invitedAccounts &&
                            booking.data.invitedAccounts.length
                                ? booking.data.invitedAccounts
                                : []
                        }
                        onClose={this.closeModalAndReload}
                        attendance
                        editAttendance
                    />
                ) : null}
                <Route
                    path={`${parentMatchURL}/edit-booking`}
                    render={this.renderBookingModal}
                />
            </Fragment>
        );
    }
}

export default translate()(BookingDetail);
