import React from 'react';

const Pin = ({ fill, size, className }) => (
    <svg
        className={`icon ${className}`}
        viewBox="0 0 16 16"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M7.891 5.367c-.703 0-1.275.572-1.275 1.274a1.276 1.276 0 1 0 1.275-1.274m0 3.552A2.28 2.28 0 0 1 5.613 6.64a2.28 2.28 0 0 1 2.278-2.277 2.28 2.28 0 0 1 2.277 2.277 2.28 2.28 0 0 1-2.277 2.28" />
            <path d="M7.891 1.003A5.645 5.645 0 0 0 2.253 6.64c0 .695.36 1.802.804 2.468.955 1.436 3.815 4.556 4.834 5.654 1.02-1.098 3.88-4.218 4.834-5.654.444-.666.805-1.773.805-2.468a5.645 5.645 0 0 0-5.64-5.637M7.89 16a.502.502 0 0 1-.365-.158c-.166-.177-4.093-4.357-5.304-6.177-.554-.833-.972-2.133-.972-3.024A6.65 6.65 0 0 1 7.891 0a6.649 6.649 0 0 1 6.642 6.64c0 .892-.418 2.192-.973 3.025-1.21 1.82-5.137 6-5.303 6.177A.502.502 0 0 1 7.89 16" />
        </g>
    </svg>
);

export default Pin;
