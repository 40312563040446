import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { autorun, values } from 'mobx';
import memoize from 'memoize-one';

import ActionPane from 'Components/Modal/ActionPane';
import { Back, Conflict, Delete, Edit, MyBookings } from 'Components/Icons';
import ConflictListItem from 'Components/Booking/ConflictListItem';
import { Constants } from 'Data/';
import RecurringBookingStore from 'Stores/RecurringBooking/RecurringBookingStore';

@inject('uiStore')
@observer
class RecurrenceConflictsStep extends Component {
    static propTypes = {
        parentMatchURL: PropTypes.string.isRequired,
        onComplete: PropTypes.func.isRequired,
        store: PropTypes.instanceOf(RecurringBookingStore),
    };

    componentDidMount() {
        const { store, parentMatchURL, history } = this.props;

        if (!store.recurrenceDuration) {
            return history.replace(`${parentMatchURL}/duration`);
        }

        // React to changes to conflicts
        this.conflictsReactionDisposer = autorun(this.verifyConflictsState);
    }

    componentWillUnmount() {
        if (this.conflictsReactionDisposer) {
            this.conflictsReactionDisposer();
        }
    }

    verifyConflictsState = () => {
        const {
            store: { conflicts },
            onComplete,
        } = this.props;

        // If there are no conflicts or the all conflicts have been cancelled
        if (
            conflicts.loaded &&
            (!Object.keys(conflicts.data).length ||
                values(conflicts.data).every(
                    (conflict) => conflict.treated && conflict.isCancelled
                ))
        ) {
            onComplete();
        }
    };

    cancelBooking = (id) => async () => {
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Delete size="3.75em" />,
                title: t('Booking.delete_booking_title'),
                message: t('Booking.delete_booking_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        const { store } = this.props;
        store.updateConflict(id, {
            isCancelled: true,
            treated: true,
        });
    };

    editBooking = (id) => () => {
        const { store, parentMatchURL, history } = this.props;
        store.updateState('currentConflictId', id);
        history.push(`${parentMatchURL}/resolve`);
    };

    nextStep = async () => {
        const {
            store,
            onComplete,
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        const unTreatedConflicts = values(store.conflicts.data).filter(
            (conflict) => !conflict.treated
        );
        if (unTreatedConflicts.length) {
            if (
                !(await simpleModalStore.confirm({
                    titleIcon: <MyBookings size="3.75em" />,
                    title: t('Booking.delete_all_booking_title'),
                    message: t('Booking.delete_all_booking_message'),
                    type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
                }))
            )
                return;

            // Dispose of reaction since we call onComplete further down.
            this.conflictsReactionDisposer();

            unTreatedConflicts.forEach((conflict) => {
                store.updateConflict(conflict.id, {
                    isCancelled: true,
                    treated: true,
                });
            });
        }

        onComplete();
    };

    mapRecurringConflicts = memoize((recurringConflicts) =>
        recurringConflicts
            .filter((recurringConflict) => !recurringConflict.isCancelled)
            .map((recurringConflict) => ({
                withImage: false,
                id: recurringConflict.id,
                title: moment
                    .parseZone(recurringConflict.periodStart)
                    .format(Constants.LONG_TEXT_DATE_FORMAT),
                subTitle:
                    moment
                        .parseZone(recurringConflict.periodStart)
                        .format(Constants.SHORT_TIME_FORMAT) +
                    ' - ' +
                    moment
                        .parseZone(recurringConflict.periodEnd)
                        .format(Constants.SHORT_TIME_FORMAT),
                amenityId: recurringConflict.amenityId,
                treated: recurringConflict.treated,
                reason: recurringConflict.reason,
                amenity: recurringConflict.amenity,
            }))
    );

    render() {
        const {
            t,
            parentMatchURL,
            store: { conflicts },
        } = this.props;
        const items =
            conflicts && conflicts.data
                ? this.mapRecurringConflicts(values(conflicts.data))
                : [];
        return (
            <ActionPane>
                <div className="content ">
                    <div className="px-3 pl-xl-3 pr-xl-7 bg-dark d-flex pt-3 text-white">
                        <Link to={`${parentMatchURL}/duration`} className="text-white">
                            <Back width="1.5em" height="1.5em" />
                        </Link>
                        <div className="d-flex flex-column ml-2 text-center flex-grow-1">
                            <span className="text-danger">
                                <Conflict size="1.5em" />
                            </span>
                            <span
                                className="mb-3 text-xs"
                                dangerouslySetInnerHTML={{
                                    __html: t('Booking.recurrence_not_available'),
                                }}
                            />
                        </div>
                    </div>
                    {items.length ? (
                        <ul className="p-3 p-xl-7 list-group">
                            {items.map((item) => (
                                <ConflictListItem
                                    tag="li"
                                    className="list-group-item-spaced"
                                    key={item.id}
                                    {...item}
                                    action={
                                        <div className="action text-right">
                                            <span
                                                className="c-pointer pr-2 text-primary"
                                                onClick={this.cancelBooking(item.id)}
                                            >
                                                <Delete size="1.5em" />
                                            </span>
                                            <span
                                                className="c-pointer text-primary"
                                                onClick={this.editBooking(item.id)}
                                            >
                                                <Edit size="1.5em" />
                                            </span>
                                        </div>
                                    }
                                />
                            ))}
                        </ul>
                    ) : null}
                </div>
                <div>
                    <button
                        className="btn btn-primary w-100 bottom-action position-relative"
                        onClick={this.nextStep}
                        style={{ zIndex: 3 }}
                    >
                        {t('Membership.confirm')}
                    </button>
                </div>
            </ActionPane>
        );
    }
}

export default translate()(RecurrenceConflictsStep);
