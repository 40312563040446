import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { inject } from 'mobx-react';

import Auth from 'Services/Auth';
import { CellPhone, EMail, PersonalInfo, X } from 'Components/Icons';
import HttpService from 'Services/HttpService';

@inject('uiStore', 'registerStore', 'dashboardStore')
class AccountValidation extends React.PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        sent: PropTypes.bool.isRequired,
        parentMatchURL: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { email, password, history, parentMatchURL } = this.props;
        if (!email || !password) history.replace(parentMatchURL);
    }

    close = () => {
        const { registerStore } = this.props;
        registerStore.clear();
        this.props.history.push('/');
    };

    onAccountConfirmedFactory = (type) => async () => {
        const {
            t,
            uiStore: { loadingStore, toastStore },
            dashboardStore,
            registerStore,
            email,
            password,
            history,
        } = this.props;

        loadingStore.addLoading();
        try {
            await Auth.login(email, password);
            await dashboardStore.loadProposals(loadingStore);
            toastStore.enqueueToast({
                icon: PersonalInfo,
                message: t('Account.account_successfully_created'),
            });
            if (!!registerStore.accessToken) {
                history.replace('/dashboard/company');
            } else {
                history.replace('/dashboard/company/first-login');
            }
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    onAccountEmailConfirmed = this.onAccountConfirmedFactory('email');

    resendEmail = async () => {
        const {
            uiStore: { loadingStore, toastStore },
            email,
            t,
        } = this.props;
        loadingStore.addLoading();
        try {
            await HttpService.resendEmailConfirmation({ email });
            toastStore.enqueueToast({
                icon: EMail,
                message: t('Account.email_has_been_sent'),
            });
            this.setState({ byEmail: true });
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    renderEmail = () => {
        const { t } = this.props;
        return (
            <ModalBody className="px-3 pb-6 px-md-11 pb-md-18 text-center">
                <div className="text-secondary mb-4 mt-9">
                    <EMail size="3.75em" />
                </div>
                <div className="text-sm font-weight-medium mb-13">
                    {t('Account.email_validation_message')}
                </div>
                <div>
                    <button
                        className="btn btn-primary w-100 mb-3"
                        onClick={this.onAccountEmailConfirmed}
                    >
                        {t('Account.account_confirmed')}
                    </button>
                    <button
                        className="btn btn-light w-100 mb-3"
                        onClick={this.resendEmail}
                    >
                        {t('Account.resend_email_confirmation')}
                    </button>
                </div>
            </ModalBody>
        );
    };

    render() {
        return (
            <Modal
                isOpen={true}
                toggle={this.close}
                className="modal-dialog-centered modal-dark"
            >
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.close}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title m-0" aria-hidden="true">
                        &nbsp;
                    </h5>
                </div>
                {this.renderEmail()}
            </Modal>
        );
    }
}

export default translate()(AccountValidation);
