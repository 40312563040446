import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import { X, Error, ConferenceRoom, Back } from 'Components/Icons';
import HttpService from 'Services/HttpService';
import { inject, observer } from 'mobx-react';
import { NoResults } from 'Components/Loading';
import ConferenceRoomAmenitiesList from 'Components/Booking/ConferenceRoomAmenitiesList';
import { Image } from 'Components/Misc';
import Constants from 'Data/Constants';
import { ListItemHeader } from 'Components/Forms';
import classnames from 'classnames';

@inject('uiStore', 'seedStore', 'dashboardStore')
@observer
class RoomDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room: {},
            isMember: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const { onClose } = this.props;
        if (onClose) {
            this.props.onClose(Constants.DETAILS_TYPES.ROOM);
        } else {
            this.props.history.push(this.props.parentMatchURL);
        }
    }

    componentDidMount() {
        const { googleAddon = false } = this.props;
        if (googleAddon) {
            this.googleAddonRoom();
        } else {
            this.loadRoom();
        }
    }

    componentDidUpdate(prevProps) {
        const { googleAddon = false, room } = this.props;
        if (room !== prevProps.room && googleAddon && room.id) {
            this.googleAddonRoom();
        }
    }

    googleAddonRoom = () => {
        const { room } = this.props;
        this.setState({
            room: { data: room, ongoing: false },
            campus: { name: room.campusName },
        });
    };

    loadRoom = async () => {
        const {
            uiStore: { loadingStore },
            roomId,
            campusId,
        } = this.props;
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();
        loadingStore.addLoading();
        this.setState({ room: { ongoing: true } });
        try {
            const room = (await HttpService.roomDetail(roomId, this.cancellation)).data;
            const campus = (await HttpService.campusDetail(campusId, this.cancellation))
                .data;
            this.setState({
                room: { data: room, ongoing: false },
                campus,
            });
            const campuses = await HttpService.campuses(false, '', this.cancellation);
            const isSubscribed = campuses.data.filter((campus) => {
                return campus.isSubscribed && campus.id === campusId;
            });
            if (isSubscribed.length) {
                this.setState({ isMember: true });
            }
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.setState({ room: { errored: true, ongoing: false } });
        } finally {
            loadingStore.removeLoading();
        }
    };

    bookThisRoom = async () => {
        const { match, seedStore, history } = this.props,
            {
                room: { data },
                campus,
            } = this.state;
        const booking = {
            campus,
            match,
            room: data,
        };
        seedStore.set('booking', booking);
        history.push('/dashboard/company/book-a-room');
    };

    addToEvent = () => {
        const { addToEvent } = this.props,
            { room } = this.state;
        addToEvent(room.data.googleCalendarId);
    };

    detailRoom = () => {
        const {
                t,
                bookThis = false,
                dashboardStore: { profile },
                googleAddon = false,
                closeTab,
            } = this.props,
            { room, campus, isMember } = this.state;
        return room && room.errored ? (
            <NoResults
                className="m-auto pt-2"
                icon={<Error size="3.75em" />}
                title={t('Error.error')}
                subTitle={t('Error.error_message')}
                action={{
                    text: t('Error.retry'),
                    onClick: this.loadRoom,
                }}
            />
        ) : (
            room && room.data && (
                <Fragment>
                    {googleAddon && (
                        <div className="p-0 bookingDetail position-relative text-white">
                            <div
                                className={classnames(
                                    'd-flex bookingTitle position-absolute h-100 w-100',
                                    { 'flex-column': googleAddon }
                                )}
                            >
                                {room.data.imageUrl && (
                                    <Image
                                        imgSrc={room.data.imageUrl}
                                        className="roomPicture w-100 h-100 position-absolute"
                                    />
                                )}
                                <button
                                    type="button"
                                    className={classnames('close', {
                                        'align-self-start pt-2 pl-1': googleAddon,
                                    })}
                                    aria-label="Save"
                                    onClick={closeTab}
                                >
                                    <span aria-hidden="true">
                                        <Back fill="white" size="24px" />
                                    </span>
                                </button>
                                <div
                                    className={classnames(
                                        'd-flex w-100 justify-content-between align-items-end',
                                        {
                                            'p-3 pb-5': !googleAddon,
                                            'p-1 pb-2 flex-grow-1': googleAddon,
                                        }
                                    )}
                                >
                                    <div className="CampusInfo">
                                        <div className="sub-title">{campus.name}</div>
                                        <h3 className="font-weight-medium text-uppercase">
                                            {room.data.name}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="scrollable d-flex flex-column">
                        {!googleAddon && (
                            <ListItemHeader
                                superTitle={campus.name}
                                title={room.data.name}
                            />
                        )}
                        <div
                            className={classnames('bookingCreditInfo pb-1', {
                                'pt-0 px-2': !googleAddon,
                                'pt-2 px-1': googleAddon,
                            })}
                        >
                            <div className="font-weight-medium ">
                                <span className="font-weight-bold ">
                                    {t('Room.credit_amount', {
                                        param1: room.data.creditPointsPerBlock,
                                        count: room.data.creditPointsPerBlock,
                                    })}
                                </span>
                                <span className="text-sm "> / {t('Booking.per_hour')}</span>
                            </div>
                            <div className=" text-xs ">
                                {room.data.numberOfSeats +
                                    ' ' +
                                    t('Booking.people', {
                                        count: room.data.numberOfSeats,
                                    })}
                            </div>
                            {googleAddon && room.data.googleCalendarId && (
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary rounded mt-2 mb-1 addToEvent"
                                        onClick={this.addToEvent}
                                    >
                                        {t('extension.add_to_event')}
                                    </button>
                                </div>
                            )}
                            {room.data.description ? (
                                <div
                                    className="text-xs my-1"
                                    dangerouslySetInnerHTML={{
                                        __html: room.data.description,
                                    }}
                                />
                            ) : null}

                            <ConferenceRoomAmenitiesList
                                className="mt-1"
                                hasPhone={room.data.hasPhone}
                                hasScreenSharing={room.data.hasScreenSharing}
                                hasTv={room.data.hasTv}
                                hasWhiteboard={room.data.hasWhiteboard}
                                details
                                googleAddon={googleAddon}
                            />
                        </div>
                    </div>
                    {//#TODO Remove after Corenet
                    !googleAddon &&
                    bookThis &&
                    isMember &&
                    campus.id !== profile.data.coreNetCampusId ? (
                        <button
                            className="btn btn-primary w-100 rounded-0 bottom-action"
                            onClick={this.bookThisRoom}
                        >
                            {t('Room.book_room')}
                        </button>
                    ) : null}
                </Fragment>
            )
        );
    };

    render() {
        const { t, googleAddon = false } = this.props,
            { room, campus } = this.state;
        return room.data && googleAddon ? (
            this.detailRoom()
        ) : (
            <Modal
                isOpen={true}
                toggle={this.toggle}
                className="modal-dialog-centered modal-full-screen adaptive"
                contentClassName="bg-transparent"
            >
                {!room.errored && room.data && (
                    <div className="modal-header p-0 bookingDetail position-relative text-white">
                        <div className="d-flex bookingTitle position-absolute h-100 w-100">
                            {room.data.imageUrl && (
                                <Image
                                    imgSrc={room.data.imageUrl}
                                    className="roomPicture w-100 h-100 position-absolute"
                                />
                            )}
                            <button
                                type="button"
                                className="close"
                                aria-label="Save"
                                onClick={this.toggle}
                            >
                                <span aria-hidden="true">
                                    <X fill="white" size="24px" />
                                </span>
                            </button>
                            <div className="p-3 pb-5 d-flex w-100 justify-content-between align-items-end">
                                <div className="CampusInfo">
                                    <div className="sub-title">{campus.name}</div>
                                    <h3 className="font-weight-medium text-uppercase">
                                        {room.data.name}
                                    </h3>
                                    <div className="text-secondary d-flex">
                                        <ConferenceRoom size="1.333em" />
                                        <div className="pl-1">{t('Room.conference')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <ModalBody className="d-flex flex-column bg-white mh-0">
                    {this.detailRoom()}
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(RoomDetail);
