import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import colorVariables from 'Sass/variables.scss';

const styles = (theme) => ({
    iOSSwitchBase: {
        padding: 1,
        '&$iOSChecked': {
            transform: 'translateX(16px)',
            color: colorVariables.white,
            '& + $iOSBar': {
                backgroundColor: colorVariables.primary,
                opacity: 1,
                border: 'none',
            },
        },
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -12,
        marginLeft: -12,
        border: 'solid 1px',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        backgroundColor: colorVariables.white,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

class ToggleSwitch extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        checked: PropTypes.bool,
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        name: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        checked: false,
    };

    handleChange = (event) => {
        const { onChange, id } = this.props;
        if (onChange) onChange(id, event.target.checked);
    };

    render() {
        const { classes, checked, id, name, disabled } = this.props;

        return (
            <Switch
                id={id}
                classes={{
                    switchBase: classes.iOSSwitchBase,
                    track: classes.iOSBar,
                    thumb: classes.iOSIcon,
                    checked: classes.iOSChecked,
                }}
                disableRipple
                checked={checked}
                onChange={this.handleChange}
                value={name || id}
                disabled={disabled}
            />
        );
    }
}

export default withStyles(styles)(ToggleSwitch);
