const AddressUtils = {
    convertAddress(address) {
        let url = [];
        Object.keys(address).forEach(function eachKey(key) {
            if (address[key]) {
                switch (key) {
                    case 'addressLine1':
                    case 'addressLine2':
                        url.push(`${address[key]}<br/>`);
                        break;
                    case 'city':
                        url.push(`${address[key]},`);
                        break;
                    default:
                        url.push(address[key]);
                }
            }
        });
        return Object.values(url).join(' ');
    },

    hasAddress(address) {
        return Object.values(address).some((x) => x);
    },

    googleMapAddress(address, location) {
        let baseUrl =
            'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=';
        if (location) {
            baseUrl += Object.values(location).join();
        } else {
            baseUrl += Object.values(address).join(' ');
        }
        return baseUrl;
    },
};

export default AddressUtils;
