import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import timezone from 'moment-timezone';

import { ActionPane } from 'Components/Modal';
import { BookingConfirmationSummary } from 'Components/Booking';
import { ListItemHeader } from 'Components/Forms';
import { dateFormat } from './FlexibleBooking/DateControls';
import FlexibleBooking from './FlexibleBooking/FlexibleBooking';
import HttpService from 'Services/HttpService';
import ModelStateStore from 'Stores/ModelStateStore';
import Constants from 'Data/Constants';
import RecurrenceChoice from './Recurrence/RecurrenceChoice';
import RecurrenceConflicts from './Recurrence/RecurrenceConflicts';
import { RecurringBooking, MyBookings } from 'Components/Icons';
import { values } from 'mobx';
import BookingUtils from 'Utils/BookingUtils';

@inject('uiStore')
@observer
class ConfirmationStep extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cost: null,
            futureCost: null,
            recurrenceOpen: false,
            conflictsOpen: false,
            bookingError: null,
        };
    }

    componentDidMount() {
        this.loadSummary();
    }

    modelStateStore = new ModelStateStore();

    loadSummary = async () => {
        const { store } = this.props;
        if (!store.conferenceRoomId) {
            return;
        }

        const summary = await store.recurringStore.loadSummaryAndConflicts();
        if (!summary) {
            return;
        }

        this.setState({
            cost: summary.cost,
            transactionCost: summary.transactionCost,
            futureCost: summary.maxCostPerMonth,
            dateCharged: summary.dateCharged,
            conflictsOpen: store.recurringStore.hasRemainingConflicts,
        });
    };

    confirm = async () => {
        const {
            store,
            history,
            parentMatchURL,
            uiStore: { toastStore, loadingStore },
            stepKey,
            onComplete,
            t,
        } = this.props;

        const summary = await store.recurringStore.loadSummaryAndConflicts();

        if (!summary) {
            return;
        }

        if (summary.occurrences.some((occ) => occ.hasConflict)) {
            return history.replace(`${parentMatchURL}/recurring/conflicts`);
        }

        try {
            loadingStore.addLoading();

            const attendees = [...store.selectedMemberIds];

            const request = {
                creditBalanceId: store.creditBalanceId,
                periodStart: store.bookingStart,
                periodEnd: store.bookingEnd,
                isPrivate: store.isPrivate,
                title: store.bookingTitle,
                description: store.bookingDescription,
                invitedAccountIds:
                    store.creditBalance.paymentMethodHolder.holderType ===
                    Constants.HOLDER_TYPE.MEMBERSHIP
                        ? attendees
                        : [],
                recurrence: store.recurring ? store.recurringStore.recurrence : null,
                exceptions: store.recurring ? store.recurringStore.exceptions : null,
                bookingCreatorId: store.bookingOrganizer,
            };
            await HttpService.book({
                conferenceRoomId: store.conferenceRoomId,
                data: request,
            });
            toastStore.enqueueToast({
                icon: MyBookings,
                message: t(
                    `Booking.${
                        store.recurring
                            ? 'recurring_booking_success_message'
                            : 'booking_success_message'
                    }`
                ),
            });
            onComplete(stepKey, null);
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        } finally {
            loadingStore.removeLoading();
        }
    };

    update = async () => {
        const {
            store,
            uiStore: { toastStore, loadingStore },
            stepKey,
            onComplete,
            t,
        } = this.props;

        const summary = !store.isEdit
            ? await store.recurringStore.loadSummaryAndConflicts()
            : null;
        if (!summary && !store.isEdit) {
            return;
        }
        try {
            loadingStore.addLoading();
            const request = {
                amenityId: store.conferenceRoomId,
                periodStart: store.bookingStart,
                periodEnd: store.bookingEnd,
                isPrivate: store.isPrivate,
                title: store.bookingTitle,
                description: store.bookingDescription,
                invitedAccountIds:
                    store.creditBalance.paymentMethodHolder.holderType ===
                    Constants.HOLDER_TYPE.MEMBERSHIP
                        ? store.selectedMemberIds
                        : [],
            };
            await HttpService.updateBooking({
                bookingId: store.bookingId,
                data: request,
            });

            toastStore.enqueueToast({
                icon: MyBookings,
                message: t('Booking.booking_update_success_message'),
            });
            onComplete(stepKey, null);
        } catch (e) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    handleEditorChange = (editor) => {
        const { store } = this.props;
        store.updateState('bookingDescription', editor.level.content);
    };

    handleInputChange = (event) => {
        const { store } = this.props;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        store.updateState(`${name}`, value);
    };

    handlePrivateToggle = (name, newVal) => {
        const { store } = this.props;
        store.updateState('isPrivate', newVal);
    };

    setAttendeeName = (attendees) => {
        return attendees && attendees.length
            ? attendees.map((attendee) =>
                  !attendee.name
                      ? {
                            ...attendee,
                            imgSrc: attendee.imageUrl,
                            name: `${attendee.lastName} ${attendee.firstName}`,
                        }
                      : { ...attendee }
              )
            : [];
    };

    handleTimeCellClick = (e, bookingInformation, period) => {
        this.setState({ bookingError: null });
        const bookingKeys = Object.keys(bookingInformation);
        for (const key of bookingKeys) {
            this.props.store.updateState(key, bookingInformation[key]);
        }
        this.resetRecurrence();
    };

    handleBookingResize = ({ formatedBookingStart, formatedBookingEnd }) => {
        const amenity = this.props.store.bookingAmenity.slice()[0];
        const start = formatedBookingStart
            ? formatedBookingStart
            : timezone(this.props.store.bookingStart)
                  .tz(this.props.store.campus.timeZone)
                  .format();
        const bookingEnd = formatedBookingEnd
            ? formatedBookingEnd
            : timezone(this.props.store.bookingEnd)
                  .tz(this.props.store.campus.timeZone)
                  .format();

        const bookingError = BookingUtils.checkForBookingError({
            bookingEnd,
            start,
            amenity,
            t: this.props.t,
        });
        this.setState({ bookingError });

        this.resetRecurrence();
        this.props.store.updateState('bookingStart', start);
        this.props.store.updateState('bookingEnd', bookingEnd);
    };

    resetRecurrence = () => {
        const {
            store,
            uiStore: { toastStore },
            t,
        } = this.props;

        if (store.recurringStore.recurrenceOption) {
            toastStore.enqueueToast({
                icon: RecurringBooking,
                message: t('Booking.booking_recurrence_reset_message'),
            });
        }
        store.updateState('recurring', false);
        store.recurringStore.reset();
    };

    toggleRecurrenceStep = async (currentChoice) => {
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        if (this.state.recurrenceOpen) {
            if (
                currentChoice !== Constants.RECURRING_NO_REPEAT &&
                !(await simpleModalStore.confirm({
                    titleIcon: <MyBookings size="3.75em" />,
                    title: t('Booking.leave_recurring_booking_title'),
                    message: t('Booking.leave_recurring_booking_message'),
                    type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
                }))
            )
                return;

            this.setState({
                recurrenceOpen: false,
            });
        } else {
            this.setState({
                recurrenceOpen: true,
            });
        }
    };

    submitRecurrenceStep = async (recurrenceOption, recurrenceDuration) => {
        const { store } = this.props;
        store.updateState('recurring', !!recurrenceOption);
        store.recurringStore.updateState('recurrenceOption', recurrenceOption);
        store.recurringStore.updateState('recurrenceDuration', recurrenceDuration);
        await store.recurringStore.loadSummaryAndConflicts();
        this.setState({
            recurrenceOpen: false,
            conflictsOpen: store.recurringStore.hasRemainingConflicts,
        });
    };

    cancelConflictStep = async () => {
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        if (
            !(await simpleModalStore.confirm({
                titleIcon: <MyBookings size="3.75em" />,
                title: t('Booking.leave_conflict_resolution_title'),
                message: t('Booking.leave_conflict_resolution_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        const { store } = this.props;
        store.updateState('recurring', false);
        store.recurringStore.reset();
        this.setState({
            conflictsOpen: false,
        });
    };

    submitConflictStep = async () => {
        const {
            store: { recurringStore },
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        const unTreatedConflicts = values(recurringStore.conflicts.data).filter(
            (conflict) => !conflict.treated
        );
        if (unTreatedConflicts.length) {
            if (
                !(await simpleModalStore.confirm({
                    titleIcon: <MyBookings size="3.75em" />,
                    title: t('Booking.delete_all_booking_title'),
                    message: t('Booking.delete_all_booking_message'),
                    type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
                }))
            )
                return;

            unTreatedConflicts.forEach((conflict) => {
                recurringStore.updateConflict(conflict.id, {
                    isCancelled: true,
                    treated: true,
                });
            });
        }
        this.setState({
            conflictsOpen: false,
        });
    };

    render() {
        const { t, store, onDetails, toggleAttendeesStep } = this.props,
            {
                campus,
                timeSlot,
                conferenceRoomId,
                creditBalance,
                isPrivate,
                recurring,
                recurringStore,
            } = store;
        const {
            cost,
            transactionCost,
            futureCost,
            dateCharged,
            recurrenceOpen,
            conflictsOpen,
        } = this.state;

        const { recurrenceOption, recurrenceDuration } = recurringStore;

        return (
            <Fragment>
                <ActionPane>
                    <div className="d-flex overflow-auto p-4">
                        <BookingConfirmationSummary
                            store={this.props.store}
                            history={this.props.history}
                            className="w-50 mr-2"
                            location={campus}
                            handleEditorChange={this.handleEditorChange}
                            onChange={this.handleInputChange}
                            conferenceRoom={this.props.store.bookingAmenity[0]}
                            timeSlot={timeSlot}
                            isRecurring={recurring}
                            creditBalance={creditBalance}
                            isPrivate={isPrivate}
                            onPrivateChange={this.handlePrivateToggle}
                            recurrenceOption={recurrenceOption}
                            recurrenceDuration={recurrenceDuration}
                            cost={cost}
                            bookingTitle={store.bookingTitle}
                            bookingDescription={store.bookingDescription}
                            futureCost={futureCost}
                            isEdit={store.isEdit}
                            organizer={store.bookingOrganizer}
                            dateCharged={dateCharged}
                            transactionCost={transactionCost}
                            onClickDetail={onDetails}
                            toggleAttendeesStep={toggleAttendeesStep}
                            toggleRecurrenceStep={this.toggleRecurrenceStep}
                            attendees={this.setAttendeeName(store.attendees)}
                            originalBooking={store.originalBooking}
                        />

                        <div className="flexible-details d-flex flex-column w-50 ml-2">
                            <ListItemHeader
                                superTitle={
                                    <>
                                        {t('Booking.your_title')}{' '}
                                        <span className="text-lowercase">
                                            {t('Booking.booking_title')}
                                        </span>
                                    </>
                                }
                                title={t('Invita.time_title')}
                            />

                            <h4 className="mb-0 p-2 text-center bg-white">
                                {moment(this.props.store.bookingStart).format(dateFormat)}
                            </h4>

                            <FlexibleBooking
                                view="details"
                                className="w-100 position-relative"
                                resizable
                                resources={this.props.store.bookingAmenity.slice()}
                                currentBooking={{
                                    bookingStart: this.props.store.bookingStart,
                                    bookingEnd: this.props.store.bookingEnd,
                                    conferenceRoomId: conferenceRoomId,
                                    bookingId:
                                        store.originalBooking && store.originalBooking.id,
                                }}
                                timezone={this.props.store.campus.timeZone}
                                bookingError={this.state.bookingError}
                                onTimeCellClick={this.handleTimeCellClick}
                                onBookingResize={this.handleBookingResize}
                                min={0}
                                max={24}
                                zoom={2}
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-primary w-100 bottom-action"
                        onClick={store.isEdit ? this.update : this.confirm}
                    >
                        {t(
                            `Booking.${
                                store.isEdit
                                    ? 'confirm_booking_edition'
                                    : 'confirm_booking'
                            }`
                        )}
                    </button>
                </ActionPane>
                <RecurrenceChoice
                    conferenceRoomId={conferenceRoomId}
                    creditBalanceId={creditBalance?.id}
                    periodStart={this.props.store.bookingStart}
                    periodEnd={this.props.store.bookingEnd}
                    isOpen={recurrenceOpen}
                    toggle={this.toggleRecurrenceStep}
                    onSubmit={this.submitRecurrenceStep}
                />

                <RecurrenceConflicts
                    isOpen={conflictsOpen}
                    onCancel={this.cancelConflictStep}
                    onSubmit={this.submitConflictStep}
                    store={recurringStore}
                    timezone={this.props.store.campus.timeZone}
                />
            </Fragment>
        );
    }
}

export default translate()(ConfirmationStep);
