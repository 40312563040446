import React from 'react';

const SegmentHeader = ({ className, IconComponent, icon, text, children }) => (
    <div
        className={`bg-dark header segment-header text-center position-relative text-uppercase ${
            className || ''
        }`}
    >
        {icon ? icon : IconComponent ? <IconComponent size="36px" /> : null}
        <h5 className="mt-1 mb-0">{text}</h5>
        {children}
    </div>
);

export default SegmentHeader;
