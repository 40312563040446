import React from 'react';

const Date = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M2.387 22.566h19.191V7.306H2.387v15.26zm0-19.487h4.76v1.187a.444.444 0 1 0 .887 0V3.079h7.842v1.187a.444.444 0 0 0 .886 0V3.079h4.816v3.34H2.387V3.08zm19.634-.887h-5.259V.944a.444.444 0 1 0-.886 0v1.248H8.034V.944a.444.444 0 0 0-.887 0v1.248H1.943a.443.443 0 0 0-.443.443v20.373c0 .246.198.444.443.444H22.02a.443.443 0 0 0 .444-.444V2.635a.443.443 0 0 0-.444-.443z" />
            <path d="M5.134 12.982h2.013V10.97H5.134v2.012zm2.456.887H4.691a.443.443 0 0 1-.443-.443v-2.9c0-.244.198-.442.443-.442h2.9c.244 0 .442.198.442.443v2.899a.443.443 0 0 1-.443.443zm3.386-.887h2.012V10.97h-2.012v2.012zm2.456.887h-2.9a.443.443 0 0 1-.443-.443v-2.9c0-.244.199-.442.444-.442h2.899c.245 0 .443.198.443.443v2.899a.443.443 0 0 1-.443.443zm3.331-.887h2.013V10.97h-2.013v2.012zm2.456.887h-2.9a.443.443 0 0 1-.443-.443v-2.9c0-.244.199-.442.444-.442h2.899c.245 0 .443.198.443.443v2.899a.443.443 0 0 1-.443.443zM5.134 18.901h2.013V16.89H5.134v2.01zm2.456.887H4.691a.443.443 0 0 1-.443-.443v-2.9c0-.244.198-.443.443-.443h2.9c.244 0 .442.199.442.444v2.899a.443.443 0 0 1-.443.443zm3.386-.887h2.012V16.89h-2.012v2.01zm2.456.887h-2.9a.443.443 0 0 1-.443-.443v-2.9c0-.244.199-.443.444-.443h2.899c.245 0 .443.199.443.444v2.899a.443.443 0 0 1-.443.443zm3.331-.887h2.013V16.89h-2.013v2.01zm2.456.887h-2.9a.443.443 0 0 1-.443-.443v-2.9c0-.244.199-.443.444-.443h2.899c.245 0 .443.199.443.444v2.899a.443.443 0 0 1-.443.443z" />
        </g>
    </svg>
);

export default Date;
