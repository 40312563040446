import React from 'react';

const Logout = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 60 60"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M2.5 0v59.858h20.1v-2.313H4.813V2.313H22.6V0z" />
            <path d="M38.952 16.477l12.534 12.005H16.434v2.89H51.49L38.952 43.375l1.408 2.294L56.8 29.93 40.36 14.188z" />
        </g>
    </svg>
);

export default Logout;
