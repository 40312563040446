import React from 'react';

import { Image } from 'Components/Misc';
import { CheckOutline, DeclineOutline, Membership } from 'Components/Icons';
import Constants from 'Data/Constants';
import HttpService from './HttpService';
import RootStore from 'Stores/RootStore';

const {
    dashboardStore,
    uiStore: { simpleModalStore, loadingStore, toastStore },
    registerStore,
} = RootStore;

const Invitations = {
    invitationDetail: async (proposal, t) => {
        const result = await simpleModalStore.confirm({
            title: t('Membership.membership_invitation'),
            icon: (
                <div className="text-center">
                    <div key="icon" className="simple-modal-icon">
                        <Image
                            imgSrc={proposal.imageUrl}
                            defaultImg={<Membership size="4.375em" />}
                        />
                    </div>
                    <h6
                        key="text"
                        className="mt-2 text-lg font-weight-bold text-uppercase"
                    >
                        {proposal.name}
                    </h6>
                </div>
            ),
            message: t('Membership.invitation_accept_explanation'),
            type: Constants.MODAL_TYPE.YES_NO,
        });
        registerStore.accessToken = null;
        if (result === null) {
            return;
        } else if (result) {
            Invitations.acceptInvitation(proposal, t);
        } else {
            Invitations.rejectInvitation(proposal, t);
        }
    },

    acceptInvitation: async (proposal, t) => {
        loadingStore.addLoading();
        try {
            await HttpService.acceptInvitation(proposal.id);
            toastStore.enqueueToast({
                icon: CheckOutline,
                message: t('Membership.invitation_accept_message'),
                preventAutoDismiss: true,
            });
            dashboardStore.load(loadingStore);
        } catch (error) {}
        loadingStore.removeLoading();
    },

    rejectInvitation: async (proposal, t) => {
        loadingStore.addLoading();
        try {
            await HttpService.rejectInvitation(proposal.id);
            toastStore.enqueueToast({
                icon: DeclineOutline,
                message: t('Membership.invitation_refuse_message'),
                preventAutoDismiss: true,
            });
            dashboardStore.load(loadingStore);
        } catch (error) {}
        loadingStore.removeLoading();
    },
};

export default Invitations;
