import React from 'react';

const Ach = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 60 60"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M30.793 0L3 23.835h7.991V46.75H7.718v5.456H3.353V60h53.78v-7.794h-4.208V46.75h-3.274V23.835h7.877v-.021L30.815 0h-.022zm-.02 3.096l20.642 18.4H9.318l21.455-18.4zm4.225 43.654h5.3V23.835h-5.3V46.75zm-14.654 0h5.301V23.835h-5.3V46.75zm22.292 0h4.677V23.99h-4.677v22.76zm-14.652 0h4.676V23.99h-4.676v22.76zm-14.654 0h4.676V23.99H13.33v22.76zm-3.273 5.455h40.53v-3.117h-40.53v3.117zM5.69 57.662h49.103v-3.118H5.691v3.118z"
        />
    </svg>
);

export default Ach;
