import React from 'react';

const AngleRight = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 10 16"
        width={size || '1em'}
        height={size || '1em'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.34802 0.195041C1.0853 -0.0650135 0.65988 -0.0650135 0.396492 0.195041C0.134438 0.455095 0.134438 0.88185 0.396492 1.1419L7.92006 7.99667L0.396492 14.8581C0.134438 15.1181 0.134438 15.5382 0.396492 15.805C0.65988 16.065 1.0853 16.065 1.34802 15.805L9.35436 8.51011C9.49439 8.37008 9.5544 8.18337 9.54506 7.99667C9.5544 7.81663 9.49439 7.62992 9.35436 7.48989L1.34802 0.195041Z"
            fill={fill || 'currentColor'}
        />
    </svg>
);

export default AngleRight;
