import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';

import HttpService from 'Services/HttpService';
import { ActionPane } from 'Components/Modal';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Company, ConferenceRoom, PrivateRoom } from 'Components/Icons';
import FlexibleBooking from './FlexibleBooking/FlexibleBooking';
import DateControls from './FlexibleBooking/DateControls';
import { X, Phone, ScreenSharing, Television, WhiteBoard } from 'Components/Icons';
import Image from 'Components/Misc/Image';
import { MultiCheckbox } from 'Components/Forms';
import Filter from 'Assets/Icons/Filter.svg';

const defaultMembershipImg = (Icon) => (
    <div className="wg-6 hg-6 rounded-circle bg-light text-white align-item-center mr-2">
        {Icon && <Icon />}
    </div>
);

const privateRoomIcon = (
    <div className="resource-private">
        <PrivateRoom />
    </div>
);

const generateResourceHeader = (t) => (resource) => {
    return (
        <>
            <div className="resource-head-thumb">
                <Image
                    defaultImg={defaultMembershipImg()}
                    imgSrc={resource.imageUrl}
                    className="FlexibleBooking__Amenity_Image mr-2"
                />
                <span className="resource-head-seats">
                    {resource.numberOfSeats}{' '}
                    <span className="people">
                        {t('Booking.people')}
                    </span>
                </span>

                {resource.isPrivate && privateRoomIcon}
            </div>
            <h3 className="resource-title" title={resource.name}>
                {resource.name}
            </h3>
        </>
    );
};

const generateResourcePeriod = (period) => {
    if (period && period.booking && period.booking) {
        if (period.unavailabilityReason === 'Booked') {
            return (
                <div className="booking-information d-flex align-items-center position-absolute text-white">
                    {period.booking.membership && period.booking.membership.imageUrl ? (
                        <div
                            style={{
                                backgroundImage:
                                    'url(' + period.booking.membership.imageUrl || +')',
                            }}
                        />
                    ) : (
                        <Image defaultImg={defaultMembershipImg(Company)} imgSrc={null} />
                    )}
                    {period.booking.membership && <p>{period.booking.membership.name}</p>}
                </div>
            );
        } else {
            return '';
        }
    } else {
        return null;
    }
};

@inject('uiStore')
@observer
class TimeSlotStep extends React.Component {
    static propTypes = {
        onComplete: PropTypes.func.isRequired,
        stepKey: PropTypes.string.isRequired,
        store: PropTypes.object.isRequired,
    };

    state = {
        selectedDate: moment().format('YYYY-MM-DD'),
        amenities: [],
        filters: [],
        isFilterModalOpen: false,
        endDate: null,
        currentBooking: {},
    };

    initialLoad = true;
    initialized = false;

    componentDidMount() {
        const { store } = this.props;
        if (store.originalBooking || (store.bookingStart && store.bookingEnd)) {
            this.initialized = true;
            this.setState({
                currentBooking: {
                    bookingStart: store.bookingStart || store.originalBooking.periodStart,
                    bookingEnd: store.bookingEnd || store.originalBooking.periodEnd,
                    conferenceRoomId:
                        store.conferenceRoomId || store.originalBooking.amenity.id,
                    bookingId: store.originalBooking && store.originalBooking.id,
                },
                selectedDate: moment(
                    store.bookingStart || store.originalBooking.periodStart
                ).format('YYYY-MM-DD'),
            });
        }

        this.loadBookings();
        this.loadDates();
    }

    componentDidUpdate(prevProps, prevState) {
        const { store } = this.props;
        if (
            (store.bookingStart || store.preSelectedBookingStart) &&
            (store.bookingEnd || store.preSelectedBookingEnd) &&
            !this.initialized
        ) {
            this.initialized = true;
            this.setState({
                selectedDate: moment(
                    store.bookingStart || store.originalBooking.periodStart
                ).format('YYYY-MM-DD'),
            });
        }

        if (prevState.selectedDate !== this.state.selectedDate) {
            this.loadBookings();
        }
    }

    loadBookings = async () => {
        const { store } = this.props;
        this.dailyBookingSummaryCancellable = HttpService.cancellable();

        const request = {
            date: this.state.selectedDate,
            creditBalanceId: store.creditBalanceId,
        };

        try {
            const { data } = await HttpService.dailyBookingSummary(
                {
                    id: store.campus.id,
                    request,
                },
                this.dailyBookingSummaryCancellable
            );

            this.setState((prevState) => {
                return {
                    amenities: data.amenities,
                    filters: this.initialLoad
                        ? this.getAmenitiesId(data.amenities)
                        : store.amenityFilters,
                    currentBooking: {
                        ...prevState.currentBooking,
                        bookingAmenity:
                            store.originalBooking &&
                            data.amenities.filter(
                                (am) => am.id === store.originalBooking.amenity.id
                            ),
                    },
                };
            });

            if (this.initialLoad) {
                this.initialLoad = false;
            }
        } catch (err) {
            if (this.dailyBookingSummaryCancellable.isCancelled(err)) return;
        }
    };

    loadDates = async () => {
        if (this.datesCancellable) this.datesCancellable.cancel();
        this.datesCancellable = HttpService.cancellable();
        const { store } = this.props;
        try {
            this.setState({ availableDays: { loading: true } });
            const { data } = await HttpService.bookingMaxDate(
                {
                    id: store.creditBalanceId,
                    campusId: store.campus.id,
                },
                this.datesCancellable
            );
            const endDate = moment(data).add(1, 'day');
            this.setState({ endDate });
        } catch (err) {
            if (this.datesCancellable.isCancelled(err)) return;
        }
    };

    getAmenitiesId = (amenities) => {
        return amenities.map((amenity) => {
            return amenity.id;
        });
    };

    nextStep = (bookingInformation) => {
        const { store, onComplete, stepKey } = this.props;

        const bookingKeys = Object.keys(bookingInformation);
        for (const key of bookingKeys) {
            store.updateState(key, bookingInformation[key]);
        }

        store.updateState('touched', true);
        onComplete(stepKey, null);
    };

    cacheTimeSlots = (timeSlots) => {
        this.availableTimeSlots = timeSlots;
    };

    toggleFilterModal = () => {
        this.setState((prevState) => {
            return {
                isFilterModalOpen: !prevState.isFilterModalOpen,
            };
        });
    };

    generateContent = (item) => {
        let hasUtilities =
            item.hasPhone || item.hasScreenSharing || item.hasTv || item.hasWhiteboard;
        return (
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center">
                    <Image
                        defaultImg={defaultMembershipImg(ConferenceRoom)}
                        imgSrc={item.imageUrl}
                        className="FlexibleBooking__Amenity_Image mr-2"
                    />
                    <div className="Amenity__Name">
                        <p className="mb-0 font-weight-bold">{item.name}</p>
                        {item.isPrivate && privateRoomIcon}
                        <p className="mb-0 small font-weight-normal">
                            {item.numberOfSeats}{' '}
                            {this.props.t('Booking.people', {
                                count: item.numberOfSeats,
                            })}
                        </p>
                    </div>
                </div>

                <div
                    className={classnames(
                        'FlexibleBooking__Amenity_Information',
                        'd-flex',
                        'flex-column',
                        'align-items-end',
                        hasUtilities
                            ? 'justify-content-between'
                            : 'justify-content-center'
                    )}
                >
                    <div className="FlexibleBooking__Amenity_Information_Utilities mx-n1">
                        {item.hasPhone && <Phone className="mx-1" />}
                        {item.hasScreenSharing && <ScreenSharing className="mx-1" />}
                        {item.hasTv && <Television className="mx-1" />}
                        {item.hasWhiteboard && <WhiteBoard className="mx-1" />}
                    </div>
                    <div className="FlexibleBooking__Amenity_Information_Cost bg-black text-white small">
                        {`${this.props.t('Room.credit_amount', {
                            param1: item.creditPointsPerBlock,
                            count: item.creditPointsPerBlock,
                        })} / ${item.minutesPerBlock} min`}
                    </div>
                </div>
            </div>
        );
    };

    cancelFilters = () => {
        this.setState({ filters: this.props.store.amenityFilters });
        this.toggleFilterModal();
    };

    confirmFilters = () => {
        this.props.store.updateState('amenityFilters', this.state.filters);
        this.toggleFilterModal();
    };

    getFilteredAmenities = () => {
        const { store } = this.props;

        if (store.amenityFilters.length > 0) {
            return this.state.amenities.filter((amenity) =>
                store.amenityFilters.includes(amenity.id)
            );
        } else {
            return this.state.amenities;
        }
    };

    handleDateChange = (selectedDate) => {
        this.setState({ selectedDate: moment(selectedDate).format('YYYY-MM-DD') });
    };

    handleTimeCellClick = (e, bookingInformation, period) => {
        this.setState({ currentBooking: bookingInformation });
        this.nextStep(bookingInformation);
    };

    handleBookingClick = () => {
        if (this.props.store.isEdit) {
            this.nextStep(this.state.currentBooking);
        } else {
            this.props.onComplete(this.props.stepKey, null);
        }
    };

    render() {
        const { t, store } = this.props;
        const bookingEdit = store.isEdit;

        return (
            <Fragment>
                <ActionPane>
                    <div className="m-5 content">
                        <div className="FlexibleBooking__actions d-flex align-items-center justify-content-between mb-1">
                            <button
                                className="btn btn-light btn-sm"
                                onClick={this.toggleFilterModal}
                            >
                                {t('Booking.booking_filters_title')}{' '}
                                <div className="room_filter_icon_container d-inline-block position-relative">
                                    <Image imgSrc={Filter} />
                                    <span className="room_filter_icon_filter_count position-absolute d-flex align-items-center justify-content-center">
                                        {store.amenityFilters.length}
                                    </span>
                                </div>
                            </button>

                            <DateControls
                                selectedDate={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                maxDate={this.state.endDate}
                            />

                            <Modal
                                isOpen={this.state.isFilterModalOpen}
                                toggle={this.toggleFilterModal}
                                className="modal-dialog"
                            >
                                <div className="modal-header bg-white">
                                    <button
                                        type="button"
                                        className="close"
                                        aria-label="Save"
                                        onClick={this.cancelFilters}
                                    >
                                        <span aria-hidden="true">
                                            <X fill="black" size="24px" />
                                        </span>
                                    </button>
                                    <h5 className="modal-title mx-auto">
                                        {t('Booking.room_preference')}
                                    </h5>
                                </div>
                                <ModalBody className="bg-light">
                                    <div className="overflow-auto">
                                        <div className="FlexibleBooking__actions_subtitle text-center mx-1 my-3"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'Booking.booking_filters_header_title'
                                                ),
                                            }}
                                        />

                                        <MultiCheckbox
                                            items={this.state.amenities}
                                            selected={this.props.store.amenityFilters}
                                            generateContent={this.generateContent}
                                            onChange={(filters) => {
                                                this.setState({ filters });
                                            }}
                                            checkedByDefault
                                        />

                                        <p className="text-center mx-1 my-3">
                                            {t('Booking.rooms_total', {
                                                param1: this.state.filters.length
                                                    ? this.state.filters.length
                                                    : this.state.amenities.length,
                                                count: this.state.filters.length
                                                    ? this.state.filters.length
                                                    : this.state.amenities.length,
                                            })}
                                        </p>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="bg-white p-3">
                                    <button
                                        className="btn btn-primary w-100 rounded-0"
                                        onClick={this.confirmFilters}
                                    >
                                        {t('Booking.apply_filters')}
                                    </button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        {this.state.amenities.length > 0 && (
                            <FlexibleBooking
                                view="bookings"
                                isEditing={bookingEdit}
                                resources={this.getFilteredAmenities()}
                                currentBooking={this.state.currentBooking}
                                selectedDate={this.state.selectedDate}
                                filters={this.props.store.amenityFilters}
                                timezone={this.props.store.campus.timeZone}
                                resourceHeader={generateResourceHeader(t)}
                                resourcePeriod={generateResourcePeriod}
                                onTimeCellClick={this.handleTimeCellClick}
                                onBookingClick={this.handleBookingClick}
                                min={0}
                                max={24}
                            />
                        )}
                    </div>
                </ActionPane>
            </Fragment>
        );
    }
}

export { generateResourceHeader, generateResourcePeriod };
export default translate()(TimeSlotStep);
