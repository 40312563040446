import memoize from 'memoize-one';

const quarterHours = ['00', '15', '30', '45'];
const halfHours = ['00', '30'];
const zoomMinutes = [60, 30, 15];

function splitToDigit(n) {
    return [...(n + '')].map(Number);
}

export const generateTimespan = memoize(
    (min, max, zoom, showSeconds = false, showSuffix = false) => {
        const times = [];
        const minutes = zoomMinutes[zoom];
        const seconds = showSeconds ? ':00' : '';

        for (var i = min; i < max; i++) {
            let timeSuffix = showSuffix ? (i < 12 ? ' AM' : ' PM') : '';
            const minutesToUse = minutes === 30 ? halfHours : quarterHours;

            let hours = i;

            if (i > 12) {
                const splitedHours = splitToDigit(i - 2);
                if (i < 22) {
                    hours = splitedHours[1];
                } else {
                    hours = Number('1' + splitedHours[1]);
                }
            }

            for (let j = 0; j < 60 / minutes; j++) {
                times.push(hours + ':' + minutesToUse[j] + seconds + timeSuffix);
            }
        }
        return times;
    }
);
