import React from 'react';

const SvgWhiteBoard = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            fillRule="evenodd"
            d="M0 3v15.646h15.082v.955H.461L0 19.604v.924h24V19.6h-2.174v-.955h2.172V3H0zm.924.924h22.15v13.798h-1.248v-1.449h-6.744v1.45H.924v-13.8zm15.082 15.175h4.896v-1.902h-4.896v1.902z"
        />
    </svg>
);

export default SvgWhiteBoard;
