import React from 'react';
import { translate } from 'react-i18next';
import { inject } from 'mobx-react';

import {
    Campuses,
    ConferenceRoom,
    Confirmation,
    Date,
    Memberships,
    RecurringBooking,
    Bookmark,
} from 'Components/Icons';
import { ModalFlowBase } from 'Components/Modal';
import BookingStore from 'Stores/BookingStore';
import { LoadingSpinner } from 'Components/Loading';
import HttpService from 'Services/HttpService';
import BalanceStep from './BalanceStep';
import { CampusStep } from 'Components/FlowSteps';
import TimeSlotStep from './TimeSlotStep';
import ConferenceRoomStep from './ConferenceRoomStep';
import RecurringStep from './RecurringStep';
import ConfirmationStep from './ConfirmationStep';
import NoSubscription from './NoSubscription';
import Constants from 'Data/Constants';

const ICON_SIZE = '20px';
const CustomCampusStep = (props) => (
    <CampusStep
        {...props}
        subTitleKey="Booking.select_campus_booking"
        removeUnsubscribed
    />
);

@inject('uiStore', 'seedStore', 'dashboardStore')
class BookingModal extends ModalFlowBase {
    constructor(props) {
        super(props);

        const seedStore = this.props.seedStore;
        const bookingStore = new BookingStore(seedStore);

        this.flowStore = bookingStore;
        this.modalSize = 'modal-xl modal-dialog-centered modal-full-screen';
        this.modalPane = 'two-panes';
        this.paths = [];

        if (!bookingStore.campusId)
            this.paths.push({
                path: 'location',
                title: 'Campus.campus_title',
                component: CustomCampusStep,
                storeKey: 'campusId',
                completed: () => !!bookingStore.campusId,
                icon: <Campuses size={ICON_SIZE} />,
            });
        if (!bookingStore.creditBalanceId)
            this.paths.push({
                path: 'company',
                title: 'Tab.tab_membership_title',
                component: BalanceStep,
                storeKey: 'creditBalanceId',
                completed: () => !!bookingStore.creditBalanceId,
                icon: <Memberships size={ICON_SIZE} />,
            });

        this.paths.push({
            path: 'booking-availabilities',
            title: 'Booking.booking_availabilities_title',
            component: TimeSlotStep,
            storeKey: 'bookingStart',
            completed: () => !!bookingStore.bookingStart,
            icon: <Date size={ICON_SIZE} />,
        });

        // Keeping until done with flexible booking - just a reminder to remove everything related
        // if (!bookingStore.conferenceRoomId)
        //     this.paths.push({
        //         path: 'conference-room',
        //         title: 'booking.conference_room_title',
        //         component: ConferenceRoomStep,
        //         storeKey: 'conferenceRoomId',
        //         completed: () => !!bookingStore.conferenceRoomId,
        //         icon: <ConferenceRoom size={ICON_SIZE} />,
        //     });

        this.paths.push(
            // TODO
            {
                path: 'booking-details',
                title: 'Booking.booking_details_title',
                component: ConfirmationStep,
                storeKey: 'bookingDetail',
                completed: () => bookingStore.isComplete,
                icon: <Bookmark size={ICON_SIZE} />,
                accessCondition: () =>
                    bookingStore.bookingStart &&
                    bookingStore.bookingEnd &&
                    bookingStore.conferenceRoomId,
            }
            // Keeping until done with flexible booking - just a reminder to remove everything related
            // {
            //     path: 'recurring',
            //     title: 'Booking.recurring_booking',
            //     component: RecurringStep,
            //     storeKey: 'recurringStore',
            //     completed: () => bookingStore.recurringStore.isComplete,
            //     icon: <RecurringBooking size={ICON_SIZE} />,
            //     optional: true,
            //     accessCondition: () => bookingStore.recurring,
            // },
            // {
            //     path: 'confirmation',
            //     title: 'Subscription.confirmation_title',
            //     component: ConfirmationStep,
            //     completed: () => bookingStore.isComplete,
            //     icon: <Confirmation size={ICON_SIZE} />,
            // }
        );

        this.titleIcon = ConferenceRoom;
        this.modalHeaderBg = 'bg-black modal-create-booking';
        this.titleKey = 'Room.book_conference_room';
        this.modalType = Constants.EVENT_TYPE.BOOKING;

        this.campusDetail = '';

        this.flowStore.updateState('modalType', this.modalType);
        this.state = {
            loading: true,
            showLoading: false,
            hasPermission: false,
        };
        this.flowStore.setAttendees(this.props.t);
        // Uncomment to pre-populate the stores with data.
        // this.seedStores();
    }

    seedStores = async () => {
        await this.flowStore.seed();
        await this.flowStore.recurringStore.seed();
    };

    componentDidMount() {
        import('Components/Campus/CampusDetail').then((CampusDetail) =>
            this.setState({ CampusDetail: CampusDetail.default })
        );
        import('Components/Plan/PlanDetail').then((PlanDetail) =>
            this.setState({ PlanDetail: PlanDetail.default })
        );
        import('Components/Room/RoomDetail').then((RoomDetail) =>
            this.setState({ RoomDetail: RoomDetail.default })
        );
        import('WebApp/Views/Logged/Membership/AddMembers').then((AddMembers) =>
            this.setState({ AddMembers: AddMembers.default })
        );

        this.mounted = true;
        this.checkPermissions();
        this.cancellation = HttpService.cancellable();
    }

    componentWillUnmount() {
        this.cancellation.cancel();
        this.mounted = false;
    }

    checkPermissions = async () => {
        try {
            setTimeout(() => {
                if (this.state.loading && this.mounted)
                    this.setState({ showLoading: true });
            }, 200);
            const { data: campuses } = await HttpService.campuses(
                false,
                '',
                this.cancellation
            );
            const hasPermission = campuses.some((campus) => campus.isSubscribed);
            if (hasPermission) {
                this.setState({ hasPermission: true });
                this.loadFirstStep();
            }
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            const { history } = this.props;
            history.replace('/dashboard/company');
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { history } = this.props,
            { loading, hasPermission, showLoading } = this.state;

        if (loading)
            return (
                <div
                    className={
                        'd-flex flex-centered w-100 h-100 modal-backdrop fade' +
                        (showLoading ? ' show' : '')
                    }
                >
                    <LoadingSpinner loading />
                </div>
            );
        else if (hasPermission) return super.render();
        else return <NoSubscription history={history} />;
    }
}

export default translate()(BookingModal);
