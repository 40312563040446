import React, { Fragment } from 'react';

const ErrorElement = (props) => (
    <div className="text-right text-danger text-xs">{props.error}</div>
);

const Errors = (props) => (
    <Fragment>
        {props.errors &&
            props.errors.map((subErrors, i) =>
                subErrors instanceof Array ? (
                    subErrors.map((error, j) => (
                        <ErrorElement key={`${i}_${j}`} error={error} />
                    ))
                ) : (
                    <ErrorElement key={`error_${i}`} error={subErrors} />
                )
            )}
    </Fragment>
);

export default Errors;
