import React from 'react';

const Selection = ({ fill, size, style }) => (
    <svg className="icon" viewBox="-1 -1 34 34" width={size} height={size} style={style}>
        <path
            fill={fill || 'currentColor'}
            stroke={fill || 'currentColor'}
            fillRule="evenodd"
            d="M22.651 10.411s-7.058 12.293-7.18 12.409a1.002 1.002 0 0 1-1.413-.047l-4.571-4.217a1 1 0 0 1 .044-1.414 1 1 0 0 1 1.413.047l3.58 3.3 6.394-11.078a1 1 0 0 1 1.733 1M16 0C7.162 0 0 7.164 0 16c0 8.838 7.162 16 16 16 8.836 0 16-7.162 16-16 0-8.836-7.164-16-16-16"
        />
    </svg>
);

export default Selection;
