import i18n from 'i18next';
import merge from 'lodash.merge';
import en from './en.json';
import fr from './fr';
import numeral from 'numeral';
import en_cg from './en_cg.json';

i18n.init({
    resources: {
        en: merge(en, en_cg),
        fr: fr,
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react!!
        format(value, format, lng) {
            if (!Number.isNaN(value)) return numeral(value).format(format);
            return value;
        },
    },

    // react i18next special options
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
    },
});

export default i18n;
