import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'reactstrap';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import SelectableListItem from './SelectableListItem';

function handleSelection(itemId) {
    if (!this.props.multiSelect) this.props.onChange(itemId);
    else {
        // We cannot use the spread operator as this breaks observableArrays (mobx). We need to slice it to create a shallow copy.
        // src: https://mobx.js.org/refguide/array.html#array-limitations-in-mobx-4-and-below
        let array = this.props.value.slice(); // make a separate copy of the array
        const selectedItem = Object.values(array).find((i) => i === itemId);
        let index = array.indexOf(selectedItem);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(itemId);
        }
        this.props.onChange(array);
    }
}

class SelectList extends React.Component {
    constructor(props) {
        super(props);

        this.handleSelection = handleSelection.bind(this);
    }

    static defaultProps = {
        withImages: false,
        isDropDown: false,
        spaced: false,
    };

    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(SelectableListItem.propTypes))
            .isRequired,
        withImages: PropTypes.bool,
        className: PropTypes.string,
        isDropDown: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        spaced: PropTypes.bool,
        multiSelect: PropTypes.bool.isRequired,
        selectionIndicatorType: SelectableListItem.propTypes.selectionIndicatorType,
        value: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            MobxPropTypes.observableArray,
        ]).isRequired,
        tag: PropTypes.string,
    };

    render() {
        const {
            items,
            withImages,
            className,
            isDropDown,
            spaced,
            value,
            selectionIndicatorType,
            children,
            detailsLink = false,
            onClickDetail,
            tag,
        } = this.props;
        return (
            <ListGroup className={className} tag={tag}>
                {items &&
                    items.map((item, i) => (
                        <SelectableListItem
                            key={item.id || i}
                            withImage={withImages}
                            hideUnselectedCheckmark={isDropDown}
                            onClick={this.handleSelection}
                            isDropDown={isDropDown}
                            spaced={spaced}
                            detailsLink={detailsLink}
                            onClickDetail={onClickDetail}
                            selected={value.includes(item.id)}
                            selectionIndicatorType={selectionIndicatorType}
                            {...item}
                        />
                    ))}
                {children}
            </ListGroup>
        );
    }
}

export default SelectList;
export { handleSelection };
