const rad = function (x) {
    return (x * Math.PI) / 180;
};

const getDistance = function (lat1, lng1, lat2, lng2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(lat2 - lat1);
    var dLong = rad(lng2 - lng1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat1)) *
            Math.cos(rad(lat2)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

const getDistanceInMiles = function (lat1, lng1, lat2, lng2) {
    return getDistance(lat1, lng1, lat2, lng2) * 0.000621371192; //1 meter in miles constant;
};

export { getDistance, getDistanceInMiles };
