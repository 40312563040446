import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import timezone from 'moment-timezone';

import FlexibleBooking from 'WebApp/Views/Logged/Booking/FlexibleBooking/FlexibleBooking';
import BookingUtils from 'Utils/BookingUtils';
import { Constants } from 'Data/index';

@observer
class RecurrenceConflictConfirmationStep extends Component {
    state = {
        bookingError: null,
    };

    handleTimeCellClick = (e, bookingInformation, period) => {
        const { store } = this.props;
        this.setState({ bookingError: null });
        store.updateConflict(store.currentConflictId, {
            periodStart: bookingInformation.bookingStart,
            periodEnd: bookingInformation.bookingEnd,
        });
    };

    handleBookingResize = ({ formatedBookingStart, formatedBookingEnd }) => {
        const { store } = this.props;
        const currentConflict = store.currentConflict;
        const amenity = currentConflict.bookingAmenity.slice()[0];
        const start = formatedBookingStart
            ? formatedBookingStart
            : timezone(currentConflict.periodStart).tz(this.props.timezone).format();

        const bookingEnd = formatedBookingEnd
            ? formatedBookingEnd
            : timezone(currentConflict.periodEnd).tz(this.props.timezone).format();

        const bookingError = BookingUtils.checkForBookingError({
            bookingEnd,
            start,
            amenity,
            t: this.props.t,
        });
        this.setState({ bookingError });

        store.updateConflict(store.currentConflictId, {
            periodStart: start,
            periodEnd: bookingEnd,
        });
    };

    computeCreditCost = (conflict) => {
        const start = moment.parseZone(conflict.periodStart);
        const end = moment.parseZone(conflict.periodEnd);
        const duration = moment.duration(end.diff(start)).asHours();
        const amenity = conflict.bookingAmenity.slice()[0];
        return amenity.creditPointsPerBlock * duration;
    };

    render() {
        const {
            store: { currentConflict },
            t,
        } = this.props;
        const amenity = currentConflict.bookingAmenity.slice()[0];
        const creditCost = this.computeCreditCost(currentConflict);
        return (
            <div className="flex-grow-1 d-flex flex-column">
                <div className="p-3 text-center bg-white">
                    <h5 className="text-lg font-weight-bold">
                        {moment
                            .parseZone(currentConflict.periodStart)
                            .format(Constants.LONG_TEXT_DATE_YEAR_FORMAT)}
                    </h5>
                    <p className="mb-0 text-sm">{amenity.name}</p>
                </div>
                <FlexibleBooking
                    view="details"
                    className="w-100 flex-grow-1 position-relative absolute-calendar"
                    resizable
                    resources={currentConflict.bookingAmenity}
                    currentBooking={{
                        bookingStart: currentConflict.periodStart,
                        bookingEnd: currentConflict.periodEnd,
                        conferenceRoomId: currentConflict.amenityId,
                    }}
                    timezone={this.props.timezone}
                    bookingError={this.state.bookingError}
                    onTimeCellClick={this.handleTimeCellClick}
                    onBookingResize={this.handleBookingResize}
                    min={0}
                    max={24}
                    zoom={2}
                />
                <div className="pt-2">
                    <p
                        className="mb-0 text-center font-weight-medium text-sm"
                        dangerouslySetInnerHTML={{
                            __html: t('Booking.total_credit_point', {
                                param1: `
                                    <span class="h4 font-weight-bold">
                                        ${numeral(creditCost).format('0[.]00')}
                                    </span>
                                `,
                                count: creditCost,
                            }),
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default translate()(RecurrenceConflictConfirmationStep);
