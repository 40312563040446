const CG_URL = 'https://cgplus.cgworkplace.com';
const TINYMCE_API = 'e3bjvc4jy9gsel6hmbqlvfj0neyz8w8tav2e7kwrf0fmg19z';
const COMPANY_TABS = {
    DASHBOARD: 'Dashboard',
    PROFILE: 'Profile',
    TEAM: 'Team',
    JOB_POSTS: 'Job',
    BOOKINGS: 'Bookings',
    LEGAL: 'Legals',
};

const COMMUNITY_TABS = {
    DASHBOARD: 'Dashboard',
    MESSAGES: 'Message',
    JOB_POSTS: 'Job',
    EVENTS: 'Events',
    FLOOR_PLAN: 'Floor',
};

const SPECIFICATIONS = {
    EXPERTISE: 'Expertise',
    TYPE: 'Type',
};

const CAMPUS_STATUSES = {
    ACTIVE: 'Active',
    PRE_ACTIVE: 'PreActive',
    COMING_SOON: 'ComingSoon',
};

const PLAN_INTERVALS = {
    DAILY: 'Daily',
    MONTHLY: 'Monthly',
};

const PRODUCT_TYPES = {
    CREDIT_PACK: 'CreditPack',
    ADDON: 'AddOn',
    PLAN: 'Plan',
    SUBSCRIPTION: 'Subscription',
    OTHER: 'Other',
    ADJUSTMENT: 'Adjustment',
    PAYMENTMETHOD_FEES: 'PaymentMethodFees',
    SECURITY_DEPOSIT: 'SecurityDeposit',
    DISCOUNT: 'Discount',
};

const UNIT_TYPES = {
    ENTERPRISE: 'Enterprise',
    COMMUNAL: 'CommunalArea',
};

const PAYMENT_METHOD_TYPES = {
    ACH: 'ACH',
    CREDIT_CARD: 'CreditCard',
    UNAVAILABLE: 'Unavailable',
};

const ACH_STATUS = {
    UNVERIFIED: 'Unverified',
    VERIFIED: 'Verified',
    ERRORED: 'Errored',
};

const SUBSCRIPTION_TYPES = {
    QUOTE: 'Quote',
    REQUEST: 'Request',
    SUBSCRIPTION: 'Subscription',
    DAY_PASS: 'Day pass',
    EDIT_QUOTE: 'EditQuote',
    EDIT_REQUEST: 'EditRequest',
};

const MODAL_TYPE = {
    YES_NO: 'yes-no',
    CONFIRM_BUTTON: 'confirm',
    YES_NO_TRANSPARENT: 'yes-no-transparent',
    ALERT: 'alert',
};

const MODAL_FLOW_EVENT = {
    MEMBERSHIP_CREATE: 'membershipCreate',
    MEMBERSHIP_EDIT: 'membershipEdit',
    MEMBER_CREATE: 'memberCreate',
    MEMBER_EDIT: 'memberEdit',
    PROFILE: 'profile',
};

const EVENT_TYPE = {
    EMPTY: 'Empty',
    BOOKING: 'Booking',
    SUBSCRIPTION: 'Subscription',
    INVOICE: 'Invoice',
    COMMUNITYEVENT: 'CommunityEvent',
};

const EVENT_STATUS = {
    DELETED: 'Deleted',
    UPDATED: 'Updated',
};

const EVENTS = {
    SUBSCRIPTION_ADDED: 'Subscription.added',
    PAYMENTMETHOD_REMOVED: 'paymentmethod.removed',
    ALL_EVENTS_LOADED: 'all-events-loaded',
};

const INVOICE_STATUSES = {
    AWAITING_PAYMENT: 'AwaitingPayment',
    PENDING: 'Pending',
    SUCCESS: 'Success',
    FAILED: 'Failed',
    VOID: 'Void',
    REFUNDED: 'Refunded',
    DUE: 'Due',
    PRE_AUTHORIZED: 'PreAuthorized',
};

const DETAILS_TYPES = {
    CAMPUS: 'Campus',
    PLAN: 'Plan',
    ROOM: 'Room',
};

const JOB_TYPES = {
    OFFER: 'Offer',
    REQUEST: 'Request',
};

const INVITATION_STATUSES = {
    VALID: 'Valid',
    ACCOUNT_EXISTS: 'AccountExists',
};

const MEMBER_ROLES = {
    ADMINISTRATOR: 'Administrator',
    CREDIT_MANAGER: 'CreditManager',
    FINANCE_MANAGER: 'FinanceManager',
    JOB_MANAGER: 'JobManager',
    MEMBER: 'Member',
    OWNER: 'Owner',
    PENDING_MEMBER: 'PendingMember',
};

const MEMBERSHIP_RIGHTS = {
    EDIT_MEMBERS: 'EditMembers',
    EDIT_MEMBERSHIP: 'EditMembership',
    SUBSCRIBE_PLAN: 'SubscribePlan',
    FINANCE: 'Finance',
    CREDITING: 'Crediting',
    EDIT_JOB_POSTS: 'EditJobPosts',
};

const BADGE_POSITIONS = {
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
};

const MESSAGE_STATUS = {
    READ: 'Read',
    UNREAD: 'Unread',
    DELETED: 'Deleted',
};

const MESSAGE_TYPE = {
    QUOTE_CREATED: 'QuoteCreated',
    QUOTE_REFUSED: 'QuoteRefused',
    REQUEST_CREATED: 'RequestCreated',
    REQUEST_REFUSED: 'RequestRefused',
    SUBSCRIPTION_CANCELLED: 'SubscriptionCancelled',
    SUBSCRIPTION_CREATED: 'SubscriptionCreated',
    INVOICE_STATUS_AWAITING: 'InvoiceStatusAwaiting',
    INVOICE_STATUS_FAILED: 'InvoiceStatusFailed',
    INVOICE_STATUS_PAID: 'InvoiceStatusPaid',
    INVOICE_STATUS_VOID: 'InvoiceStatusVoid',
    INVOICE_STATUS_REFUNDED: 'InvoiceStatusRefunded',
    MEMBERSHIP_INVITATION: 'MembershipInvitation',
    RECURRENCE_ERRORS: 'RecurrenceErrors',
    ONE_ON_ONE: 'OneOnOne',
    WELCOME: 'Welcome',
};

const BOOKING_STATUS = {
    RESERVED: 'Reserved',
    BOOKED: 'Booked',
    ERRORED: 'Errored',
};

const NOTIFICATION_TYPE = {
    MESSAGE: 'Message',
    PROPOSAL: 'Subscription',
    INVITATION: 'Membership',
    INVOICE: 'Invoice',
    RECURRENCE: 'Recurrence',
    BOOKING_CONFLICT: 'Conflict',
};

const SELECTION_INDICATOR_TYPE = {
    CHECKMARK: 'checkmark',
    RADIO: 'radio',
    NONE: 'none',
};

const AMENITY_STATUSES = {
    AVAILABLE: 'Available',
    UNAVAILABLE: 'Unavailable',
    BOOKED: 'Booked',
    TOTAL_LIMIT: 'TotalLimit',
    CONSECUTIVE_LIMIT: 'ConsecutiveLimit',
};

const CONFLICT_REASONS = {
    CONFLICT: 'Conflict',
    CREDIT: 'Credit',
    CONTINUOUSLY: 'Continuously',
    LIMIT_REACHED: 'LimitReached',
};

const TOAST_TYPE = {
    DEFAULT: 'Default',
    PERSISTENT: 'Persistent',
};

const HOLDER_TYPE = {
    PERSONAL: 'Personal',
    MEMBERSHIP: 'Membership',
};

const SETTINGS_TYPE = {
    CALENDAR: 'Calendar',
    EMAIL: 'Email',
    NOTIFICATION: 'Notification',
    QUICKACTION: 'QuickAction',
};

const SETTINGS_CATEGORIES = {
    INVOICE: 'Invoice',
    COMMUNITY_EVENT: 'CommunityEvent',
    SUBSCRIPTION: 'Subscription',
    BOOKING: 'Booking',
    OWN_BOOKINGS_ONLY: 'OwnBookingsOnly',
};
const SETTINGS_TABS = {
    COMMUNICATION: 'correspondence_title',
};
const INDUSTRIES = {
    Agriculture: "agriculture",
    Apparel: "apparel",
    Banking: "banking",
    Biotechnology: "biotechnology",
    Chemicals: "chemicals",
    Communications: "communications",
    Construction: "construction",
    Consulting: "consulting",
    Education: "education",
    Electronics: "electronics",
    Energy: "energy",
    Engineering: "engineering",
    Entertainment: "entertainment",
    Environmental: "environmental",
    Finance: "finance",
    FoodAndBeverage: "food_beverage",
    Government: "government",
    Healthcare: "healthcare",
    Hospitality: "hospitality",
    Insurance: "insurance",
    Machinery: "machinery",
    Manufacturing: "manufacturing",
    Marketing: "marketing",
    Media: "media",
    NotForProfit: "not_for_profit",
    OilAndGas: "oil_gas",
    Other: "other",
    RealEstate: "real_estate",
    Recreation: "recreation",
    Retail: "retail",
    Shipping: "shipping",
    Technology: "technology",
    Telecommunications: "telecommunications",
    Transportation: "transportation",
    Utilities: "utilities",
}

export default {
    CG_URL,
    TINYMCE_API,
    DATE_FORMAT: 'MM/DD/YYYY',
    PERIOD_DATE_FORMAT: 'LL',
    CREDIT_DATE_FORMAT: 'MM/YYYY',
    TEXT_DATE_EVENT_FORMAT: 'MMMM YYYY',
    TEXT_DATE_EVENT_LIST_ORDER_FORMAT: 'YYYY MM',
    TEXT_MONTH_FORMAT: 'MMMM',
    TEXT_DATE_FORMAT: 'MMMM D',
    TEXT_DATE_HISTORY_BOOKING_FORMAT: 'MMMM D - YYYY',
    LONG_TEXT_DATE_FORMAT: 'dddd MMMM D',
    LONG_TEXT_DATE_YEAR_FORMAT: 'dddd MMMM D, YYYY',
    TIME_FORMAT: 'hh:mm A',
    SHORT_TIME_FORMAT: 'h:mm A',
    MONEY_FORMAT: '$0,0.00',
    DEFAULT_VISIBLE_LIST_ITEMS: 4,
    DEFAULT_PAGE_SIZE: 50,
    MINIMUM_CANCEL_DELAY_MINUTES: 1440,
    RADIO_SELECT_TRANSITION_DELAY: 200,
    HOLDER_TYPE: Object.assign(Object.values(HOLDER_TYPE), HOLDER_TYPE),
    RECURRING_PERSONAL: 'personal_recurring',
    RECURRING_NO_REPEAT: 'Does not repeat',
    COMPANY_TABS: Object.assign(Object.values(COMPANY_TABS), COMPANY_TABS),
    COMMUNITY_TABS: Object.assign(Object.values(COMMUNITY_TABS), COMMUNITY_TABS),
    SPECIFICATIONS: Object.assign(Object.values(SPECIFICATIONS), SPECIFICATIONS),
    CAMPUS_STATUSES: Object.assign(Object.values(CAMPUS_STATUSES), CAMPUS_STATUSES),
    PLAN_INTERVALS: Object.assign(Object.values(PLAN_INTERVALS), PLAN_INTERVALS),
    PRODUCT_TYPES: Object.assign(Object.values(PRODUCT_TYPES), PRODUCT_TYPES),
    UNIT_TYPES: Object.assign(Object.values(UNIT_TYPES), UNIT_TYPES),
    DEFAULT_CURRENCY: 'US',
    PAYMENT_METHOD_TYPES: Object.assign(
        Object.values(PAYMENT_METHOD_TYPES),
        PAYMENT_METHOD_TYPES
    ),
    ACH_STATUS: Object.assign(Object.values(ACH_STATUS), ACH_STATUS),
    SUBSCRIPTION_TYPES: Object.assign(
        Object.values(SUBSCRIPTION_TYPES),
        SUBSCRIPTION_TYPES
    ),
    MODAL_TYPE: Object.assign(Object.values(MODAL_TYPE), MODAL_TYPE),
    MODAL_FLOW_EVENT: Object.assign(Object.values(MODAL_FLOW_EVENT), MODAL_FLOW_EVENT),

    INVOICE_STATUSES: Object.assign(Object.values(INVOICE_STATUSES), INVOICE_STATUSES),
    JOB_TYPES: Object.assign(Object.values(JOB_TYPES), JOB_TYPES),
    DETAILS_TYPES: Object.assign(Object.values(DETAILS_TYPES), DETAILS_TYPES),
    INVITATION_STATUSES: Object.assign(
        Object.values(INVITATION_STATUSES),
        INVITATION_STATUSES
    ),
    EVENT_TYPE: Object.assign(Object.values(EVENT_TYPE), EVENT_TYPE),
    EVENT_STATUS: Object.assign(Object.values(EVENT_STATUS), EVENT_STATUS),
    EVENTS,
    MEMBER_ROLES: Object.assign(Object.values(MEMBER_ROLES), MEMBER_ROLES),
    MEMBERSHIP_RIGHTS: Object.assign(Object.values(MEMBERSHIP_RIGHTS), MEMBERSHIP_RIGHTS),
    BADGE_POSITIONS: Object.assign(Object.values(BADGE_POSITIONS), BADGE_POSITIONS),
    MESSAGE_STATUS: Object.assign(Object.values(MESSAGE_STATUS), MESSAGE_STATUS),
    MESSAGE_TYPE: Object.assign(Object.values(MESSAGE_TYPE), MESSAGE_TYPE),
    BOOKING_STATUS: Object.assign(Object.values(BOOKING_STATUS), BOOKING_STATUS),
    NOTIFICATION_TYPE: Object.assign(Object.values(NOTIFICATION_TYPE), NOTIFICATION_TYPE),
    SELECTION_INDICATOR_TYPE: Object.assign(
        Object.values(SELECTION_INDICATOR_TYPE),
        SELECTION_INDICATOR_TYPE
    ),
    AMENITY_STATUSES: Object.assign(Object.values(AMENITY_STATUSES), AMENITY_STATUSES),
    CONFLICT_REASONS: Object.assign(Object.values(CONFLICT_REASONS), CONFLICT_REASONS),
    TOAST_TYPE: Object.assign(Object.values(TOAST_TYPE), TOAST_TYPE),
    SETTINGS_TYPE: Object.assign(Object.values(SETTINGS_TYPE), SETTINGS_TYPE),
    SETTINGS_CATEGORIES: Object.assign(
        Object.values(SETTINGS_CATEGORIES),
        SETTINGS_CATEGORIES
    ),
    SETTINGS_TABS: Object.assign(Object.values(SETTINGS_TABS), SETTINGS_TABS),
    INDUSTRIES
};
