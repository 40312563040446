import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import Constants from 'Data/Constants';

import { Errors, FormLabel, DateFormGroup } from './';

class FormGroup extends React.PureComponent {
    static defaultProps = {
        type: 'text',
        required: false,
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        type: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        errors: PropTypes.any,
        maxlength: PropTypes.number,
        minlength: PropTypes.number,
        placeholder: PropTypes.string,
        mask: PropTypes.string,
        maskChar: PropTypes.string,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.isRequired,
                resourceKey: PropTypes.string,
                text: PropTypes.string,
            })
        ),
        value: PropTypes.any,
    };

    renderInput() {
        const {
            id,
            name,
            type,
            value,
            onChange,
            required,
            minlength,
            maxlength,
            placeholder,
            mask,
            maskChar,
            values,
            t,
            autoFocus = false,
        } = this.props;

        return mask ? (
            <Input
                key="input"
                id={id}
                name={name || id}
                value={value || ''}
                onChange={onChange}
                type={type}
                minLength={minlength}
                maxLength={maxlength}
                required={required}
                placeholder={placeholder}
                mask={mask}
                autoFocus={autoFocus}
                maskChar={maskChar}
                tag={InputMask}
            />
        ) : type === 'Editor' ? (
            <Editor
                initialValue={value || ''}
                value={value || ''}
                apiKey={Constants.TINYMCE_API}
                init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                    ],
                    toolbar:
                        'formatselect | bold italic superscript subscript underline strikethrough link | \
                            alignleft aligncenter alignright | \
                            bullist numlist',
                }}
                onChange={onChange}
            />
        ) : (
            <Input
                key="input"
                id={id}
                name={name || id}
                value={value || ''}
                onChange={onChange}
                type={type}
                minLength={minlength}
                maxLength={maxlength}
                required={required}
                autoFocus={autoFocus}
                placeholder={placeholder}
                className={type === 'select' ? 'custom-select' : ''}
            >
                {values &&
                    values.map((entry) => (
                        <option key={entry.value} value={entry.value}>
                            {entry.text || t(entry.resourceKey) || entry.value}
                        </option>
                    ))}
            </Input>
        );
    }

    render() {
        const {
            type,
            id,
            displayName,
            className,
            required,
            labelClassName,
            onChange,
        } = this.props;

        switch (type) {
            case 'date':
                return <DateFormGroup {...this.props} />;
            default:
                const { errors } = this.props;
                const displayedErrors = errors && (errors.length ? errors : errors[id]);
                return (
                    <div className={'form-group ' + (className || '')}>
                        <FormLabel
                            required={required}
                            id={id}
                            displayName={displayName}
                            className={labelClassName}
                        />
                        {this.renderInput()}
                        {displayedErrors && (
                            <Errors key="errors" errors={displayedErrors} />
                        )}
                    </div>
                );
        }
    }
}

export default translate()(FormGroup);
