import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { MyBookings } from 'Components/Icons';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import BookingList from './BookingList';
import BookingDetail from './BookingDetail';
import ScrollUtils from 'Utils/ScrollUtils';
import { inject, observer } from 'mobx-react';
import LoadingStore from 'Stores/UI/LoadingStore';
import HttpService from 'Services/HttpService';
import { observable } from 'mobx';

@inject('dashboardStore', 'uiStore')
@observer
class BookingHistory extends React.Component {
    scrollUtils = new ScrollUtils();
    @observable localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);

    constructor(props) {
        super(props);
        this.state = {
            bookings: { ongoing: true },
            bookingId: null,
        };
    }

    componentDidMount() {
        this.loadBookings();
        this.scrollUtils.scrollToRef();
    }

    updateSelectedBooking = (selectedBookingId) => {
        this.setState({ active: selectedBookingId });
    };

    loadBookings = async () => {
        const { membershipId } = this.props;
        this.cancellation = HttpService.cancellable();
        this.localLoadingStore.addLoading();
        const request = {
            pastBookingsOnly: true,
        };
        try {
            const { data } = await HttpService.bookingsMembership(
                { id: membershipId, request },
                this.cancellation
            );
            this.setState({ bookings: { data: data, ongoing: false } });
            this.openFirstBooking();
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError))
                this.setState({ bookings: { ongoing: false } });
        } finally {
            this.localLoadingStore.removeLoading();
        }
    };

    openFirstBooking = () => {
        const { bookingId } = this.props,
            { bookings } = this.state;
        this.setState({ active: bookingId || bookings.data[0].id });
    };

    render() {
        const { matchUrl, membershipName, t } = this.props,
            { bookings, active } = this.state;
        return (
            <Fragment>
                {bookings && bookings.data && bookings.data.length && active ? (
                    <Fragment>
                        <div className="col-md-6 position-relative">
                            <div
                                className="position-absolute"
                                id="infinite-list"
                                ref={this.scrollUtils.registerRef}
                            >
                                <BookingList
                                    matchUrl={matchUrl}
                                    membershipName={membershipName}
                                    headerClassName="mt-2"
                                    clickable
                                    bookings={bookings}
                                    bookingHistory
                                    onClick={this.updateSelectedBooking}
                                    currentId={active}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <BookingDetail
                                {...this.props}
                                onBookingUpdate={this.loadBookings}
                                paneDetail={true}
                                matchUrl={matchUrl}
                                bookingHistory
                                parentMatchURL={matchUrl}
                                bookingId={active}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <div className="col-12">
                        <NoResults
                            className="m-auto py-3 w-100 h-auto"
                            icon={<MyBookings size="3.75em" />}
                            title={t('Booking.booking_no_booking')}
                            subTitle={t('Booking.booking_no_booking_text')}
                        />
                    </div>
                )}
                <LoadingSpinner loading={this.localLoadingStore.isLoading} />
            </Fragment>
        );
    }
}

export default translate()(BookingHistory);
