import React from 'react';

const PrivateRoom = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
        viewBox="0 0 20 20"
    >
        <g
            transform="translate(-.476 -.476) scale(1.04762)"
            fill="none"
            fillRule="evenodd"
        >
            <circle cx={10} cy={10} r={9.091} fill={fill} />
            <path
                d="M10 19.545a9.545 9.545 0 110-19.09 9.545 9.545 0 010 19.09zm0-.909a8.636 8.636 0 100-17.272 8.636 8.636 0 000 17.272z"
                fill="#fff"
                fillRule="nonzero"
            />
            <path
                d="M12.34 7.795c0 1.075-.724 1.98-1.71 2.253l1.35 4.497H8.02l1.35-4.497A2.34 2.34 0 0110 5.455a2.34 2.34 0 012.34 2.34z"
                fill="#fff"
            />
        </g>
    </svg>
);

export default PrivateRoom;
