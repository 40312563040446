import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import HttpService from 'Services/HttpService';
import { Constants } from 'Data/';
import {
    Arrow,
    CGMessages,
    NotificationBadge,
    Calendar,
    JobPostIcon,
} from 'Components/Icons';
import { ListItem } from 'Components/Forms';
import MessageIcon from 'Components/Message/MessageIcon';
import moment from 'moment';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import ScrollUtils from 'Utils/ScrollUtils';
import LoadingStore from 'Stores/UI/LoadingStore';
import EventListItem from 'Components/Event/EventListItem';
import memoize from 'memoize-one';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { SegmentHeader } from 'Components/Dashboard';
import PersonalMessages from 'WebApp/Views/Logged/Message/PersonalMessages';
import JobPosts from 'Components/Membership/JobPosts';
import FloorPlan from 'Components/Membership/FloorPlan';
import qs from 'qs';
import Membership from 'Assets/Icons/membership.svg';
import { Image } from 'Components/Misc';
import MemberCircle from 'Assets/Icons/member-circle.svg';
import EventGrid from 'WebApp/Views/Logged/Event/EventGrid';
import { autorun } from 'mobx';

const { DEFAULT_VISIBLE_LIST_ITEMS } = Constants;

@inject('uiStore', 'dashboardStore', 'floorPlanStore')
@observer
class CommunityDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: Constants.COMMUNITY_TABS.DASHBOARD,
            currentMessage: null,
            currentEvent: null,
            currentPeriodStart: null,
            currentJob: null,
            messages: [],
            events: [],
            jobPosts: [],
        };
    }

    toggle = (tab, id, periodStart) => () => {
        if (this.state.activeTab !== tab) {
            switch (tab) {
                case Constants.COMMUNITY_TABS.MESSAGES:
                    this.setState({
                        currentMessage: id,
                    });
                    break;
                case Constants.COMMUNITY_TABS.EVENTS:
                    this.setState({
                        currentEvent: id,
                        currentPeriodStart: periodStart,
                    });
                    break;
                case Constants.COMMUNITY_TABS.JOB_POSTS:
                    this.setState({
                        currentJob: id,
                    });
                    break;
                case Constants.COMMUNITY_TABS.FLOOR_PLAN:
                    break;
                default:
                    this.setState({
                        activeTab: tab,
                    });
            }
            this.setState({
                activeTab: tab,
            });
        }
    };

    scrollUtils = new ScrollUtils();
    messagesLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);
    eventsLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);
    jobsLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);

    componentDidMount() {
        const { dashboardStore } = this.props;
        dashboardStore.clearEvents();
        this.loadMessages();
        this.loadEvents();
        this.loadJobPosts();
        this.floorPlanStoreReactionDisposer = autorun(this.verifyFloorPlanStoreChanges);
    }

    componentWillUnmount() {
        if (this.floorPlanStoreReactionDisposer) {
            this.floorPlanStoreReactionDisposer();
        }
    }

    verifyFloorPlanStoreChanges = () => {
        const { floorPlanStore } = this.props;
        if (floorPlanStore.showCompanyOnFloorPlan === true) {
            this.setState({
                activeTab: Constants.COMMUNITY_TABS.FLOOR_PLAN,
            });
            floorPlanStore.updateState('showCompanyOnFloorPlan', false);
        }
    };

    loadMessages = async () => {
        this.cancellation = HttpService.cancellable();
        this.messagesLoadingStore.addLoading();
        try {
            const { data } = await HttpService.messages(
                {
                    forManagers: false,
                },
                this.cancellation
            );
            this.setState({ messages: data.slice(0, DEFAULT_VISIBLE_LIST_ITEMS) });
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;
        } finally {
            this.scrollUtils.scrollToRef();
            this.messagesLoadingStore.removeLoading();
        }
    };

    loadEvents = async () => {
        this.cancellation = HttpService.cancellable();
        this.eventsLoadingStore.addLoading();
        try {
            const { data } = await HttpService.communityEvents(
                {
                    includePastEvents: false,
                },
                this.cancellation
            );
            this.setState({ events: data.items.slice(0, DEFAULT_VISIBLE_LIST_ITEMS) });
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;
        } finally {
            this.eventsLoadingStore.removeLoading();
        }
    };

    loadJobPosts = async () => {
        this.cancellation = HttpService.cancellable();
        this.jobsLoadingStore.addLoading();
        const request = {
            request: {},
        };
        try {
            const { data } = await HttpService.jobPosts(
                qs.stringify(request),
                this.cancellation
            );
            this.setState({ jobPosts: data });
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;
        } finally {
            this.jobsLoadingStore.removeLoading();
        }
    };

    mapEvents = memoize((events) =>
        events.map((event) => ({
            ...event,
            name: event.title,
            eventType: Constants.EVENT_TYPE.COMMUNITYEVENT,
            communityEventDetails: {
                isAllMonth: event.isAllMonth,
                isAllDay: event.isAllDay,
            },
        }))
    );

    render() {
        const {
                t,
                dashboardStore: { profile },
            } = this.props,
            {
                messages,
                events,
                jobPosts,
                currentMessage,
                currentJob,
                currentEvent,
                currentPeriodStart,
            } = this.state;

        const communityEvents = events ? this.mapEvents(events) : [];
        //#TODO Remove after Corenet
        const onlyCorenet =
            profile &&
            profile.data &&
            profile.data.hasOwnProperty('coreNetStatus') &&
            profile.data.coreNetStatus === 'CoreNetOnly';

        const { COMMUNITY_TABS } = Constants;
        return (
            <div className="row grid mb-2">
                <div className="col-12 mb-1" ref={this.scrollUtils.registerRef}>
                    <div className="bg-dark p-7 text-center">
                        <h5 className="text-uppercase m-0">
                            {t('Tab.tab_community_title')}
                        </h5>
                    </div>
                    <div className="bg-white text-black d-flex text-xs justify-content-around ">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            COMMUNITY_TABS.DASHBOARD,
                                    })}
                                    onClick={this.toggle(COMMUNITY_TABS.DASHBOARD)}
                                >
                                    {t('Profile.profile_finance_title')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            COMMUNITY_TABS.MESSAGES,
                                    })}
                                    onClick={this.toggle(COMMUNITY_TABS.MESSAGES)}
                                >
                                    {t('Tab.tab_notifications_title')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            COMMUNITY_TABS.EVENTS,
                                    })}
                                    onClick={this.toggle(COMMUNITY_TABS.EVENTS)}
                                >
                                    {t('Community.events_title')}
                                </NavLink>
                            </NavItem>
                            {!onlyCorenet && (
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                COMMUNITY_TABS.JOB_POSTS,
                                        })}
                                        onClick={this.toggle(COMMUNITY_TABS.JOB_POSTS)}
                                    >
                                        {t('Community.job_post_title')}
                                    </NavLink>
                                </NavItem>
                            )}
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            COMMUNITY_TABS.FLOOR_PLAN,
                                    })}
                                    onClick={this.toggle(COMMUNITY_TABS.FLOOR_PLAN)}
                                >
                                    {t('FloorPlan.floorplan')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <TabContent className="col-12 p-0" activeTab={this.state.activeTab}>
                    <TabPane tabId={Constants.COMPANY_TABS.DASHBOARD}>
                        <div className="d-flex flex-wrap row-eq-height">
                            <div className="w-50 px-1">
                                <div className="position-relative">
                                    <SegmentHeader
                                        IconComponent={CGMessages}
                                        text={t('Tab.tab_notifications_title')}
                                    />
                                    {messages && messages.length ? (
                                        <div className="list-group">
                                            {messages.map((message, index) => (
                                                <ListItem
                                                    key={index}
                                                    id={message}
                                                    title={message.title}
                                                    subTitle={message.body}
                                                    className={
                                                        message.id === currentMessage
                                                            ? 'active-item'
                                                            : null
                                                    }
                                                    extraTextChildren={
                                                        <div className="super-title text-xs text-warm-grey font-weight-medium">
                                                            {moment(message.date).format(
                                                                Constants.DATE_FORMAT
                                                            )}
                                                        </div>
                                                    }
                                                    withImage={true}
                                                    defaultImg={
                                                        <Fragment>
                                                            <MessageIcon
                                                                messageType={message.type}
                                                                inCircle
                                                                circleClassName="float-left"
                                                            />
                                                            <div className="pl-1 pt-1 position-relative float-left">
                                                                {message.status ===
                                                                    Constants
                                                                        .MESSAGE_STATUS
                                                                        .UNREAD && (
                                                                    <NotificationBadge className="bg-secondary top-right" />
                                                                )}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    action={
                                                        <Arrow
                                                            size="1.5em"
                                                            className="text-primary"
                                                        />
                                                    }
                                                    clickable
                                                    onClick={this.toggle(
                                                        COMMUNITY_TABS.MESSAGES,
                                                        message.id
                                                    )}
                                                />
                                            ))}
                                            <div className="list-group-very-compact ">
                                                <div className="bg-white list-group-item list-more">
                                                    <button
                                                        className="btn btn-primary btn-sm  d-block w-75 mx-auto"
                                                        onClick={this.toggle(
                                                            COMMUNITY_TABS.MESSAGES
                                                        )}
                                                    >
                                                        {t('show_more')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoResults
                                            className="m-auto p-1 bg-white"
                                            icon={<CGMessages size="3.75em" />}
                                            title={t('Message.no_messages')}
                                            subTitle={t(
                                                'message.no_messages_description'
                                            )}
                                        />
                                    )}
                                    <LoadingSpinner
                                        loading={this.messagesLoadingStore.isLoading}
                                    />
                                </div>
                            </div>
                            <div className="w-50 px-1">
                                <div className="position-relative">
                                    <SegmentHeader
                                        IconComponent={Calendar}
                                        text={t('Community.events_title')}
                                    />
                                    {communityEvents && communityEvents.length ? (
                                        <div className="list-group">
                                            {communityEvents.map((event, index) => (
                                                <EventListItem
                                                    event={event}
                                                    onClick={this.toggle(
                                                        COMMUNITY_TABS.EVENTS,
                                                        event.id,
                                                        event.periodStart
                                                    )}
                                                    key={event.id}
                                                    communityEvents
                                                />
                                            ))}
                                            <div className="list-group-very-compact ">
                                                <div className="bg-white list-group-item list-more">
                                                    <button
                                                        className="btn btn-primary btn-sm  d-block w-75 mx-auto"
                                                        onClick={this.toggle(
                                                            COMMUNITY_TABS.EVENTS
                                                        )}
                                                    >
                                                        {t('show_more')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoResults
                                            className="m-auto p-1 bg-white"
                                            icon={<Calendar size="3.75em" />}
                                            title={t('Home.no_event')}
                                            subTitle={t('Home.no_event_text')}
                                        />
                                    )}
                                    <LoadingSpinner
                                        loading={this.eventsLoadingStore.isLoading}
                                    />
                                </div>
                            </div>
                            {!onlyCorenet && (
                                <div className="w-50 px-1">
                                    <div className="position-relative">
                                        <SegmentHeader
                                            className="mt-1"
                                            IconComponent={JobPostIcon}
                                            text={t('Community.job_post_title')}
                                        />
                                        {jobPosts &&
                                        jobPosts.items &&
                                        jobPosts.items.length ? (
                                            <div className="list-group">
                                                {jobPosts.items
                                                    .slice(0, DEFAULT_VISIBLE_LIST_ITEMS)
                                                    .map((jobPost) => (
                                                        <ListItem
                                                            key={jobPost.id}
                                                            id={jobPost.id}
                                                            withImage
                                                            className={
                                                                jobPost.id === currentJob
                                                                    ? 'active-item'
                                                                    : null
                                                            }
                                                            clickable
                                                            onClick={this.toggle(
                                                                COMMUNITY_TABS.JOB_POSTS,
                                                                jobPost.id
                                                            )}
                                                            defaultImgSrc={Membership}
                                                            title={jobPost.title}
                                                            subTitle={
                                                                <Fragment>
                                                                    <span className="rounded-picture-list">
                                                                        <span className="rounded-picture-list-item">
                                                                            <Image
                                                                                imgSrc={
                                                                                    jobPost
                                                                                        .userCreated
                                                                                        .imageUrl
                                                                                }
                                                                                defaultImgSrc={
                                                                                    MemberCircle
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </span>
                                                                    <span>{` ${t('by')} ${
                                                                        jobPost
                                                                            .userCreated
                                                                            .firstName
                                                                    } ${
                                                                        jobPost
                                                                            .userCreated
                                                                            .lastName
                                                                    }`}</span>
                                                                </Fragment>
                                                            }
                                                            imgSrc={
                                                                jobPost.membership
                                                                    .imageUrl
                                                            }
                                                            action={
                                                                <Arrow
                                                                    size="1.5em"
                                                                    className="text-primary"
                                                                />
                                                            }
                                                        />
                                                    ))}
                                                <div className="list-group-very-compact ">
                                                    <div className="bg-white list-group-item list-more">
                                                        <button
                                                            className="btn btn-primary btn-sm  d-block w-75 mx-auto"
                                                            onClick={this.toggle(
                                                                COMMUNITY_TABS.JOB_POSTS
                                                            )}
                                                        >
                                                            {t('show_more')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <NoResults
                                                className="m-auto p-1 bg-white"
                                                icon={<JobPostIcon size="3.75em" />}
                                                title={t('JobBoard.empty_job_post_title')}
                                                subTitle={t(
                                                    'jobBoard.empty_job_post_message'
                                                )}
                                            />
                                        )}
                                        <LoadingSpinner
                                            loading={this.jobsLoadingStore.isLoading}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabPane>
                    <TabPane className="mx-1" tabId={COMMUNITY_TABS.MESSAGES}>
                        {this.state.activeTab === COMMUNITY_TABS.MESSAGES && (
                            <PersonalMessages
                                {...this.props}
                                currentId={currentMessage}
                            />
                        )}
                    </TabPane>
                    <TabPane tabId={COMMUNITY_TABS.EVENTS}>
                        {this.state.activeTab === COMMUNITY_TABS.EVENTS && (
                            <EventGrid
                                {...this.props}
                                communityEvents
                                currentPeriodStart={currentPeriodStart}
                                currentId={currentEvent}
                                currentType={Constants.EVENT_TYPE.COMMUNITYEVENT}
                            />
                        )}
                    </TabPane>
                    {!onlyCorenet && (
                        <TabPane tabId={COMMUNITY_TABS.JOB_POSTS}>
                            {this.state.activeTab === COMMUNITY_TABS.JOB_POSTS && (
                                <div className="col-12">
                                    <div
                                        className="row no-gutters position-relative"
                                        id="list-page"
                                    >
                                        <JobPosts
                                            currentId={currentJob}
                                            activeTab={COMMUNITY_TABS.JOB_POSTS}
                                        />
                                    </div>
                                </div>
                            )}
                        </TabPane>
                    )}
                    <TabPane tabId={COMMUNITY_TABS.FLOOR_PLAN}>
                        {this.state.activeTab === COMMUNITY_TABS.FLOOR_PLAN && (
                            <div className="col-12">
                                <div
                                    className="row no-gutters position-relative"
                                    id="list-page"
                                >
                                    <FloorPlan
                                        campusId={this.props.floorPlanStore.campusId}
                                        unitId={this.props.floorPlanStore.unitId}
                                    />
                                </div>
                            </div>
                        )}
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default translate()(CommunityDashboard);
