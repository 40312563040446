import React from 'react';

const Next = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
        viewBox="0 0 24 24"
    >
        <path
            d="M1.04 12.586c0-.625.497-1.122 1.122-1.122h.016l17.954.016-6.38-6.444a1.137 1.137 0 01.016-1.603 1.13 1.13 0 01.801-.337c.289 0 .577.112.802.337l8.255 8.336c.065.064.129.128.177.208a1.124 1.124 0 01-.145 1.443l-8.207 8.255c-.433.433-1.154.433-1.603 0a1.122 1.122 0 010-1.603l6.284-6.316-17.97-.032a1.119 1.119 0 01-1.122-1.138"
            fill={fill || 'currentColor'}
            fillRule="evenodd"
        />
    </svg>
);

export default Next;
