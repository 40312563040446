const sortBy = function (object, key, type) {
    if (type) {
        return object.sort((a, b) => {
            if (a[key] && b[key]) {
                if (a[key] > b[key]) return 1;
                else if (a[key] < b[key]) return -1;
                else return 0;
            } else {
                return 0;
            }
        });
    } else {
        return object.sort((a, b) => {
            // sort by name
            if (a[key] && b[key]) {
                if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
                else if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
                else return 0;
            } else {
                return 0;
            }
        });
    }
};

const isEmpty = function (object) {
    for (let key in object) {
        //if the value is 'object'
        if (object[key] instanceof Object === true) {
            if (isEmpty(object[key]) === false) return false;
        }
        //if value is string/number
        else {
            //if array or string have length is not 0.
            if (!!object[key]) return false;
        }
    }
    return true;
};

export { sortBy, isEmpty };
