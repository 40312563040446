import React, { Fragment, PureComponent } from 'react';

import { Constants } from 'Data/';
import { translate } from 'react-i18next';
import EventDateGroup from './EventDateGroup';
import { ListItemHeader } from 'Components/Forms';

class EventMonthGroup extends PureComponent {
    render() {
        const { currentMonth, t, onClick, currentId, currentPeriodStart } = this.props;

        return (
            <Fragment>
                <div className="eventList">
                    <div className="bg-white p-2">
                        <ListItemHeader
                            superTitle={t('Home.my_event_for_title')}
                            title={currentMonth.momentDate.format(
                                Constants.TEXT_DATE_EVENT_FORMAT
                            )}
                        />
                    </div>
                </div>
                {Object.keys(currentMonth)
                    .filter((day) => day !== 'momentDate')
                    .map((day) => {
                        return (
                            <div key={day} className="eventList">
                                <EventDateGroup
                                    currentDate={currentMonth[day]}
                                    onClick={onClick}
                                    currentId={currentId}
                                    currentPeriodStart={currentPeriodStart}
                                />
                            </div>
                        );
                    })}
            </Fragment>
        );
    }
}

export default translate()(EventMonthGroup);
