import React, { Component, Fragment } from 'react';
import { X } from 'Components/Icons';
import FlowStep from './FlowStep';
import FlowComponent from './FlowComponent';
import { Modal, ModalBody } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import NoMatch from 'WebApp/Views/NoMatch';
import Constants from 'Data/Constants';
import classnames from 'classnames';
import { isEmpty } from 'Utils/ObjectUtils';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

import AccountValidation from 'WebApp/Views/Anonymous/AccountValidation';

//IMPORTANT: See ModalFlowBaseREADME.md before using.
class ModalFlowBase extends Component {
    componentDidMount() {
        if (this.modalType !== Constants.MODAL_FLOW_EVENT.MEMBER_CREATE) {
            import('Components/Campus/CampusDetail').then((CampusDetail) =>
                this.setState({ CampusDetail: CampusDetail.default })
            );
            import('Components/Plan/PlanDetail').then((PlanDetail) =>
                this.setState({ PlanDetail: PlanDetail.default })
            );
            import('Components/Room/RoomDetail').then((RoomDetail) =>
                this.setState({ RoomDetail: RoomDetail.default })
            );
            import('WebApp/Views/Logged/Membership/AddMembers').then((AddMembers) =>
                this.setState({ AddMembers: AddMembers.default })
            );
        }
        this.loadFirstStep();
    }

    state = {
        accountConfirmation: false,
        campusDetails: false,
        attendees: null,
        campusId: null,
        planDetails: false,
        planId: null,
        roomDetails: false,
        attendeesStep: false,
        roomId: null,
        touched: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { location: prevLocation } = prevProps,
            { location, match, history } = this.props;
        // The user has clicked back to the original path of the flow (which would render a 404), go back
        // to where the user was instead.
        if (
            prevLocation.pathname !== location.pathname &&
            location.pathname === match.url
        ) {
            history.goBack();
        }
    }

    loadFirstStep = () => {
        if (this.paths[this.paths.length - 1].optional) {
            console.error('ModalFlowBase: Last path cannot be optional.');
            alert('ModalFlowBase: Last path cannot be optional.');
        }
        const { history, location, match } = this.props;
        if (location.pathname === match.url) {
            history.push(`${match.url}/${this.paths[0].path}`);
        }
    };

    completeStep = (stepKey, value) => {
        const step = this.paths.find((step) => step.path === stepKey),
            indexOfStep = this.paths.indexOf(step);

        if (stepKey === this.paths[this.paths.length - 1].path) {
            this.close(null, true);
            return;
        }

        if (value !== null) {
            UpdateStateStoreUtils(this.flowStore, step.storeKey, value);
        }

        let nextStep = this.paths[indexOfStep + 1];

        let nextStepIndex = indexOfStep + 2;
        while (
            nextStep.optional &&
            !nextStep.accessCondition() &&
            nextStepIndex < this.paths.length
        ) {
            nextStep = this.paths[nextStepIndex++];
        }

        const nextStepUrl = `${this.props.match.url}/${nextStep.path}`;
        this.props.history.push(nextStepUrl);
    };

    renderDetails = (type, id) => {
        switch (type) {
            case Constants.DETAILS_TYPES.CAMPUS:
                this.setState({
                    campusDetails: true,
                    campusId: id,
                });
                break;
            case Constants.DETAILS_TYPES.PLAN:
                this.setState({
                    planDetails: true,
                    planId: id,
                });
                break;
            case Constants.DETAILS_TYPES.ROOM:
                this.setState({
                    roomDetails: true,
                    roomId: id,
                });
                break;
            default:
                return null;
        }
    };

    toggleAccountConfirmation = () => {
        this.flowStore.isCoreNetInvitation
            ? this.props.history.replace('/download-app')
            : this.setState({
                  accountConfirmation: !this.state.accountConfirmation,
              });
    };

    toggleAttendeesStep = () => {
        this.setState({
            attendeesStep: !this.state.attendeesStep,
        });
    };

    closeDetail = () => {
        this.setState({
            planDetails: false,
            roomDetails: false,
            campusDetails: false,
        });
    };

    renderStep = (renderProps) => {
        const { t } = this.props;
        const path = renderProps.match.url.substring(this.props.match.url.length + 1);
        const step = this.paths.find((entry) => (entry.path || '') === path);
        const TitleIcon = this.titleIcon;
        return (
            <FlowComponent onMounted={this.stepRendered} step={step}>
                <step.component
                    {...renderProps}
                    onComplete={this.completeStep}
                    onDetails={this.renderDetails}
                    onAccountConfirmation={this.toggleAccountConfirmation}
                    toggleAttendeesStep={this.toggleAttendeesStep}
                    stepKey={step.path}
                    storeKey={step.storeKey}
                    store={this.flowStore}
                    modalTitle={
                        this.modalType !== Constants.MODAL_FLOW_EVENT.MEMBERSHIP_EDIT &&
                        this.modalType !==
                            Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE && (
                            <h3 className=" mx-auto mt-1 mb-10">
                                {TitleIcon && (
                                    <div className="text-secondary mb-2 align-item-center">
                                        <TitleIcon size="1.5em" />
                                    </div>
                                )}
                                <span>{t(this.titleKey)}</span>
                            </h3>
                        )
                    }
                    parentMatchURL={this.props.match.url}
                />
            </FlowComponent>
        );
    };

    stepRendered = (step) => {
        let previousStepIndex = this.paths.indexOf(step) - 1;
        if (previousStepIndex < 0) return;

        let previousStep = this.paths[previousStepIndex];
        while (
            previousStep.optional &&
            !previousStep.accessCondition() &&
            previousStepIndex > 0
        ) {
            previousStep = this.paths[--previousStepIndex];
        }
        if (!previousStep.completed())
            this.props.history.replace(
                this.props.match.url + (previousStep.path ? '/' + previousStep.path : '')
            );
    };

    close = async (e, finalStep) => {
        const {
            uiStore: { simpleModalStore },
            t,
            onUpdate,
            seedStore,
            history,
            parentMatchURL,
        } = this.props;
        let title = '';
        let message = '';
        switch (this.modalType) {
            case Constants.EVENT_TYPE.BOOKING:
                title = t('Booking.leave_booking_title');
                message = t('Booking.leave_booking_message');
                break;
            case Constants.EVENT_TYPE.SUBSCRIPTION:
                if (this.flowStore.dayPassSubscription) {
                    title = t('Subscription.leave_day_pass_title');
                    message = t('Subscription.leave_day_pass_message');
                } else {
                    title = t('Subscription.leave_subscription_title');
                    message = t('Subscription.leave_subscription_message');
                }
                break;
            case Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE:
                if (
                    this.flowStore.isSubscription &&
                    isEmpty(this.flowStore.membership.legalContact) &&
                    this.flowStore.membershipId
                ) {
                    title = t('Company.empty_contact_person_title');
                    message =
                        t('Company.empty_contact_person_message') +
                        t('Membership.leave_company_creation_message');
                } else {
                    title = t('Membership.leave_company_creation_title');
                    message = t('Membership.leave_company_creation_message');
                }
                break;
            case Constants.MODAL_FLOW_EVENT.MEMBERSHIP_EDIT:
                title = t('Membership.leave_edit_title');
                message = t('Membership.leave_edit_message');
                break;
            case Constants.MODAL_FLOW_EVENT.MEMBER_CREATE:
                title = t('Account.leave_account_creation_title');
                message = t('Account.leave_account_creation_message');
                break;
            default:
                title = t('Profile.leave_edit_title');
                message = t('Profile.leave_edit_message');
                break;
        }
        const flowStoreId =
            this.flowStore.membershipId || this.flowStore.bookingId || null;
        const TitleIcon = this.titleIcon;
        if (
            !finalStep &&
            (this.flowStore.touched ||
                (this.modalType === Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE &&
                    this.flowStore.isSubscription &&
                    isEmpty(this.flowStore.contactLegalStep))) &&
            !(await simpleModalStore.confirm({
                titleIcon: <TitleIcon size="3.75em" />,
                title: title,
                message: message,
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;
        else if (finalStep) {
            if (
                this.modalType === Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE &&
                this.flowStore.isSubscription &&
                onUpdate
            ) {
                if (isEmpty(this.flowStore.membership.legalContact)) {
                    onUpdate(flowStoreId, true);
                    history.push(parentMatchURL);
                } else {
                    onUpdate(flowStoreId);
                }
            } else if (onUpdate) onUpdate(flowStoreId);
        }

        if (seedStore && seedStore[this.modalType.toLowerCase()])
            seedStore[this.modalType.toLowerCase()] = undefined;
        if (this.modalType === Constants.MODAL_FLOW_EVENT.MEMBER_CREATE) {
            this.flowStore.clear();
            history.push('/');
        } else {
            if (
                this.modalType === Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE &&
                this.flowStore.isSubscription &&
                onUpdate
            ) {
                if (isEmpty(this.flowStore.membership.legalContact)) {
                    onUpdate(flowStoreId, true);
                    history.push(parentMatchURL);
                } else {
                    onUpdate(flowStoreId);
                }
            } else {
                history.push(parentMatchURL);
            }
        }
    };

    render() {
        const { t, match, history, location } = this.props,
            {
                accountConfirmation,
                CampusDetail,
                PlanDetail,
                RoomDetail,
                campusDetails,
                campusId,
                planDetails,
                planId,
                roomDetails,
                roomId,
                attendeesStep,
                AddMembers,
            } = this.state,
            { paths } = this;
        const lastPathNameUrl = location.pathname.substr(
            location.pathname.lastIndexOf('/') + 1
        );
        const currentPath = paths.find(
            (path) =>
                path.path ===
                location.pathname.substr(location.pathname.lastIndexOf('/') + 1)
        );

        const TitleIcon = this.titleIcon;

        return (
            <Fragment>
                <Modal
                    isOpen={true}
                    className={`${this.modalSize} ${
                        lastPathNameUrl === 'skip'
                            ? 'adaptive'
                            : currentPath?.adaptive
                            ? 'adaptive'
                            : ''
                    } ${currentPath?.modalXl ? 'modal-xl' : ''}`}
                >
                    <div className={classnames('modal-header', this.modalHeaderBg)}>
                        <button
                            type="button"
                            className="close modal-flow"
                            aria-label="Close"
                            onClick={this.close}
                        >
                            <span aria-hidden="true">
                                <X fill="currentColor" size="24px" />
                            </span>
                        </button>

                        {this.modalType === Constants.MODAL_FLOW_EVENT.MEMBERSHIP_EDIT ||
                        this.modalType === Constants.MODAL_FLOW_EVENT.MEMBERSHIP_CREATE ||
                        this.modalType === Constants.MODAL_FLOW_EVENT.MEMBER_CREATE ||
                        this.modalType === Constants.EVENT_TYPE.BOOKING ? (
                            <h4 className="modal-flow-title mx-auto mt-1 mb-4">
                                {TitleIcon &&
                                    (this.modalType ===
                                        Constants.MODAL_FLOW_EVENT.MEMBER_CREATE ||
                                        this.modalType ===
                                            Constants.EVENT_TYPE.BOOKING) && (
                                        <span className="text-secondary mr-2 align-item-center">
                                            <TitleIcon size="1em" />
                                        </span>
                                    )}
                                <span>{t(this.titleKey)}</span>
                            </h4>
                        ) : null}
                        <ul className="steps">
                            {
                                paths.reduce(
                                    (state, entry, i) => {
                                        const completed = entry.optional
                                            ? entry.accessCondition()
                                                ? entry.completed()
                                                : true
                                            : entry.completed();
                                        const available = entry.optional
                                            ? state.previousComplete &&
                                              entry.accessCondition()
                                            : state.previousComplete;
                                        state.steps[i] = (
                                            <li className="step" key={entry.path}>
                                                <FlowStep
                                                    icon={entry.icon}
                                                    to={
                                                        entry.path
                                                            ? `${match.url}/${entry.path}`
                                                            : match.url
                                                    }
                                                    text={t(entry.title)}
                                                    available={available}
                                                    completed={completed}
                                                />
                                            </li>
                                        );
                                        state.previousComplete =
                                            state.previousComplete && completed;
                                        return state;
                                    },
                                    {
                                        previousComplete: true,
                                        steps: new Array(paths.length),
                                    }
                                ).steps
                            }
                        </ul>
                    </div>
                    <ModalBody className={this.modalPane}>
                        <Switch>
                            {paths.map((entry) => (
                                <Route
                                    key={entry.path}
                                    path={`${match.url}/${entry.path}`}
                                    render={this.renderStep}
                                />
                            ))}
                            <Route component={NoMatch} />
                        </Switch>
                    </ModalBody>
                </Modal>
                {campusDetails ? (
                    <CampusDetail
                        campusId={campusId}
                        isOpen={campusDetails}
                        onClose={this.closeDetail}
                    />
                ) : null}
                {planDetails ? (
                    <PlanDetail
                        campusId={this.flowStore.campusId}
                        planId={planId}
                        isOpen={planDetails}
                        onClose={this.closeDetail}
                    />
                ) : null}
                {roomDetails ? (
                    <RoomDetail
                        campusId={this.flowStore.campusId}
                        roomId={roomId}
                        isOpen={roomDetails}
                        onClose={this.closeDetail}
                    />
                ) : null}
                {attendeesStep ? (
                    <AddMembers
                        membershipId={
                            this.flowStore.creditBalance &&
                            this.flowStore.creditBalance.paymentMethodHolder &&
                            this.flowStore.creditBalance.paymentMethodHolder.holderId
                                ? this.flowStore.creditBalance.paymentMethodHolder
                                      .holderId
                                : ''
                        }
                        isOpen={attendeesStep}
                        store={this.flowStore}
                        attendees={this.flowStore.attendees}
                        onClose={this.toggleAttendeesStep}
                        attendance
                        organizer={this.flowStore.bookingOrganizer}
                    />
                ) : null}
                {accountConfirmation ? (
                    <AccountValidation
                        email={this.flowStore.email}
                        password={this.flowStore.password}
                        sent={this.flowStore.sent}
                        onClose={this.toggleAccountConfirmation}
                        parentMatchURL={match.url}
                        history={history}
                    />
                ) : null}
            </Fragment>
        );
    }
}

export default ModalFlowBase;
