import React from 'react';
import { translate } from 'react-i18next';
import { FormGroup } from 'Components/Forms';
import { inject, observer } from 'mobx-react';
import ModelStateStore from 'Stores/ModelStateStore';
import HttpService from 'Services/HttpService';
import { Personal, Memberships, PersonalInfo, X } from 'Components/Icons';
import colorVariables from 'Sass/variables.scss';
import { Modal, ModalBody } from 'reactstrap';
import { Constants } from 'Data/';
import classnames from 'classnames';
import { isEmpty } from 'Utils/ObjectUtils';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

@inject('dashboardStore', 'uiStore')
@observer
class LegalContactStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            required: false,
            uniqueStep: false,
        };

        this.modelStateStore = new ModelStateStore(
            { to: 'contactInfo.phone1', from: 'contactInfo.phone1' },
            { to: 'contactInfo.website', from: 'contactInfo.website' },
            { to: 'contactInfo.angelListUrl', from: 'contactInfo.angelListUrl' },
            { to: 'contactInfo.twitterUrl', from: 'contactInfo.twitterUrl' },
            { to: 'contactInfo.facebookUrl', from: 'contactInfo.facebookUrl' },
            { to: 'contactInfo.linkedInUrl', from: 'contactInfo.linkedInUrl' },
            { to: 'contactInfo.instagramUrl', from: 'contactInfo.instagramUrl' },
            { to: 'legalContact.birthDate', from: 'legalContact.BirthDate' }
        );
    }

    touched = false;
    componentDidMount = () => {
        const {
            isUniqueStep = false,
            membership,
            store,
            isSubscription = false,
        } = this.props;
        if (store && store.isSubscription && !isUniqueStep)
            this.setState({ required: true }, () => {
                UpdateStateStoreUtils(store, 'contactLegalStep', false);
                this.setTouched();
            });
        if (membership && store) {
            UpdateStateStoreUtils(
                store,
                `membership.legalContact`,
                membership && membership.legalContact ? membership.legalContact : {}
            );
            UpdateStateStoreUtils(
                store,
                `membership.legalContact.address`,
                membership && membership.legalContact
                    ? membership.legalContact.address
                    : {}
            );
        }

        this.generateContactInfo();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.membership !== this.props.membership) {
            this.generateContactInfo();
        }
    }

    generateContactInfo = () => {
        const { isUniqueStep, isSubscription, membership } = this.props;
        if (isUniqueStep) {
            if (membership) {
                this.setState(
                    { contactInfo: JSON.parse(JSON.stringify(membership)) },
                    () => {
                        if (isSubscription) {
                            this.setState({ required: true });
                        }
                    }
                );
            }
        }
    };

    handleInputChange = (event) => {
        const { store, isUniqueStep } = this.props,
            { contactInfo } = this.state;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (isUniqueStep) {
            let keys = name.split('.');
            const update = { ...contactInfo };
            let object = null;
            for (let i = 0; i < keys.length - 1; i++) {
                object = object ? object[keys[i]] : update.legalContact;
            }
            object[keys[keys.length - 1]] = value;

            this.setState({ contactInfo: update }, () => {
                this.setTouched();
            });
        } else {
            UpdateStateStoreUtils(store, `membership.${name}`, value);
            UpdateStateStoreUtils(store, 'touched', true);
        }
        this.isRequired();
    };

    isRequired = () => {
        const { isUniqueStep, store } = this.props,
            { contactInfo } = this.state;
        let membership = null;
        isUniqueStep ? (membership = contactInfo) : (membership = store.membership);
        if (membership.legalContact) {
            const legalKey = Object.keys(membership.legalContact).reduce(
                (object, key) => {
                    if (key !== 'address') {
                        object[key] = membership.legalContact[key];
                    }
                    return object;
                },
                {}
            );
            const address = membership.legalContact.address;
            if (
                Object.values(address).every((x) => x === null || x === '') &&
                Object.values(legalKey).every((x) => x === null || x === '')
            ) {
                this.setState({ required: false });
            } else {
                this.setState({ required: true });
            }
        }
    };

    nextStep = () => {
        const { onComplete, stepKey, store } = this.props;
        onComplete(stepKey, store.contact);
    };

    editMembership = async (event) => {
        event.preventDefault();
        const {
            t,
            isUniqueStep = false,
            store,
            dashboardStore,
            uiStore: { loadingStore, toastStore },
            onComplete,
            isSubscription,
        } = this.props;
        const { contactInfo } = this.state;
        let membership = null;
        if (isUniqueStep) {
            membership = contactInfo;
        } else {
            membership = store.membership;
        }
        if (!membership) {
            toastStore.enqueueErrorToast({
                message: t('Error.error_message'),
            });
            return;
        }
        loadingStore.addLoading();
        try {
            if (this.touched) {
                await HttpService.editMembership(membership);

                toastStore.enqueueToast({
                    message: t('Membership.sucess_edit_membership_message'),
                    icon: Memberships,
                });

                await dashboardStore.loadMemberships(loadingStore);
            }
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        } finally {
            if (store) {
                UpdateStateStoreUtils(store, 'touched', false);
            }
            this.touched = false;

            if (isUniqueStep) {
                if (onComplete) onComplete(membership.id);
                if (this.props.uiStore.subscriptionUrl) {
                    let subscriptionUrl = this.props.uiStore.subscriptionUrl;
                    delete this.props.uiStore.subscriptionUrl;
                    this.props.history.push(subscriptionUrl);
                } else if (!isSubscription) this.close();
            } else {
                if (store && isEmpty(store.membership.legalContact))
                    UpdateStateStoreUtils(store, 'contact', false);
                this.nextStep();
            }
        }
        loadingStore.removeLoading();
    };

    loadProfileInfo = async () => {
        const {
                uiStore: { loadingStore },
                store,
                isUniqueStep = false,
            } = this.props,
            { contactInfo } = this.state;
        loadingStore.addLoading();
        try {
            const { data } = await HttpService.profile(this.cancellation);
            const contactInfoMap = {
                address: {
                    addressLine1: data.address.addressLine1,
                    city: data.address.city,
                    state: data.address.state,
                    country: data.address.country,
                    postalCode: data.address.postalCode,
                },
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.contactInfo.email,
                phone: data.contactInfo.phone1,
                birthDate: data.birthDate,
            };
            const response = { ...contactInfo };
            response['legalContact'] = contactInfoMap;

            if (isUniqueStep) {
                this.setState({ contactInfo: response });
            } else {
                UpdateStateStoreUtils(store, 'membership.legalContact', contactInfoMap);
            }
            this.setTouched();
            this.isRequired();
        } catch (e) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    renderStep = () => {
        const { t, isUniqueStep = false, store } = this.props;
        const { required, contactInfo } = this.state,
            { errors } = this.modelStateStore;
        let isCreation = null;
        let membership = null;
        let classForm = 'px-5 px-md-11 pb-1 pt-2 bg-dark';
        let classFormGroup = '';
        if (isUniqueStep) {
            isCreation = false;
            membership = contactInfo;
            classForm = 'bg-dark';
            classFormGroup = 'px-5 px-md-11 pb-1 pt-2 mb-0';
        } else {
            isCreation = store.isCreation;
            membership = store.membership;
        }

        return (
            membership && (
                <form
                    onSubmit={this.editMembership}
                    className="d-flex flex-column bg-body mh-0"
                >
                    <div className="scrollable d-flex flex-column">
                        <div className="bg-black text-center text-sm pt-2 pb-1 px-10">
                            <PersonalInfo fill={colorVariables.secondary} size="24px" />
                        </div>
                        <div
                            className="bg-black text-center text-sm pb-2 px-10"
                            dangerouslySetInnerHTML={{
                                __html: t('Company.contact_person_subscription_message'),
                            }}
                        />
                        <div className="bg-dark py-2 text-center">
                            <button
                                type="button"
                                className="rounded-0 btn btn-light w-100"
                                onClick={this.loadProfileInfo}
                            >
                                <PersonalInfo fill={colorVariables.primary} size="24px" />
                                &nbsp;
                                <span>{t('PaymentMethod.fill_with_profile')}</span>
                            </button>
                        </div>
                        {(membership.legalContact || isCreation) && (
                            <div className={classForm}>
                                <FormGroup
                                    id="legalContact.firstName"
                                    displayName={t('Account.first_name')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    errors={errors}
                                    className={classFormGroup}
                                    required={required}
                                    value={
                                        membership.legalContact
                                            ? membership.legalContact.firstName
                                            : ''
                                    }
                                />

                                <FormGroup
                                    id="legalContact.lastName"
                                    displayName={t('Account.last_name')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    errors={errors}
                                    required={required}
                                    value={
                                        membership.legalContact
                                            ? membership.legalContact.lastName
                                            : ''
                                    }
                                />
                                <FormGroup
                                    id="legalContact.email"
                                    displayName={t('Account.email')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    type="email"
                                    className={classFormGroup}
                                    errors={errors}
                                    required={required}
                                    value={
                                        membership.legalContact
                                            ? membership.legalContact.email
                                            : ''
                                    }
                                />
                                <FormGroup
                                    id="legalContact.phone"
                                    displayName={t('Account.phone')}
                                    type="text"
                                    className={classFormGroup}
                                    onChange={this.handleInputChange}
                                    value={
                                        membership.legalContact
                                            ? membership.legalContact.phone
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />
                                <FormGroup
                                    id="legalContact.address.addressLine1"
                                    displayName={t('Account.address')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.address
                                            ? membership.legalContact.address.addressLine1
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />
                                <FormGroup
                                    id="legalContact.address.city"
                                    displayName={t('Account.city')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.address
                                            ? membership.legalContact.address.city
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />
                                <FormGroup
                                    id="legalContact.address.state"
                                    displayName={t('Account.state')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.address
                                            ? membership.legalContact.address.state
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />
                                <FormGroup
                                    id="legalContact.address.postalCode"
                                    displayName={t('Account.zipcode')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.address
                                            ? membership.legalContact.address.postalCode
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />
                                <FormGroup
                                    id="legalContact.address.country"
                                    displayName={t('Account.country')}
                                    onChange={this.handleInputChange}
                                    maxlength={100}
                                    className={classFormGroup}
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.address
                                            ? membership.legalContact.address.country
                                            : ''
                                    }
                                    errors={errors}
                                    required={required}
                                />

                                <FormGroup
                                    id="legalContact.birthDate"
                                    value={
                                        membership.legalContact &&
                                        membership.legalContact.birthDate
                                            ? membership.legalContact.birthDate
                                            : null
                                    }
                                    displayName={t('Account.birthday')}
                                    type="date"
                                    className={classFormGroup}
                                    onChange={this.handleInputChange}
                                    errors={errors}
                                    required={required}
                                />
                                {required && (
                                    <div
                                        className={classnames(
                                            'pb-2 text-white text-xs font-weight-medium ',
                                            {
                                                'pl-5 pl-md-11 py-1': isUniqueStep,
                                            }
                                        )}
                                    >
                                        <span className="text-secondary ">* </span>
                                        {t('Account.required_field')}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <button className="btn btn-primary w-100 bottom-action" type="submit">
                        {isUniqueStep ? t('Membership.confirm') : t('next')}
                    </button>
                </form>
            )
        );
    };

    setTouched = () => {
        const { store } = this.props;
        if (!this.touched) {
            this.touched = true;
            if (store) UpdateStateStoreUtils(store, 'touched', true);
        }
    };

    close = async () => {
        const {
            uiStore: { simpleModalStore },
            t,
            history,
            parentMatchURL,
        } = this.props;

        if (
            this.touched &&
            !(await simpleModalStore.confirm({
                titleIcon: <Personal size="3.75em" />,
                title: t('Company.leave_legal_contact_title'),
                message: t('Company.leave_legal_contact_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        history.push(parentMatchURL);
    };

    render() {
        const { t, isUniqueStep = false } = this.props;

        return isUniqueStep ? (
            <Modal
                isOpen={true}
                className="modal-dialog-centered modal-full-screen adaptive"
            >
                <div className="modal-header bg-dark">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.close}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <h4 className="modal-title mx-auto px-2 mt-1">
                        {t('Company.contact_person_title')}
                    </h4>
                </div>
                <ModalBody>{this.renderStep()}</ModalBody>
            </Modal>
        ) : (
            this.renderStep()
        );
    }
}

export default translate()(LegalContactStep);
