import React from 'react';

const Confirmation = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M12 23.2C5.825 23.2.8 18.175.8 12 .8 5.823 5.825.8 12 .8 18.177.8 23.2 5.823 23.2 12c0 6.175-5.023 11.2-11.2 11.2M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12c6.618 0 12-5.383 12-12S18.618 0 12 0" />
            <path d="M7.3 13.277a.571.571 0 0 0-.78.031.508.508 0 0 0 .033.742l3.846 3.37a.568.568 0 0 0 .847-.12l6.316-10.109c.155-.25.005-.507-.257-.655-.262-.148-.536-.13-.692.12l-5.968 9.551-3.345-2.93z" />
        </g>
    </svg>
);

export default Confirmation;
