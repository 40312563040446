import React from 'react';

const Phone = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M2.3 116.53c-3.63-6-2.8-11.66 1.35-17.06a46.66 46.66 0 0 1 17.42-13.7c5.42-2.57 10.64-2.39 15.28 2.09 3 2.92 6.34 5.55 9.49 8.34 3 2.63 6 3.21 9.42.77 7.58-5.48 15.5-10.54 22.64-16.53C85.59 74 92.71 66.85 99.76 59.69c4-4.09 3.9-5.45 1-10.54-2.19-3.87-4.55-7.64-6.83-11.46-2.46-4.13-2.22-8.19.24-12.25a43.45 43.45 0 0 1 18.6-17.09c5.51-2.66 10.73-2.24 15.48 1.73 17 14.2 19.78 32.44 10.55 51.52-5 10.35-12.32 19.12-20.72 26.93-15.25 14.18-30.76 28.06-48.81 38.7-9.94 5.85-20.48 10.08-32.3 10.1-15.78.11-26.78-7.75-34.67-20.8zM124.57 13.27a8.12 8.12 0 0 0-8.83-1A35.71 35.71 0 0 0 98.09 28.6a8.65 8.65 0 0 0 0 6.39c1.22 3.09 3.28 5.85 5 8.71 6.32 10.35 6 13.94-2.41 22.41a257.44 257.44 0 0 1-35.3 30c-2.56 1.79-5.22 3.45-7.94 5-6 3.36-9.49 3.06-14.79-1.31-3.53-2.91-6.92-6-10.36-9a6.84 6.84 0 0 0-7.67-1.24 40.6 40.6 0 0 0-17.55 13.37c-2.77 3.69-2.79 7.39-.6 11.44 7.64 14.16 23.11 20.9 39.63 17.22 10.64-2.38 20.1-7.32 28.9-13.59 17.81-12.67 34.88-26.41 49.24-43 8.4-9.72 14.87-20.76 14.91-36.8-.56-8.99-5.67-17.97-14.58-24.93z"
        />
    </svg>
);

export default Phone;
