import axios from 'axios';
import qs from 'qs';

import { getDistanceInMiles } from 'Utils/';
import AuthStore from 'Stores/AuthStore';

function throwIfRequested(res) {
    this.token.throwIfRequested();
    return res;
}

function isCancelled(err) {
    return axios.isCancel(err);
}

let endpoints = {
    // Account
    createAccount: (data) => axios.post('/account/register', data),
    registerValidateAccount: (data) => axios.post('/account/registervalidate', data),
    joinCorenet: (data) => axios.post('/account/joincorenet', data),
    confirmAccountByToken: ({ email, token, ignoreErrorToast }) =>
        axios.post(
            '/account/confirmemail',
            {
                email,
                token,
            },
            {
                headers: { 'Limited-Access-Token': token },
                ignoreErrorToast: ignoreErrorToast,
            }
        ),
    resetPasswordByToken: (data) => axios.post('/account/resetpassword', data),
    fetchInvitationByToken: (token) =>
        axios.get(`/memberships/emailinvitations?token=${token}`),
    fetchGuestCheckinInvitationByToken: (token) =>
        axios.get(`/guestcheckins?token=${token}`),

    profile: (cancelSource) =>
        axios.get(`/accounts/${AuthStore.currentUserId}/profile`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    editProfile: (data) =>
        axios.patch(`/accounts/${AuthStore.currentUserId}/profile`, data),
    resendEmailConfirmation: (data) =>
        axios.post('/account/resendemailconfirmation', data),
    changePassword: (data) => axios.post('/account/changepassword', data),
    forgottenPassword: (data) => axios.post('/account/forgottenpassword', data),

    //Booking
    amenityTimeSlots: (
        { date, amenityId, bookingId, creditBalanceId, campusId },
        cancelSource
    ) =>
        axios.get(
            `/amenitytimeslots?${qs.stringify({
                date,
                amenityId,
                bookingId,
                creditBalanceId,
                campusId,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    bookingMaxDate: ({ id, campusId, amenityId }, cancelSource) =>
        axios.get(
            `/credits/balances/${id}/enddate?${qs.stringify({
                campusId,
                amenityId,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    recurrencePresets: (
        { conferenceRoomId: id, periodStart, periodEnd, creditBalanceId },
        cancelSource
    ) =>
        axios.post(
            `/amenities/${id}/recurringpresets`,
            {
                periodStart,
                periodEnd,
                creditBalanceId,
                enableMemberRestrictions: true,
            },
            { cancelToken: cancelSource && cancelSource.token }
        ),
    bookingSummary: ({ conferenceRoomId: id, data }, cancelSource) =>
        axios.post(`/amenities/${id}/summary`, data, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    book: ({ conferenceRoomId: id, data }) => axios.post(`/amenities/${id}/book`, data),
    updateBooking: ({ bookingId, data }) => axios.post(`/bookings/${bookingId}`, data),

    // Campuses
    campusesList: ({ campusIds }, cancelSource) =>
        axios.get(`/campus?${qs.stringify({ campusIds })}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    campuses: (withDayPassPlansOnly, membershipId, cancelSource) =>
        axios.get(
            `/campus?withDayPassPlansOnly=${withDayPassPlansOnly}&membershipId=${membershipId}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    campusDetail: (id, cancelSource) =>
        axios.get(`/campus/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    campusesWithDistance: (
        campusTransform,
        withDayPassPlansOnly,
        membershipId,
        cancelSource
    ) => {
        const campusesPromise = campusTransform(
            endpoints.campuses(withDayPassPlansOnly, membershipId, cancelSource)
        );

        const geolocationPromise = new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject)
        )
            .then(cancelSource.throwIfCancelled)
            .then((position) => position.coords);

        const positionPromise = Promise.all([geolocationPromise, campusesPromise]).then(
            ([coords, campuses]) =>
                campuses.map((campus) => {
                    const distance = getDistanceInMiles(
                        coords.latitude,
                        coords.longitude,
                        campus.lat,
                        campus.lng
                    );

                    return Object.assign({}, campus, {
                        distance,
                    });
                })
        );

        return {
            campusesPromise,
            positionPromise,
        };
    },
    floorPlan: (campusId) => axios.get(`/campus/${campusId}/floorplan`),
    unitOccupancyStatuses: (
        request = {
            campusIds: [],
            campusesWithManagementRights: false,
            search: '',
            statuses: [''],
            sortColumn: '',
            sortDirection: '',
            unitType: 'All',
            unitIds: [],
        },
        cancelSource
    ) =>
        axios.get(
            `/unitoccupancystatuses?${qs.stringify({
                ...request,
            })}`,
            {
                cancelToken: cancelSource && cancelSource.token,
            }
        ),
    //Credits
    balances: ({ campusId, amenityId, canDebit, canCredit }, cancelSource) =>
        axios.get(
            `/credits/balances?${qs.stringify({
                campusId,
                amenityId,
                canDebit,
                canCredit,
            })}`,
            {
                cancelToken: cancelSource && cancelSource.token,
            }
        ),

    //Invoices
    membershipInvoices: (id, cancelSource) =>
        axios.get(`/memberships/${id}/invoices`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    personalInvoices: (cancelSource) =>
        axios.get('/profile/invoices', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    invoice: (id, cancelSource) =>
        axios.get(`/invoices/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    tryPayment: (action, id) => axios.post(`/invoices/${id}/${action}`),
    invoiceExport: (data) =>
        axios.post('/invoices/export', data, { responseType: 'arraybuffer' }),
    fetchInvoiceByToken: (id, cancelSource, token) =>
        axios.get(`/invoices/${id}/getwithtoken`, {
            cancelToken: cancelSource && cancelSource.token,
            headers: token ? { 'Limited-Access-Token': token } : undefined,
        }),
    tryPaymentWithToken: ({ token, id, stripeToken, ignoreErrorToast }) =>
        axios.post(
            `/invoices/${id}/trywithtoken`,
            { token: stripeToken },
            {
                headers: { 'Limited-Access-Token': token },
                ignoreErrorToast: ignoreErrorToast,
            }
        ),

    // Memberships
    allMemberships: (params, cancelSource) =>
        axios.get(`/memberships?${qs.stringify(params)}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    createMembership: (data) => axios.post('/memberships/create', data),
    editMembership: (data) => axios.post('/memberships/update', data),
    memberships: (cancelSource) =>
        axios.get('/accounts/current/memberships', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    proposals: (cancelSource) =>
        axios.get('/memberships/proposals', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    membershipDetail: (id, cancelSource, excludePendingMembers = false) =>
        axios.get(`memberships/${id}?excludePendingMembers=${excludePendingMembers}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    membershipInvitation: (id, cancelSource) =>
        axios.get(`memberships/invitations/${id}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    memberDetail: (id, cancelSource) =>
        axios.get(`members/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    members: ({ request, page, pageSize }, cancelSource) =>
        axios.get(
            `/members?${qs.stringify({
                request,
                page,
                pageSize,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),

    inviteMembers: (data) => axios.post('/memberships/invite', data),
    cancelEmailInvite: (id) => axios.post(`/memberships/emailinvitations/${id}/cancel`),
    acceptInvitation: (id) => axios.post('/memberships/accept', { membershipId: id }),
    rejectInvitation: (id) => axios.post('/memberships/reject', { membershipId: id }),
    transferOwnership: (data) => axios.post('/memberships/transfer', data),
    leaveMembership: (id) => axios.post('/memberships/leave', { membershipId: id }),
    updateMember: (data) => axios.post('/memberships/updateMember', data),
    updateEmailInvitationMemberRoles: (id, data) =>
        axios.post(`/memberships/emailinvitations/${id}/roles`, data),
    removeMember: (data) => axios.post('/memberships/remove', data),
    expertises: (cancelSource) =>
        axios.get('/expertises', { cancelToken: cancelSource && cancelSource.token }),
    contractTypes: (cancelSource) =>
        axios.get('/jobContractTypes', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    membershipProfile: (id, cancelSource) =>
        axios.get(`memberships/${id}/public`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    mainUnit: (id) => axios.get(`/memberships/${id}/mainunit`),
    // Messages
    messages: ({ page, pageSize, forManagers }, cancelSource) =>
        axios.get(
            `/messages?${qs.stringify({
                page,
                pageSize,
                forManagers,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    messageDetail: (id, cancelSource) =>
        axios.get(`/messages/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    setMessageStatus: (data) => axios.post('/messages/status', data),
    setAllMessagesStatus: (data) => axios.post('/messages/status/all', data),
    hasUnread: ({ forManagers }, cancelSource) =>
        axios.get(
            `/messages/hasunread?${qs.stringify({
                forManagers,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),

    // Home
    events: ({ page, pageSize, currentTime }, cancelSource) =>
        axios.get(
            `/accounts/${AuthStore.currentUserId}/events?${qs.stringify({
                page,
                pageSize,
                currentTime,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    updateSettings: (data) =>
        axios.put(`/accounts/${AuthStore.currentUserId}/preferences`, data),
    settings: (cancelSource) =>
        axios.get(`/accounts/${AuthStore.currentUserId}/preferences`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    communityEvents: (
        { page, pageSize, currentTime, includePastEvents = false },
        cancelSource
    ) =>
        axios.get(
            `/communityevents?${qs.stringify({
                page,
                pageSize,
                currentTime,
                includePastEvents,
            })}`,
            { cancelToken: cancelSource && cancelSource.token }
        ),
    communityEvent: (id, cancelSource) =>
        axios.get(`/communityevents/${id}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    feedback: (message) => axios.post('/support/feedbacks', message),
    // Bookings
    bookings: (cancelSource) =>
        axios.get('/bookings', { cancelToken: cancelSource && cancelSource.token }),
    bookingsMembership: ({ id, request }, cancelSource) =>
        axios.get(`/memberships/${id}/bookings?${qs.stringify({ request })}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    bookingsConflicts: (id, cancelSource) =>
        axios.get(`/bookings/errorsforrecurrence/${id}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    bookingDetail: (id, cancelSource) =>
        axios.get(`/bookings/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    cancelBooking: (data) => axios.post('/bookings/cancel', data),
    cancelBookingRecurrence: (data) => axios.post('/bookings/cancelrecurrence', data),
    dailyBookingSummary: ({ id, request }, cancelSource) =>
        axios.get(`/campus/${id}/dailybookingsummary?${qs.stringify({ request })}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),

    // Job
    jobPosts: (params, cancelSource) =>
        axios.get(`/jobposts?${params}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    jobPostsDetails: (id, cancelSource) =>
        axios.get(`/jobposts/${id}`, { cancelToken: cancelSource && cancelSource.token }),
    createJobPost: (data) => axios.post('/jobposts', data),
    editJobPost: (id, data) => axios.patch(`/jobposts/${id}`, data),
    cancelJobPost: (id) => axios.delete(`/jobposts/${id}`),

    // Payment
    apiInfo: (cancelSource) =>
        axios.get('/payment/apiinfo', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    updatePaymentMethod: (data) => axios.post('/payment/paymentmethod', data),
    achConsent: (cancelSource) =>
        axios.get('/payment/achconsent', {
            cancelToken: cancelSource && cancelSource.token,
        }),
    verifyPaymentMethod: (data) => axios.post('/payment/verify', data),
    paymentMethod: ({ holderType, holderId }, cancelSource) =>
        axios.get(`/payment/forHolder?holderType=${holderType}&holderId=${holderId}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    removePaymentMethod: (data) => axios.post('/payment/remove', data),
    setPlaidPaymentMethod: ({ holderId, holderType, plaidPublicToken, plaidAccountId }) =>
        axios.post('/payment/paymentmethod/plaid', {
            holderId,
            holderType,
            plaidPublicToken: plaidPublicToken,
            plaidAccountId: plaidAccountId,
        }),

    // Plan
    plans: (campusId, cancelSource) =>
        axios.get(`/campus/${campusId}/plans`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    planDetail: (planId, cancelSource) =>
        axios.get(`/plans/${planId}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),

    // Room
    roomsGet: (request, cancelSource) =>
        axios.get(`/amenities?${qs.stringify(request)}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    rooms: (campusId, cancelSource) =>
        axios.get(`/campus/${campusId}/amenities`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    roomDetail: (roomId, cancelSource) =>
        axios.get(`/amenities/${roomId}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),

    // Subscriptions
    fetchSubscriptionByToken: (id, cancelSource, token) =>
        axios.get(`/subscriptions/${id}/getwithtoken`, {
            cancelToken: cancelSource && cancelSource.token,
            headers: token ? { 'Limited-Access-Token': token } : undefined,
        }),
    subscription: (id, cancelSource) =>
        axios.get(`/subscriptions/${id}`, {
            cancelToken: cancelSource && cancelSource.token,
        }),
    cancelSubscription: (data) => axios.post('/subscriptions/cancel', data),
    createSubscriptionRequest: (data) => axios.post('/subscription-requests', data),
    acceptSubscriptionByToken: ({ token, subscriptionId, consentVersion, ignoreErrorToast }) =>
        axios.post(
            `/subscription-proposals/${subscriptionId}/acceptwithtoken`,
            { consentVersion },
            {
                headers: { 'Limited-Access-Token': token },
                ignoreErrorToast: ignoreErrorToast,
            }
        ),
    rejectSubscriptionByToken: ({ token, subscriptionId, ignoreErrorToast }) =>
        axios.post(`/subscription-proposals/${subscriptionId}/rejectwithtoken`, null, {
            headers: { 'Limited-Access-Token': token },
            ignoreErrorToast: ignoreErrorToast,
        }),

    // Tour
    fetchTourByToken: (token) => axios.get(`/tours/getwithtoken?token=${token}`),

    cancelTourByToken: (token) =>
        axios.post(`/tours/cancelwithtoken?token=${token}`, null, {
            headers: { 'Limited-Access-Token': token },
        }),

    cancellable: () => {
        const source = axios.CancelToken.source();
        source.throwIfCancelled = throwIfRequested.bind(source);
        source.isCancelled = isCancelled;
        return source;
    },
};

export default endpoints;
