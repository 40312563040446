import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import { inject } from 'mobx-react';

import { ActionPane, InfoPane } from 'Components/Modal';
import PaymentMethodBackground from 'Assets/Images/PaymentMethod.jpg';
import {
    ACHExplanations,
    ACHForm,
    ACHPlaidChoice,
    CreditCardForm,
    PaymentMethodChoice,
} from 'Components/PaymentMethod';
import { Back } from 'Components/Icons';
import PaymentMethodStore from 'Stores/PaymentMethod/PaymentMethodStore';
import Constants from 'Data/Constants';
import CurrentPaymentMethod from './CurrentPaymentMethod';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

@inject('uiStore')
class PaymentMethodFlowStep extends React.Component {
    constructor(props) {
        super(props);

        const {
            uiStore: { loadingStore, toastStore },
            store,
        } = this.props;
        this.paymentMethodStore = new PaymentMethodStore(
            store.membershipId,
            Constants.HOLDER_TYPE.MEMBERSHIP,
            loadingStore,
            toastStore,
            store.modalType
        );
        this.componentPaths = [
            { path: 'type', component: PaymentMethodChoice },
            { path: 'ach-plaid-choice', component: ACHPlaidChoice },
            { path: 'ach-explanations', component: ACHExplanations },
            { path: 'ach', component: ACHForm },
            { path: 'credit-card', component: CreditCardForm },
        ];

        this.state = {
            paymentMethod: null,
            subscriptionParentMatchURL: '',
        };
    }

    nextStep = () => {
        const { onComplete, stepKey, store, match, history } = this.props;
        UpdateStateStoreUtils(store, 'touched', true);
        store.isDayPass &&
        store.paymentMethod.type.toUpperCase() === Constants.PAYMENT_METHOD_TYPES.ACH &&
        store.paymentMethod.status === Constants.ACH_STATUS.UNVERIFIED
            ? history.push(`${match.url}`)
            : onComplete(stepKey, true);
    };

    callEntryAction = async (path) => {
        const { t, store, storeKey } = this.props,
            { paths } = this.paymentMethodStore;
        if (
            storeKey === 'paymentMethodUpdated' &&
            path.toUpperCase() === Constants.PAYMENT_METHOD_TYPES.ACH
        ) {
            store.paymentMethod = {
                status: Constants.ACH_STATUS.UNVERIFIED,
                type: path,
            };
        }
        const entry = Object.values(paths).find((entry) => (entry.path || '') === path);

        const result = await entry.action(t);

        if (result) {
            this.nextStep();
        }
    };

    renderCurrentPaymentMethod = (renderProps) => {
        const { match, store, t } = this.props,
            { paymentMethod } = this.state;

        return (
            <Fragment>
                <CurrentPaymentMethod
                    {...renderProps}
                    parentMatchURL={match.url}
                    membershipId={store.membershipId}
                    editPath={'type'}
                    onLoaded={this.paymentMethodLoaded}
                />

                <button
                    type="button"
                    className="btn btn-primary w-100 bottom-action"
                    onClick={this.nextStep}
                    disabled={
                        !paymentMethod ||
                        (store.isDayPass &&
                            paymentMethod.paymentMethodType ===
                                Constants.PAYMENT_METHOD_TYPES.ACH &&
                            paymentMethod.status === Constants.ACH_STATUS.UNVERIFIED)
                    }
                >
                    {t('next')}
                </button>
            </Fragment>
        );
    };

    paymentMethodLoaded = (paymentMethod) => {
        const { history, match, store } = this.props;
        if (!paymentMethod) {
            history.push(`${match.url}/type`);
        } else {
            this.setState({ paymentMethod });
            store.paymentMethod.status = paymentMethod.status;
            store.paymentMethod.type = paymentMethod.paymentMethodType;
        }
    };

    renderStep = (renderProps) => {
        const { t, match, parentMatchURL } = this.props,
            { paths } = this.paymentMethodStore;

        const path = renderProps.match.url.substring(this.props.match.url.length + 1);
        const entry = Object.values(paths).find((entry) => (entry.path || '') === path);
        const back = paths[entry.back];
        const componentPath = this.componentPaths.find((com) => com.path === entry.path);

        return (
            <Fragment>
                <Elements>
                    <div className="content d-flex flex-column">
                        {entry.title ? (
                            <div className="step-header p-3">
                                {back && (
                                    <Link
                                        className="close"
                                        aria-label="Close"
                                        to={
                                            back.path
                                                ? `${match.url}/${back.path}`
                                                : match.url
                                        }
                                        replace={true}
                                    >
                                        <span aria-hidden="true">
                                            <Back fill="currentColor" size="24px" />
                                        </span>
                                    </Link>
                                )}
                                <h4 className="text-center mx-auto mt-0 text-uppercase">
                                    {t(entry.title)}
                                </h4>
                            </div>
                        ) : null}

                        <componentPath.component
                            {...renderProps}
                            store={this.paymentMethodStore}
                            action={this.callEntryAction}
                            path={entry.path}
                            parentMatchURL={match.url}
                            onSkip={this.nextStep}
                            subscriptionParentMatchURL={parentMatchURL}
                        />
                    </div>
                </Elements>
                {entry.nextPath ? (
                    <Link
                        to={`${match.url}/${entry.nextPath}`}
                        className="btn btn-primary w-100 bottom-action"
                    >
                        {t('next')}
                    </Link>
                ) : entry.action && path !== 'type' && path !== 'ach-plaid-choice' ? (
                    <button
                        type="submit"
                        className="btn btn-primary w-100 bottom-action"
                        form={entry.path}
                    >
                        {t('next')}
                    </button>
                ) : null}
            </Fragment>
        );
    };

    render() {
        const { t, match, isPanelFlow = true, modalTitle } = this.props,
            { paths } = this.paymentMethodStore;

        const switchView = (
            <Switch>
                {Object.entries(paths).map(([key, entry]) => (
                    <Route
                        key={key}
                        exact
                        path={entry.path ? `${match.url}/${entry.path}` : match.url}
                        render={this.renderStep}
                    />
                ))}

                <Route path={match.url} render={this.renderCurrentPaymentMethod} />
            </Switch>
        );

        return isPanelFlow ? (
            <Fragment>
                <InfoPane
                    title={t('Invoice.invoice_payment_method')}
                    subTitle={t('Subscription.add_payment_method')}
                    bgImgUrl={PaymentMethodBackground}
                    modalTitle={modalTitle}
                />
                <ActionPane className="bg-dark">{switchView}</ActionPane>
            </Fragment>
        ) : (
            <div className="flex-grow-1 bg-dark ">{switchView}</div>
        );
    }
}

export default translate()(PaymentMethodFlowStep);
