import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

import variables from 'Sass/variables.scss';

const LoadingSpinner = ({
    loading = false,
    color = variables.secondary,
    global = false,
}) => {
    return (
        <div
            className={
                'loading-container' +
                (global ? ' global' : '') +
                (loading ? ' loading' : '')
            }
        >
            <ClipLoader color={color} loading={loading} size={60} />
        </div>
    );
};

LoadingSpinner.propTypes = {
    loading: PropTypes.bool,
    color: PropTypes.string,
};

export default LoadingSpinner;
