import Constants from 'Data/Constants';
import sanitizeHtml from 'sanitize-html';

const StringUtils = {
    enumeration(values = [], t) {
        return !values.length
            ? ''
            : values.length === 1
            ? values[0]
            : values.length === 2 &&
              values.includes(t('Profile.pending_invitation_subtitle'))
            ? values.slice(0, values.length).join(', ')
            : values.slice(0, values.length - 1).join(', ') +
              ' ' +
              t('Member.and') +
              ' ' +
              values[values.length - 1];
    },

    subscriptionsStatusSegments(subscriptions, t) {
        let quoteCount = 0,
            editQuoteCount = 0;

        subscriptions = subscriptions || [];

        for (const subscription of subscriptions) {
            if (subscription.type === Constants.SUBSCRIPTION_TYPES.QUOTE) quoteCount++;
            else if (subscription.type === Constants.SUBSCRIPTION_TYPES.EDIT_QUOTE)
                editQuoteCount++;
        }

        const segments = [];

        if (quoteCount)
            segments.push(
                '<b>' +
                    t('Membership.subscription_proposal', {
                        number0: quoteCount,
                        count: quoteCount,
                    }) +
                    '</b>'
            );

        if (editQuoteCount)
            segments.push(
                '<b>' +
                    t('Membership.edit_proposal', {
                        number0: editQuoteCount,
                        count: editQuoteCount,
                    }) +
                    '</b>'
            );

        return segments;
    },

    invoicesStatusSegments(invoices, t) {
        let openInvoiceCount = 0,
            failedInvoiceCount = 0;

        const segments = [];

        invoices = invoices || [];

        for (const invoice of invoices) {
            if (invoice.status === Constants.INVOICE_STATUSES.AWAITING_PAYMENT)
                openInvoiceCount++;
            else if (invoice.status === Constants.INVOICE_STATUSES.FAILED)
                failedInvoiceCount++;
        }

        if (openInvoiceCount)
            segments.push(
                t('Membership.waiting_invoice', {
                    number0: openInvoiceCount,
                    count: openInvoiceCount,
                })
            );

        if (failedInvoiceCount)
            segments.push(
                t('Membership.failed_invoice', {
                    number0: failedInvoiceCount,
                    count: failedInvoiceCount,
                })
            );

        return segments;
    },

    membershipsStatusEnumeration(memberships, t) {
        if (!memberships) return '';
        const subscriptions = memberships.reduce(
            (subs, membership) => subs.concat(Array.from(membership.subscriptions)),
            []
        );
        const segments = StringUtils.subscriptionsStatusSegments(subscriptions, t);
        return !segments.length
            ? ''
            : `${t(
                  'Membership.membership_status_start_message'
              )} ${StringUtils.enumeration(segments, t)} ${t(
                  'Membership.membership_status_end_message'
              )}`;
    },

    membershipStatusEnumeration(membership, t) {
        if (!membership) return '';
        const subscriptionsSegments = StringUtils.subscriptionsStatusSegments(
            membership.subscriptions || [],
            t
        );
        const subscriptionsEnumeration = !subscriptionsSegments.length
            ? ''
            : `${StringUtils.enumeration(subscriptionsSegments, t)} ${t(
                  'Membership.membership_status_end_message'
              )}`;

        const invoicesSegments = StringUtils.invoicesStatusSegments(
            membership.openInvoices || [],
            t
        );

        const segments = [subscriptionsEnumeration, ...invoicesSegments].filter(
            (segment) => segment
        );
        return !segments.length
            ? ''
            : `${t(
                  'Membership.membership_status_start_message'
              )} ${StringUtils.enumeration(segments, t)}`;
    },

    getDisplayRole(member, count, t, number = member.roles.length) {
        if (!member) return;
        const roles = member.roles;
        if (roles.length === 1) {
            switch (roles[0]) {
                case Constants.MEMBER_ROLES.PENDING_MEMBER:
                    return t('Profile.pending_invitation_subtitle');
                case Constants.MEMBER_ROLES.ADMINISTRATOR:
                    return t('Membership.role_admin_manager');
                case Constants.MEMBER_ROLES.CREDIT_MANAGER:
                    return t('Membership.role_credit_manager');
                case Constants.MEMBER_ROLES.FINANCE_MANAGER:
                    return t('Membership.role_finance_manager');
                case Constants.MEMBER_ROLES.MEMBER:
                    return t('Membership.role_member');
                case Constants.MEMBER_ROLES.OWNER:
                    return t('Membership.role_owner');

                default:
                    return roles[0];
            }
            // return roles[0] === Constants.MEMBER_ROLES.PENDING_MEMBER ?
            //     t('Profile.pending_invitation_subtitle')
            //     : roles[0];
        }
        return StringUtils.enumeration(
            roles
                .filter((role) => role !== Constants.MEMBER_ROLES.MEMBER)
                .slice(0, number)
                .map((role) => {
                    switch (role) {
                        case Constants.MEMBER_ROLES.PENDING_MEMBER:
                            return t('Profile.pending_invitation_subtitle', { count });
                        case Constants.MEMBER_ROLES.ADMINISTRATOR:
                            return t('Membership.role_admin_manager', { count });
                        case Constants.MEMBER_ROLES.CREDIT_MANAGER:
                            return t('Membership.role_credit_manager', { count });
                        case Constants.MEMBER_ROLES.FINANCE_MANAGER:
                            return t('Membership.role_finance_manager', { count });
                        case Constants.MEMBER_ROLES.MEMBER:
                            return t('Membership.role_member', { count });
                        case Constants.MEMBER_ROLES.OWNER:
                            return t('Membership.role_owner', { count });

                        default:
                            return role;
                    }
                }),
            t
        );
    },

    getDisplayRights(rights, roles, t) {
        let display = '';

        if (rights.includes(Constants.MEMBERSHIP_RIGHTS.SUBSCRIBE_PLAN))
            display += t('Membership.role_info_subscribe_plan');

        if (rights.includes(Constants.MEMBERSHIP_RIGHTS.EDIT_MEMBERS))
            display += t('Membership.role_info_edit_members');

        if (rights.includes(Constants.MEMBERSHIP_RIGHTS.CREDITING))
            display += t('Membership.role_info_crediting');

        if (rights.includes(Constants.MEMBERSHIP_RIGHTS.FINANCE))
            display += t('Membership.role_info_finance');

        if (roles.includes(Constants.MEMBER_ROLES.OWNER))
            display += t('Membership.role_info_owner');

        if (roles.includes(Constants.MEMBER_ROLES.MEMBER) && !display.length)
            display += t('Membership.role_info_member');

        return display;
    },

    getDisplayConflictReason(reason, t) {
        const { CONFLICT_REASONS } = Constants;
        switch (reason) {
            case CONFLICT_REASONS.CONFLICT:
                return t('Booking.booking_conflict_message');
            case CONFLICT_REASONS.CONTINUOUSLY:
                return t('Booking.booking_continuous_message');
            case CONFLICT_REASONS.CREDIT:
                return t('Booking.booking_no_credits_message');
            case CONFLICT_REASONS.LIMIT_REACHED:
                return t('Booking.booking_limit_reached_message');
            default:
                return '';
        }
    },

    sanitize(dirtyHtml, config) {
        config = Object.assign(
            {
                transformTags: {
                    h1: 'h5',
                    h2: 'h5',
                    h3: 'h5',
                    h4: 'h5',
                },
            },
            config || {}
        );
        const sanitizedHtml = sanitizeHtml(dirtyHtml, config);
        return sanitizedHtml;
    },

    isEmpty(string) {
        const regex = /(<([^>]+)>)/gi;
        const result = string
            .replace(regex, '')
            .replace(/&nbsp;/gi, ' ')
            .trim();
        return !result.replace(/\s/g, '').length;
    },
};

export default StringUtils;
