import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import svgPanZoom from 'svg-pan-zoom';
import classnames from 'classnames';
import FloorPlanUnit from './FloorPlanUnit';

function FloorPlanSvg({ floor, units, onUnitInformationClick, className, unitId, isModal = false }) {
    const [svgInstance, setSvgInstance] = useState(null);
    const [selectedSvgElementId, setSelectedSvgElementId] = useState(null);
    const svgRef = useRef(null);
    const svgRefWrap = useRef(null);

    useEffect(() => {
        if (floor.svg) {
            generateSVG(floor.svg);
            setSelectedSvgElementId(null);
        }
    }, [floor.svg]); // Only re-run the effect if floor changes

    // if unitId is provided center to this unit
    useEffect(() => {
        if (svgInstance && unitId) {
            const unit = units.find((unit) => unit.id === unitId);
            if (unit) {
                svgInstance.zoom(2.25);
                const realZoom = svgInstance.getSizes().realZoom;
                svgInstance.pan({
                    x:
                        -(unit.poleOfInaccessibility.x * realZoom) +
                        svgInstance.getSizes().width / 2,
                    y:
                        -(unit.poleOfInaccessibility.y * realZoom) +
                        svgInstance.getSizes().height / 2,
                });
            }
            if (isModal) {
                svgInstance.disablePan();
            }
        }
    }, [svgInstance, unitId]);

    const generateSVG = async (base64SVG) => {
        if (svgInstance) {
            svgInstance.destroy();
        }

        var decodedSVG = atob(base64SVG);

        svgRef.current.innerHTML = decodedSVG;

        setSvgInstance(
            svgPanZoom(svgRefWrap.current, {
                controlIconsEnabled: true,
                mouseWheelZoomEnabled: false,
                zoomScaleSensitivity: 0.4,
            })
        );
    };

    const handleClick = (unit) => {
        if (!isModal) {
            if (selectedSvgElementId === unit.svgElementId) {
                setSelectedSvgElementId(null);
            } else {
                setSelectedSvgElementId(unit.svgElementId);
            }
        }
    };

    return (
        <div className={classnames('floorplan-svg-container', className)}>
            <div className="w-100">
                <svg className="w-100 h-100" ref={svgRefWrap}>
                    <svg className="w-100 h-100 overflow-visible" ref={svgRef} />
                    {/* We need to keep this svg outside the condition/map. Without it, svg-pan-zoom crashes when recreating an instance */}
                    <svg style={{ overflow: 'visible' }}>
                        {units &&
                            units.map((unit) => {
                                const isSelected =
                                    unit.svgElementId === selectedSvgElementId;
                                return (
                                    <FloorPlanUnit
                                        className={
                                            selectedSvgElementId
                                                ? isSelected
                                                    ? 'selected'
                                                    : 'unselected'
                                                : null
                                        }
                                        key={unit.svgElementId}
                                        unit={unit}
                                        onClick={handleClick}
                                        onUnitInformationClick={onUnitInformationClick}
                                        isSelected={isSelected}
                                    />
                                );
                            })}
                    </svg>
                </svg>
            </div>
        </div>
    );
}

FloorPlanSvg.propTypes = {
    floor: PropTypes.object,
    units: PropTypes.array,
    className: PropTypes.string,
};

export default FloorPlanSvg;
