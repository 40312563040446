import React from 'react';

const ShowMap = ({ size, fill, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'}>
            <path d="M124 18.2L87.2 2.6l-3-1.3c-.1 0-.2-.1-.3-.1-.6-.2-1.3-.1-1.9.1L43 18.2l-.5-.2-1-.4L3.8 1.5c-.9-.3-1.8-.2-2.6.3S-.1 3.2-.1 4.1v104.2c0 1 .6 2 1.5 2.5l40.8 21.3c.4.2.8.3 1.3.3s.9-.1 1.3-.3l25.6-13.9c1.4-.7 1.9-2.4 1.1-3.8-.7-1.4-2.4-1.9-3.8-1.1L44.2 126l-.4-102.1c.1 0 .2-.1.4-.1L81.9 7.4V53c0 1.5 1.2 2.8 2.8 2.8 1.5 0 2.8-1.2 2.8-2.8V8.8l32.6 13.8-.4 28.4c0 1.5 1.2 2.8 2.8 2.8 1.5 0 2.8-1.2 2.8-2.8l.5-30.3c0-1-.7-2.1-1.8-2.5zM5.5 8.3l32.7 13.8.4 101.7-33.1-17.2V8.3z" />
            <path d="M42.5 18l-1-.4c.4 0 .7.2 1 .4z" />
        </g>
        <path
            fill={fill || 'currentColor'}
            d="M107.1 103.4c-7 0-12.7-5.7-12.7-12.7S100.1 78 107.1 78c7 0 12.7 5.7 12.7 12.7s-5.7 12.7-12.7 12.7zm0-19.8c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1z"
        />
        <path
            fill={fill || 'currentColor'}
            d="M107.1 142.9c-.8 0-1.5-.3-2-.9-.9-1-22.8-24.3-29.6-34.4-3.1-4.6-5.4-11.9-5.4-16.9 0-20.4 16.6-37 37-37s37 16.6 37 37c0 5-2.3 12.2-5.4 16.9-6.7 10.1-28.6 33.4-29.6 34.4-.5.6-1.3.9-2 .9zm0-83.6c-17.3 0-31.4 14.1-31.4 31.4 0 3.9 2 10 4.5 13.8 5.3 8 21.3 25.4 26.9 31.5 5.7-6.1 21.6-23.5 26.9-31.5 2.5-3.7 4.5-9.9 4.5-13.8 0-17.3-14.1-31.4-31.4-31.4z"
        />
    </svg>
);

export default ShowMap;
