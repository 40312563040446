import React from 'react';
import { observer, inject } from 'mobx-react';

import LoadingSpinner from './LoadingSpinner';

@inject('uiStore')
@observer
class GlobalLoadingSpinner extends React.Component {
    render() {
        return (
            <LoadingSpinner
                loading={this.props.uiStore.loadingStore.isLoading}
                global={true}
            />
        );
    }
}

export default GlobalLoadingSpinner;
