import { action, computed, keys, observable, set, values } from 'mobx';
import HttpService from 'Services/HttpService';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

class RecurringBookingStore {
    @observable recurrenceOption = null;
    @observable recurrenceDuration = null;
    @observable conflicts = { data: {} };
    @observable currentConflictId = '';

    @action reset() {
        this.recurrenceOption = null;
        this.recurrenceDuration = null;
        this.conflicts = { data: {} };
        this.currentConflictId = '';
    }

    constructor(bookingStore) {
        this.bookingStore = bookingStore;
    }

    @computed get isComplete() {
        if (!this.bookingStore.recurring) return true;

        return !!(
            this.recurrenceDuration &&
            this.recurrenceOption &&
            this.conflicts.loaded &&
            (!keys(this.conflicts.data).length ||
                values(this.conflicts.data).every((conflict) => conflict.treated))
        );
    }

    @computed get hasRemainingConflicts() {
        return (
            keys(this.conflicts.data).length &&
            values(this.conflicts.data).some((conflict) => !conflict.treated)
        );
    }

    @computed get currentConflict() {
        return this.conflicts.data[this.currentConflictId];
    }

    @computed get recurrence() {
        if (!this.bookingStore.recurring) return null;

        const recurrence = {};
        if (this.recurrenceOption) {
            const {
                recurrence: { type, frequency, options },
            } = this.recurrenceOption;
            recurrence.type = type;
            recurrence.frequency = frequency;
            recurrence.options = options;
        }
        if (this.recurrenceDuration) {
            const { occurrences, date } = this.recurrenceDuration;
            recurrence.occurrences = occurrences;
            recurrence.endDate = date;
        }
        return recurrence;
    }

    @computed get exceptions() {
        if (!this.bookingStore.recurring) return null;

        if (!this.conflicts.data) return [];
        return Object.values(this.conflicts.data)
            .filter((conflict) => conflict.treated)
            .map((conflict) => ({
                id: conflict.id,
                periodStart: conflict.periodStart,
                periodEnd: conflict.periodEnd,
                amenityId: conflict.amenityId,
                isCancelled: conflict.isCancelled,
            }));
    }

    @action loadSummaryAndConflicts = async () => {
        if (!this.bookingStore.conferenceRoomId) return null;
        try {
            const request = {
                bookingId: this.bookingStore.bookingId || null,
                creditBalanceId: this.bookingStore.creditBalanceId,
                periodStart: this.bookingStore.bookingStart,
                periodEnd: this.bookingStore.bookingEnd,
                isPrivate: this.bookingStore.isPrivate,
                recurrence: this.bookingStore.recurring ? this.recurrence : null,
                exceptions: this.bookingStore.recurring ? this.exceptions : null,
                bookingCreatorId: this.bookingStore.bookingOrganizer,
            };
            const { data } = await HttpService.bookingSummary({
                conferenceRoomId: this.bookingStore.conferenceRoomId,
                data: request,
            });
            let newExceptions = {};
            data.occurrences
                .filter((occ) => occ.hasConflict)
                .forEach((occ) => {
                    occ.amenity = data.amenity;
                    occ.isCancelled = false;
                    occ.treated = false;

                    if (!newExceptions[occ.id]) newExceptions[occ.id] = occ;
                });
            set(this.conflicts, { loaded: true });
            set(this.conflicts.data, newExceptions);
            return data;
        } catch (e) {
            return null;
        }
    };

    @action updateState(key, value) {
        if (
            key === 'recurrenceOption' &&
            this.recurrenceOption &&
            (!value || this.recurrenceOption.name !== value.name)
        ) {
            this.updateState('recurrenceDuration', null);
        }

        if (
            key === 'recurrenceDuration' &&
            this.recurrenceDuration &&
            (!value || this.recurrenceDuration.name !== value.name)
        ) {
            this.conflicts = { data: {} };
        }
        UpdateStateStoreUtils(this, key, value);
    }

    @action updateConflict(conflictId, newValues) {
        const conflict = this.conflicts.data[conflictId];
        if (!conflict) return;
        Object.keys(newValues).forEach((key) => {
            conflict[key] = newValues[key];
        });
    }

    @action
    async seed() {
        const { default: seedData } = await import('../Seeds/RecurringBookingStoreSeed');
        Object.keys(seedData).forEach((key) => {
            this[key] = seedData[key];
        });
    }
}

export default RecurringBookingStore;
