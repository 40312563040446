import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Link, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';

import HttpService from 'Services/HttpService';
import ModelStateStore from 'Stores/ModelStateStore';
import Constants from 'Data/Constants';
import { isEmpty } from 'Utils/ObjectUtils';
import SubscriptionDetailModal from './SubscriptionDetailModal';
import SubscriptionEditModal from './SubscriptionEditModal';
import {
    SubscriptionOverview,
    SubscriptionPaymentModalities,
    SubscriptionStatus,
} from 'Components/Subscription';
import {
    CheckOutline,
    DeclineCircle,
    DeclineOutline,
    Error,
    I,
    Selection,
    X,
} from 'Components/Icons';
import { NoResults } from 'Components/Loading';
import ScrollUtils from 'Utils/ScrollUtils';
import { ListItemHeader } from 'Components/Forms';
import LegalContactStep from 'Components/Membership/LegalContactStep';

@inject('uiStore', 'dashboardStore')
@observer
class SubscriptionDetail extends React.Component {
    static propTypes = {
        subscriptionId: PropTypes.string.isRequired,
        parentMatchURL: PropTypes.string.isRequired,
        backIcon: PropTypes.node,
        onComplete: PropTypes.func,
        onLoaded: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            membership: null,
            subscription: null,
            consentChecked: false,
            shouldAskConsent: false,
            consent: null,
            errored: false,
        };

        this.modelStateStore = new ModelStateStore();
    }

    formatMembership() {
        const { membership } = this.state;
        return (
            membership && {
                name: membership.name,
                imageUrl: membership.imageUrl,
                members: membership.members.map((member) => ({
                    id: member.id,
                    imgSrc: member.imageUrl,
                    name: `${member.firstName} ${member.lastName}`,
                })),
                rights: membership.rights,
                legalContact: membership.legalContact,
            }
        );
    }

    canEditSubscription = () => {
        const { subscription, membership } = this.state;
        return (
            subscription &&
            !subscription.linked?.length &&
            membership &&
            membership.rights.includes(Constants.MEMBERSHIP_RIGHTS.SUBSCRIBE_PLAN)
        );
    };

    componentDidMount() {
        this.loadSubscription();

        if (this.state.membership && this.state.membership)
            this.scrollUtils.scrollToRef();
    }

    componentWillUnmount() {
        this.cancellation.cancel();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.subscriptionId !== this.props.subscriptionId &&
            this.props.subscriptionId
        ) {
            this.setState({
                membership: null,
                subscription: null,
                consentChecked: false,
            });
            this.loadSubscription();
        }

        if (
            prevState.membership !== this.state.membership &&
            !prevState.membership &&
            this.state.membership
        )
            this.scrollUtils.scrollToRef();
    }

    loadMembership = async (membershipId) => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        try {
            const { data: membership } = await HttpService.membershipDetail(
                membershipId,
                this.cancellation
            );
            this.setState({ membership, errored: false });
        } catch (error) {
            if (this.cancellation.isCancelled(error)) return;
            this.setState({ errored: true });
        }
    };

    loadSubscription = async () => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();
        const {
            subscriptionId,
            uiStore: { loadingStore },
        } = this.props;

        loadingStore.addLoading();
        try {
            const response = await HttpService.subscription(
                subscriptionId,
                this.cancellation
            );
            const subscription = response.data;
            this.setState({ subscription, errored: false });
            if (this.props.onLoaded) this.props.onLoaded();
            this.scrollUtils.scrollToRef();
            this.loadMembership(subscription.membershipId);
        } catch (error) {
            if (this.cancellation.isCancelled(error)) return;
            this.setState({ errored: true });
            if (this.props.onLoaded) this.props.onLoaded();
        } finally {
            loadingStore.removeLoading();
        }
    };

    onConsentChecked = (checked, shouldAskConsent, consent) =>
        this.setState({
            consentChecked: checked,
            shouldAskConsent: shouldAskConsent,
            consent: consent,
        });

    renderUpdateMembershipLegalContact = (routeProps) => {
        const { match } = this.props,
            { membership } = this.state;
        return (
            <LegalContactStep
                {...routeProps}
                parentMatchURL={match.url}
                membershipId={membership.id}
                membership={membership}
                isUniqueStep
                isSubscription
            />
        );
    };

    onComplete = (subscriptionExists) => {
        const {
            uiStore: { loadingStore },
            onComplete,
            parentMatchURL,
            history,
            dashboardStore,
        } = this.props;
        dashboardStore.load(loadingStore);
        onComplete();
        if (subscriptionExists) {
            this.loadSubscription();
        } else {
            history.push(parentMatchURL);
        }
    };

    onCompleteExists = () => this.onComplete(true);

    scrollUtils = new ScrollUtils();

    renderChild = (routeProps) => (
        <SubscriptionDetailModal
            {...routeProps}
            subscriptionId={routeProps.match.params.subscriptionId}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership}
            onComplete={this.onCompleteExists}
            backIcon={<X />}
            isModal
        />
    );

    renderEdit = (routeProps) => (
        <SubscriptionEditModal
            {...routeProps}
            //TODO: Pass subscription promise instead so that the child can await it and use the result too.
            subscriptionId={this.props.subscriptionId}
            parentMatchURL={this.props.match.url}
            onComplete={this.onCompleteExists}
        />
    );

    render() {
        const membership = this.formatMembership(),
            {
                t,
                match,
                parentMatchURL,
                backIcon,
                paneDetail = false,
                modal = false,
            } = this.props,
            { errored, subscription, consentChecked } = this.state;
        return membership ? (
            <React.Fragment>
                <SubscriptionOverview
                    ref={this.scrollUtils.registerRef}
                    editURL={`${match.url}/edit`}
                    canEdit={this.canEditSubscription()}
                    membership={membership}
                    subscription={subscription}
                    consentChecked={consentChecked}
                    paneDetail={paneDetail}
                    modal={modal}
                    onConsentChecked={this.onConsentChecked}
                    headerChildren={
                        subscription && (
                            <SubscriptionStatus
                                rights={membership.rights}
                                subscription={subscription}
                            />
                        )
                    }
                    headerOtherChildren={
                        <React.Fragment>
                            {!paneDetail && (
                                <Link className="close" to={parentMatchURL}>
                                    <span aria-hidden="true">{backIcon}</span>
                                </Link>
                            )}
                        </React.Fragment>
                    }
                    topRightPaneChildren={
                        subscription &&
                        membership.rights.includes(
                            Constants.MEMBERSHIP_RIGHTS.SUBSCRIBE_PLAN
                        ) && (
                            <Fragment>
                                <ListItemHeader
                                    superTitle={
                                        subscription.plan.interval ===
                                        Constants.PLAN_INTERVALS.DAILY
                                            ? t('Membership.day_pass_title')
                                            : t('Subscription.subscription')
                                    }
                                    title={t('Subscription.payment_type_title')}
                                    className={paneDetail ? 'mt-0 pb-0' : 'ml-1 pb-0'}
                                />
                                <div
                                    className={classnames(
                                        'list-group-very-compact py-3 bg-white mb-1',
                                        { 'ml-1': !paneDetail }
                                    )}
                                >
                                    <SubscriptionPaymentModalities
                                        subscription={subscription}
                                        paneDetail={paneDetail}
                                        consentChecked={consentChecked}
                                        onConsentChecked={this.onConsentChecked}
                                    />
                                </div>
                            </Fragment>
                        )
                    }
                    bottomRightPaneChildren={null}
                />
                
                {errored && (
                    <div className="row py-6">
                        <NoResults
                            className="offset-md-3 col-md-6 col-12"
                            icon={<Error size="5em" />}
                            title={t('Error.error')}
                            subTitle={t('Error.error_message')}
                            action={{
                                text: t('Error.retry'),
                                onClick: this.loadSubscription,
                            }}
                        />
                    </div>
                )}
                <Switch>
                    <Route
                        path={`${match.url}/child/:subscriptionId`}
                        render={this.renderChild}
                    />
                    <Route path={`${match.url}/edit`} render={this.renderEdit} />
                    <Route
                        path={`${match.url}/edit-legal-contact`}
                        render={this.renderUpdateMembershipLegalContact}
                    />
                </Switch>
            </React.Fragment>
        ) : null;
    }
}

export default translate()(SubscriptionDetail);
