import { inject } from 'mobx-react';
import { translate } from 'react-i18next';
import qs from 'qs';

import HttpService from 'Services/HttpService';

import ActionBase from './ActionBase';

async function handleConfirmAccountActionError(error) {
    if (error.response) {
        if (error.response.data && error.response.data.error === 'E005407') {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        } else if (error.response.status === (400 || 404)) {
            this.setState({
                loginTextKey: 'Account.login',
                loginPath: `/`,
                resultMessageKey: 'Account.account_already_confirmed',
            });
            return true;
        } else if (error.response.status) {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        }
    }
    return false;
}

@inject('uiStore', 'seedStore', 'authStore')
class ConfirmAccount extends ActionBase {
    constructor(props) {
        super(props);
        const { seedStore } = this.props;
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        this.props.authStore.logout();
        this.actionCall = async () => {
            await HttpService.confirmAccountByToken({
                email: params.email,
                token: params.token,
                ignoreErrorToast: true,
            });
            let link = '';
            let storeLink = '/';
            let loginText = 'Account.login';

            if (typeof window.orientation !== 'undefined') {
                if (navigator.userAgent.match(/Android/i)) {
                    storeLink = 'market://details?id=com.appcom.coworkingspace';
                    link = 'cg://account/confirmed';
                } else {
                    storeLink = 'itms-apps://commongrounds-coworking/id1162971589?mt=8';
                    link = 'CGCC://confirmed';
                }
                loginText = 'Account.skip_message';
            }
            seedStore.set('firstLogin', true);
            this.setState({
                loginTextKey: loginText,
                loginPath: '/',
                deepLink: link,
                storeLink: storeLink,
                resultMessageKey: 'Account.account_successfully_confirmed',
            });
        };

        this.handleError = handleConfirmAccountActionError.bind(this);
    }

    prepareState = () => {
        this.setState({
            loginPath: `/`,
        });
    };
}

export { handleConfirmAccountActionError };

export default translate()(ConfirmAccount);
