import { action, computed, observable } from 'mobx';
import moment from 'moment';

import HttpService from 'Services/HttpService';
import Constants from 'Data/Constants';
import { sortBy } from 'Utils/ObjectUtils';

class DashboardStore {
    constructor(eventBus) {
        this.eventBus = eventBus;
    }

    @observable cancellation = null;

    // TODO: Save this data to localStorage and load from localStorage, only fetch from API when viewed.
    @observable profile = null;
    @observable memberships = null;
    @observable proposals = null;
    @observable hasUnreadMessages = false;
    @observable bookings = null;
    @observable events = null;
    @observable settings = null;
    @observable locations = null;
    @observable expertises = null;
    @observable bookingExpress = null;

    @observable plaidConfig = {
        enabled: false,
        publicKey: null,
        environment: null,
    };

    @computed get eventList() {
        let currentDate = '';
        let currentMonth = '';
        return this.events && this.events.data
            ? this.events.data.reduce((loop, currentEvent) => {
                  const momentDate = moment.parseZone(currentEvent.periodStart);
                  currentMonth = momentDate.format(
                      Constants.TEXT_DATE_EVENT_LIST_ORDER_FORMAT
                  );
                  currentDate = momentDate.format(Constants.DATE_FORMAT);
                  // group by month
                  loop[currentMonth] = loop[currentMonth] || { momentDate: momentDate };
                  // group by date
                  loop[currentMonth][currentDate] = loop[currentMonth][currentDate] || {
                      events: [],
                      momentDate: momentDate,
                  };
                  if (currentEvent.id)
                      // push event in date ref
                      loop[currentMonth][currentDate].events.push(currentEvent);
                  return loop;
              }, {})
            : [];
    }

    @computed get subscriptionProposals() {
        return (
            this.memberships &&
            this.memberships.data &&
            this.memberships.data
                .reduce(
                    (subs, membership) =>
                        subs.concat(Array.from(membership.subscriptions)),
                    []
                )
                .filter(
                    (subscription) =>
                        subscription.type === Constants.SUBSCRIPTION_TYPES.QUOTE ||
                        subscription.type === Constants.SUBSCRIPTION_TYPES.EDIT_QUOTE
                )
        );
    }

    @computed get hasProposals() {
        return !!(
            (this.subscriptionProposals && this.subscriptionProposals.length) ||
            (this.proposals && this.proposals.data && this.proposals.data.length)
        );
    }

    @action
    load = (loadingStore) => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        this.loadProfile(loadingStore);
        this.loadMemberships(loadingStore);
        this.loadProposals(loadingStore);
        this.checkUnreadmessagse(loadingStore);
        this.loadSettings(loadingStore);
        this.loadMoreEvents({ page: 0 }, loadingStore);
        this.loadLocations(loadingStore);
        this.loadExpertises(loadingStore);
        this.loadContractTypes(loadingStore);
    };

    @action
    cancel = () => {
        if (this.cancellation) this.cancellation.cancel();
    };

    @action
    loadProfile = async (loadingStore) => {
        if (!this.cancellation) return;

        this.profile = { ongoing: true, errored: false };
        loadingStore.addLoading();
        try {
            const response = await HttpService.profile(this.cancellation);
            this.profile = { data: response.data };
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.profile = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadMemberships = async (loadingStore) => {
        if (!this.cancellation) return;

        this.memberships = { ongoing: true, errored: false };
        loadingStore.addLoading();
        try {
            const profile = await HttpService.profile(this.cancellation);
            const response = await HttpService.memberships(this.cancellation);
            sortBy(response.data, 'name');
            response.data.forEach((membership) => {
                membership.members = sortBy(
                    membership.members
                        .filter((member) =>
                            membership.memberRoles.some(
                                (memberRole) =>
                                    memberRole.memberId === member.id &&
                                    memberRole.roles.includes(
                                        Constants.MEMBER_ROLES.MEMBER
                                    )
                            )
                        )
                        .map((member) => {
                            member.name = `${member.firstName} ${member.lastName}`;
                            member.imgSrc = member.imageUrl;
                            return member;
                        }),
                    'firstName'
                );
                membership.subscriptions.forEach(
                    (sub) => (sub.membershipId = membership.id)
                );
            });
            this.memberships = {
                ongoing: false,
                data: response.data.filter(
                    (membership) =>
                        //#TODO Remove after Corenet
                        membership.id !==
                        (profile.data &&
                        profile.data.hasOwnProperty('coreNetMembershipId')
                            ? profile.data.coreNetMembershipId
                            : '')
                ),
            };
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.memberships = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadProposals = async (loadingStore) => {
        if (!this.cancellation) return;

        this.proposals = { ongoing: true, errored: false };
        loadingStore.addLoading();
        try {
            const response = await HttpService.proposals(this.cancellation);
            this.proposals = { data: response.data };
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.proposals = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    checkUnreadmessagse = async (loadingStore) => {
        if (!this.cancellation) return;

        try {
            if (loadingStore) loadingStore.addLoading();
            const { data } = await HttpService.hasUnread(
                {
                    forManagers: false,
                },
                this.cancellation
            );
            this.hasUnreadMessages = data;
        } catch (e) {
        } finally {
            if (loadingStore) loadingStore.removeLoading();
        }
    };

    @action
    loadMoreEvents = async (
        { page, pageSize = Constants.DEFAULT_PAGE_SIZE, communityEvents = false },
        loadingStore
    ) => {
        if (!this.cancellation) return;

        if (!this.events) this.events = {};

        this.events = Object.assign(this.events, { ongoing: true, errored: false });
        loadingStore.addLoading();
        try {
            let response = {};
            if (communityEvents) {
                response = await HttpService.communityEvents(
                    {
                        page,
                        pageSize,
                        currentTime: new Date().toISOString(),
                    },
                    this.cancellation
                );
                response.data = response.data.items;

                response.data = response.data.map((event) => ({
                    ...event,
                    name: event.title,
                    eventType: Constants.EVENT_TYPE.COMMUNITYEVENT,
                    communityEventDetails: {
                        isAllDay: event.isAllDay,
                        isAllMonth: event.isAllMonth,
                        isExternalSpeaker: event.isExternalSpeaker,
                        isFromSystem: event.isFromSystem,
                    },
                }));
            } else {
                response = await HttpService.events(
                    {
                        page,
                        pageSize,
                        currentTime: new Date().toISOString(),
                    },
                    this.cancellation
                );
            }

            this.events = {
                data:
                    this.events.data && page !== 0
                        ? this.events.data.concat(response.data)
                        : response.data,
            };

            if (response.data.length < pageSize)
                this.eventBus.emit(Constants.EVENTS.ALL_EVENTS_LOADED);
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.events = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadSettings = async (loadingStore) => {
        if (!this.cancellation) return;

        if (!this.settings) this.settings = {};

        this.settings = Object.assign(this.settings, { ongoing: true, errored: false });
        loadingStore.addLoading();
        try {
            const response = await HttpService.settings(this.cancellation);
            this.settings = { data: response.data };
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.settings = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadBookings = async (loadingStore = null) => {
        if (!this.cancellation) return;

        if (!this.bookings) this.bookings = {};

        this.bookings = Object.assign(this.bookings, { ongoing: true, errored: false });
        loadingStore.addLoading();
        try {
            const response = await HttpService.bookings(this.cancellation);
            let bookings = response.data;

            this.bookings = { data: bookings };
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.bookings = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadLocations = async (loadingStore) => {
        if (!this.cancellation) return;

        if (!this.locations) this.locations = {};

        this.locations = Object.assign(this.locations, { ongoing: true, errored: false });
        loadingStore.addLoading();
        try {
            const response = await HttpService.campuses(false, '', this.cancellation);
            this.locations = { data: response.data };
            let campusSubscribed = [];
            response.data.forEach((location) => {
                if (location.isSubscribed)
                    if (!campusSubscribed.includes(location)) {
                        campusSubscribed.push(location);
                    }
            });

            if (campusSubscribed.length === 1) {
                this.bookingExpress = {
                    campus: campusSubscribed[0],
                };
                const { data: balances } = await HttpService.balances(
                    {
                        campusId: campusSubscribed[0].id,
                        canDebit: true,
                        canCredit: false,
                    },
                    this.cancellation
                );
            }
            return response.data;
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.locations = { errored: true };
            return null;
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadExpertises = async (loadingStore) => {
        if (!this.cancellation) return;

        this.expertises = { ongoing: true, errored: false };
        loadingStore.addLoading();
        try {
            const response = await HttpService.expertises(this.cancellation);
            this.expertises = { data: response.data };
            return response.data;
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.expertises = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    loadContractTypes = async (loadingStore) => {
        if (!this.cancellation) return;

        this.contractTypes = { ongoing: true, errored: false };
        loadingStore.addLoading();
        try {
            const response = await HttpService.contractTypes(this.cancellation);
            this.contractTypes = { data: response.data };
            return response.data;
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.contractTypes = { errored: true };
        } finally {
            loadingStore.removeLoading();
        }
    };

    @action
    clearEvents = () => {
        this.events = null;
    };

    @action
    clear = () => {
        this.cancellation = this.profile = this.memberships = this.proposals = this.bookings = this.events = this.settings = this.locations = this.expertises = this.bookingExpress = null;
        this.hasUnreadMessages = false;
    };
}

export default DashboardStore;
