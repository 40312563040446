import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { inject } from 'mobx-react';
import UpdateMembers from './UpdateMembers';
import DownloadInvoices from './DownloadInvoices';
import TransferOwnership from './TransferOwnership';
import UpdatePaymentMethod from 'WebApp/Views/Logged/PaymentMethod/UpdatePaymentMethod';
import VerifyAchAccount from 'WebApp/Views/Logged/PaymentMethod/VerifyAchAccount';
import { SubscriptionDetail } from 'WebApp/Views/Logged/Subscription';
import { MembershipDetail as MembershipDetailComponent } from 'Components/Membership';
import { Back, Error, Memberships } from 'Components/Icons';
import { NoResults } from 'Components/Loading';
import CreateMembership from './CreateMembership';
import LegalContactStep from 'Components/Membership/LegalContactStep';
import EditBillingInformation from './EditBillingInformation';
import MemberDetails from './MemberDetails';
import RemoveMembers from './RemoveMembers';
import InvoiceDetail from 'WebApp/Views/Logged/Invoice/InvoiceDetail';
import NoMatch from 'WebApp/Views/NoMatch';
import { ScrollUtils } from 'Utils/';

import Constants from 'Data/Constants';
import HttpService from 'Services/HttpService';
import qs from 'qs';
import { sortBy } from 'Utils/ObjectUtils';

@inject('uiStore', 'eventBus', 'dashboardStore', 'membershipStore')
class MembershipDetail extends React.Component {
    static propTypes = {
        membershipId: PropTypes.string.isRequired,
    };

    state = {
        membership: { ongoing: true },
        invoices: { ongoing: false },
        jobPosts: { ongoing: false },
        bookings: { ongoing: true },
        members: { ongoing: true },
    };

    componentDidMount() {
        this.loadMembership();
        this.unregisterSubscriptionHandler = this.props.eventBus.on(
            Constants.EVENTS.SUBSCRIPTION_ADDED,
            this.loadMembership
        );
        this.unregisterPaymentMethodHandler = this.props.eventBus.on(
            Constants.EVENTS.PAYMENTMETHOD_REMOVED,
            this.loadMembership
        );
    }

    componentWillUnmount() {
        this.cancellation.cancel();
        if (this.invoiceCancellation) this.invoiceCancellation.cancel();
        this.unregisterSubscriptionHandler();
        this.unregisterPaymentMethodHandler();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.membershipId !== this.props.membershipId) {
            this.loadMembership();
        }
    }

    loadMembership = async (isUpdate = false) => {
        const {
            uiStore: { loadingStore },
            history,
            membershipStore,
            store,
        } = this.props;

        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        loadingStore.addLoading();
        this.setState({ membership: { ongoing: true } });
        try {
            const membership = (
                await HttpService.membershipDetail(
                    this.props.membershipId,
                    this.cancellation
                )
            ).data;
            // //TODO: Sort members by role.
            membership.members.forEach((member) => {
                const roles = membership.memberRoles.filter(
                    (memberRole) => memberRole.memberId === member.id
                );
                member.roles = roles.reduce(
                    (agg, memberRole) => agg.concat(memberRole.roles),
                    []
                );
                member.roleIndex = membershipStore.getRoleIndex(member.roles);
            });
            membership.emailInvitations.forEach((member) => {
                const roles = membership.emailInvitationRoles.filter(
                    (memberRole) => memberRole.invitationId === member.id
                );
                member.roles = roles.reduce(
                    (agg, memberRole) => agg.concat(memberRole.roles),
                    [Constants.MEMBER_ROLES.PENDING_MEMBER]
                );
                member.roleIndex = membershipStore.getRoleIndex(member.roles);
                member.firstName = member.email;
                member.lastName = '';
                member.isEmailInvitation = true;
            });

            membership.members = sortBy(
                [...membership.members, ...membership.emailInvitations],
                'firstName'
            );
            this.setState(
                { membership: { data: membership }, members: membership.members },
                () => {
                    this.loadJobPosts();
                    this.loadBookings();
                }
            );
            if (!store && !isUpdate) this.scrollUtils.scrollToRef();
            if (membership.rights.includes(Constants.MEMBERSHIP_RIGHTS.FINANCE)) {
                this.loadInvoices();
            }
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;

            if (
                err.response &&
                err.response.data &&
                err.response.data.error &&
                err.response.data.error.includes('E004008')
            ) {
                history.replace('/dashboard/company');
            } else {
                this.setState({ membership: { errored: true } });
            }
        } finally {
            loadingStore.removeLoading();
        }
    };

    updateMembers = async (memberId, newRoles) => {
        const {
                uiStore: { loadingStore },
            } = this.props,
            { members } = this.state;
        try {
            loadingStore.addLoading();
            let array = [...members]; // make a separate copy of the array
            const memberIndex = array.findIndex((member) => member.id === memberId);
            if (memberIndex !== -1) {
                if (
                    array[memberIndex].roles.includes(
                        Constants.MEMBER_ROLES.PENDING_MEMBER
                    )
                )
                    array[memberIndex].roles = [
                        Constants.MEMBER_ROLES.PENDING_MEMBER,
                        ...newRoles,
                    ];
                else array[memberIndex].roles = newRoles;
            }
            this.setState({ members: array });
        } finally {
            loadingStore.removeLoading();
        }
    };

    loadBookings = async () => {
        const {
            uiStore: { loadingStore },
        } = this.props;
        this.cancellation = HttpService.cancellable();
        loadingStore.addLoading();
        this.setState({ bookings: { ongoing: true } });
        const request = {
            pastBookingsOnly: true,
        };
        try {
            const { data } = await HttpService.bookingsMembership(
                {
                    id: this.state.membership.data.id,
                    request,
                },
                this.cancellation
            );
            this.setState({
                bookings: {
                    data: data.slice(0, Constants.DEFAULT_VISIBLE_LIST_ITEMS),
                    ongoing: false,
                },
            });
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError))
                this.setState({ bookings: { ongoing: false } });
        } finally {
            loadingStore.removeLoading();
        }
    };

    loadJobPosts = async (id) => {
        const {
            uiStore: { loadingStore },
        } = this.props;

        if (this.jobCancellation) this.jobCancellation.cancel();
        this.jobCancellation = HttpService.cancellable();

        loadingStore.addLoading();
        this.setState({ jobPosts: { ongoing: true } });
        const request = {
            request: {
                membershipIds: [this.state.membership.data.id],
            },
        };
        try {
            const { data: results } = await HttpService.jobPosts(
                qs.stringify(request),
                this.cancellation
            );
            this.setState({
                jobPosts: {
                    data: results,
                    ongoing: false,
                    currentJobPost: id ? id : null,
                },
            });
        } catch (err) {
            if (!this.jobCancellation.isCancelled(err)) {
                this.setState({ jobPosts: { errored: true, ongoing: false } });
            }
        } finally {
            loadingStore.removeLoading();
        }
    };

    loadInvoices = async () => {
        const {
            uiStore: { loadingStore },
        } = this.props;

        if (this.invoiceCancellation) this.invoiceCancellation.cancel();
        this.invoiceCancellation = HttpService.cancellable();

        loadingStore.addLoading();
        this.setState({ invoices: { ongoing: true } });
        try {
            const invoices = (
                await HttpService.membershipInvoices(
                    this.props.membershipId,
                    this.invoiceCancellation
                )
            ).data;
            this.setState({ invoices: { data: invoices, ongoing: false } });
        } catch (err) {
            if (!this.invoiceCancellation.isCancelled(err)) {
                this.setState({ invoices: { errored: true, ongoing: false } });
            }
        } finally {
            loadingStore.removeLoading();
        }
    };

    leaveMembership = async (id) => {
        const {
            uiStore: { simpleModalStore, loadingStore },
            dashboardStore,
            t,
            history,
        } = this.props;

        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Memberships size="3.75em" />,
                title: t('Membership.leave_membership'),
                message: t('Membership.leave_membership_detail'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        try {
            loadingStore.addLoading();
            await HttpService.leaveMembership(id);
            dashboardStore.loadMemberships(loadingStore);
            history.replace('/dashboard/company');
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    scrollUtils = new ScrollUtils();

    renderDetail = (routeProps) => (
        <MembershipDetailComponent
            {...routeProps}
            ref={this.scrollUtils.registerRef}
            membership={this.state.membership.data}
            members={this.state.members}
            matchUrl={this.props.match.url}
            onLeaveMembership={this.leaveMembership}
            invoices={this.state.invoices}
            jobPosts={this.state.jobPosts}
            bookings={this.state.bookings}
            onUpdate={this.loadMembership}
            updateMembers={this.updateMembers}
            reloadInvoices={this.loadInvoices}
            reloadJobPosts={this.loadJobPosts}
        />
    );

    renderAddMembers = (routeProps) => (
        <UpdateMembers
            {...routeProps}
            onUpdate={this.loadMembership}
            members={this.state.members}
            parentMatchURL={this.props.match.url}
            membershipId={this.props.membershipId}
        />
    );

    renderRemoveMembers = (routeProps) => (
        <RemoveMembers
            {...routeProps}
            membershipId={this.props.membershipId}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
        />
    );

    renderDownloadInvoices = (routeProps) => (
        <DownloadInvoices
            {...routeProps}
            invoices={this.state.invoices}
            membershipId={this.props.membershipId}
        />
    );

    renderUpdatePaymentMethod = (routeProps) => (
        <UpdatePaymentMethod
            {...routeProps}
            holderId={this.props.membershipId}
            holderType={Constants.HOLDER_TYPE.MEMBERSHIP}
            parentMatchURL={this.props.match.url}
            onComplete={this.loadMembership}
        />
    );

    renderVerifyAchAccount = (routeProps) => (
        <VerifyAchAccount
            {...routeProps}
            paymentMethodId={routeProps.match.params.paymentMethodId}
            parentMatchURL={this.props.match.url}
            onComplete={this.loadMembership}
        />
    );

    renderSubscription = (routeProps) => (
        <SubscriptionDetail
            {...routeProps}
            subscriptionId={routeProps.match.params.subscriptionId}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
            backIcon={<Back />}
        />
    );

    renderTransferOwnership = (routeProps) => (
        <TransferOwnership
            {...routeProps}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
        />
    );

    renderEdit = (routeProps) => (
        <CreateMembership
            {...routeProps}
            membership={this.state.membership.data}
            onUpdate={this.loadMembership}
            parentMatchURL={this.props.match.url}
        />
    );

    renderEditLegalContact = (routeProps) => (
        <LegalContactStep
            {...routeProps}
            parentMatchURL={this.props.match.url}
            membershipId={this.props.membershipId}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
            isUniqueStep
        />
    );

    renderEditBillingName = (routeProps) => (
        <EditBillingInformation
            {...routeProps}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
        />
    );

    renderMemberDetails = (routeProps) => (
        <MemberDetails
            {...routeProps}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership.data}
            onComplete={this.loadMembership}
        />
    );

    renderInvoiceDetails = (routeProps) => (
        <InvoiceDetail
            {...routeProps}
            parentMatchURL={this.props.match.url}
            membership={this.state.membership.data}
            onTry={this.loadMembership}
            renderVerifyAchAccount={this.renderVerifyAchAccount}
        />
    );

    render() {
        const { match, t } = this.props,
            { membership } = this.state;
        return membership.errored ? (
            <NoResults
                className="p-2 m-auto"
                icon={<Error size="3em" />}
                title={t('Tab.tab_membership_title')}
                subTitle={t('Error.error_message')}
                action={{ text: t('Error.retry'), onClick: this.loadMembership }}
            />
        ) : (
            <div>
                <Switch>
                    <Route
                        path={`${match.url}/subscriptions/:subscriptionId`}
                        render={this.renderSubscription}
                    />
                    <Route
                        path={`${match.url}/members/:memberId`}
                        render={this.renderMemberDetails}
                    />
                    <Route
                        path={`${match.url}/invoices/:invoiceId`}
                        render={this.renderInvoiceDetails}
                    />
                    <Route path={`${match.url}/edit`} render={this.renderDetail} />
                    <Route
                        path={`${match.url}/edit-legal-contact`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/edit-billing-name`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/download-invoices`}
                        render={this.renderDetail}
                    />
                    <Route path={`${match.url}/add-members`} render={this.renderDetail} />
                    <Route
                        path={`${match.url}/remove-members`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/transfer-ownership`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/remove-members`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/update-payment-method`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/verify-ach-account/:paymentMethodId`}
                        render={this.renderDetail}
                    />
                    <Route
                        path={`${match.url}/subscribe-to-a-plan`}
                        render={this.renderDetail}
                    />
                    <Route exact path={match.url} render={this.renderDetail} />
                    <Route component={NoMatch} />
                </Switch>

                {/* Sub-components */}
                <Route path={`${match.url}/edit`} render={this.renderEdit} />
                <Route
                    path={`${match.url}/edit-legal-contact`}
                    render={this.renderEditLegalContact}
                />
                <Route
                    path={`${match.url}/edit-billing-name`}
                    render={this.renderEditBillingName}
                />
                <Route
                    path={`${match.url}/download-invoices`}
                    render={this.renderDownloadInvoices}
                />
                <Route path={`${match.url}/add-members`} render={this.renderAddMembers} />
                <Route
                    path={`${match.url}/remove-members`}
                    render={this.renderRemoveMembers}
                />
                <Route
                    path={`${match.url}/transfer-ownership`}
                    render={this.renderTransferOwnership}
                />

                <Route
                    path={`${match.url}/update-payment-method`}
                    render={this.renderUpdatePaymentMethod}
                />
                <Route
                    path={`${match.url}/verify-ach-account/:paymentMethodId`}
                    render={this.renderVerifyAchAccount}
                />
            </div>
        );
    }
}

export default translate()(MembershipDetail);
