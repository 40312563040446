import React, { Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import qs from 'qs';

import Anonymous from 'WebApp/Views/Anonymous';
import LogIn from 'WebApp/Views/Anonymous/LogIn';
import Register from 'WebApp/Views/Anonymous/Register';

import DldApp from 'WebApp/Views/DldApp';
import ForgotPassword from 'WebApp/Views/Anonymous/ForgotPassword';
import NoMatch from 'WebApp/Views/NoMatch';

import Dashboard from 'WebApp/Views/Logged/Dashboard';

import Actions from './Actions';
import MobileBlocker from 'WebApp/Views/MobileBlocker';

@inject('authStore')
@observer
class Main extends React.Component {
    renderDashboard = (loggedIn) => (props) => {
        return (
            <Fragment>
                <MobileBlocker />
                {loggedIn ? (
                    <Dashboard {...props} />
                ) : (
                    <Redirect
                        push
                        to={`/?retUrl=${encodeURIComponent(
                            props.location.pathname + props.location.search
                        )}`}
                    />
                )}
            </Fragment>
        );
    };

    renderAnonymous = (loggedIn) => (props) => {
        let redirectToPath = '/dashboard';
        if (props.location.search) {
            const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            if (params.retUrl) {
                redirectToPath = decodeURIComponent(params.retUrl);
            }
        }
        return loggedIn ? (
            <Fragment>
                <MobileBlocker />
                <Redirect push to={redirectToPath} />
            </Fragment>
        ) : (
            <Anonymous
                child={
                    <Switch>
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/register" component={Register} />
                        <Redirect from="/corenet" to="/register" />
                        <Route path="/confirm" component={LogIn} />
                        <Route exact path="/" component={LogIn} />
                        <Route component={NoMatch} />
                    </Switch>
                }
            />
        );
    };

    render() {
        // Since this observable is only used in the render methods of the Route components,
        // we have to "register" it in the parent by accessing it if we want subsequent
        // re-renders when the store.loggedIn value changes.
        const loggedIn = this.props.authStore.loggedIn;

        return (
            <Fragment>
                <Switch>
                    <Route path="/action" component={Actions} />
                    <Redirect from="/corenet" to="/register" />
                    <Route exact path="/download-app" component={DldApp} />
                    <Route path="/dashboard" render={this.renderDashboard(loggedIn)} />
                    <Route path="/" render={this.renderAnonymous(loggedIn)} />
                </Switch>
            </Fragment>
        );
    }
}

//We have to wrap the component with withRouter because observer prevents the component from updating
export default withRouter(Main);
