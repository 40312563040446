import React from 'react';
import PropTypes from 'prop-types';

const InfoPane = ({
    title,
    subTitle,
    action,
    actionText,
    className,
    bgImgUrl,
    titleClassName,
    modalTitle,
}) => {
    return (
        <div
            className={`info-pane ${className || ''}`}
            style={{ backgroundImage: `url(${bgImgUrl})` }}
        >
            <div className="contents">
                {modalTitle}

                <h1
                    className={
                        'text-uppercase px-5 mb-5 title' +
                        (titleClassName ? ` ${titleClassName}` : '')
                    }
                >
                    {title}
                </h1>
                <h2
                    className="mb-7 px-5 sub-title"
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                />
                {actionText && (
                    <div className="more-info">
                        <button
                            id="more-info"
                            type="button"
                            className="btn btn-outline-light font-weight-medium"
                            onClick={action}
                        >
                            {actionText}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

InfoPane.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    action: PropTypes.func,
    actionText: PropTypes.string,
    className: PropTypes.string,
    bgImgUrl: PropTypes.string.isRequired,
};

export default InfoPane;
