import React from 'react';

const CheckFull = ({ fill, size }) => (
    <svg className="icon" viewBox="0 0 20 21" width={size} height={size}>
        <path
            fill={fill || 'currentColor'}
            stroke={fill || 'currentColor'}
            d="M1.651 12.777a.985.985 0 0 0-1.333 1.45l6.867 6.317A.985.985 0 0 0 8.7 20.32L19.862 1.487A.985.985 0 1 0 18.168.483L7.624 18.272l-5.973-5.495z"
        />
    </svg>
);

export default CheckFull;
