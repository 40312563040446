import React from 'react';
import colorVariables from 'Sass/variables.scss';

const MarkUnread = ({ fill, size }) => (
    <svg
        className="icon"
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill={fill || 'currentColor'}
                d="M17.977 19.562H2.467c-.311 0-.61-.085-.87-.245l6.47-6.718 2.155 1.697 2.15-1.697 6.527 6.685a1.65 1.65 0 0 1-.922.278M.794 17.89V7.446c0-.175.028-.348.08-.513l6.566 5.172-6.405 6.65a1.647 1.647 0 0 1-.241-.866M2.467 5.773h15.51c.44 0 .854.172 1.163.473l-8.918 7.04-8.927-7.032a1.67 1.67 0 0 1 1.172-.481m17.186 1.673v10.443c0 .289-.074.566-.213.812L13 12.104l6.568-5.184c.055.168.085.345.085.526m.562-1.038a2.258 2.258 0 0 0-.13-.246l-.05-.077a2.487 2.487 0 0 0-.268-.333 2.457 2.457 0 0 0-1.79-.773H2.467c-.689 0-1.337.287-1.798.782a2.494 2.494 0 0 0-.445.66A2.472 2.472 0 0 0 0 7.447V17.89c0 .662.258 1.281.728 1.747.468.464 1.086.72 1.739.72h15.51c.682 0 1.318-.274 1.79-.771.438-.459.68-1.062.68-1.696V7.446c0-.362-.08-.715-.232-1.038"
            />
            <path
                fill={colorVariables.secondary}
                d="M23.89 7.187a4.687 4.687 0 1 1-9.374 0 4.687 4.687 0 0 1 9.374 0"
            />
        </g>
    </svg>
);

export default MarkUnread;
