import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import memoize from 'memoize-one';
import { inject, observer } from 'mobx-react';

import { Delete, Member, X } from 'Components/Icons';
import { Constants } from 'Data/';
import HttpService from 'Services/HttpService';
import { RemoveMembers as RemoveMembersComponent } from 'Components/Membership';

@inject('uiStore', 'dashboardStore')
@observer
class RemoveMembers extends React.Component {
    static propTypes = {
        membership: PropTypes.object,
        onComplete: PropTypes.func.isRequired,
    };

    state = {
        membership: null,
    };

    toggle = () => {
        this.props.history.push('/dashboard/company/detail/' + this.props.membershipId);
    };

    memberProfile = memoize((membership, profile) =>
        membership.members.find((member) => member.id === profile.id)
    );

    componentDidMount() {
        this.setState({ membership: this.props.membership });
        this.checkIfAccessible();
    }

    componentDidUpdate() {
        const { membership } = this.props;
        //Only keep last loaded membership to prevent modal closing and re-opening.
        if (membership && membership !== this.state.membership) {
            this.setState({ membership: membership });
        }
        this.checkIfAccessible();
    }

    checkIfAccessible() {
        const {
                history,
                dashboardStore: { profile: profileRequest },
            } = this.props,
            { membership } = this.state;
        const profile = profileRequest && profileRequest.data;

        if (!membership || !profile) return;

        const memberProfile = this.memberProfile(membership, profile);
        if (
            !memberProfile ||
            !memberProfile.roles.some(
                (role) =>
                    role === Constants.MEMBER_ROLES.OWNER ||
                    role === Constants.MEMBER_ROLES.ADMINISTRATOR
            )
        )
            return history.push('/dashboard/company/detail/' + this.props.membershipId);
    }

    deleteMember = async (member) => {
        const {
            uiStore: { simpleModalStore, loadingStore, toastStore },
            t,
            membership,
            onComplete,
        } = this.props;
        if (
            !(await simpleModalStore.confirm({
                titleIcon: <Delete size="3.75em" />,
                title: t('Membership.remove_member_title'),
                message: t('Membership.remove_member_confirm'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;

        try {
            loadingStore.addLoading();
            if (member.isEmailInvitation) await HttpService.cancelEmailInvite(member.id);
            else
                await HttpService.removeMember({
                    membershipId: membership.id,
                    accountId: member.id,
                });
            toastStore.enqueueToast({
                message: t('Membership.success_remove_member_message'),
                icon: Member,
            });
            onComplete(true);
        } catch (error) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    render() {
        const {
                t,
                dashboardStore: { profile: profileRequest },
            } = this.props,
            { membership } = this.state;
        const profile = profileRequest && profileRequest.data;

        if (!membership || !profile) return null;

        return (
            <Modal
                isOpen={true}
                toggle={this.toggle}
                className="modal-dialog-centered modal-full-screen adaptive"
            >
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.toggle}
                    >
                        <span aria-hidden="true">
                            <X fill="currentColor" size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title mx-auto">
                        {t('Membership.delete_members')}
                    </h5>
                </div>
                <ModalBody>
                    <RemoveMembersComponent
                        members={membership.members}
                        profileMember={this.memberProfile(membership, profile)}
                        onDeleteMember={this.deleteMember}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(RemoveMembers);
