import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { observer } from 'mobx-react';

import MembershipSpecificationsItem from './MembershipSpecificationsItem';
import { Errors } from 'Components/Forms';

@observer
class MembershipSpecifications extends React.Component {
    render() {
        const {
            onChange,
            specs,
            activeSpecs,
            className,
            type,
            required,
            errors,
            id,
        } = this.props;
        const displayedErrors = errors && (errors.length ? errors : errors[id]);
        return (
            <Fragment>
                <div
                    className={`form-group text-xs title-list-expertises ${
                        className || ''
                    }`}
                >
                    {type}
                    {required && <span className="text-secondary float-right">*</span>}
                </div>
                <div className="list-group-very-compact text-sm">
                    {specs.map((expertise) => (
                        <MembershipSpecificationsItem
                            key={expertise.id}
                            className="px-5 py-1 px-md-11"
                            title={expertise.description}
                            defaultImg={<div />}
                            imgSrc={expertise.iconUrl}
                            onChange={onChange}
                            checked={activeSpecs.includes(expertise.id)}
                            id={expertise.id}
                            imgWidth="25px"
                            imgHeight="25px"
                        />
                    ))}
                    {displayedErrors && (
                        <div className="px-2">
                            <Errors errors={displayedErrors} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default translate()(MembershipSpecifications);
