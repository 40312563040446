import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PaymentMethodFlowStep from 'WebApp/Views/Logged/PaymentMethod/PaymentMethodFlowStep';

import Constants from 'Data/Constants';
import { If } from 'react-if';
import { PaymentMethod } from 'Components/Icons';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

class PaymentMethodStep extends React.Component {
    componentDidMount = () => {
        const {
            store: { paymentMethod },
            history,
            parentMatchURL,
            store,
        } = this.props;
        store.modalSize &&
            UpdateStateStoreUtils(
                store,
                'modalSize',
                store.modalSize.replace('modal-xl', '')
            );
        if (!paymentMethod) {
            history.replace(`${parentMatchURL}/update-payment-method/skip`);
        }
    };

    nextStep = () => {
        const { onComplete, stepKey } = this.props;
        onComplete(stepKey, false);
    };

    editPaymentMethod = () => {
        const { store, history, parentMatchURL } = this.props;
        UpdateStateStoreUtils(store, 'paymentMethod', true);
        history.replace(`${parentMatchURL}/update-payment-method/type`);
    };

    renderSkipChoice = () => {
        const { t } = this.props;

        return (
            <div className="flex-grow-1 d-flex flex-column bg-dark segment-body text-center">
                <div className="bg-black text-center text-sm py-2 px-10">
                    <PaymentMethod size="3em" className="mb-2 text-secondary" />
                    <div>{t('Membership.company_payment_method_step_info')}</div>
                </div>
                <div className="d-flex flex-column justify-content-center flex-grow-1">
                    <button
                        className="btn btn-primary w-100 mb-1 rounded-0"
                        onClick={this.editPaymentMethod}
                    >
                        {t('yes')}
                    </button>
                    <button
                        className="btn btn-light w-100 mb-3 rounded-0"
                        onClick={this.nextStep}
                    >
                        {t('not_for_now')}
                    </button>
                </div>
            </div>
        );
    };

    renderStep = () => {
        return <RenderStepPaymentMethod {...this.props} />;
    };

    render() {
        const { parentMatchURL, store } = this.props;
        return (
            <Fragment>
                <If condition={store.paymentMethod}>
                    <Route
                        path={`${parentMatchURL}/update-payment-method`}
                        render={this.renderStep}
                    />
                </If>
                <Route
                    exact
                    path={`${parentMatchURL}/update-payment-method/skip`}
                    render={this.renderSkipChoice}
                />
            </Fragment>
        );
    }
}

export default translate()(PaymentMethodStep);

@inject('dashboardStore', 'uiStore')
@observer
class RenderStepPaymentMethod extends React.Component {
    nextStep = () => {
        const { onComplete, stepKey } = this.props;
        onComplete(stepKey, true);
    };

    render() {
        const {
            store: { membership },
            parentMatchURL,
        } = this.props;
        return (
            <div className="d-flex flex-column  mh-0">
                <div className="scrollable d-flex flex-column">
                    <PaymentMethodFlowStep
                        {...this.props}
                        isPanelFlow={false}
                        holderId={membership.id}
                        holderType={Constants.HOLDER_TYPE.MEMBERSHIP}
                        parentMatchURL={parentMatchURL}
                        onComplete={this.nextStep}
                    />
                </div>
            </div>
        );
    }
}
