import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import NoResults from 'Components/Loading/NoResults';
import PropTypes from 'prop-types';
import HttpService from 'Services/HttpService';
import LoadingSpinner from 'Components/Loading/LoadingSpinner';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Error, Memberships, Arrow, X, Member } from 'Components/Icons';
import ListItem from 'Components/Forms/ListItem';
import MemberPictureList from 'Components/Membership/MemberPictureList';
import MembershipSpecificationsItem from 'Components/Membership/MembershipSpecificationsItem';
import Constants from 'Data/Constants';
import Campuses from 'Components/Icons/Campuses';
import { observer } from 'mobx-react';
import DashboardDetailHeader from 'Components/Dashboard/DashboardDetailHeader';
import { Modal, ModalBody } from 'reactstrap';
import { ContactProfile } from 'Components/Membership';
import { ListItemHeader } from 'Components/Forms';
import CampusDetail from 'Components/Campus/CampusDetail';
import CompanyFloorPlan from 'Components/FloorPlan/CompanyFloorPlan';
import MembershipMembersModal from 'Components/Membership/MembershipMembersModal';

const defaultMembershipImg = (
    <div className="img rounded-circle">
        <Memberships size="2em" />
    </div>
);
const defaultCampusImg = (
    <div className="img rounded-circle">
        <Campuses size="2em" />
    </div>
);
const defaultMemberImg = (
    <div className="img rounded-circle">
        <Member size="2em" />
    </div>
);

@observer
class Profile extends React.Component {
    static propTypes = {
        membershipId: PropTypes.string,
        parentMatchURL: PropTypes.string,
        modal: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            profile: { ongoing: false },
            modalProfile: { ongoing: false },
            membershipId: null,
            memberId: null,
            activeTab: null,
            modal: this.props.isOpen || false,
            campusToggle: false,
            profileToggle: false,
            companyFloorPlanToggle: false,
            membershipMembersToggle: false,
        };
    }

    toggle = () => {
        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    };

    campusToggle = (campusId) => {
        this.setState((prevState) => ({
            campusToggle: !prevState.campusToggle,
            campusId,
        }));
    };

    profileToggle = (profileId) => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
            profileId,
        }));
    };

    companyFloorPlanToggle = (closeProfile) => {
        this.setState((prevState) => ({
            companyFloorPlanToggle: !prevState.companyFloorPlanToggle,
        }));

        if(closeProfile === true) {
            this.toggle();
        }
    };

    membershipMembersToggle = () => {
        this.setState((prevState) => ({
            membershipMembersToggle: !prevState.membershipMembersToggle,
        }));
    };

    componentDidMount() {
        const { membershipId, memberId, activeTab, modal } = this.props;
        this.setState({ membershipId, memberId, activeTab, modal }, () => {
            this.loadProfile();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { membershipId, memberId, activeTab } = this.props;
        if (
            (prevProps.memberId !== memberId && memberId) ||
            (prevProps.membershipId !== membershipId && membershipId) ||
            (prevProps.activeTab !== activeTab && activeTab)
        )
            this.setState({ memberId, membershipId, activeTab }, () => {
                this.loadProfile();
            });
    }

    loadProfile = async () => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();
        this.setState({ profile: { ongoing: true } });
        const { localLoadingStore } = this.props;
        if (localLoadingStore) localLoadingStore.addLoading();

        try {
            let profile = null;
            if (this.props.isMemberProfile)
                profile = await HttpService.memberDetail(this.state.memberId);
            else if (this.state.activeTab === Constants.COMPANY_TABS.PROFILE)
                profile = await HttpService.membershipDetail(this.state.membershipId);
            else profile = await HttpService.membershipProfile(this.state.membershipId);

            this.setState({ profile: { ongoing: false, data: profile.data } });
            if (localLoadingStore) localLoadingStore.removeLoading();
            return profile.data;
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;

            this.setState({ profile: { ongoing: false, errored: true } });
            if (localLoadingStore) localLoadingStore.removeLoading();
            return null;
        }
    };

    profileContactInfo = (contact) => {
        const { profileMember, isMemberProfile } = this.props,
            { profile } = this.state;
        const membership = profile.data;
        const hasContactInfo =
            contact.phone1 ||
            contact.phone2 ||
            contact.email ||
            contact.website ||
            contact.angelListUrl ||
            contact.facebookUrl ||
            contact.instagramUrl ||
            contact.linkedInUrl ||
            contact.twitterUrl;
        return (isMemberProfile && hasContactInfo) || !isMemberProfile ? (
            <ContactProfile
                contact={contact}
                membership={membership}
                profileMember={profileMember}
                isMemberProfile={isMemberProfile}
            />
        ) : null;
    };

    profileCampuses = (campuses) => {
        const { t, isMemberProfile } = this.props;

        return campuses.length ? (
            <Fragment>
                <ListItemHeader
                    superTitle={
                        isMemberProfile
                            ? t('Profile.profile_member_title')
                            : t('Membership.company_title')
                    }
                    title={t('Campus.campus_title')}
                />
                <div
                    className={classnames(
                        'list-group-very-compact text-sm list-scrollable',
                        { 'mb-1': isMemberProfile }
                    )}
                >
                    {campuses.map((campus) => (
                        <ListItem
                            key={campus.id}
                            id={campus.id}
                            className="py-0 list-group-item-action"
                            title={<span className="pr-1">{campus.name}</span>}
                            withImage={true}
                            imgSrc={campus.mainImageUrl}
                            defaultImg={defaultCampusImg}
                            action={
                                <div className="list-group-item-status text-primary">
                                    <Arrow size="1.5em" />
                                </div>
                            }
                            clickable
                            onClick={this.campusToggle}
                        />
                    ))}
                </div>
            </Fragment>
        ) : null;
    };

    profileMemberships = (memberships) => {
        const { t, isMemberProfile } = this.props;

        return memberships.length ? (
            <Fragment>
                <ListItemHeader
                    superTitle={
                        isMemberProfile
                            ? t('Profile.profile_member_title')
                            : t('Membership.company_title')
                    }
                    title={t('Tab.tab_membership_title')}
                />
                <div
                    className={classnames(
                        'list-group-very-compact text-sm list-scrollable',
                        { 'mb-1': isMemberProfile }
                    )}
                >
                    {memberships.map((membership) => (
                        <ListItem
                            key={membership.id}
                            id={membership.id}
                            className="py-0 list-group-item-action"
                            title={<span className="pr-1">{membership.name}</span>}
                            withImage={true}
                            imgSrc={membership.imageUrl}
                            defaultImg={defaultMembershipImg}
                            action={
                                <div className="list-group-item-status text-primary">
                                    <Arrow size="1.5em" />
                                </div>
                            }
                            clickable
                            onClick={this.profileToggle}
                        />
                    ))}
                </div>
            </Fragment>
        ) : null;
    };

    profileExpertises = (expertises) => {
        const { t, isMemberProfile, profileMember, modal } = this.props;

        return !isMemberProfile ||
            (isMemberProfile && modal) ||
            (isMemberProfile && expertises.length) ? (
            <Fragment>
                <ListItemHeader
                    superTitle={
                        isMemberProfile
                            ? t('Profile.profile_member_title')
                            : t('Membership.company_title')
                    }
                    title={t('Expertises.expertises_title')}
                    message={
                        expertises.length
                            ? null
                            : isMemberProfile
                            ? t('Profile.profile_empty_expertise_message')
                            : profileMember &&
                              profileMember.roles.includes(Constants.MEMBER_ROLES.OWNER)
                            ? t('Membership.membership_empty_expertise')
                            : t('Membership.membership_empty_expertise_message')
                    }
                />
                <div
                    className={classnames('list-group-very-compact text-sm', {
                        'pb-1': isMemberProfile,
                    })}
                >
                    {expertises.length
                        ? expertises.map((expertise) => (
                              <MembershipSpecificationsItem
                                  key={expertise.id}
                                  title={expertise.description}
                                  defaultImg={
                                      isMemberProfile
                                          ? defaultMemberImg
                                          : defaultMembershipImg
                                  }
                                  imgSrc={expertise.iconUrl}
                                  id={expertise.id}
                                  disabled={true}
                                  imgWidth="25px"
                                  imgHeight="25px"
                              />
                          ))
                        : null}
                </div>
            </Fragment>
        ) : null;
    };

    profileMembers = (members) => {
        const { t, isMemberProfile } = this.props;

        return members.length ? (
            <Fragment>
                <ListItemHeader
                    superTitle={
                        isMemberProfile
                            ? t('Profile.profile_member_title')
                            : t('Membership.company_title')
                    }
                    title={t('Membership.company_team_big_title')}
                    className="mt-1"
                />
                <ListItem
                    subTitle={
                        members.length ? (
                            <div>
                                <span className="font-weight-normal text-sm">{t('Membership.membership_total_members', {number: members.length, count: members.length})}</span>
                                <MemberPictureList idPrefix="profileMembers" className="mt-1" members={members.map((member)=> ({
                                        id: member.id,
                                        name: member.firstName + ' ' + member.lastName,
                                        imgSrc: member.imageUrl
                                    })
                                )}
                                />
                            </div>
                        ) : null
                    }
                    action={<Arrow size="1.5em" className="text-primary" />}
                    clickable
                    onClick={this.membershipMembersToggle}
                />
            </Fragment>
        ) : null;
    };

    isEmptyProfile = (data) => {
        if (this.state.profile && data)
            return !(
                !!data.presentation ||
                (data.expertises && data.expertises.length > 0) ||
                (data.campuses && data.campuses.length > 0) ||
                !Object.values(data.contactInfo).every((x) => x === null || x === '')
            );
    };

    renderProfile = () => {
        const {
                t,
                parentMatchURL,
                profileMember,
                modal = false,
                isMemberProfile = false,
                isFloorPlan = false,
            } = this.props,
            { profile, campusToggle, campusId, profileToggle, profileId, companyFloorPlanToggle, membershipMembersToggle } = this.state;

        let detail = {};
        if (profile && profile.data) {
            detail = {
                name: profile.data.name,
                imageUrl: profile.data.imageUrl,
            };
        }

        return profile.ongoing ? (
            <div className="w-100 mb-6" style={{ minHeight: '720px' }}>
                <LoadingSpinner loading={profile.ongoing} />
            </div>
        ) : profile.errored ? (
            <div className="px-2 px-md-20">
                <NoResults
                    icon={<Error size="3.75em" />}
                    title={t('Error.error')}
                    subTitle={t('Error.error_message')}
                    action={{
                        text: t('Error.retry'),
                        onClick: this.loadProfile,
                    }}
                />
            </div>
        ) : profile.data ? (
            !isMemberProfile &&
            this.isEmptyProfile(profile.data) &&
            ((profileMember &&
                !profileMember.roles.includes(Constants.MEMBER_ROLES.OWNER)) ||
                modal) ? (
                <div className="w-100 p-2 px-md-20">
                    <NoResults
                        icon={<Memberships size="3.75em" />}
                        title={t('Membership.no_information_title')}
                        subTitle={t('Membership.no_information_message')}
                    />
                </div>
            ) : (
                <Fragment>
                    <div
                        className={classnames('w-50 w-md-100', {
                            'px-1': !modal,
                            'pr-1': modal,
                            'w-100': isMemberProfile,
                        })}
                    >
                        {modal && (
                            <div className="list-group">
                                {profile && profile.data && (
                                    <DashboardDetailHeader
                                        name={
                                            <React.Fragment>
                                                <span>
                                                    {isMemberProfile
                                                        ? `${profile.data.firstName} ${profile.data.lastName}`
                                                        : profile.data.name}
                                                </span>
                                                {!isFloorPlan && !isMemberProfile ? (
                                                    <div>
                                                        <button className="btn btn-light modal-header-action mt-3"
                                                                onClick={this.companyFloorPlanToggle}>
                                                            {t('Membership.public_membership_see_floor_plan')}
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        }
                                        detail={detail}
                                        defaultImg={
                                            isMemberProfile
                                                ? defaultMemberImg
                                                : defaultMembershipImg
                                        }
                                    />
                                )}
                            </div>
                        )}
                        {!isMemberProfile ||
                        (isMemberProfile && modal) ||
                        (isMemberProfile && profile.data.introduction) ? (
                            <Fragment>
                                <ListItemHeader
                                    className="mt-1"
                                    superTitle={
                                        isMemberProfile
                                            ? t('Profile.profile_member_title')
                                            : t('Membership.company_title')
                                    }
                                    title={t('Profile.profile_introduction_title')}
                                    message={
                                        isMemberProfile ? (
                                            profile.data.introduction ? (
                                                <div
                                                    className="pt-0 pb-1 px-2 bg-white text-sm"
                                                    dangerouslySetInnerHTML={{
                                                        __html: profile.data.introduction,
                                                    }}
                                                />
                                            ) : (
                                                t(
                                                    'Profile.profile_empty_introduction_message'
                                                )
                                            )
                                        ) : !!profile.data.presentation ? (
                                            <div
                                                className="pt-0 pb-1 px-2 bg-white text-sm"
                                                dangerouslySetInnerHTML={{
                                                    __html: profile.data.presentation,
                                                }}
                                            />
                                        ) : profileMember &&
                                          profileMember.roles.includes(
                                              Constants.MEMBER_ROLES.OWNER
                                          ) ? (
                                            t('Membership.membership_empty_introduction')
                                        ) : (
                                            t(
                                                'Membership.membership_empty_introduction_message'
                                            )
                                        )
                                    }
                                />
                            </Fragment>
                        ) : null}
                        <div className="list-group profile-list-item mt-1">
                            {profile.data.expertises &&
                                this.profileExpertises(profile.data.expertises)}
                        </div>
                    </div>
                    <div
                        className={classnames('w-50 w-md-100 profile-list-item', {
                            'px-1': !modal,
                            'pl-1': modal,
                            'w-100': isMemberProfile,
                        })}
                    >
                        {profile.data.contactInfo &&
                            this.profileContactInfo(profile.data.contactInfo)}
                        {profile.data.memberships &&
                            this.profileMemberships(profile.data.memberships)}
                        {profile.data.campuses &&
                            this.profileCampuses(profile.data.campuses)}
                        {profile.data.members &&
                            this.profileMembers(profile.data.members)}
                    </div>
                    {profileMember &&
                    profileMember.roles.includes(Constants.MEMBER_ROLES.OWNER) ? (
                        <div className="w-100 px-1 d-flex justify-content-center">
                            {isMemberProfile ? (
                                <Link
                                    className="btn btn-primary d-block rounded-0 my-2 w-50 w-md-100"
                                    to={`${parentMatchURL}/edit-profile`}
                                >
                                    {t('Membership.edit_membership_information')}
                                </Link>
                            ) : (
                                <Link
                                    className="btn btn-primary d-block rounded-0 my-2 w-50 w-md-100"
                                    to={`${parentMatchURL}/edit`}
                                >
                                    {t('Membership.edit_membership_information')}
                                </Link>
                            )}
                        </div>
                    ) : null}
                    {campusToggle && (
                        <CampusDetail
                            {...this.props}
                            onClose={this.campusToggle}
                            campusId={campusId}
                            isOpen={campusToggle}
                        />
                    )}
                    {profileToggle && (
                        <Profile
                            {...this.props}
                            onClose={this.profileToggle}
                            membershipId={profileId}
                            isMemberProfile={false}
                            isOpen={profileToggle}
                            modal
                        />
                    )}
                    {companyFloorPlanToggle && (
                        <CompanyFloorPlan
                            isOpen={companyFloorPlanToggle}
                            onClose={this.companyFloorPlanToggle}
                            profileId={profile.data.id}
                        />
                    )}
                    {membershipMembersToggle && (
                        <MembershipMembersModal
                            isOpen={membershipMembersToggle}
                            onClose={this.membershipMembersToggle}
                            membership={profile.data.id}
                        />
                    )}
                </Fragment>
            )
        ) : null;
    };

    render() {
        const { modal = false, isMemberProfile } = this.props,
            { profile } = this.state;

        return modal ? (
            <Modal
                size={classnames({ lg: !this.isEmptyProfile(profile.data) })}
                isOpen={this.state.modal}
                toggle={this.toggle}
                className="modal-dialog-centered modal-full-screen adaptive modal-profile"
            >
                <ModalBody className="bg-light-grey">
                    <div className="d-flex flex-column bg-body mh-0">
                        <div className="scrollable d-flex flex-row">
                            {this.renderProfile()}
                        </div>
                    </div>

                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.toggle}
                    >
                        <span aria-hidden="true">
                            <X
                                fill="#244C5A"
                                size="28px"
                            />
                        </span>
                    </button>

                </ModalBody>
            </Modal>
        ) : (
            this.renderProfile()
        );
    }
}

export default translate()(Profile);
