import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react/index';
import { JobDetailHeader } from './';
import { Arrow, Error, Member } from 'Components/Icons';
import HttpService from 'Services/HttpService';
import ListItem from 'Components/Forms/ListItem';
import Membership from 'Assets/Icons/membership.svg';
import MembershipSpecificationsItem from 'Components/Membership/MembershipSpecificationsItem';
import Campuses from 'Components/Icons/Campuses';
import Constants from 'Data/Constants';
import { observable } from 'mobx';
import LoadingStore from 'Stores/UI/LoadingStore';
import { LoadingSpinner } from 'Components/Loading';
import NoResults from 'Components/Loading/NoResults';
import ScrollUtils from 'Utils/ScrollUtils';
import { ListItemHeader } from 'Components/Forms';
import Profile from 'WebApp/Views/Logged/Membership/Profile';
import CampusDetail from 'Components/Campus/CampusDetail';

const defaultCampusImg = (
    <div className="img">
        <Campuses size="50%" />
    </div>
);
const defaultMemberImg = (
    <div className="img">
        <Member size="50%" />
    </div>
);

@inject('uiStore')
@observer
class JobDetail extends React.Component {
    state = {
        jobPost: { ongoing: true },
        profileToggle: false,
        campusToggle: false,
        isMemberProfile: false,
    };

    scrollUtils = new ScrollUtils();
    @observable localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);

    viewMembershipProfile = () => {
        this.setState(
            {
                isMemberProfile: false,
            },
            () => this.profileToggle()
        );
    };

    viewMemberProfile = () => {
        this.setState(
            {
                isMemberProfile: true,
            },
            () => this.profileToggle()
        );
    };

    profileToggle = () => {
        this.setState((prevState) => ({
            profileToggle: !prevState.profileToggle,
        }));
    };

    campusToggle = () => {
        this.setState((prevState) => ({
            campusToggle: !prevState.campusToggle,
        }));
    };

    componentDidMount() {
        if (this.props.jobPostId) this.loadJobPost();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.jobPost !== this.props.jobPost ||
            (prevProps.jobPostId !== this.props.jobPostId && this.props.jobPostId)
        )
            this.loadJobPost();
    }

    loadJobPost = async () => {
        const { localLoadingStore, jobPostId } = this.props;

        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        if (localLoadingStore) localLoadingStore.addLoading();

        try {
            const { data: results } = await HttpService.jobPostsDetails(
                jobPostId,
                this.cancellation
            );
            this.setState({ jobPost: { data: results, ongoing: false } });
        } catch (err) {
            if (!this.cancellation.isCancelled(err)) {
                this.setState({ jobPost: { errored: true, ongoing: false } });
            }
        } finally {
            this.scrollUtils.scrollToRef();
            if (localLoadingStore) localLoadingStore.removeLoading();
        }
    };

    jobPostSpecifications = (data, type) => {
        const { t } = this.props;
        return data.length ? (
            <Fragment>
                <ListItemHeader
                    superTitle={t('JobBoard.job_header_title')}
                    title={type}
                    message={
                        data.length ? null : (
                            <div className="py-0 px-2 list-group-item text-sm">
                                {t('Membership.membership_empty_expertise_message')}
                            </div>
                        )
                    }
                />
                <div className="list-group-very-compact text-sm pb-1">
                    {data.length
                        ? data.map((item) => (
                              <MembershipSpecificationsItem
                                  key={item.id}
                                  title={item.description}
                                  defaultImgSrc={Membership}
                                  imgSrc={item.iconUrl}
                                  id={item.id}
                                  disabled={true}
                                  imgWidth="25px"
                                  imgHeight="25px"
                              />
                          ))
                        : null}
                </div>
            </Fragment>
        ) : null;
    };

    render() {
        const { paneDetail, t, onEdit } = this.props,
            { jobPost, profileToggle, isMemberProfile, campusToggle } = this.state;
        let contactPerson = '';
        let emailBody = '';
        if (jobPost.data) {
            contactPerson = `${jobPost.data.contactPerson.firstName || ''} ${
                jobPost.data.contactPerson.lastName || ''
            }`;
            emailBody = t('JobBoard.job_post_apply_prefill_form', {
                param1: contactPerson,
                param2: jobPost.data.title,
            });
        }

        return jobPost.ongoing ? (
            <div
                className="position-relative mb-6"
                style={{ minHeight: '720px' }}
                ref={this.scrollUtils.registerRef}
            >
                <LoadingSpinner loading={jobPost.ongoing} />
            </div>
        ) : jobPost.errored ? (
            <div className="px-2 px-md-20" ref={this.scrollUtils.registerRef}>
                <NoResults
                    icon={<Error size="3.75em" />}
                    title={t('Error.error')}
                    subTitle={t('Error.error_message')}
                    action={{
                        text: t('Error.retry'),
                        onClick: this.loadJobPost,
                    }}
                />
            </div>
        ) : jobPost.data ? (
            <div className="bookingDetail pb-6" ref={this.scrollUtils.registerRef}>
                <JobDetailHeader
                    jobPost={jobPost.data}
                    defaultImgSrc={Membership}
                    name={<span className="text-none">{jobPost.data.title}</span>}
                    paneDetail={paneDetail}
                    onEdit={onEdit}
                />
                <div className="w-100">
                    {jobPost.data.canApply && (
                        <a
                            href={`mailto:${
                                jobPost.data.contactPerson.contactInfo.email
                            }?subject=${jobPost.data.title}&body=${emailBody.replace(
                                /<br\s*\/?>/gm,
                                '%0D%0A'
                            )}`}
                            className="btn btn-primary w-100 bottom-action"
                        >
                            {t('Community.apply_job_title')}
                        </a>
                    )}
                    <ListItemHeader
                        superTitle={t('Membership.company_title')}
                        title={t('Profile.profile_info_title')}
                    />
                    <div className="list-group-very-compact text-sm pb-1">
                        {
                            <ListItem
                                key={jobPost.data.membership.id}
                                id={jobPost.data.membership.id}
                                className="py-0 list-group-item-action"
                                title={
                                    <span className="pr-1">
                                        {jobPost.data.membership.name}
                                    </span>
                                }
                                withImage={true}
                                imgSrc={jobPost.data.membership.imageUrl}
                                defaultImgSrc={Membership}
                                action={
                                    <div className="list-group-item-status text-primary">
                                        <Arrow size="1.5em" />
                                    </div>
                                }
                                clickable
                                onClick={this.viewMembershipProfile}
                            />
                        }
                    </div>
                    {jobPost.data.introduction ? (
                        <ListItemHeader
                            className="mb-1"
                            superTitle={t('Community.job_post_title')}
                            title={t('JobBoard.job_header_title')}
                            message={
                                <div
                                    className="text-xs font-weight-medium mt-1"
                                    dangerouslySetInnerHTML={{
                                        __html: jobPost.data.introduction,
                                    }}
                                />
                            }
                        />
                    ) : null}
                    <div className="list-group profile-list-item">
                        {jobPost.data.expertises &&
                            this.jobPostSpecifications(
                                jobPost.data.expertises,
                                Constants.SPECIFICATIONS.EXPERTISE
                            )}
                        {jobPost.data.contractTypes &&
                            this.jobPostSpecifications(
                                jobPost.data.contractTypes,
                                Constants.SPECIFICATIONS.TYPE
                            )}
                    </div>

                    <ListItemHeader
                        superTitle={t('JobBoard.job_header_title')}
                        title={t('Campus.campus_title')}
                    />
                    <div className="list-group-very-compact mb-1">
                        <ListItem
                            key={jobPost.data.campus.id}
                            id={jobPost.data.campus.id}
                            className="py-0 list-group-item-action"
                            title={
                                <span className="pr-1">{jobPost.data.campus.name}</span>
                            }
                            withImage={true}
                            imgSrc={jobPost.data.campus.mainImageUrl}
                            defaultImg={defaultCampusImg}
                            action={
                                <div className="list-group-item-status text-primary">
                                    <Arrow size="1.5em" />
                                </div>
                            }
                            clickable
                            onClick={this.campusToggle}
                        />
                    </div>
                    <ListItemHeader
                        superTitle={t('Community.job_post_title')}
                        title={t('JobBoard.person_title')}
                    />
                    <div className="list-group-very-compact mb-1">
                        <ListItem
                            key={jobPost.data.contactPerson.id}
                            id={jobPost.data.contactPerson.id}
                            className="py-0 list-group-item-action"
                            title={<span className="pr-1">{contactPerson}</span>}
                            withImage={true}
                            imgSrc={jobPost.data.contactPerson.imageUrl}
                            defaultImg={defaultMemberImg}
                            action={
                                <div className="list-group-item-status text-primary">
                                    <Arrow size="1.5em" />
                                </div>
                            }
                            clickable
                            onClick={this.viewMemberProfile}
                        />
                    </div>
                </div>

                {profileToggle && (
                    <Profile
                        onClose={this.profileToggle}
                        membershipId={jobPost.data.membership.id}
                        memberId={jobPost.data.contactPerson.id}
                        isMemberProfile={isMemberProfile}
                        isOpen={profileToggle}
                        modal
                    />
                )}
                {campusToggle && (
                    <CampusDetail
                        onClose={this.campusToggle}
                        campusId={jobPost.data.campus.id}
                        isOpen={campusToggle}
                    />
                )}
            </div>
        ) : null;
    }
}

export default translate()(JobDetail);
