import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import ImageSelectList, { ImageSelectListItem } from './ImageSelectList';
import { Link } from 'react-router-dom';

const Title = (props) => (
    <h6 {...props} className={`${props.className || ''} w-100 text-uppercase`}>
        {props.children}
    </h6>
);
const SubTitle = (props) => (
    <small {...props} className={`${props.className || ''} font-weight-medium text-xs`}>
        {props.children}
    </small>
);

class ImageSelectListItemExtension extends ImageSelectListItem {
    render() {
        const { item, defaultImgSrc } = this.props;
        const Tag = item.to ? Link : 'div';

        return (
            <div className="roomList">
                <Tag
                    className="list-group-item list-group-item-action image-list-group-item  rounded border-0"
                    style={{ backgroundImage: `url(${item.imgSrc || defaultImgSrc})` }}
                    role="button"
                    tabIndex="0"
                    to={item.to}
                    onClick={this.handleItemClick}
                >
                    <div className="list-group-item-hover rounded" />
                    <div className="flex-grow-1" />
                    <div className="list-group-item-text pt-2">
                        {!item.superTitle ? null : (
                            <SubTitle className={` ${item.title ? 'mb-1' : 'mb-0'}`}>
                                {item.superTitle}
                            </SubTitle>
                        )}
                        {!item.subTitle ? null : (
                            <SubTitle className={` mb-0`}>{item.subTitle}</SubTitle>
                        )}
                    </div>
                </Tag>
                {!item.title ? null : (
                    <Fragment>
                        <Title className="mb-0 mt-1 text-prewrap font-weight-bold">
                            {item.title}
                        </Title>
                        {!item.people ? null : (
                            <p className="mb-1 font-weight-medium text-sm">
                                {item.people}
                            </p>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

class ImageSelectListExtension extends ImageSelectList {
    render() {
        const { defaultImgSrc, items, onSelected, onClickDetail } = this.props;

        return (
            <div className="pt-2 pb-1 d-flex flex-wrap w-100 px-1 justify-content-between">
                {items &&
                    items.map((item, i) => (
                        <ImageSelectListItemExtension
                            key={item.id}
                            item={item}
                            defaultImgSrc={defaultImgSrc}
                            onClick={onSelected}
                            onClickDetail={onClickDetail}
                        />
                    ))}
            </div>
        );
    }
}

export default translate()(ImageSelectListExtension);
export { ImageSelectListItemExtension };
