import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { observer } from 'mobx-react';

import MemberRoleItem from './MemberRoleItem';

@observer
class MemberRoles extends React.Component {
    render() {
        const { onChange, roles, isOwner = false } = this.props;

        return (
            <Fragment>
                {Object.entries(roles).map(([key, value]) => (
                    <MemberRoleItem
                        isOwner={isOwner}
                        key={key}
                        {...value}
                        onChange={onChange}
                        id={key}
                    />
                ))}
            </Fragment>
        );
    }
}

export default translate()(MemberRoles);
