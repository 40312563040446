import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const FormLabel = (props) => (
    <label
        className={`${props.className || ''} ${props.required ? 'required' : ''}`}
        htmlFor={props.id}
    >
        {props.displayName}
    </label>
);

FormLabel.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    className: PropTypes.string,
    required: PropTypes.bool,
};

export default translate()(FormLabel);
