import React from 'react';

const Invoices = (props) => (
    <svg
        className={'icon' + (props.className ? ` ${props.className}` : '')}
        viewBox="0 0 60 60"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <g fill={props.fill || 'currentColor'} fillRule="evenodd">
            <path d="M8.611 7v8.611H0v37.635h51.35v-8.611h8.61V7H8.611zm2.392 2.392h46.565v32.85H51.35v-26.63H11.003v-6.22zm6.06 41.462h31.894v-32.85H17.064v32.85zm-14.671 0h12.28v-32.85H2.392v32.85z" />
            <path d="M22.804 24.86h20.572v-2.39H22.804zm0 5.103h20.572v-2.392H22.804zm0 5.263h20.572v-2.392H22.804zm0 5.103h11.96v-2.392h-11.96z" />
        </g>
    </svg>
);

export default Invoices;
