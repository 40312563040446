import React from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { X, Information } from 'Components/Icons';
import Constants from 'Data/Constants';
import HttpService from 'Services/HttpService';
import { Errors, FormGroup, ListItemHeader } from 'Components/Forms';
import ModelStateStore from 'Stores/ModelStateStore';

@inject('uiStore', 'dashboardStore')
@observer
class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            touched: false,
            feedback: '',
        };
    }

    modelStateStore = new ModelStateStore(
        { to: 'feedback', from: 'request.feedback' },
        { to: 'feedback', from: 'request.other' }
    );

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value, touched: true });
    };

    toggle = async (checkTouched = true) => {
        const {
            uiStore: { simpleModalStore },
            t,
            onClose,
        } = this.props;
        if (
            checkTouched &&
            this.state.touched &&
            !(await simpleModalStore.confirm({
                titleIcon: <Information size="3.75em" />,
                title: t('Feedback.leave_feedback_title'),
                message: t('Feedback.leave_feedback_message'),
                type: Constants.MODAL_TYPE.YES_NO_TRANSPARENT,
            }))
        )
            return;
        this.setState({ feedback: '', touched: false });
        onClose();
    };

    sendFeedback = async (event) => {
        event.preventDefault();
        const { feedback } = this.state;

        const {
            uiStore: { loadingStore, toastStore },
            t,
        } = this.props;
        loadingStore.addLoading();
        try {
            await HttpService.feedback({ message: feedback });
            toastStore.enqueueToast({
                message: t('Feedback.feedback_sent_success'),
                icon: Information,
            });
            this.toggle(false, true);
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        }
        loadingStore.removeLoading();
    };

    render() {
        const { t, toggle } = this.props,
            { feedback } = this.state,
            { errors } = this.modelStateStore;
        return (
            <Modal isOpen={toggle} className="modal-dialog modal-dialog-centered">
                <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.toggle}
                    >
                        <span aria-hidden="true">
                            <X fill="dark" size="24px" />
                        </span>
                    </button>
                    <h5 className="modal-title mx-auto">
                        {t('Feedback.feedback_title')}
                    </h5>
                </div>
                <ModalBody>
                    <ListItemHeader
                        superTitle={t('Feedback.web_application')}
                        title={t('Feedback.feedback')}
                        message={t('Feedback.feedback_message')}
                    />
                    <form onSubmit={this.sendFeedback}>
                        <FormGroup
                            id="feedback"
                            displayName=""
                            required={true}
                            className="px-2 pb-1 mb-1 bg-white"
                            type="textarea"
                            onChange={this.handleInputChange}
                            value={feedback}
                            errors={errors}
                        />
                        <div className="col-12 mb-1 ">
                            <Errors errors={errors['other']} />
                        </div>
                    </form>
                    <button
                        className="btn btn-primary bottom-action w-100"
                        onClick={this.sendFeedback}
                    >
                        {t('Feedback.send_feedback')}
                    </button>
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(Feedback);
