import React from 'react';

const Proposal = (props) => (
    <svg
        className={'icon' + (props.className ? ` ${props.className}` : '')}
        viewBox="-6 0 40 40"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
            d="M11.688 28.336h4.941l.006-1.19c.138-1.04.487-1.926 1.036-2.634a9.899 9.899 0 0 1 1.762-1.762c.62-.48 1.292-.971 1.999-1.459a8.975 8.975 0 0 0 1.77-1.62c.524-.622.971-1.388 1.33-2.279.353-.874.532-2.024.532-3.415 0-.812-.18-1.71-.538-2.667-.352-.941-.948-1.836-1.772-2.661-.83-.83-1.952-1.538-3.33-2.105-1.382-.567-3.145-.854-5.242-.854-1.604 0-3.07.272-4.36.807-1.284.534-2.394 1.286-3.299 2.233a10.28 10.28 0 0 0-2.133 3.4c-.438 1.125-.701 2.364-.786 3.692h5.49a9.757 9.757 0 0 1 .28-1.845 6.303 6.303 0 0 1 .959-2.12 4.971 4.971 0 0 1 1.706-1.495c.697-.375 1.527-.565 2.467-.565 1.408 0 2.537.406 3.356 1.207.822.803 1.239 2.018 1.241 3.61.033.952-.146 1.77-.53 2.429a6.898 6.898 0 0 1-1.407 1.69c-.538.475-1.128.954-1.755 1.424a10.98 10.98 0 0 0-1.662 1.554c-.512.583-.97 1.302-1.358 2.137-.379.814-.615 1.851-.703 3.082v1.406zm6.256 1.315h-7.571l.001-2.79c.098-1.402.376-2.602.825-3.568.441-.949.967-1.773 1.562-2.45a12.376 12.376 0 0 1 1.86-1.738c.6-.45 1.163-.906 1.675-1.357.47-.415.854-.875 1.14-1.368.26-.443.378-1.026.352-1.73v-.024c0-1.23-.284-2.133-.845-2.681-.573-.56-1.37-.833-2.437-.833-.72 0-1.34.138-1.843.408-.51.275-.934.645-1.26 1.101a5.003 5.003 0 0 0-.755 1.681 8.827 8.827 0 0 0-.258 2.178v.657H2.25l.012-.669c.032-1.738.335-3.358.902-4.815.57-1.463 1.379-2.752 2.408-3.83 1.032-1.081 2.292-1.936 3.746-2.54 1.449-.603 3.085-.908 4.864-.908 2.269 0 4.2.32 5.741.953 1.54.633 2.806 1.437 3.76 2.39.96.96 1.657 2.013 2.075 3.132.412 1.106.621 2.158.621 3.127 0 1.56-.21 2.875-.627 3.907-.411 1.02-.93 1.905-1.543 2.634a10.263 10.263 0 0 1-2.03 1.856c-.687.474-1.34.95-1.94 1.415a8.594 8.594 0 0 0-1.529 1.528c-.398.514-.655 1.173-.766 1.96v2.374zm-6.905 8.603H17V32.43h-5.96v5.823zm-1.315 1.315h8.591v-8.453H9.724v8.453z"
        />
    </svg>
);

export default Proposal;
