import * as React from 'react';

function Dot({ fill, size }) {
    return (
        <svg
            className="icon"
            width={size || '1em'}
            height={size || '1em'}
            viewBox="0 0 17 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 16a8 8 0 100-16 8 8 0 000 16z"
                fill={fill || 'currentColor'}
            />
        </svg>
    );
}

export default Dot;
