import React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Errors, FormGroup, ListItemHeader, MediaFormGroup } from 'Components/Forms';
import SelectList from 'Components/Forms/SelectList';
import { Dropdown } from 'Components/Forms';
import { AngleDown } from 'Components/Icons';
import { Image } from 'Components/Misc';
import { Arrow, EMail, I, Password } from 'Components/Icons';
import MembershipSpecifications from 'Components/Membership/MembershipSpecifications';
import { inject, observer } from 'mobx-react';
import Constants from 'Data/Constants';
import moment from 'moment';
import MembershipSpecificationsItem from 'Components/Membership/MembershipSpecificationsItem';
import colorVariables from 'Sass/variables.scss';
import classnames from 'classnames';

@inject('dashboardStore', 'uiStore')
@observer
class EditProfile extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount() {
        const {
            uiStore: { loadingStore },
            dashboardStore,
            profile,
        } = this.props;
        if (profile) this.updateProfile(profile);
        dashboardStore.loadExpertises(loadingStore);
    }

    componentDidUpdate(prevProps, prevState) {
        const { profile } = this.props;
        if (prevProps.profile !== profile && profile) {
            this.updateProfile(profile);
        }
    }

    updateProfile(profile) {
        this.setState({
            image: {
                uploadBase64: '',
                delete: false,
            },
            jobPosition: profile.jobPosition,
            introduction: profile.introduction,
            personal_expertises: profile.expertises.map((expertise) => expertise.id),
            imageUrl: profile.imageUrl,
            address_addressLine1: profile.address.addressLine1,
            address_city: profile.address.city,
            address_country: profile.address.country,
            address_postalCode: profile.address.postalCode,
            address_state: profile.address.state,
            birthDate: profile.birthDate,
            salutation: profile.salutation,
            firstName: profile.firstName,
            middleName: profile.middleName,
            lastName: profile.lastName,
            phoneNumber: profile.contactInfo.phone1,
            phoneNumber2: profile.contactInfo.phone2,
            email: profile.contactInfo.email,
            contactInfo_angelListUrl: profile.contactInfo.angelListUrl,
            contactInfo_facebookUrl: profile.contactInfo.facebookUrl,
            contactInfo_instagramUrl: profile.contactInfo.instagramUrl,
            contactInfo_linkedInUrl: profile.contactInfo.linkedInUrl,
            contactInfo_twitterUrl: profile.contactInfo.twitterUrl,
            isPrivate: profile.isPrivate,
        });
    }

    togglePrivacy = () => {
        const { isPrivate } = this.state;
        this.setState({ isPrivate: !isPrivate });
    };

    handleEditorChange = (editor) => {
        this.setState({ introduction: editor.level.content });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
        this.props.onTouch();
    };

    updateExpertises = (id, value) => {
        if (value) {
            this.setState({
                personal_expertises: [...this.state.personal_expertises, id],
            });
        } else {
            var array = [...this.state.personal_expertises]; // make a separate copy of the array
            var index = array.indexOf(id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ personal_expertises: array });
            }
        }
    };

    showDetails = (e) => {
        e.stopPropagation();
        const {
            uiStore: { simpleModalStore },
            t,
        } = this.props;
        const descriptionKeys = ['Profile.privacy_description'];
        simpleModalStore.alert({
            dark: true,
            message: (
                <React.Fragment>
                    <h5 key="role" className="text-uppercase">
                        {t('Profile.privacy_profile_edit')}
                    </h5>
                    <div key="can">{t('Profile.means')}</div>
                    <ul
                        key="rights"
                        className="mt-1 text-left spaced"
                        dangerouslySetInnerHTML={{
                            __html: descriptionKeys.reduce(
                                (content, key) => (content += t(key)),
                                ''
                            ),
                        }}
                    />
                </React.Fragment>
            ),
        });
    };

    updateSalutation = (value) => {
        this.setState({ salutation: value });
    };

    onSubmit = (e) =>
        this.props.onSubmit(e, {
            image: !this.state.image.delete && 
                this.state.image.uploadBase64 === '' ? null : this.state.image,
            address: {
                addressLine1: this.state.address_addressLine1,
                city: this.state.address_city,
                country: this.state.address_country,
                postalCode: this.state.address_postalCode,
                state: this.state.address_state,
            },
            jobPosition: this.state.jobPosition,
            introduction: this.state.introduction,
            expertiseIds: this.state.personal_expertises,
            birthDate: this.state.birthDate,
            salutation: this.state.salutation,
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            isPrivate: this.state.isPrivate,
            contactInfo: {
                phone1: this.state.phoneNumber,
                phone2: this.state.phoneNumber2,
                email: this.state.email,
                angelListUrl: this.state.contactInfo_angelListUrl,
                facebookUrl: this.state.contactInfo_facebookUrl,
                instagramUrl: this.state.contactInfo_instagramUrl,
                linkedInUrl: this.state.contactInfo_linkedInUrl,
                twitterUrl: this.state.contactInfo_twitterUrl,
            },
        });

    render() {
        const {
            imageUrl,
            address_addressLine1,
            address_city,
            address_country,
            address_postalCode,
            address_state,
            birthDate,
            salutation,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            phoneNumber2,
            email,
            jobPosition,
            introduction,
            isPrivate,
            personal_expertises,
            contactInfo_angelListUrl,
            contactInfo_facebookUrl,
            contactInfo_instagramUrl,
            contactInfo_linkedInUrl,
            contactInfo_twitterUrl,
        } = this.state;
        const {
            t,
            className,
            errors,
            matchUrl,
            dashboardStore: { expertises },
        } = this.props;
        
        const dropdownButton = (chosenItem) => ({ onClick, open }) => {
            return (
                <div
                    onClick={onClick}
                    className={classnames(
                        'text-primary list-group-item-status dropdown-indicator d-flex h-100',
                        open && 'toggled'
                    )}
                >
                    <div className="w-100">
                        {chosenItem ? (
                            <>
                                {chosenItem.value}{' '}
                            </>
                        ) : ''}
                    </div>
                    <AngleDown size="18px" />
                </div>
            );
        };

        const salutations = [
            { id: '', value: '', title: '' },
            { id: t('Account.mr'), value: t('Account.mr'), title: t('Account.mr') },
            { id: t('Account.ms'), value: t('Account.ms'), title: t('Account.ms') },
            { id: t('Account.mrs'), value: t('Account.mrs'), title: t('Account.mrs') },
            { id: t('Account.dr'), value: t('Account.dr'), title: t('Account.dr') },
            { id: t('Account.prof'), value: t('Account.prof'), title: t('Account.prof') },
        ]

        return (
            <form onSubmit={this.onSubmit} className={className || ''}>
                <div className="mx-auto">
                    <MediaFormGroup
                        id="image"
                        value={imageUrl}
                        onChange={this.handleInputChange}
                        errors={errors}
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 list-group-item text-sm font-weight-medium">
                            <div className="list-group-item-text">{email}</div>
                            <div className="list-group-item-image">
                                <Image img={<EMail size="36px" />} />
                            </div>
                        </div>
                        <Link
                            className="col-12 col-md-6 list-group-item list-group-item-action text-sm font-weight-medium"
                            to={`${matchUrl}/change-password`}
                        >
                            <div className="list-group-item-text">**********</div>
                            <div className="list-group-item-image">
                                <Image img={<Password size="30px" />} />
                            </div>
                            <div className="list-group-item-status text-primary ml-3">
                                <Arrow size="24px" />
                            </div>
                        </Link>
                        <div className="col-12 col-md-6 dropdown-list">
                            <label className="required">
                                {t('Account.salutation')}
                            </label>

                            <Dropdown
                                button={dropdownButton(
                                salutations.find((item) => item.value === salutation))}
                                className="form-control col-12"
                            >
                                <SelectList
                                    tag="div"
                                    id="salutation"
                                    className="list-group-compact"
                                    items={salutations}
                                    value={salutation ? salutation : ''}
                                    onChange={(e) => this.updateSalutation(e)}
                                    multiSelect={false}
                                    isDropDown
                                    selectionIndicatorType={Constants.SELECTION_INDICATOR_TYPE.NONE}
                                >
                                </SelectList>
                            </Dropdown>
                        </div>
                        <FormGroup
                            id="firstName"
                            value={firstName}
                            displayName={t('Account.first_name')}
                            className="col-12 col-md-6"
                            required={true}
                            onChange={this.handleInputChange}
                            maxlength={25}
                            errors={errors}
                        />
                        <FormGroup
                            id="middleName"
                            value={middleName}
                            displayName={t('Account.middle_name')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={25}
                            errors={errors}
                        />
                        <FormGroup
                            id="lastName"
                            value={lastName}
                            displayName={t('Account.last_name')}
                            type="text"
                            className="col-12 col-md-6"
                            required={true}
                            onChange={this.handleInputChange}
                            maxlength={25}
                            errors={errors}
                        />
                        <FormGroup
                            id="jobPosition"
                            value={jobPosition}
                            displayName={t('Profile.profile_job_position_title')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={25}
                            errors={errors}
                        />
                        <FormGroup
                            id="introduction"
                            className="col-12 "
                            displayName={t('Profile.profile_introduction_title')}
                            type="Editor"
                            onChange={this.handleEditorChange}
                            value={introduction}
                        />
                        <div className="col-12">
                            {personal_expertises &&
                            expertises &&
                            expertises.data &&
                            expertises.data.length ? (
                                <MembershipSpecifications
                                    onChange={this.updateExpertises}
                                    specs={expertises.data}
                                    activeSpecs={personal_expertises}
                                    type={Constants.SPECIFICATIONS.EXPERTISE}
                                />
                            ) : null}
                        </div>
                        <div className="col-12 list-group-very-compact">
                            <ListItemHeader
                                superTitle={t('Profile.profile_contact_title')}
                                title={t('Profile.information_title')}
                                className="px-0"
                            />
                        </div>
                        <FormGroup
                            id="contactInfo_angelListUrl"
                            displayName={t('Profile.profile_angellist_title')}
                            onChange={this.handleInputChange}
                            value={contactInfo_angelListUrl}
                            className="col-12 col-md-6"
                            errors={errors}
                        />
                        <FormGroup
                            id="contactInfo_twitterUrl"
                            displayName={t('Profile.profile_twitter_title')}
                            onChange={this.handleInputChange}
                            value={contactInfo_twitterUrl}
                            className="col-12 col-md-6"
                            errors={errors}
                        />
                        <FormGroup
                            id="contactInfo_facebookUrl"
                            displayName={t('Profile.profile_facebook_title')}
                            onChange={this.handleInputChange}
                            value={contactInfo_facebookUrl}
                            className="col-12 col-md-6"
                            errors={errors}
                        />
                        <FormGroup
                            id="contactInfo_linkedInUrl"
                            displayName={t('Profile.profile_linkedin_title')}
                            onChange={this.handleInputChange}
                            value={contactInfo_linkedInUrl}
                            className="col-12 col-md-6"
                            errors={errors}
                        />
                        <FormGroup
                            id="contactInfo_instagramUrl"
                            displayName={t('Profile.profile_instagram_title')}
                            onChange={this.handleInputChange}
                            value={contactInfo_instagramUrl}
                            className="col-12 col-md-6"
                            errors={errors}
                        />
                        <div className="col-12 list-group-very-compact">
                            <ListItemHeader
                                superTitle={t('Profile.personal_title')}
                                title={t('Profile.information_title')}
                                className="px-0"
                            />
                        </div>
                        <FormGroup
                            id="phoneNumber"
                            value={phoneNumber}
                            displayName={t('Account.cell_phone_number')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={21}
                            errors={errors}
                        />
                        <FormGroup
                            id="phoneNumber2"
                            value={phoneNumber2}
                            displayName={t('Account.phone')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={21}
                            errors={errors}
                        />
                        <FormGroup
                            id="address_addressLine1"
                            value={address_addressLine1}
                            displayName={t('Account.address')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={500}
                            errors={errors}
                        />
                        <FormGroup
                            id="address_city"
                            value={address_city}
                            displayName={t('Account.city')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={100}
                            errors={errors}
                        />
                        <FormGroup
                            id="address_country"
                            value={address_country}
                            displayName={t('Account.country')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={100}
                            errors={errors}
                        />
                        <FormGroup
                            id="address_state"
                            value={address_state}
                            displayName={t('Account.state')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={300}
                            errors={errors}
                        />
                        <FormGroup
                            id="address_postalCode"
                            value={address_postalCode}
                            displayName={t('Account.zipcode')}
                            type="text"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            maxlength={25}
                            errors={errors}
                        />
                        <FormGroup
                            id="birthDate"
                            value={moment
                                .parseZone(birthDate)
                                .format(Constants.DATE_FORMAT)}
                            displayName={t('Account.birthday')}
                            type="date"
                            className="col-12 col-md-6"
                            onChange={this.handleInputChange}
                            errors={errors}
                        />

                        <div className="col-12 list-group-very-compact">
                            <ListItemHeader
                                superTitle={t('Profile.personal_title')}
                                title={t('Profile.privacy_title')}
                                className="px-0"
                            />
                            <MembershipSpecificationsItem
                                key="privacy"
                                className="text-sm px-0 px-md-0"
                                title={t('Profile.privacy_profile_edit')}
                                details={
                                    <span
                                        className="text-primary mr-2 btn btn-link p-0"
                                        onClick={this.showDetails}
                                    >
                                        <I className="align-middle" size="1.5em" />
                                    </span>
                                }
                                defaultImg={
                                    <Password
                                        size="2em"
                                        fill={
                                            isPrivate
                                                ? colorVariables.primary
                                                : colorVariables.warmGrey
                                        }
                                    />
                                }
                                onChange={this.togglePrivacy}
                                checked={isPrivate}
                                id="privacy"
                                imgWidth="25px"
                                imgHeight="25px"
                            />
                        </div>
                        <div className="col-12 col-md-6" />
                        <div className="col-12 text-xs font-weight-medium">
                            <span className="text-secondary">* </span>
                            {t('Account.required_field')}
                        </div>
                        <div className="col-12">
                            <Errors errors={errors['other']} />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4">
                            <button type="submit" className="btn btn-primary w-100">
                                {t('Membership.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default translate()(EditProfile);
