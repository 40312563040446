import React from 'react';
import PropTypes from 'prop-types';

import NotificationBadge from './NotificationBadge';
import { Constants } from 'Data/';

class IconWithNotificationBadge extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        badgeClassName: PropTypes.string,
        badgePosition: PropTypes.oneOf(Constants.BADGE_POSITIONS),
        badgeVisible: PropTypes.bool,
    };

    render() {
        const {
            className,
            children,
            badgeClassName,
            badgePosition,
            badgeVisible,
        } = this.props;
        return (
            <div className={`notification-badge-wrapper ${className || ''}`}>
                {children}
                {badgeVisible && (
                    <NotificationBadge className={`${badgeClassName} ${badgePosition}`} />
                )}
            </div>
        );
    }
}

export default IconWithNotificationBadge;
