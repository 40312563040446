import React from 'react';

const StripeCreditRecto = ({ width, height, className }) => (
    <svg
        className={`icon ${className}`}
        viewBox="0 0 154 97"
        width={width}
        height={height}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#A2A2A2"
                fillRule="nonzero"
                d="M2 11.991V85.01C2 90.525 6.48 95 12.004 95h129.992c5.53 0 10.004-4.471 10.004-9.991V11.99C152 6.475 147.52 2 141.996 2H12.004C6.474 2 2 6.471 2 11.991zm-2 0C0 5.37 5.368 0 12.004 0h129.992C148.626 0 154 5.37 154 11.991V85.01c0 6.62-5.368 11.99-12.004 11.99H12.004C5.374 97 0 91.63 0 85.009V11.99z"
            />
            <path
                fill="#616167"
                fillRule="nonzero"
                d="M7 13.997v69.006C7 86.865 10.131 90 13.99 90h126.02c3.858 0 6.99-3.134 6.99-6.997V13.997C147 10.135 143.869 7 140.01 7H13.99C10.132 7 7 10.134 7 13.997zm-1 0C6 9.58 9.581 6 13.99 6h126.02c4.413 0 7.99 3.585 7.99 7.997v69.006c0 4.417-3.581 7.997-7.99 7.997H13.99C9.577 91 6 87.415 6 83.003V13.997z"
            />
            <rect width={24} height={24} x={16} y={18} fill="#616167" rx={3} />
            <g fill="#A2A2A2">
                <g transform="translate(26 67)">
                    <circle cx={2} cy={2} r={2} />
                    <circle cx={8} cy={2} r={2} />
                    <circle cx={14} cy={2} r={2} />
                    <circle cx={20} cy={2} r={2} />
                </g>
                <g transform="translate(53 67)">
                    <circle cx={2} cy={2} r={2} />
                    <circle cx={8} cy={2} r={2} />
                    <circle cx={14} cy={2} r={2} />
                    <circle cx={20} cy={2} r={2} />
                </g>
                <g transform="translate(80 67)">
                    <circle cx={2} cy={2} r={2} />
                    <circle cx={8} cy={2} r={2} />
                    <circle cx={14} cy={2} r={2} />
                    <circle cx={20} cy={2} r={2} />
                </g>
                <g transform="translate(107 67)">
                    <circle cx={2} cy={2} r={2} />
                    <circle cx={8} cy={2} r={2} />
                    <circle cx={14} cy={2} r={2} />
                    <circle cx={20} cy={2} r={2} />
                </g>
            </g>
        </g>
    </svg>
);

export default StripeCreditRecto;
