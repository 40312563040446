import qs from 'qs';
import HttpService from 'Services/HttpService';
import { Member, Memberships } from 'Components/Icons';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import ActionBase from './ActionBase';
import React, { Fragment } from 'react';
import { Image } from 'Components/Misc';
import { Link } from 'react-router-dom';
import Constants from 'Data/Constants';
import Auth from 'Services/Auth';

const defaultMembershipImg = (
    <div className="img w-100 h-100 rounded-circle">
        <Memberships size="50%" />
    </div>
);

async function handleInvitationActionError(error) {
    if (error.response) {
        if (error.response.status === 404) {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        } else if (error.response.status) {
            this.setState({
                resultMessageKey: 'Error.error_message',
                loginTextKey: 'Account.login',
                loginPath: '/',
            });
            return true;
        }
    }
    return false;
}

@inject('uiStore', 'registerStore', 'authStore', 'dashboardStore')
@observer
class InvitationDetail extends ActionBase {
    constructor(props) {
        super(props);

        this.actionCall = async () => {
            const {
                uiStore: { toastStore, loadingStore },
                registerStore,
                history,
                t,
                authStore,
                dashboardStore,
            } = this.props;
            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            registerStore.email = params.email;
            registerStore.accessToken = params.token;
            const { data: invitation } = await HttpService.fetchInvitationByToken(
                params.token
            );
            if (invitation.firstName) {
                registerStore.firstName = invitation.firstName;
                registerStore.lastName = invitation.lastName;
                registerStore.phoneNumber = invitation.phone;
                registerStore.isCoreNetInvitation = invitation.isCoreNetInvitation;
            }
            const loggedIn = authStore.loggedIn;
            if (loggedIn) dashboardStore.load(loadingStore);
            if (invitation.status === Constants.INVITATION_STATUSES.ACCOUNT_EXISTS) {
                toastStore.enqueueToast({
                    message: t('Account.already_used_email'),
                    icon: Member,
                });
                history.push('');
            } else {
                this.setState({
                    component: (
                        <Invitation
                            invitation={invitation}
                            loggedIn={loggedIn}
                            {...this.props}
                        />
                    ),
                });
            }
        };

        this.handleError = handleInvitationActionError.bind(this);
    }
}

@inject('uiStore', 'dashboardStore')
@observer
class Invitation extends React.Component {
    render() {
        const {
            t,
            invitation,
            loggedIn,
            registerStore,
            dashboardStore: { profile },
        } = this.props;

        return (
            invitation && (
                <div id="tourDetail" className="px-2 px-md-6 text-center py-3">
                    <div className="mx-auto wg-8 hg-8 mt-1">
                        <Image
                            imgSrc={invitation.membershipImageUrl}
                            defaultImg={defaultMembershipImg}
                            className="w-100 h-100 rounded-circle"
                        />
                    </div>
                    <h5 className="my-2 text-uppercase">{invitation.membershipName}</h5>
                    {loggedIn ? (
                        profile &&
                        profile.data && (
                            <Fragment>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'Membership.create_other_account_message',
                                            {
                                                param1: profile.data.contactInfo.email,
                                                param2: registerStore.email,
                                                param3: invitation.membershipName,
                                                param4: profile.data.contactInfo.email,
                                            }
                                        ),
                                    }}
                                />
                                <div className="col-12 col-md-6 mx-auto mt-3">
                                    <Link to="/" className="btn btn-primary w-100">
                                        {t('Membership.got_it')}
                                    </Link>
                                    <Link
                                        to="/register"
                                        onClick={Auth.logout}
                                        className=" text-sm pt-2 c-pointer d-block text-dark"
                                    >
                                        {t('Membership.create_other_account_title')}
                                    </Link>
                                </div>
                            </Fragment>
                        )
                    ) : (
                        <Fragment>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('Membership.offline_invitation_message'),
                                }}
                            />
                            <div className="col-12 col-md-6 mx-auto mt-3">
                                <Link to="/register" className="btn btn-primary w-100">
                                    {t('Account.create_my_account')}
                                </Link>
                            </div>
                        </Fragment>
                    )}
                </div>
            )
        );
    }
}

export default translate()(InvitationDetail);
