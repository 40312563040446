import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import EventMonthGroup from './EventMonthGroup';
import ListLoadingSpinner from 'Components/Loading/ListLoadingSpinner';

class EventList extends React.PureComponent {
    static propTypes = {
        events: PropTypes.any,
        matchUrl: PropTypes.string,
        currentPathName: PropTypes.string,
        moreEventsAreLoading: PropTypes.bool,
    };

    render() {
        const {
            events,
            moreEventsAreLoading,
            onClick,
            currentId,
            currentPeriodStart,
        } = this.props;

        return (
            events && (
                <div className="list-group">
                    {Object.keys(events).map((month) => {
                        return (
                            <div key={month}>
                                <EventMonthGroup
                                    currentMonth={events[month]}
                                    onClick={onClick}
                                    currentPeriodStart={currentPeriodStart}
                                    currentId={currentId}
                                />
                            </div>
                        );
                    })}
                    <ListLoadingSpinner loading={moreEventsAreLoading} />
                </div>
            )
        );
    }
}

export default translate()(EventList);
