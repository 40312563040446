import React from 'react';

const Personal = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M72.19 58.13a29.07 29.07 0 1 0-29.06-29.06 29.1 29.1 0 0 0 29.06 29.06zm0-52.32a23.26 23.26 0 1 1-23.25 23.26A23.28 23.28 0 0 1 72.19 5.81zm0 0M89.38 75.41H55a34.45 34.45 0 0 0-34.35 34.26V144h102.7v-34.33a34.12 34.12 0 0 0-34-34.26zm-8.31 5.81l-9.38 17-9.81-17zm-54.61 28.45A28.63 28.63 0 0 1 55 81.22h.17l13.24 23.15-18.53 33.82H26.46zm45.34.74l16 27.78H56.52zm45.74 27.78H94.48l-19.4-33.88L87.7 81.22h1.68a28.31 28.31 0 0 1 28.16 28.45zm0 0"
        />
    </svg>
);

export default Personal;
