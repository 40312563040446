import React, { Fragment } from 'react';
import { translate } from 'react-i18next';

import { Errors, FormGroup, MediaFormGroup } from 'Components/Forms';
import { Arrow, Campuses, Memberships, Personal } from 'Components/Icons';
import MembershipSpecifications from 'Components/Membership/MembershipSpecifications';
import { inject, observer } from 'mobx-react';
import Constants from 'Data/Constants';
import colorVariables from 'Sass/variables.scss';
import { MemberPictureList } from 'Components/Membership';
import ListItem from 'Components/Forms/ListItem';
import MemberCircle from 'Assets/Icons/member-circle.svg';
import classnames from 'classnames';

const defaultMembershipImg = (
    <div className="img rounded-circle">
        <Memberships size="50%" />
    </div>
);
const defaultCampusImg = (
    <div className="img rounded-circle">
        <Campuses size="50%" />
    </div>
);

@inject('dashboardStore', 'uiStore')
@observer
class EditJobPost extends React.Component {
    constructor(props) {
        super(props);
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    state = {
        job_expertises: [],
        job_contractTypes: [],
        introduction: '',
    };

    handleEditorChange = (editor) => {
        this.setState({ introduction: editor.level.content });
    };

    componentDidMount() {
        const {
            uiStore: { loadingStore },
            dashboardStore,
            job,
        } = this.props;
        dashboardStore.loadExpertises(loadingStore);
        dashboardStore.loadContractTypes(loadingStore);
        if (job.id) this.updateJob(job);
    }

    componentDidUpdate(prevProps, prevState) {
        const { job } = this.props;
        if (prevProps.job !== job && job) {
            this.updateJob(job);
        }
    }

    updateJob(job) {
        this.setState({
            image: {
                uploadBase64: '',
                delete: false,
            },
            introduction: job.introduction,
            job_expertises: job.expertises.map((expertise) => expertise.id),
            job_contractTypes: job.contractTypes.map((contractType) => contractType.id),
            imageUrl: job.imageUrl,
            title: job.title,
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
        this.props.onTouch();
    };

    updateExpertises = (id, value) => {
        if (value) {
            this.setState({
                job_expertises: [...this.state.job_expertises, id],
            });
        } else {
            const array = [...this.state.job_expertises]; // make a separate copy of the array
            const index = array.indexOf(id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ job_expertises: array });
            }
        }
        this.props.onTouch();
    };

    updateContractTypes = (id, value) => {
        if (value) {
            this.setState({
                job_contractTypes: [...this.state.job_contractTypes, id],
            });
        } else {
            const array = [...this.state.job_contractTypes]; // make a separate copy of the array
            const index = array.indexOf(id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ job_contractTypes: array });
            }
        }
        this.props.onTouch();
    };

    onSubmit = (e) =>
        this.props.onSubmit(e, {
            image: !this.state.image.delete &&
                this.state.image.uploadBase64 === '' ? null : this.state.image,
            introduction: this.state.introduction,
            expertiseIds: this.state.job_expertises,
            contractTypeIds: this.state.job_contractTypes,
            title: this.state.title,
        });

    render() {
        const {
            imageUrl,
            introduction,
            title,
            job_expertises,
            job_contractTypes,
        } = this.state;
        const {
            t,
            className,
            errors,
            dashboardStore: { expertises, contractTypes, profile },
            onSetMembership,
            contact,
            campus,
            onSetLocation,
            onSetContact,
            membership,
            newJob,
        } = this.props;

        return (
            <form onSubmit={this.onSubmit} className={className || ''}>
                <div className="scrollable d-flex flex-column">
                    <div className="px-5 px-md-11 pb-1 pt-2 bg-dark">
                        <div className="mx-auto">
                            <MediaFormGroup
                                id="image"
                                value={imageUrl}
                                onChange={this.handleInputChange}
                                errors={errors}
                            />
                        </div>
                        <FormGroup
                            id="title"
                            value={title}
                            displayName={t('JobBoard.job_title')}
                            className="col-12 "
                            required={true}
                            onChange={this.handleInputChange}
                            errors={errors}
                        />
                    </div>
                    <div className="bg-body">
                        {membership && (
                            <Fragment>
                                <div className="form-group text-xs title-list-expertises py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey">
                                    {t('Tab.tab_membership_title')}
                                    <span className="text-secondary float-right">*</span>
                                </div>
                                <div className="list-group-very-compact text-sm">
                                    {membership && membership.id ? (
                                        <ListItem
                                            className={classnames(
                                                { 'list-group-item-action ': newJob },
                                                'list-group-item d-flex px-2 px-5 py-1 px-md-11'
                                            )}
                                            title={
                                                <span className="pr-1">
                                                    {membership.name}
                                                </span>
                                            }
                                            subTitle={
                                                membership.members && (
                                                    <MemberPictureList
                                                        idPrefix={
                                                            'grid' + membership.id + '_'
                                                        }
                                                        className="m-0"
                                                        members={membership.members}
                                                    />
                                                )
                                            }
                                            withImage={true}
                                            defaultImg={defaultMembershipImg}
                                            imgSrc={membership.imageUrl}
                                            action={
                                                newJob && (
                                                    <div className="list-group-item-status text-primary">
                                                        <Arrow size="1.5em" />
                                                    </div>
                                                )
                                            }
                                            clickable={newJob}
                                            onClick={onSetMembership}
                                        />
                                    ) : (
                                        <Fragment>
                                            <span
                                                className=" list-group-item list-group-item-action text-sm font-weight-medium px-5 px-md-11"
                                                onClick={onSetMembership}
                                            >
                                                <div className="list-group-item-text d-flex  flex-column align-items-center py-1 ">
                                                    <Fragment>
                                                        <Memberships
                                                            size="2em"
                                                            fill={colorVariables.primary}
                                                        />
                                                        <h5 className="font-weight-bold py-1">
                                                            {t(
                                                                'Membership.select_campus_title'
                                                            )}
                                                        </h5>
                                                    </Fragment>
                                                </div>
                                                <div className="list-group-item-status text-primary ml-3">
                                                    <Arrow size="24px" />
                                                </div>
                                            </span>
                                            {errors && errors.MembershipId && (
                                                <div className="px-2">
                                                    <Errors
                                                        errors={errors.MembershipId}
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        )}
                        <FormGroup
                            id="introduction"
                            className=" mt-1 px-5 py-1 px-md-11 bg-white mb-1"
                            displayName={t('Profile.profile_introduction_title')}
                            type="Editor"
                            required={true}
                            errors={errors}
                            onChange={this.handleEditorChange}
                            value={introduction}
                        />
                        {expertises && expertises.data && expertises.data.length ? (
                            <MembershipSpecifications
                                id="ExpertiseIds"
                                className="py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey"
                                onChange={this.updateExpertises}
                                specs={expertises.data}
                                activeSpecs={job_expertises}
                                required={true}
                                errors={errors}
                                type={Constants.SPECIFICATIONS.EXPERTISE}
                            />
                        ) : null}
                        {contractTypes &&
                        contractTypes.data &&
                        contractTypes.data.length ? (
                            <MembershipSpecifications
                                id="ContractTypeIds"
                                className="mt-1 py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey"
                                onChange={this.updateContractTypes}
                                specs={contractTypes.data}
                                errors={errors}
                                activeSpecs={job_contractTypes}
                                required={true}
                                type={Constants.SPECIFICATIONS.TYPE}
                            />
                        ) : null}

                        <Fragment>
                            <div className="form-group text-xs title-list-expertises mt-1 py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey">
                                {t('Campus.campus_title')}
                                <span className="text-secondary float-right">*</span>
                            </div>
                            <div className="list-group-very-compact text-sm ">
                                {campus && campus.id ? (
                                    <ListItem
                                        className="list-group-item list-group-item-action d-flex px-2 px-5 py-1 px-md-11"
                                        title={
                                            <span className="pr-1">
                                                {campus.title || campus.name}
                                            </span>
                                        }
                                        withImage={true}
                                        imgSrc={campus.imgSrc || campus.mainImageUrl}
                                        defaultImg={defaultCampusImg}
                                        action={
                                            <div className="list-group-item-status text-primary">
                                                <Arrow size="1.5em" />
                                            </div>
                                        }
                                        clickable
                                        onClick={onSetLocation}
                                    />
                                ) : (
                                    <Fragment>
                                        <span
                                            className={classnames(
                                                { 'disabled inactive': !membership.id },
                                                'list-group-item list-group-item-action text-sm font-weight-medium px-5 px-md-11'
                                            )}
                                            onClick={onSetLocation}
                                        >
                                            <div className="list-group-item-text d-flex  flex-column align-items-center py-1 ">
                                                <Fragment>
                                                    <Campuses
                                                        size="2em"
                                                        fill={colorVariables.primary}
                                                    />
                                                    <h5 className="font-weight-bold py-1">
                                                        {t(
                                                            'Membership.select_campus_title'
                                                        )}
                                                    </h5>
                                                </Fragment>
                                            </div>
                                            <div className="list-group-item-status text-primary ml-3">
                                                <Arrow size="24px" />
                                            </div>
                                        </span>
                                        {errors && errors.CampusId && (
                                            <div className="px-2">
                                                <Errors errors={errors.CampusId} />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                        <Fragment>
                            <div className="form-group text-xs title-list-expertises mt-1 py-1 bg-white px-5 px-md-11 mb-0 border-bottom border-light-grey border-4 text-warm-grey">
                                {t('JobBoard.contact_title')}
                                <span className="text-secondary float-right">*</span>
                            </div>
                            <div className="list-group-very-compact text-sm">
                                {contact && contact.id ? (
                                    <ListItem
                                        className="list-group-item list-group-item-action d-flex px-2 px-5 py-1 px-md-11"
                                        title={
                                            <span className="pr-1">
                                                {contact.name ||
                                                contact.id === profile.data.id
                                                    ? t('Member.me', {
                                                          param1: `${
                                                              contact.firstName || ''
                                                          } ${contact.lastName || ''}`,
                                                      })
                                                    : `${contact.firstName || ''} ${
                                                          contact.lastName || ''
                                                      }`}
                                            </span>
                                        }
                                        withImage={true}
                                        imgSrc={contact.imgSrc || contact.imageUrl}
                                        defaultImgSrc={MemberCircle}
                                        action={
                                            <div className="list-group-item-status text-primary">
                                                <Arrow size="1.5em" />
                                            </div>
                                        }
                                        clickable
                                        onClick={onSetContact}
                                    />
                                ) : (
                                    <Fragment>
                                        <span
                                            className={classnames(
                                                { 'disabled inactive': !membership.id },
                                                ' list-group-item list-group-item-action text-sm font-weight-medium px-5 px-md-11'
                                            )}
                                            onClick={onSetContact}
                                        >
                                            <div className="list-group-item-text d-flex  flex-column align-items-center py-1 ">
                                                <Fragment>
                                                    <Personal
                                                        size="2em"
                                                        fill={colorVariables.primary}
                                                    />
                                                    <h5 className="font-weight-bold py-1">
                                                        {t(
                                                            'JobBoard.select_contact_title'
                                                        )}
                                                    </h5>
                                                </Fragment>
                                            </div>
                                            <div className="list-group-item-status text-primary ml-3">
                                                <Arrow size="24px" />
                                            </div>
                                        </span>
                                        {errors && errors.ContactPersonId && (
                                            <div className="px-2">
                                                <Errors errors={errors.ContactPersonId} />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                        <div className="pt-2 px-2 text-warm-grey text-xs font-weight-medium ">
                            <span className="text-secondary ">* </span>
                            {t('Account.required_field')}
                        </div>
                        <div className="p-1 px-2">
                            <Errors errors={errors['other']} />
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary w-100 bottom-action">
                        {t('Membership.confirm')}
                    </button>
                </div>
            </form>
        );
    }
}

export default translate()(EditJobPost);
