import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import EventGrid from './EventGrid';
import NoMatch from 'WebApp/Views/NoMatch';

class EventDashboard extends React.PureComponent {
    renderEventGrid = (renderProps) => <EventGrid {...renderProps} />;

    render() {
        const { match } = this.props;

        return (
            <Fragment>
                <Switch>
                    <Route path={match.url} render={this.renderEventGrid} />
                    <Route component={NoMatch} />
                </Switch>
            </Fragment>
        );
    }
}

export default translate()(EventDashboard);
