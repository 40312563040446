import React from 'react';

const Ach = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M19.594 18.802H.467A.467.467 0 0 1 0 18.335V6.875a.467.467 0 0 1 .467-.476h17.281a.468.468 0 1 1 0 .936H.936v10.531h18.191v-3.745a.468.468 0 0 1 .936 0v4.214a.467.467 0 0 1-.469.467z"
        />
        <path
            fill={fill || 'currentColor'}
            d="M13.146 12.538h-3.114a.467.467 0 0 1-.47-.475V9.8a.467.467 0 0 1 .468-.467h5.23a.468.468 0 0 1 0 .936h-4.762v1.338h2.648a.468.468 0 0 1 0 .936zM8.214 10.269H3.309a.468.468 0 0 1 0-.936h4.905a.468.468 0 0 1 0 .936zM8.214 12.538H3.309a.468.468 0 0 1 0-.936h4.905a.468.468 0 0 1 0 .936zM8.214 16.09H3.309a.468.468 0 0 1 0-.936h4.905a.468.468 0 0 1 0 .935zM12.016 16.09H9.757a.468.468 0 1 1 0-.936h2.26a.468.468 0 0 1 0 .935zM23.765 6.948l-1.506-1.506a.825.825 0 0 0-.657-.241.956.956 0 0 0-.595.283l-6.671 6.684-1.41 4.11 4.116-1.414 6.676-6.666a.887.887 0 0 0 .047-1.25zm-9.332 7.824l.512-1.494.98.98zm2.355-.977l-1.38-1.378 4.914-4.922 1.388 1.388zm5.584-5.582l-1.387-1.387.67-.67 1.388 1.39z"
        />
        />
    </svg>
);

export default Ach;
