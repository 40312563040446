import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import { X, Error, Pin } from 'Components/Icons';
import InvitaWhite from 'Assets/Icons/Invita_white.svg';
import HttpService from 'Services/HttpService';
import { inject } from 'mobx-react';
import { ContactProfile, AddressProfile } from 'Components/Membership';
import { NoResults } from 'Components/Loading';

import { Slideshow } from 'Components/Slideshow';
import TimeZoneUtils from 'Utils/TimeZoneUtils';
import { getDistanceInMiles, StringUtils } from 'Utils/';
import { Image } from 'Components/Misc';
import { ListItemHeader } from 'Components/Forms';
import CampusPlans from './CampusPlans';
import CampusRooms from './CampusRooms';

@inject('uiStore')
class CampusDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campus: { ongoing: true },
            plans: {},
            planToggle: false,
            roomToggle: false,
        };
    }

    componentDidMount() {
        this.loadCampus();
    }

    planToggle = () => {
        this.setState((prevState) => ({
            planToggle: !prevState.planToggle,
        }));
    };

    roomToggle = () => {
        this.setState((prevState) => ({
            roomToggle: !prevState.roomToggle,
        }));
    };

    loadCampus = async () => {
        const {
            uiStore: { loadingStore },
            campusId,
        } = this.props;

        if (this.campusCancellation) this.campusCancellation.cancel();
        this.campusCancellation = HttpService.cancellable();

        loadingStore.addLoading();
        this.setState({ campus: { ongoing: true } });
        try {
            const campus = (
                await HttpService.campusDetail(campusId, this.campusCancellation)
            ).data;
            const plans = (await HttpService.plans(campusId, this.campusCancellation))
                .data;

            let campuseSlides = [campus.mainImageUrl];
            if (campus.imageUrls.length > 0) campuseSlides.push(...campus.imageUrls);

            const slides = campuseSlides.map((img) => ({
                src: img,
            }));
            try {
                const geoLocation = await new Promise((resolve, reject) =>
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                );
                const distance = getDistanceInMiles(
                    geoLocation.coords.latitude,
                    geoLocation.coords.longitude,
                    campus.location.latitude,
                    campus.location.longitude
                );
                Object.assign(campus, {
                    distance,
                });
            } catch (e) {}

            this.setState({
                campus: { data: campus, slides, ongoing: false },
                plans,
            });
        } catch (err) {
            if (this.campusCancellation.isCancelled(err)) return;
            this.setState({ campus: { errored: true, ongoing: false } });
        } finally {
            loadingStore.removeLoading();
        }
    };

    render() {
        const { t, viewPlans, viewRooms, isOpen = true, onClose } = this.props,
            { planToggle, campus, roomToggle } = this.state;
        return (
            <Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={onClose}
                    className="modal-dialog-centered modal-full-screen adaptive"
                >
                    {!campus.errored && campus.data && (
                        <div className="modal-header p-0 campusHeader position-relative">
                            <Slideshow images={campus.slides} />
                            <div className="d-flex campusTitle position-absolute h-100 w-100">
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={onClose}
                                >
                                    <span aria-hidden="true">
                                        <X fill="dark" size="24px" />
                                    </span>
                                </button>
                                <div className="p-3 pb-5 d-flex w-100 justify-content-between align-items-end">
                                    <div className="CampusInfo">
                                        <div className="sub-title font-weight-semibold">
                                            {t('Campus.campus_title')}
                                        </div>
                                        <h3 className="font-weight-medium text-uppercase">
                                            {campus.data.name}
                                        </h3>
                                        {campus.data.isSubscribed ? (
                                            <div className="myLocation">
                                                <Pin className="mr-1" size="1em" />
                                                {t('Campus.my_campus_title')}
                                                {campus.data.distance
                                                    ? campus.data.distance >= 1000
                                                        ? ` (${t(
                                                              'campus.mile_1000_format'
                                                          )})`
                                                        : ` (${t('Campus.mile_format', {
                                                              decimal0:
                                                                  campus.data.distance,
                                                          })})`
                                                    : null}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="CampusTime">
                                        <div className="text-xs font-weight-semibold">
                                            {TimeZoneUtils.getTimeZoneWithCampus(
                                                campus.data
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ModalBody className="d-flex flex-column bg-light-grey mh-0">
                        <div className="scrollable d-flex flex-column">
                            {campus && campus.errored ? (
                                <NoResults
                                    className="m-auto pt-2"
                                    icon={<Error size="3.75em" />}
                                    title={t('Error.error')}
                                    subTitle={t('Error.error_message')}
                                    action={{
                                        text: t('Error.retry'),
                                        onClick: this.loadCampus,
                                    }}
                                />
                            ) : (
                                campus &&
                                campus.data && (
                                    <Fragment>
                                        <AddressProfile
                                            address={campus.data.address}
                                            location={campus.data.location}
                                            isLocation
                                        />
                                        {campus.data.description &&
                                            !StringUtils.isEmpty(
                                                campus.data.description
                                            ) && (
                                                <Fragment>
                                                    <ListItemHeader
                                                        superTitle={t(
                                                            'Campus.campus_title'
                                                        )}
                                                        title={campus.data.name}
                                                    />
                                                    {campus.data.description && (
                                                        <div
                                                            className="pt-0 pb-1 px-2 bg-white text-sm"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    campus.data
                                                                        .description,
                                                            }}
                                                        />
                                                    )}
                                                </Fragment>
                                            )}

                                        {viewPlans && (
                                            <button
                                                className="btn btn-primary w-100 rounded-0"
                                                onClick={this.planToggle}
                                            >
                                                {t('Campus.see_plans')}
                                            </button>
                                        )}
                                        {viewRooms && (
                                            <button
                                                className="btn btn-dark w-100 rounded-0 mt-1"
                                                onClick={this.roomToggle}
                                            >
                                                {t('Campus.see_conference_rooms')}
                                            </button>
                                        )}

                                        <ContactProfile
                                            contact={campus.data.contactInfo}
                                            isLocation
                                        />
                                        {campus.data.hasInvita ? (
                                            <a
                                                href="https://www.invitacafe.com/"
                                                target="_blank"
                                                className="btn py-2 rounded-0 text-center text-white bg-black w-100 mt-1"
                                                rel="noopener noreferrer"
                                            >
                                                <Image
                                                    title={t('invita.invita')}
                                                    imgSrc={InvitaWhite}
                                                />
                                            </a>
                                        ) : null}
                                    </Fragment>
                                )
                            )}
                        </div>
                    </ModalBody>
                </Modal>
                {planToggle && (
                    <CampusPlans
                        {...this.props}
                        isOpen={planToggle}
                        onClose={this.planToggle}
                        campusId={campus.data.id}
                    />
                )}

                {roomToggle && (
                    <CampusRooms
                        {...this.props}
                        isOpen={roomToggle}
                        onClose={this.roomToggle}
                        campusId={campus.data.id}
                    />
                )}
            </Fragment>
        );
    }
}

export default translate()(CampusDetail);
