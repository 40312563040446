import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import RecurringBookingBackground from 'Assets/Images/RecurringBooking.jpg';
import RecurringBookingStore from 'Stores/RecurringBooking/RecurringBookingStore';
import { InfoPane } from 'Components/Modal';
import Constants from 'Data/Constants';
import RecurrenceTypeStep from './Recurrence/RecurrenceTypeStep';
import RecurringDurationStep from './Recurrence/RecurrenceDurationStep';
import RecurrenceConflictsStep from './Recurrence/RecurrenceConflictsStep';
import RecurrenceConflictResolutionStep from './Recurrence/RecurrenceConflictResolutionStep';

/**
 * TODO:
 *  Delete this file + RecurrenceTypeStep, RecurringDurationStep, RecurrenceConflictsStep
 *  and RecurrenceConflictResolutionStep when CGCC-9807 is done.
 */

@inject('uiStore')
@observer
class RecurringStep extends React.Component {
    static propTypes = {
        store: PropTypes.shape({
            recurringStore: PropTypes.instanceOf(RecurringBookingStore),
        }),
    };

    constructor(props) {
        super(props);
        this.store = props.store.recurringStore;
    }

    componentDidMount() {
        const { location, match, history } = this.props;
        if (location.pathname === match.url) {
            history.replace(`${match.url}/recurrence-type`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { location, match, history } = this.props;
        if (location.pathname === match.url) {
            history.replace(`${match.url}/recurrence-type`);
        }
    }

    componentWillUnmount() {
        if (this.cancellation) this.cancellation.cancel();
    }

    nextStep = () => {
        const { onComplete, stepKey, store } = this.props;
        store.updateState('touched', true);
        onComplete(stepKey, null);
    };

    nextRecurrenceStep = (path) => () => {
        const {
            history,
            match: { url },
        } = this.props;
        history.push(`${url}/${path}`);
    };

    reccurenceTypeOnChange = (selectedRecurrenceId) => {
        if (selectedRecurrenceId === Constants.RECURRING_NO_REPEAT) {
            this.nextStep();
        } else {
            this.nextRecurrenceStep('duration')();
        }
    };

    renderRecurrenceType = () => {
        const { store } = this.props;
        return (
            <RecurrenceTypeStep
                periodStart={store.bookingStart}
                periodEnd={store.bookingEnd}
                store={this.store}
                conferenceRoomId={store.conferenceRoomId}
                creditBalanceId={store.creditBalanceId}
                onChange={this.reccurenceTypeOnChange}
            />
        );
    };

    renderDuration = (routerProps) => {
        const { match } = this.props;
        return (
            <RecurringDurationStep
                {...routerProps}
                onComplete={this.nextRecurrenceStep('conflicts')}
                store={this.store}
                parentMatchURL={match.url}
                onChange={this.updateSelectedRecurringDuration}
            />
        );
    };

    renderConflicts = (routerProps) => {
        const { match } = this.props;
        return (
            <RecurrenceConflictsStep
                {...routerProps}
                parentMatchURL={match.url}
                onComplete={this.nextStep}
                store={this.store}
            />
        );
    };

    renderConflictResolution = (routerProps) => {
        const { match } = this.props;
        return (
            <RecurrenceConflictResolutionStep
                {...routerProps}
                parentMatchURL={match.url}
                store={this.store}
            />
        );
    };

    render() {
        const { t, match, modalTitle } = this.props;

        return (
            <Fragment>
                <InfoPane
                    title={t('Booking.recurring_booking')}
                    subTitle={t('Booking.select_recurring_option')}
                    bgImgUrl={RecurringBookingBackground}
                    modalTitle={modalTitle}
                />
                <Route
                    path={`${match.url}/recurrence-type`}
                    render={this.renderRecurrenceType}
                />
                <Route path={`${match.url}/duration`} render={this.renderDuration} />
                <Route path={`${match.url}/conflicts`} render={this.renderConflicts} />
                <Route
                    path={`${match.url}/resolve`}
                    render={this.renderConflictResolution}
                />
            </Fragment>
        );
    }
}

export default translate()(RecurringStep);
