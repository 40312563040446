import React from 'react';

const Guest = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 48 48"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M10 48h27.773V4.38H10V48zm1.207-1.577h25.359V5.956H11.207v40.467z" />
            <path d="M14.428 35.38h18.918v-1.208H14.428zM14.428 39.371h18.918v-1.207H14.428zM14.428 43.444h18.918v-1.207H14.428zM23.933 19.752h-.007a2.333 2.333 0 0 1-1.656-.691 2.33 2.33 0 0 1-.685-1.66c0-.628.247-1.217.692-1.66a2.338 2.338 0 0 1 1.656-.684h.004a2.352 2.352 0 0 1 1.659.692c.442.447.686 1.035.684 1.66a2.35 2.35 0 0 1-2.347 2.343m2.439-4.777a3.423 3.423 0 0 0-2.432-1.015h-.01c-.914 0-1.777.356-2.427 1.004a3.425 3.425 0 0 0-1.015 2.434 3.448 3.448 0 0 0 3.436 3.45h.009c.914 0 1.777-.357 2.43-1.005a3.416 3.416 0 0 0 1.014-2.431 3.421 3.421 0 0 0-1.005-2.437M21.362 23.474c.187-.043.38-.067.59-.071.021-.003.044-.003.064-.003l3.806.009a2.851 2.851 0 0 1 1.097.221 2.914 2.914 0 0 1 1.789 2.71l-.006 2.93-9.632-.02.006-2.932c.004-1.345.964-2.541 2.286-2.844m4.462-1.16l-3.805-.009a4.03 4.03 0 0 0-4.04 4.011l-.009 4.026 11.824.026.009-4.026c.005-2.215-1.78-4.024-3.98-4.029M20.276 10.822h7.221V9.098h-7.221v1.724zm-.928.928h9.078V8.17h-9.078v3.58z" />
            <path d="M21.953 10h3.868V0h-3.868z" />
        </g>
    </svg>
);

export default Guest;
