function handlePositionError(toastStore, t) {
    if (!sessionStorage.getItem('location_refused')) {
        toastStore.enqueueErrorToast({
            message: t('Campus.gps_disable'),
        });
        sessionStorage.setItem('location_refused', 'true');
    }
}

export { handlePositionError };
