import AuthStore from './AuthStore';
import UIStore from './UIStore';
import EventBus from 'Utils/EventBus';
import DashboardStore from './DashboardStore';
import MembershipStore from './MembershipStore';
import RegisterStore from './RegisterStore';
import AddMembersStore from './AddMembersStore';
import SeedStore from './SeedStore';
import FloorPlanStore from 'Stores/FloorPlanStore';

class RootStore {
    constructor() {
        this.authStore = new AuthStore();
        this.registerStore = new RegisterStore();
        this.membershipStore = new MembershipStore();
        this.addMembersStore = new AddMembersStore();
        this.seedStore = new SeedStore();
        this.uiStore = new UIStore();
        this.eventBus = new EventBus();
        this.dashboardStore = new DashboardStore(this.eventBus);
        this.floorPlanStore = new FloorPlanStore();
    }
}

export default new RootStore();
