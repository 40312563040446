import React from 'react';
import moment from 'moment';

import { Image } from 'Components/Misc';
import { Constants } from 'Data/';
import { Arrow, ConferenceRoom, RecurringBooking } from 'Components/Icons';
import { translate } from 'react-i18next';

const defaultImg = (
    <div className="img rounded-circle">
        <ConferenceRoom size="50%" />
    </div>
);

class BookingListItem extends React.PureComponent {
    viewBooking = () => {
        const { onClick, id } = this.props;
        onClick(id);
    };

    render() {
        const {
            amenity,
            periodStart,
            periodEnd,
            recurrence,
            active,
            cost,
            bookingHistory = false,
            t,
            title,
        } = this.props;
        return (
            <div
                className={
                    'list-group-item list-group-item-action' +
                    (active ? ' active-item' : '')
                }
                onClick={this.viewBooking}
            >
                <div className="list-group-item-text pl-1">
                    <span className="rounded-picture-list">
                        <span className="rounded-picture-list-item">
                            <Image imgSrc={amenity.imageUrl} defaultImg={defaultImg} />
                        </span>
                    </span>
                    <span className="pl-1 font-weight-bold">
                        {title ? title : amenity.name}
                    </span>
                    {bookingHistory && (
                        <div className="font-weight-semibold">
                            {moment.parseZone(periodStart).format(Constants.DATE_FORMAT)}
                        </div>
                    )}
                    <div className="text-sm d-flex flex-row align-item-center justify-content-between">
                        <div className="text-truncate pr-1">
                            {moment.parseZone(periodStart).format(Constants.TIME_FORMAT) +
                                ' - ' +
                                moment.parseZone(periodEnd).format(Constants.TIME_FORMAT)}
                            {recurrence ? <RecurringBooking size="1em" /> : ''}
                        </div>
                        <span className="text-sm">
                            {t('Room.credit_amount', {
                                param1: cost,
                                count: cost,
                            })}
                        </span>
                    </div>
                </div>
                <div className="list-group-item-status text-primary ml-1 ml-md-2">
                    <Arrow size="1.5em" />
                </div>
            </div>
        );
    }
}

export default translate()(BookingListItem);
