import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import moment from 'moment';
import Next from 'Components/Icons/Next';

import Constants from 'Data/Constants';

const formatDate = (date) => moment(date).format(Constants.TEXT_DATE_FORMAT);
const { INVOICE_STATUSES } = Constants;

class InvoiceStatus extends React.PureComponent {
    static STATUSES = {
        [INVOICE_STATUSES.SUCCESS]: {
            className: 'badge-success',
            text: (t) => t('Invoice.payment_closed'),
            detailText: (t, paymentDate) =>
                t('Invoice.past_payment_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.dateClosed) return this.text(t);

                return showDetail
                    ? this.detailText(t, formatDate(moment.parseZone(invoice.dateClosed)))
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.FAILED]: {
            className: 'badge-danger',
            text: (t) => t('Invoice.payment_fail'),
            detailText: (t, paymentDate) =>
                t('Invoice.payment_fail_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.datePayment) return this.text(t);

                return showDetail
                    ? this.detailText(
                          t,
                          formatDate(moment.parseZone(invoice.datePayment))
                      )
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.PENDING]: {
            className: 'badge-primary',
            text: (t) => t('Invoice.payment_pending'),
            renderText(invoice, showDetail, t) {
                return this.text(t);
            },
        },
        [INVOICE_STATUSES.VOID]: {
            className: 'badge-warm-grey',
            text: (t) => t('Invoice.invoice_voided'),
            detailText: (t, paymentDate) =>
                t('Invoice.payment_voided_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.dateClosed) return this.text(t);

                return showDetail
                    ? this.detailText(t, formatDate(moment.parseZone(invoice.dateClosed)))
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.REFUNDED]: {
            className: 'badge-secondary',
            text: (t) => t('Invoice.payment_refunded'),
            detailText: (t, paymentDate) =>
                t('Invoice.payment_refunded_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.datePayment) return this.text(t);

                return showDetail
                    ? this.detailText(
                          t,
                          formatDate(moment.parseZone(invoice.datePayment))
                      )
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.AWAITING_PAYMENT]: {
            className: 'badge-warning',
            text: (t) => t('Invoice.awaiting_payment'),
            detailText: (t, paymentDate) =>
                t('Invoice.awaiting_payment_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.dateCharged) return this.text(t);

                return showDetail
                    ? this.detailText(t, formatDate(moment.parseZone(invoice.dueDate)))
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.PRE_AUTHORIZED]: {
            className: 'badge-black',
            text: (t) => t('PaymentMethod.preauthorized_payment'),
            detailText: (t, paymentDate) =>
                t('Invoice.preauthorized_payment_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                if (!invoice.dateCharged) return this.text(t);

                return showDetail
                    ? this.detailText(t, formatDate(moment.parseZone(invoice.dueDate)))
                    : this.text(t);
            },
        },
        [INVOICE_STATUSES.DUE]: {
            className: 'badge-black',
            text: (t) => t('PaymentMethod.awaiting_payment'),
            detailText: (t, paymentDate) =>
                t('Invoice.due_payment_date', { param1: paymentDate }),
            renderText(invoice, showDetail, t) {
                return this.detailText(t, formatDate(moment.parseZone(invoice.dueDate)));
            },
        },
    };

    static propTypes = {
        invoice: PropTypes.shape({
            status: PropTypes.oneOf(INVOICE_STATUSES),
            datePayment: PropTypes.string,
            dateClosed: PropTypes.string,
        }),
    };

    render() {
        const { invoice, className, t, showDetail } = this.props;
        let status = '';
        let previousStatus = '';
        if (invoice) {
            status = InvoiceStatus.STATUSES[invoice.status || invoice.newStatus];
            if (
                invoice.status === Constants.INVOICE_STATUSES.AWAITING_PAYMENT ||
                invoice.newStatus === Constants.INVOICE_STATUSES.AWAITING_PAYMENT
            ) {
                if (
                    invoice.isChargedAutomatically &&
                    moment().isBefore(invoice.dueDate)
                ) {
                    status =
                        InvoiceStatus.STATUSES[Constants.INVOICE_STATUSES.PRE_AUTHORIZED];
                } else if (
                    moment().isBefore(invoice.dueDate) &&
                    !invoice.isChargedAutomatically
                ) {
                    status = InvoiceStatus.STATUSES[Constants.INVOICE_STATUSES.DUE];
                }
            }
            if (
                invoice.previousStatus &&
                invoice.previousStatus === Constants.INVOICE_STATUSES.AWAITING_PAYMENT
            ) {
                if (
                    invoice.isChargedAutomatically &&
                    moment().isBefore(invoice.dueDate)
                ) {
                    previousStatus =
                        InvoiceStatus.STATUSES[Constants.INVOICE_STATUSES.PRE_AUTHORIZED];
                } else if (
                    moment().isBefore(invoice.dueDate) &&
                    !invoice.isChargedAutomatically
                ) {
                    previousStatus =
                        InvoiceStatus.STATUSES[Constants.INVOICE_STATUSES.DUE];
                } else {
                    previousStatus =
                        InvoiceStatus.STATUSES[invoice.status || invoice.previousStatus];
                }
            }

            if (status) {
                return (
                    <Fragment>
                        {previousStatus && previousStatus !== status ? (
                            <Fragment>
                                <span
                                    className={`font-weight-light badge ${
                                        previousStatus.className
                                    } text-white ${className || ''}`}
                                >
                                    {previousStatus.renderText(invoice, showDetail, t)}
                                </span>
                                <Next size="1.5em" className="mx-1" />
                            </Fragment>
                        ) : (
                            ''
                        )}
                        <span
                            className={`font-weight-light badge ${
                                status.className
                            } text-white ${className || ''}`}
                        >
                            {status.renderText(invoice, showDetail, t)}
                        </span>
                    </Fragment>
                );
            } else return <span className="badge badge-secondary">{invoice.status}</span>;
        }
    }
}

export default translate()(InvoiceStatus);
