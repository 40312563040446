import React from 'react';

const CellPhone = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 60 60"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M43.925 53.033a4.642 4.642 0 0 1-4.637 4.637H20.712a4.642 4.642 0 0 1-4.637-4.64v-5.277h27.85zm-27.85-7.615h27.85V13.02h-27.85v32.4zm0-38.446a4.644 4.644 0 0 1 4.64-4.64h18.573c2.56 0 4.64 2.083 4.64 4.64v3.714H16.072V6.972zM39.288 0H20.712a6.98 6.98 0 0 0-6.97 6.97v46.06a6.977 6.977 0 0 0 6.97 6.97h18.576a6.977 6.977 0 0 0 6.97-6.97V6.97A6.98 6.98 0 0 0 39.288 0z" />
            <path d="M33.196 52.554a2.794 2.794 0 1 1-5.588 0 2.794 2.794 0 0 1 5.588 0" />
        </g>
    </svg>
);

export default CellPhone;
