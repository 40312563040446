import React from 'react';
const MyBookings = (props) => (
    <svg
        className={'icon' + (props.className ? ` ${props.className}` : '')}
        width={props.size || '1em'}
        height={props.size || '1em'}
        viewBox="0 0 102.14 144.04"
    >
        <g data-name="Layer 2" fill={props.fill || 'currentColor'} fillRule="evenodd">
            <path
                d="M.15 144L0 0h102.14v144l-51.06-36.37zm50.92-43.28l45.48 32.42V5.59H5.6l.14 127.59z"
                data-name="Layer 1"
            />
        </g>
    </svg>
);

export default MyBookings;
