import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { inject } from 'mobx-react';

import { ConferenceRoom, Bookmark, Date } from 'Components/Icons';
import { ModalFlowBase } from 'Components/Modal';
import BookingStore from 'Stores/BookingStore';
import { LoadingSpinner } from 'Components/Loading';
import HttpService from 'Services/HttpService';

import TimeSlotStep from './TimeSlotStep';
import ConferenceRoomStep from './ConferenceRoomStep';
import ConfirmationStep from './ConfirmationStep';
import { Constants } from 'Data/';

const ICON_SIZE = '20px';

@inject('uiStore')
class BookingModalEdit extends ModalFlowBase {
    static propTypes = {
        booking: PropTypes.shape({
            creditBalance: PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                imageUrl: PropTypes.string,
                totalAmount: PropTypes.number,
            }),
            id: PropTypes.string,
            periodEnd: PropTypes.string,
            periodStart: PropTypes.string,
            isPrivate: PropTypes.bool,
            amenity: PropTypes.shape({
                id: PropTypes.string,
                campusId: PropTypes.string,
            }),
        }),
    };

    constructor(props) {
        super(props);

        const bookingStore = new BookingStore();
        this.flowStore = bookingStore;
        this.modalSize = 'modal-xl modal-dialog-centered modal-full-screen';
        this.modalPane = 'two-panes';
        this.paths = [
            {
                path: 'booking-availabilities',
                title: 'Booking.booking_availabilities_title',
                component: TimeSlotStep,
                storeKey: 'bookingStart',
                completed: () => !!bookingStore.bookingStart,
                icon: <Date size={ICON_SIZE} />,
            },
            {
                path: 'booking-details',
                title: 'Booking.booking_details_title',
                component: ConfirmationStep,
                completed: () => true,
                icon: <Bookmark size={ICON_SIZE} />,
            },
        ];

        this.titleIcon = ConferenceRoom;
        this.titleKey = 'Booking.edit_booking_title';
        this.modalHeaderBg = 'bg-black modal-edit-booking';
        this.modalType = Constants.EVENT_TYPE.BOOKING;
        this.state = {
            loading: true,
            showLoading: false,
            hasPermission: false,
        };
        // Uncomment to pre-populate the store with data.
        // this.flowStore.seed();
    }

    componentDidMount() {
        import('Components/Campus/CampusDetail').then((CampusDetail) =>
            this.setState({ CampusDetail: CampusDetail.default })
        );
        import('Components/Plan/PlanDetail').then((PlanDetail) =>
            this.setState({ PlanDetail: PlanDetail.default })
        );
        import('Components/Room/RoomDetail').then((RoomDetail) =>
            this.setState({ RoomDetail: RoomDetail.default })
        );
        import('WebApp/Views/Logged/Membership/AddMembers').then((AddMembers) =>
            this.setState({ AddMembers: AddMembers.default })
        );

        this.mounted = true;
        this.cancellation = HttpService.cancellable();
        if (this.props.booking) {
            this.checkPermissions();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.booking !== this.props.booking &&
            !prevProps.booking &&
            this.props.booking
        ) {
            this.checkPermissions();
        }
    }

    populateStore = async () => {
        const {
            booking,
            uiStore: { loadingStore },
        } = this.props;
        this.flowStore.seed({
            bookingId: booking.id,
            bookingTitle: booking.title,
            bookingDescription: booking.description,
            attendees: booking.invitedAccounts,
            selectedMemberIds:
                booking.invitedAccounts && booking.invitedAccounts.length
                    ? booking.invitedAccounts.map((attendee) => attendee.id)
                    : [],
            creditBalanceId: booking.creditBalance.id,
            campusId: booking.amenity.campusId,
            preSelectedBookingStart: booking.periodStart,
            preSelectedBookingEnd: booking.periodEnd,
            preSelectedConferenceRoomId: booking.amenity.id,
            isPrivate: booking.isPrivate,
            recurring: booking.recurrence,
            creditBalance: booking.creditBalance,
            originalBooking: booking,
        });
        if (this.locationCancellable) this.locationCancellable.cancel();
        this.locationCancellable = HttpService.cancellable();
        try {
            loadingStore.addLoading();
            const { data } = await HttpService.campusDetail(booking.amenity.campusId);

            this.flowStore.campus = data;
        } catch (e) {
        } finally {
            loadingStore.removeLoading();
        }
    };

    componentWillUnmount() {
        this.cancellation.cancel();
        if (this.locationCancellable) this.locationCancellable.cancel();
        this.mounted = false;
    }

    checkPermissions = async () => {
        const { history, booking, parentMatchURL } = this.props;
        try {
            setTimeout(() => {
                if (this.state.loading && this.mounted)
                    this.setState({ showLoading: true });
            }, 200);
            let hasPermission;
            if (
                booking.creditBalance.paymentMethodHolder.holderType ===
                Constants.HOLDER_TYPE.MEMBERSHIP
            ) {
                const { data: membership } = await HttpService.membershipDetail(
                    booking.creditBalance.paymentMethodHolder.holderId,
                    this.cancellation
                );
                hasPermission = membership.rights.includes(
                    Constants.MEMBERSHIP_RIGHTS.CREDITING
                );
            }
            //Personal booking
            else {
                hasPermission = true;
            }
            if (hasPermission) {
                await this.populateStore();
                this.setState({ hasPermission: true, loading: false });
                this.loadFirstStep();
            } else {
                return history.replace(parentMatchURL);
            }
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            history.replace(parentMatchURL);
        }
    };

    render() {
        const { loading, hasPermission, showLoading } = this.state;
        if (loading)
            return (
                <div
                    className={
                        'd-flex flex-centered w-100 h-100 modal-backdrop fade' +
                        (showLoading ? ' show' : '')
                    }
                >
                    <LoadingSpinner loading />
                </div>
            );
        else if (hasPermission) return super.render();
        else return null;
    }
}

export default translate()(BookingModalEdit);
