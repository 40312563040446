import React from 'react';
import ReactDOM from 'react-dom';
import { translate } from 'react-i18next';

import { LogoName } from 'Components/Icons';

class NoMatch extends React.PureComponent {
    back = () => {
        const { history } = this.props;
        //If page is not from a fresh tab/window
        if (history.length > 2) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    rootElm = document.getElementById('root');

    componentDidMount() {
        this.rootElm.classList.add('fullscreen-view-open');
    }

    componentWillUnmount() {
        this.rootElm.classList.remove('fullscreen-view-open');
    }

    handleClick = (e) => {
        e.stopPropagation();
    };

    render() {
        const { t } = this.props;

        return ReactDOM.createPortal(
            <div
                className="bg-dark d-flex flex-column no-match"
                onClick={this.handleClick}
            >
                <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 pb-6">
                    <h1 className="_404 text-secondary text-center mb-6">404</h1>
                    <h1 className="text-uppercase text-center mb-8 mb-md-16">
                        {t('page_not_found')}
                    </h1>
                    <div className="container">
                        <div className="row">
                            <div className="offset-md-3 col-md-6 col-12">
                                <button
                                    className="btn btn-primary w-100"
                                    onClick={this.back}
                                >
                                    {t('back_to_site')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo pb-6 pb-md-11">
                    <a href="/">
                        <LogoName className="d-block mx-auto" fill="#fff" size="35em" />
                    </a>
                </div>
            </div>,
            this.rootElm
        );
    }
}

export default translate()(NoMatch);
