import React from 'react';

const DeclineOutline = (props) => (
    <svg
        className="icon"
        viewBox="0 0 48 48"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            fill={props.fill || 'currentColor'}
            fillRule="evenodd"
            d="M42.278 0L23.803 18.474 5.525.197 0 5.722 18.277 24 0 42.278 5.525 47.8 23.8 29.522 42.278 48l5.522-5.526L29.325 24 47.8 5.522 42.278 0zm-17.2 19.749l17.2-17.2 2.972 2.973-17.2 17.2L26.777 24 45.25 42.474l-2.972 2.973-17.2-17.2-1.275-1.275-1.278 1.275-17 17.003-2.975-2.972L20.827 24 2.55 5.722 5.525 2.75l17.003 17.002 1.275 1.275 1.274-1.278z"
        />
    </svg>
);

export default DeclineOutline;
