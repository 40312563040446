import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import moment from 'moment';

import { Constants } from 'Data/';
import BookingDateGroup from './BookingDateGroup';
import TimeZoneUtils from 'Utils/TimeZoneUtils';

const bookingShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
    reservedBy: PropTypes.string,
    type: PropTypes.string,
    recurrence: PropTypes.object,
    amenity: PropTypes.object,
    status: PropTypes.oneOf(Constants.BOOKING_STATUS),
});

@observer
class BookingGrid extends React.Component {
    static propTypes = {
        bookings: PropTypes.oneOfType([
            PropTypes.arrayOf(bookingShape),
            MobxPropTypes.observableArrayOf(bookingShape),
        ]),
        matchUrl: PropTypes.string,
    };

    render() {
        const { bookings, locations, matchUrl, onClick, currentId } = this.props;
        let currentDate = '';
        let listBookings = bookings.reduce((loop, currentBooking) => {
            const momentDate = moment.parseZone(currentBooking.periodStart);
            currentBooking.timeZone = TimeZoneUtils.getTimeZone(
                locations,
                currentBooking.amenity.campusId
            );
            currentDate = momentDate.format(Constants.DATE_FORMAT);
            loop[currentDate] = loop[currentDate] || {
                bookings: [],
                momentDate: momentDate,
            };
            loop[currentDate].bookings.push(currentBooking);
            return loop;
        }, {});
        return (
            listBookings &&
            Object.keys(listBookings).map((date) => {
                return (
                    <div key={date} className="bookingList mb-1">
                        <BookingDateGroup
                            currentDate={listBookings[date]}
                            matchUrl={matchUrl}
                            currentId={currentId}
                            onClick={onClick}
                        />
                    </div>
                );
            })
        );
    }
}

export default translate()(BookingGrid);
