import React from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Errors, FormGroup } from 'Components/Forms';
import { Back, EMail, LogoName } from 'Components/Icons';
import ModelStateStore from 'Stores/ModelStateStore';
import HttpService from 'Services/HttpService';

@inject('uiStore')
@observer
class ForgotPassword extends React.Component {
    state = {
        email: '',
    };

    modelStateStore = new ModelStateStore({
        to: 'email',
        from: 'forgottenPasswordRequest.email',
    });

    handleInputChange = (event) => {
        this.setState({ email: event.target.value });
    };

    submit = async (e) => {
        e.preventDefault();
        const {
            uiStore: { toastStore, loadingStore },
            t,
            history,
        } = this.props;
        try {
            loadingStore.addLoading();
            await HttpService.forgottenPassword({ email: this.state.email });
            toastStore.enqueueToast({
                icon: EMail,
                message: t('Account.recover_email_sent'),
            });
            history.replace('/');
        } catch (error) {
            this.modelStateStore.setResponse(error.response, t);
        } finally {
            loadingStore.removeLoading();
        }
    };

    render() {
        const { t } = this.props,
            { errors } = this.modelStateStore;

        return (
            <div id="login" className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12">
                        <Link to="/" className="d-block my-5">
                            <LogoName
                                className="w-75 d-block mx-auto"
                                fill="#fff"
                                size="35em"
                            />
                        </Link>
                        <p className="text-center text-sm mb-3 mt-7">
                            {t('Account.forgot_password_message')}
                        </p>
                        <div
                            id="forgot-password-form"
                            className="header card card-inverse px-md-9 py-md-2"
                        >
                            <Link className="close" to={'/'}>
                                <span aria-hidden="true">
                                    <Back />
                                </span>
                            </Link>
                            <form className="card-body" onSubmit={this.submit}>
                                <FormGroup
                                    id="email"
                                    displayName={t('Account.email')}
                                    className="mt-3 mb-0"
                                    type="email"
                                    required={true}
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    errors={errors}
                                />
                                <div className="col-12">
                                    <Errors errors={errors['other']} />
                                </div>
                                <div className="form-group my-3">
                                    <input
                                        type="submit"
                                        value={t('Account.recover_password')}
                                        className="btn btn-primary w-100 mt-2"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(ForgotPassword);
