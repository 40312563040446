import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { ToggleSwitch } from 'Components/Forms';
import Image from 'Components/Misc/Image';
import classnames from 'classnames';

class MembershipSpecificationsItem extends React.PureComponent {
    static propTypes = {
        permission: PropTypes.shape({
            resourceKey: PropTypes.string,
            checked: PropTypes.bool,
            id: PropTypes.string,
            onChange: PropTypes.func,
            disabled: PropTypes.bool,
        }),
    };

    handleClick = () => {
        const { onChange, id, checked } = this.props;
        onChange(id, !checked);
    };

    render() {
        const {
            imgSrc,
            defaultImg,
            title,
            id,
            checked,
            onChange,
            disabled,
            imgWidth,
            imgHeight,
            className,
            details = false,
        } = this.props;

        return (
            <div
                className={classnames(
                    {
                        'list-group-item-action': onChange,
                        granted: checked,
                    },
                    'list-group-item list-expertises d-flex px-2',
                    className
                )}
                onClick={onChange ? this.handleClick : null}
            >
                <div className="list-group-item-image mr-2 ">
                    <Image
                        title={title}
                        imgSrc={imgSrc}
                        width={imgWidth}
                        height={imgHeight}
                        className="rounded-0"
                        defaultImg={defaultImg}
                    />
                </div>
                <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                    <span className="sub-title text-xs">{title}</span>
                    {details && <span className="text-right">{details}</span>}
                </div>
                {!disabled ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <ToggleSwitch id={id} checked={checked} onChange={onChange} />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default translate()(MembershipSpecificationsItem);
