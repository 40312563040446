import React from 'react';

const Campuses = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M11.308.028v4.03H7.443v2.208H5.4v17.668h12.687V6.266H16V4.058h-3.864V.028h-.828zM8.27 6.266h6.901v-1.38h-6.9v1.38zm-2.043 16.84H17.27V7.093H6.228v16.011z" />
            <path d="M7.498 21.063h.828v-2.208h-.828zm2.54 0h.828v-2.208h-.828zm2.539 0h.829v-2.208h-.829zm2.54 0h.828v-2.208h-.828zm-7.619-4.859h.828v-2.208h-.828zm2.54 0h.828v-2.208h-.828zm2.539 0h.829v-2.208h-.829zm2.54 0h.828v-2.208h-.828zm-7.619-4.858h.828V9.137h-.828zm2.54 0h.828V9.137h-.828zm2.539 0h.829V9.137h-.829zm2.54 0h.828V9.137h-.828z" />
        </g>
    </svg>
);

export default Campuses;
