import { inject } from 'mobx-react';
import { translate } from 'react-i18next';
import qs from 'qs';

import HttpService from 'Services/HttpService';

import ActionBase from './ActionBase';
import { handleSubscriptionActionError } from './AcceptSubscriptionProposal';

@inject('uiStore')
class DeclineSubscriptionProposal extends ActionBase {
    constructor(props) {
        super(props);
        this.actionCall = async () => {
            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });

            await HttpService.rejectSubscriptionByToken({
                token: this.token,
                subscriptionId: params.subscriptionId,
                ignoreErrorToast: true,
            });
            this.setState({
                loginTextKey: 'Account.login',
                resultMessageKey: 'Subscription.subscription_declined_confirmation',
            });
        };
        this.state.loginPath = '/';
        this.handleError = handleSubscriptionActionError.bind(this);
    }
}

export default translate()(DeclineSubscriptionProposal);
