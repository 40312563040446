import React from 'react';

const Birthday = ({ size, fill }) => (
    <svg
        className="icon"
        viewBox="0 0 30 30"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M3.08 27.863h23.363V9.286H3.079v18.577zm0-23.723h5.794v1.444a.54.54 0 1 0 1.08 0V4.14H19.5v1.444a.54.54 0 0 0 1.08 0V4.14h5.862v4.067H3.079V4.14zm23.902-1.08H20.58V1.54a.54.54 0 1 0-1.079 0v1.52H9.954V1.54a.54.54 0 0 0-1.08 0v1.52H2.54A.539.539 0 0 0 2 3.6v24.8c0 .3.241.541.539.541h24.443c.3 0 .54-.241.54-.54V3.598a.54.54 0 0 0-.54-.539z" />
            <path d="M6.424 16.196h2.45v-2.45h-2.45v2.45zm2.99 1.08h-3.53a.54.54 0 0 1-.539-.54v-3.53a.54.54 0 0 1 .54-.54h3.529a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.54zm4.122-1.08h2.45v-2.45h-2.45v2.45zm2.99 1.08h-3.53a.54.54 0 0 1-.54-.54v-3.53a.54.54 0 0 1 .54-.54h3.53a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.54zm4.055-1.08h2.45v-2.45h-2.45v2.45zm2.99 1.08h-3.53a.54.54 0 0 1-.54-.54v-3.53a.54.54 0 0 1 .54-.54h3.53a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.54zM6.424 23.402h2.45v-2.45h-2.45v2.45zm2.99 1.079h-3.53a.54.54 0 0 1-.539-.54v-3.529a.54.54 0 0 1 .54-.54h3.529a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.539zm4.122-1.079h2.45v-2.45h-2.45v2.45zm2.99 1.079h-3.53a.54.54 0 0 1-.54-.54v-3.529a.54.54 0 0 1 .54-.54h3.53a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.539zm4.055-1.079h2.45v-2.45h-2.45v2.45zm2.99 1.079h-3.53a.54.54 0 0 1-.54-.54v-3.529a.54.54 0 0 1 .54-.54h3.53a.54.54 0 0 1 .54.54v3.53a.54.54 0 0 1-.54.539z" />
        </g>
    </svg>
);

export default Birthday;
