import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { inject } from 'mobx-react';
import { translate } from 'react-i18next';

import HttpService from 'Services/HttpService';
import LoggedRouter from 'Routers/Logged';
import { DashboardHeader, DashboardNavigation } from 'Components/Dashboard';
import { Information } from 'Components/Icons';
import Feedback from './Feedback';

@inject('uiStore', 'dashboardStore')
class Dashboard extends React.PureComponent {
    state = { stripe: null, feedbackToggle: false };

    componentDidMount() {
        const {
            match,
            history,
            location,
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;

        this.cancellation = HttpService.cancellable();
        this.getPaymentAPIKeys();

        dashboardStore.load(loadingStore);

        if (location.pathname === match.url) history.replace(`${match.url}/company`);
    }

    componentWillUnmount() {
        this.props.dashboardStore.cancel();
        this.cancellation.cancel();
    }

    getPaymentAPIKeys = async () => {
        try {
            const { dashboardStore } = this.props;
            const { data } = await HttpService.apiInfo(this.cancellation);
            this.setState({ stripe: window.Stripe(data.stripePublicKey) });
            dashboardStore.plaidConfig = {
                enabled: data.plaidEnabled,
                publicKey: data.plaidPublicKey,
                environment: data.plaidEnvironment.toLowerCase(),
            };
        } catch (err) {}
    };

    loadMemberships = () => {
        const {
            uiStore: { loadingStore },
            dashboardStore,
        } = this.props;
        dashboardStore.loadMemberships(loadingStore);
    };

    feedbackToggle = () => {
        this.setState((prevState) => ({
            feedbackToggle: !prevState.feedbackToggle,
        }));
    };

    render() {
        const { match, history, t } = this.props;

        return (
            <StripeProvider stripe={this.state.stripe}>
                <div id="base" className="d-flex flex-column">
                    <DashboardHeader matchUrl={match.url} history={history} />
                    <div className="container flex-grow-1">
                        <DashboardNavigation
                            match={match}
                            onReloadMemberships={this.loadMemberships}
                        />
                        <LoggedRouter />
                    </div>
                    <div className="d-flex text-uppercase py-1 px-4 border-top text-xs justify-content-between align-items-center">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.feedbackToggle}
                        >
                            <Information size="1.25em" />
                            <span className="pl-2">{t('Feedback.feedback_title')}</span>
                        </button>
                        <div>
                            <a
                                href={t('Settings.terms_of_use_url')}
                                className="text-warm-grey mr-4"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('Settings.terms_of_use')}
                            </a>
                            <a
                                href={t('Settings.privacy_policy_url')}
                                className="text-warm-grey"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('Settings.privacy_policy')}
                            </a>
                        </div>
                    </div>
                    <Feedback
                        parentMatchURL={match.url}
                        toggle={this.state.feedbackToggle}
                        onClose={this.feedbackToggle}
                    />
                </div>
            </StripeProvider>
        );
    }
}

export default translate()(Dashboard);
