import React from 'react';

const Edit = ({ size, fill, className }) => {
    return (
        <svg
            className={`icon ${className || ''}`}
            viewBox="0 0 24 24"
            width={size || '1em'}
            height={size || '1em'}
        >
            <g fill="none" fillRule="evenodd">
                <path
                    fill={fill || 'currentColor'}
                    d="M8.84 17.265l-4.03 2.44c-.366.17-.696-.183-.525-.526l2.439-4.032 2.116 2.118zm7.09-11.849l2.652 2.618-8.43 8.435-2.634-2.636 8.411-8.417zm2.652-2.654a.745.745 0 0 1 1.055 0l1.58 1.58a.747.747 0 0 1 0 1.056l-1.58 1.581-2.598-2.674 1.543-1.543zm-12.5 10.79L2.858 20.08c-.27.735.318 1.394 1.053 1.052l6.524-3.227c.281-.055.554-.163.772-.38l11.591-11.6a1.492 1.492 0 0 0 0-2.11L20.163 1.18a1.489 1.489 0 0 0-2.107 0L6.464 12.78c-.218.218-.327.491-.381.772zM22.5 9v12c0 .824-.814 1.509-1.637 1.509H2.98a1.49 1.49 0 0 1-1.49-1.491V3.124C1.49 2.3 2.176 1.5 3 1.5h12V0H3C1.354 0 0 1.476 0 3.124v17.894A2.981 2.981 0 0 0 2.98 24h17.882C22.509 24 24 22.647 24 21V9h-1.5z"
                />
            </g>
        </svg>
    );
};

export default Edit;
