import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { observer } from 'mobx-react';
import moment from 'moment';

import DateControls from 'WebApp/Views/Logged/Booking/FlexibleBooking/DateControls';
import FlexibleBooking from 'WebApp/Views/Logged/Booking/FlexibleBooking/FlexibleBooking';
import {
    generateResourcePeriod,
    generateResourceHeader,
} from 'WebApp/Views/Logged/Booking/TimeSlotStep';
import HttpService from 'Services/HttpService';

@observer
class RecurrenceConflictTimeSlotStep extends Component {
    state = {
        selectedDate: null,
        endDate: null,
        amenities: [],
        currentConflict: null,
    };

    componentDidMount() {
        const { store } = this.props;

        const conflict = store.currentConflict;
        this.setState(
            {
                currentConflict: {
                    bookingStart: conflict.periodStart,
                    bookingEnd: conflict.periodEnd,
                    conferenceRoomId: conflict.amenityId,
                    reason: conflict.reason,
                    inProgress: conflict.inProgress,
                },
                selectedDate: moment(conflict.periodStart).format('YYYY-MM-DD'),
            },
            () => {
                this.loadBookings();
                this.loadDates();
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.loadBookings();
        }
    }

    loadBookings = async () => {
        const { store } = this.props;
        this.dailyBookingSummaryCancellable = HttpService.cancellable();

        const request = {
            date: this.state.selectedDate,
            creditBalanceId: store.bookingStore.creditBalanceId,
        };

        try {
            const { data } = await HttpService.dailyBookingSummary(
                {
                    id: store.bookingStore.campus.id,
                    request,
                },
                this.dailyBookingSummaryCancellable
            );
            this.setState({
                amenities: data.amenities,
            });

            if (this.initialLoad) {
                this.initialLoad = false;
            }
        } catch (err) {}
    };

    loadDates = async () => {
        if (this.datesCancellable) this.datesCancellable.cancel();
        this.datesCancellable = HttpService.cancellable();
        const { store } = this.props;
        try {
            this.setState({ availableDays: { loading: true } });
            const { data } = await HttpService.bookingMaxDate(
                {
                    id: store.bookingStore.creditBalanceId,
                    campusId: store.bookingStore.campus.id,
                },
                this.datesCancellable
            );
            const endDate = moment(data).add(1, 'day');
            this.setState({ endDate });
        } catch (err) {}
    };

    handleDateChange = (selectedDate) => {
        this.setState({ selectedDate: moment(selectedDate).format('YYYY-MM-DD') });
    };

    handleTimeCellClick = (e, bookingInformation, period) => {
        this.setState({ currentBooking: bookingInformation });
        this.nextStep(bookingInformation);
    };

    nextStep = (bookingInformation) => {
        const { store, onComplete } = this.props;
        store.updateConflict(store.currentConflictId, {
            periodStart: bookingInformation.bookingStart,
            periodEnd: bookingInformation.bookingEnd,
            bookingAmenity: bookingInformation.bookingAmenity,
            amenityId: bookingInformation.conferenceRoomId,
            inProgress: true,
        });
        onComplete();
    };

    render() {
        const { store, t } = this.props;
        const conflict = store.currentConflict;
        const showConflict =
            moment.parseZone(store.currentConflict.periodStart).format('YYYY-MM-DD') ===
            this.state.selectedDate;
        return conflict ? (
            <div className="flex-grow-1 d-flex flex-column">
                <div className="FlexibleBooking__actions d-flex align-items-center justify-content-center mb-3">
                    <DateControls
                        selectedDate={this.state.selectedDate}
                        onChange={this.handleDateChange}
                        maxDate={this.state.endDate}
                        showTodayButton={false}
                    />
                </div>
                {this.state.amenities.length > 0 && (
                    <FlexibleBooking
                        className="w-100 flex-grow-1 position-relative absolute-calendar"
                        view="bookings"
                        resources={this.state.amenities}
                        currentConflict={showConflict ? this.state.currentConflict : null}
                        timezone={this.props.timezone}
                        resourceHeader={generateResourceHeader(t)}
                        resourcePeriod={generateResourcePeriod}
                        onTimeCellClick={this.handleTimeCellClick}
                        min={0}
                        max={24}
                    />
                )}
            </div>
        ) : null;
    }
}

export default translate()(RecurrenceConflictTimeSlotStep);
