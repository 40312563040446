import { action, observable } from 'mobx';
import Constants from 'Data/Constants';
import HttpService from 'Services/HttpService';
import RootStore from './RootStore';
import Invitations from 'Services/Invitations';

const { NOTIFICATION_TYPE, HOLDER_TYPE } = Constants;
const {
    uiStore: { toastStore },
    dashboardStore,
} = RootStore;

class MessageStore {
    @observable error = null;
    @observable cancellation = null;

    @action
    isAvailable = async (messageType, messageId, history, t) => {
        try {
            switch (messageType) {
                case NOTIFICATION_TYPE.PROPOSAL:
                    return this.fetchSubscription(messageId, history, t);
                case NOTIFICATION_TYPE.INVOICE:
                    return this.fetchInvoice(messageId, history, t);
                case NOTIFICATION_TYPE.INVITATION:
                    return this.fetchInvitation(messageId, history, t);
                default:
                    history.goBack();
                    return null;
            }
        } catch (err) {
            this.unavailable(err, t);
        }
    };

    @action
    fetchSubscription = async (id, history, t) => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        try {
            const { data } = await HttpService.subscription(id, this.cancellation);
            history.replace(
                `/dashboard/company/detail/${data.membershipId}/subscriptions/${data.id}`
            );
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.unavailable(err, t);
        }
    };

    @action
    fetchInvitation = async (id, history, t) => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();
        try {
            await HttpService.membershipInvitation(id, this.cancellation);
            const proposal = dashboardStore.proposals.data.find(
                (proposal) => proposal.id === id
            );
            if (proposal) Invitations.invitationDetail(proposal, t);
            else history.replace(`/dashboard/company/detail/${id}`);
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.unavailable(err, t);
        }
    };

    @action
    fetchInvoice = async (id, history, t) => {
        if (this.cancellation) this.cancellation.cancel();
        this.cancellation = HttpService.cancellable();

        try {
            const { data } = await HttpService.invoice(id, this.cancellation);

            if (data.paymentCustomer.type === HOLDER_TYPE.MEMBERSHIP)
                history.replace(
                    `/dashboard/company/detail/${data.paymentCustomer.id}/invoices/${data.id}`
                );
            else if (data.paymentCustomer.type === HOLDER_TYPE.PERSONAL)
                history.replace(`/dashboard/personal/invoices/${data.id}`);
            else throw new Error();
        } catch (err) {
            if (this.cancellation.isCancelled(err)) return;
            this.unavailable(err, t);
        }
    };

    @action
    unavailable = (err, t) => {
        if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.includes('E004008')
        ) {
            this.error = err.response;
        } else {
            this.error = { message: t('Message.see_detail_unavailable') };
        }
        if (this.error.message)
            toastStore.enqueueErrorToast({
                message: this.error.message,
            });
    };
}

export default MessageStore;
