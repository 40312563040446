import React from 'react';

const Download = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g>
            <path
                fill={fill || 'currentColor'}
                d="M22.044 15.52l-.002 1.725v4.872H1.85v-4.872l.011-1.726H0v8.322h23.894v-8.322z"
            />
            <path
                fill={fill || 'currentColor'}
                d="M10.92 0v14.442l-3.33-3.479-1.718 1.053 6.075 6.345 6.075-6.345-1.715-1.053-3.332 3.477V0z"
            />
        </g>
    </svg>
);

export default Download;
