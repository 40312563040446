import React from 'react';

const Settings = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        width={size || '1em'}
        height={size || '1em'}
        viewBox="0 0 144 144"
    >
        <path
            fill={fill || 'currentColor'}
            d="M54.67 144.13l-.11-17.83A56.67 56.67 0 0 1 33.43 114l-15.25 8.79L.78 92.66l15.39-8.91A57.55 57.55 0 0 1 15 72a58.78 58.78 0 0 1 1.38-12.59l-15.2-8.83 17.45-30.06 15.43 9A56.62 56.62 0 0 1 54.64 17.7L54.53.11l34.75-.24.1 17.82a57.19 57.19 0 0 1 21.18 12.38l15.26-8.8 17.4 30.07-15.44 8.91A57.92 57.92 0 0 1 129 72a58.82 58.82 0 0 1-1.38 12.58l15.17 8.8-17.4 30.06-15.48-9a56.17 56.17 0 0 1-20.58 11.79l.1 17.58zM34.31 107l1.52 1.51a51.13 51.13 0 0 0 22.27 13l2 .58.09 16.39 23.57-.16-.1-16.25 2.08-.56a50.59 50.59 0 0 0 21.78-12.47l1.52-1.47 14.26 8.25 11.8-20.4-14-8.1.52-2A53.6 53.6 0 0 0 123.39 72a52.22 52.22 0 0 0-1.51-12.5l-.5-2 14.2-8.19-11.81-20.41-14.08 8.1-1.52-1.5a51.44 51.44 0 0 0-22.32-13.03l-2-.57-.1-16.4-23.56.16.09 16.23-2 .58a51.21 51.21 0 0 0-21.89 12.47l-1.51 1.48-14.22-8.26L8.81 48.57l14 8.15-.52 2A53.61 53.61 0 0 0 20.56 72a52 52 0 0 0 1.5 12.44l.51 2L8.42 94.7l11.81 20.44zM72 95.58A23.58 23.58 0 1 1 95.55 72 23.61 23.61 0 0 1 72 95.58zM72 54a18 18 0 1 0 18 18 18 18 0 0 0-18-18z"
        />
    </svg>
);

export default Settings;
