import React from 'react';

const ConferenceRoom = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill || 'currentColor'} fillRule="evenodd">
            <path d="M1.833 2.666h19.991V.833H1.833v1.833zm1.055 15.27h17.881V3.499H2.888v14.439zm9.974 4.198a1.035 1.035 0 0 1-2.067 0 1.035 1.035 0 0 1 2.067 0zM22.657 0H1v3.498h1.055V18.77h9.33v1.551a1.87 1.87 0 0 0-1.423 1.813c0 1.029.838 1.866 1.867 1.866a1.868 1.868 0 0 0 1.866-1.866 1.87 1.87 0 0 0-1.478-1.826V18.77h9.385V3.498h1.055V0z" />
            <path d="M4.72 15.826h2.055v-2.054H4.721v2.054zm4.332-8.94h5.498V5.609H9.052v1.277zm7.83 8.94h2.054v-2.054h-2.054v2.054zm-6.108 0h2.11v-2.054h-2.11v2.054zM7.608 12.94H6.164v-2.055h5.22v2.055H9.941v3.72h3.776v-3.72h-1.5v-2.055h5.276v2.055h-1.444v3.72h3.72v-3.72h-1.443v-2.89h-6.109V7.72h3.165V4.776H8.22v2.943h3.165v2.332H5.331v2.888H3.888v3.72h3.72v-3.72z" />
        </g>
    </svg>
);

export default ConferenceRoom;
