import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import MembershipList from './MembershipList';
import MembershipDetail from './MembershipDetail';

class MembershipDashboard extends React.PureComponent {
    renderMembershipDetail = (renderProps) => (
        <MembershipDetail
            {...renderProps}
            membershipId={renderProps.match.params.membershipId}
        />
    );

    renderMembershipList = (renderProps) => (
        <MembershipList {...renderProps} parentMatchURL={this.props.match.url} />
    );

    render() {
        const { match } = this.props;

        return (
            <Fragment>
                <Switch>
                    <Route
                        path={`${match.url}/detail/:membershipId`}
                        render={this.renderMembershipDetail}
                    />
                    <Route path={match.url} render={this.renderMembershipList} />
                </Switch>
            </Fragment>
        );
    }
}

export default translate()(MembershipDashboard);
