import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Plan } from 'Components/Icons';

class FirstLogin extends React.PureComponent {
    static propTypes = {
        parentMatchURL: PropTypes.string.isRequired,
    };

    render() {
        const { t, parentMatchURL } = this.props;

        return (
            <Modal isOpen={true} className="modal-dialog-centered modal-dark">
                <div className="modal-header">
                    <h5 className="modal-title m-0" aria-hidden="true">
                        &nbsp;
                    </h5>
                </div>
                <ModalBody className="px-3 pb-6 px-md-11 pb-md-18 text-center">
                    <div className="text-primary mb-4 mt-9">
                        <Plan size="3.75em" />
                    </div>
                    <div className="text-sm font-weight-medium mb-13">
                        {t('Account.incite_subscribe_message')}
                    </div>
                    <div>
                        <Link
                            replace
                            className="btn btn-primary w-100 mb-3"
                            to={`${parentMatchURL}/subscribe-to-a-plan`}
                        >
                            {t('Membership.membership_subscribe_to_plan')}
                        </Link>
                        <Link replace className="btn btn-light w-100" to={parentMatchURL}>
                            {t('Account.skip_message')}
                        </Link>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default translate()(FirstLogin);
