import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import MemberCircle from 'Assets/Icons/member-circle.svg';
import Membership from 'Assets/Icons/membership.svg';
import { JobActions, JobDetail } from 'Components/Job';
import { ListItem } from 'Components/Forms';
import { ListGroup } from 'reactstrap';
import { Arrow, JobPostIcon } from 'Components/Icons';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Image } from 'Components/Misc';
import { observable } from 'mobx';
import LoadingStore from 'Stores/UI/LoadingStore';
import JobPost from 'WebApp/Views/Logged/JobPost/JobPost';
import HttpService from 'Services/HttpService';
import qs from 'qs';
import { LoadingSpinner, NoResults } from 'Components/Loading';
import RemoveJobPosts from 'WebApp/Views/Logged/JobPost/RemoveJobPosts';

@inject('uiStore')
@observer
class JobPosts extends React.Component {
    state = {
        jobPosts: [],
        jobPost: {},
        active: null,
        newJob: false,
        currentId: this.props.currentId,
        editToggle: false,
        removeToggle: false,
    };

    @observable localLoadingStore = new LoadingStore(this.props.uiStore.loadingStore);

    componentDidMount() {
        this.loadJobPosts(true);
    }

    loadJobPosts = async (openFirstJob = false, currentJobId) => {
        const { membership } = this.props;
        this.cancellation = HttpService.cancellable();
        let request = {};
        if (membership) {
            request = {
                request: {
                    membershipIds: [membership.id],
                },
            };
        } else {
            request = {
                request: {},
            };
        }
        this.localLoadingStore.addLoading();
        try {
            const { data } = await HttpService.jobPosts(
                qs.stringify(request),
                this.cancellation
            );
            this.setState({ jobPosts: data });
            if (openFirstJob) this.openFirstJob();
            if (currentJobId) this.updateSelectedJobPost(currentJobId);
        } catch (searchError) {
            if (this.cancellation.isCancelled(searchError)) return;
        } finally {
            this.localLoadingStore.removeLoading();
        }
    };

    openFirstJob = () => {
        const { jobPosts, currentId } = this.state;
        this.setState({ active: currentId || jobPosts.items[0].id });
    };

    reloadJobPosts = (closeToggle = true, currentJobId) => {
        if (closeToggle) this.editToggle();
        if (currentJobId) {
            this.loadJobPosts(false, currentJobId);
        } else {
            this.setState({ currentId: null, removeToggle: false });
            this.loadJobPosts(true);
        }
    };

    addJobPost = () => {
        this.editToggle(null, true);
    };

    removeJobPost = () => {
        this.setState((prevState) => ({
            removeToggle: !prevState.removeToggle,
        }));
    };

    editToggle = (e, newJob = false) => {
        this.setState((prevState) => ({
            editToggle: !prevState.editToggle,
            newJob,
        }));
    };

    updateSelectedJobPost = (selectedJobPostId) => {
        const { jobPosts } = this.state;
        this.setState({
            active: selectedJobPostId,
            jobPost: jobPosts.items.find((jobPost) => jobPost.id === selectedJobPostId),
        });
    };

    render() {
        const { parentMatchURL, activeTab, t, membership } = this.props,
            { active, editToggle, jobPosts, newJob, jobPost, removeToggle } = this.state;
        if (!jobPosts) return null;
        return (
            <Fragment>
                {jobPosts && jobPosts.items && jobPosts.items.length ? (
                    <Fragment>
                        <div className="col-md-6 position-relative">
                            {jobPosts.canCreate && (
                                <div className="position-relative">
                                    <JobActions
                                        addJobPost={this.addJobPost}
                                        canDelete={jobPosts.items.some(
                                            (jobPost) => jobPost.canEdit
                                        )}
                                        removeJobPost={this.removeJobPost}
                                    />
                                </div>
                            )}
                            <div id="infinite-list" className="position-absolute">
                                <ListGroup tag="div" className="list-group-compact">
                                    {jobPosts.items.map((jobPost) => (
                                        <ListItem
                                            key={jobPost.id}
                                            id={jobPost.id}
                                            withImage
                                            className={classnames(
                                                'default list-group-item-action',
                                                { 'active-item': active === jobPost.id }
                                            )}
                                            clickable
                                            onClick={this.updateSelectedJobPost}
                                            defaultImgSrc={Membership}
                                            title={jobPost.title}
                                            subTitle={
                                                <Fragment>
                                                    <span className="rounded-picture-list">
                                                        <span className="rounded-picture-list-item">
                                                            <Image
                                                                imgSrc={
                                                                    jobPost.userCreated
                                                                        .imageUrl
                                                                }
                                                                defaultImgSrc={
                                                                    MemberCircle
                                                                }
                                                            />
                                                        </span>
                                                    </span>
                                                    <span>{` ${t('by')} ${
                                                        jobPost.userCreated.firstName
                                                    } ${
                                                        jobPost.userCreated.lastName
                                                    }`}</span>
                                                </Fragment>
                                            }
                                            imgSrc={jobPost.membership.imageUrl}
                                            action={
                                                <Arrow
                                                    size="1.5em"
                                                    className="text-primary"
                                                />
                                            }
                                        />
                                    ))}
                                </ListGroup>
                            </div>
                        </div>
                        <div className="col-md-6 position-relative">
                            <JobDetail
                                parentMatchURL={parentMatchURL}
                                paneDetail
                                localLoadingStore={this.localLoadingStore}
                                jobPostId={active}
                                jobPost={jobPost}
                                onEdit={this.editToggle}
                                activeTab={activeTab}
                                reloadJobPosts={this.reloadJobPosts}
                            />
                        </div>
                        {removeToggle && (
                            <RemoveJobPosts
                                {...this.props}
                                isOpen={removeToggle}
                                jobPosts={jobPosts.items}
                                reloadJobPosts={this.reloadJobPosts}
                                onClose={this.removeJobPost}
                            />
                        )}
                    </Fragment>
                ) : (
                    <div className="col-12">
                        <NoResults
                            className="m-auto py-3 h-auto"
                            icon={<JobPostIcon size="3.75em" />}
                            title={t('JobBoard.empty_job_post_title')}
                            subTitle={t('JobBoard.empty_job_post_message')}
                            action={{
                                text: t('JobBoard.create_job_post_title'),
                                onClick: this.addJobPost,
                            }}
                        />
                    </div>
                )}
                {editToggle && (
                    <JobPost
                        {...this.props}
                        onClose={this.editToggle}
                        reloadJobPosts={this.reloadJobPosts}
                        jobId={active}
                        newJob={newJob}
                        toggle={editToggle}
                        membership={membership}
                    />
                )}
                <LoadingSpinner loading={this.localLoadingStore.isLoading} global />
            </Fragment>
        );
    }
}

export default translate()(JobPosts);
