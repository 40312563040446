import React from 'react';

const Back = ({ fill = 'currentColor', width = '1em', height = '1em' }) => (
    <svg className="icon" viewBox="0 0 24 24" width={width} height={height}>
        <path
            fill={fill}
            fillRule="evenodd"
            d="M23.28 12.414c0 .625-.497 1.122-1.122 1.122h-.016L4.188 13.52l6.38 6.444c.433.449.433 1.17-.016 1.603a1.13 1.13 0 0 1-.801.337 1.13 1.13 0 0 1-.802-.337L.694 13.231a1.104 1.104 0 0 1-.177-.208 1.124 1.124 0 0 1 .145-1.443l8.207-8.255c.433-.433 1.154-.433 1.603 0 .449.448.449 1.17 0 1.603l-6.284 6.316 17.97.032c.625 0 1.122.497 1.122 1.138"
        />
    </svg>
);

export default Back;
