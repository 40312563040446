import { action, computed, observable } from 'mobx';
import memoize from 'memoize-one';
import Constants from 'Data/Constants';
import UpdateStateStoreUtils from 'Utils/UpdateStateStoreUtils';

class SubscriptionStore {
    @observable membershipId = '';
    @observable campusId = '';
    @observable planId = '';
    @observable addonIds = null;
    @observable paymentMethodUpdated = false;
    @observable isSubscription = true;
    @observable dateObj = null;
    @observable modalType = '';
    @observable paymentMethod = {
        status: '',
        type: '',
    };

    dayPassSubscription = false;
    nbPlansAvailable = null;
    membership = null;
    campus = null;
    plan = null;

    _isDayPass = memoize(
        (plan) => plan && plan.interval === Constants.PLAN_INTERVALS.DAILY
    );

    get isDayPass() {
        return this._isDayPass(this.plan);
    }

    @computed get date() {
        if (this.dateObj) return this.dateObj.toISOString();

        return null;
    }

    @action updateState(key, value) {
        if (key === 'campusId' && this.campusId !== value) {
            this.updateState('planId', '');
        }

        if (key === 'planId' && this.planId !== value) {
            this.updateState('addonIds', null);
            this.updateState('dateObj', null);
            this.updateState('modalType', Constants.EVENT_TYPE.SUBSCRIPTION);
        }

        if (key === 'paymentMethodUpdated' && !this.addonIds) {
            this.updateState('addonIds', []);
        }

        UpdateStateStoreUtils(this, key, value);
    }

    @computed get isComplete() {
        return !!(
            this.membershipId &&
            this.campusId &&
            this.planId &&
            (this.isDayPass ? !!this.dateObj : true)
        );
    }
}

export default SubscriptionStore;
