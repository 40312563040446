import React from 'react';

const Checkbox = ({ fill, size, className, stroke }) => (
    <svg
        viewBox="0 0 32 32"
        width={size}
        height={size}
        className={`icon ${className || ''}`}
    >
        <g transform="translate(4 4)" fill="none" fillRule="evenodd">
            <rect fill={fill} width={24} height={24} rx={2} stroke={stroke} />
            <path
                d="M16.552 6.85a.75.75 0 0 0-1.025.275l-4.796 8.308-2.684-2.475a.751.751 0 0 0-1.094 1.026l3.429 3.162a.751.751 0 0 0 1.06.035c.091-.086 5.384-9.306 5.384-9.306a.75.75 0 0 0-.274-1.024"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Checkbox;
