import { inject } from 'mobx-react';
import { translate } from 'react-i18next';
import qs from 'qs';

import HttpService from 'Services/HttpService';

import ActionBase from './ActionBase';
import Constants from 'Data/Constants';

const { PAYMENT_METHOD_TYPES, ACH_STATUS } = Constants;

async function isPaymentMethodInvalid(id, token) {
    const { data: subscription } = await HttpService.fetchSubscriptionByToken(
        id,
        null,
        token
    );
    return !!(
        subscription.isChargedAutomatically &&
        (subscription.paymentMethod === null ||
            (subscription.paymentMethod &&
                subscription.paymentMethod.status === ACH_STATUS.UNVERIFIED &&
                subscription.paymentMethod.paymentMethodType ===
                    PAYMENT_METHOD_TYPES.ACH))
    );
}

async function handleSubscriptionActionError(error) {
    if (error.response) {
        if (error.response.status === 404) {
            this.setState({
                loginTextKey: 'Subscription.new_subscription_request',
                loginPath: '/dashboard/company/subscribe-to-a-plan',
                resultMessageKey: 'Subscription.subscription_already_declined',
            });
            return true;
        } else if (
            error.response.status === 400 &&
            error.response.data &&
            (error.response.data.error === 'E008016' ||
                error.response.data.error === 'E004016')
        ) {
            const { location } = this.props;
            const params = qs.parse(location.search, { ignoreQueryPrefix: true });

            if (error.response.data.error === 'E004016') {
                this.setState({
                    loginTextKey: 'Company.add_contact_person_title',
                    loginPath: `/dashboard/company/detail/${params.membershipId}/edit-legal-contact`,
                    resultMessageKey:
                        'Subscription.subscription_accepted_no_legal_contact',
                });

                this.props.uiStore.subscriptionUrl = `/dashboard/company/detail/${params.membershipId}/subscriptions/${params.subscriptionId}`;
            } else {
                this.setState({
                    loginTextKey: 'Subscription.view_subscription',
                    loginPath: `/dashboard/company/detail/${params.membershipId}/subscriptions/${params.subscriptionId}`,
                    resultMessageKey: 'Subscription.subscription_already_accepted',
                });
            }

            return true;
        } else if (error.response.config &&
            error.response.config.headers &&
            error.response.config.headers['Limited-Access-Token']) {
                this.setState({
                    resultMessageKey: 'Error.expired_link',
                    loginTextKey: 'Account.login',
                    loginPath: '/',
                });
            return true;
        } else if (error.response.status) {
            this.setState({
                resultMessageKey: 'Error.error_message',
            });
            return true;
        }
    }
    return false;
}

@inject('uiStore')
class AcceptSubscriptionProposal extends ActionBase {
    constructor(props) {
        super(props);
        this.actionCall = async () => {
            const { data: consentInfo } = await HttpService.achConsent();

            const params = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            
            await HttpService.acceptSubscriptionByToken({
                token: this.token,
                subscriptionId: params.subscriptionId,
                consentVersion: consentInfo.version,
                ignoreErrorToast: true,
            });

            this.setState({
                loginTextKey: 'Account.login',
                resultMessageKey: 'Subscription.subscription_accepted_confirmation',
            });
        };

        this.handleError = handleSubscriptionActionError.bind(this);
    }

    prepareState = (params) => {
        this.setState({
            loginPath: `/dashboard/company/detail/${params.membershipId}/subscriptions/${params.subscriptionId}`,
        });
    };
}

export { handleSubscriptionActionError };

export default translate()(AcceptSubscriptionProposal);
