import React from 'react';

const Conflict = (props) => (
    <svg
        className="icon"
        viewBox="0 0 24 24"
        width={props.size || '1em'}
        height={props.size || '1em'}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M23.934 21.717L12.43 1.792a.496.496 0 0 0-.864 0L.068 21.717a.484.484 0 0 0 0 .496.496.496 0 0 0 .428.247h23.008a.496.496 0 0 0 .43-.743zm-22.579-.248L12.002 3.031 22.646 21.47z"
        />
        <path
            fill={props.fill || 'currentColor'}
            d="M12.002 16.962a1.439 1.439 0 1 0 1.437 1.44 1.44 1.44 0 0 0-1.437-1.44zm0 2.004a.565.565 0 1 1 .563-.57.567.567 0 0 1-.563.57zm1.321-9.683a.435.435 0 0 0-.313-.135h-2.018a.44.44 0 0 0-.436.454l.25 6.402a.435.435 0 0 0 .43.42h1.517a.433.433 0 0 0 .435-.42l.251-6.402a.433.433 0 0 0-.116-.319zm-.99 6.267h-.672l-.214-5.528h1.11z"
        />
    </svg>
);

export default Conflict;
