import React from 'react';

const Calendar = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 144 144"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            fill={fill || 'currentColor'}
            d="M135.28 10.32h-33.14V2.45a2.8 2.8 0 0 0-5.6 0v7.87H47.1V2.45a2.79 2.79 0 1 0-5.58 0v7.87H8.71a2.79 2.79 0 0 0-2.79 2.79v128.44a2.79 2.79 0 0 0 2.79 2.8h126.57a2.8 2.8 0 0 0 2.8-2.8V13.11a2.79 2.79 0 0 0-2.8-2.79zM11.51 15.91h30v7.48a2.79 2.79 0 1 0 5.58 0v-7.48h49.45v7.48a2.8 2.8 0 0 0 5.6 0v-7.48h30.35V37h-121zm121 122.85h-121v-96.2h121z"
        />
        <path
            fill={fill || 'currentColor'}
            d="M44.31 83.93H26a2.79 2.79 0 0 1-2.79-2.79V62.86A2.79 2.79 0 0 1 26 60.07h18.31a2.79 2.79 0 0 1 2.79 2.79v18.28a2.79 2.79 0 0 1-2.79 2.79zm-15.48-5.59h12.68V65.66H28.83zM81.14 83.93H62.86a2.8 2.8 0 0 1-2.8-2.79V62.86a2.8 2.8 0 0 1 2.8-2.79h18.28a2.79 2.79 0 0 1 2.79 2.79v18.28a2.79 2.79 0 0 1-2.79 2.79zm-15.49-5.59h12.69V65.66H65.65zM117.62 83.93H99.34a2.79 2.79 0 0 1-2.79-2.79V62.86a2.79 2.79 0 0 1 2.79-2.79h18.28a2.8 2.8 0 0 1 2.8 2.79v18.28a2.8 2.8 0 0 1-2.8 2.79zm-15.48-5.59h12.69V65.66h-12.69zM44.31 121.25H26a2.79 2.79 0 0 1-2.79-2.8v-18.27a2.79 2.79 0 0 1 2.79-2.8h18.31a2.79 2.79 0 0 1 2.79 2.8v18.27a2.79 2.79 0 0 1-2.79 2.8zm-15.48-5.59h12.68V103H28.83zM81.14 121.25H62.86a2.8 2.8 0 0 1-2.8-2.8v-18.27a2.8 2.8 0 0 1 2.8-2.8h18.28a2.79 2.79 0 0 1 2.79 2.8v18.27a2.79 2.79 0 0 1-2.79 2.8zm-15.49-5.59h12.69V103H65.65zM117.62 121.25H99.34a2.79 2.79 0 0 1-2.79-2.8v-18.27a2.79 2.79 0 0 1 2.79-2.8h18.28a2.8 2.8 0 0 1 2.8 2.8v18.27a2.8 2.8 0 0 1-2.8 2.8zm-15.48-5.59h12.69V103h-12.69z"
        />
    </svg>
);

export default Calendar;
