import React from 'react';

const RecurringBooking = ({ fill, size, className, cssFill }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <path
            d="M24 10.398l-2.723 2.877-2.724-2.877h2.492C20.457 5.947 16.64 2.5 12.03 2.5A9.117 9.117 0 0 0 4.1 7.138l-.872-.49A10.116 10.116 0 0 1 12.03 1.5c5.16 0 9.43 3.893 10.022 8.898H24zm-3.408 6.545a10.044 10.044 0 0 1-8.562 4.745c-4.905 0-9.003-3.515-9.908-8.158H0l2.723-2.877 2.724 2.877H3.143c.889 4.087 4.535 7.158 8.887 7.158a9.052 9.052 0 0 0 7.713-4.275l.849.53z"
            fill={fill || 'currentColor'}
            style={cssFill ? { fill: cssFill } : undefined}
            fillRule="evenodd"
        />
    </svg>
);

export default RecurringBooking;
