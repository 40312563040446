import React from 'react';
const CollapseArrow = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="0 0 24 24"
        width={size || '1em'}
        height={size || '1em'}
    >
        <g fill={fill} fillRule="evenodd">
            <circle opacity={0.3} cx={12} cy={12} r={12} />
            <path d="M12 15.667L7 9h10z" />
        </g>
    </svg>
);

export default CollapseArrow;
