import React from 'react';
import { translate } from 'react-i18next';

import { Ach, CellPhone, Information } from 'Components/Icons';
import colorVariables from 'Sass/variables.scss';

const ACHExplanations = (props) => {
    const { t } = props;

    return (
        <div className="d-flex flex-column align-item-center py-2 px-5 px-xl-15">
            <Information
                fill={colorVariables.secondary}
                size="1.75em"
                className="d-block mx-auto mb-2 mt-2"
            />
            <p
                className="text-center mb-3"
                dangerouslySetInnerHTML={{ __html: t('PaymentMethod.ach_info_step_one') }}
            />
            <div className="divider-v mt-1 mb-2" />
            <Ach
                fill={colorVariables.secondary}
                size="1.75em"
                className="d-block mx-auto mb-2"
            />
            <p
                className="text-center mb-3"
                dangerouslySetInnerHTML={{ __html: t('PaymentMethod.ach_info_step_two') }}
            />
            <div className="divider-v mt-1 mb-2" />
            <CellPhone
                fill={colorVariables.secondary}
                size="1.75em"
                className="d-block mx-auto mb-2"
            />
            <p
                className="text-center mb-6"
                dangerouslySetInnerHTML={{
                    __html: t('PaymentMethod.ach_info_step_three'),
                }}
            />
        </div>
    );
};

export default translate()(ACHExplanations);
